.ResultZeroGridEFeedback-tabHeader {
  color: @c24-color-black;
  display: flex;
  align-items: center;
  padding: 10px 0px;

  &.is-clickable {
    cursor: pointer;
  }

  &.is-selected {
    padding-bottom: 5px;
    border-bottom: 3px solid @c24-color-malaga-grey !important;
  }

  &:hover {
    text-decoration: underline;
  }
}

.ResultZeroGridEFeedback-tabArrow {
  display: block;
  width: 17px;
  height: 17px;
  fill: @c24-color-nobel-grey;
  margin-left: 5px;
  margin-left: 1px;

  transition: transform 0.3s ease;
  transform: rotate(90deg);

  &.is-open {
    transform: rotate(270deg);
  }
}

.ResultZeroGridEFeedback-tabHeaderContent {
  display: flex;
  align-items: center;

  & .EFeedbackRatingStars {
    width: 78px;
  }
}

.ResultZeroGridEFeedback-EFeedbackTooltip {
  align-self: center;

  .Tooltip {
    width: 380px;
    text-align: left;
    cursor: default;
  }

  & .EFeedbackRatingStars {
    width: 78px;
  }

  & .EFeedbackRatingStars-star {
    margin-right: -3px;
  }

  & .EFeedbackRatingStars-starsContainer {
    top: 1px !important;
  }
}

.ResultZeroGridEFeedback-ratingStars {
  margin-right: 0px;

  // & .EFeedbackRatingStars {
  //   width: 78px;
  // }

  & .EFeedbackRatingStars-star {
    margin-right: -3px;
  }
}
