.InitialInformationOverlay-pageWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.is-blurred {
    filter: blur(5px);
    z-index: 100;
    height: 100vh;
    overflow: hidden;
  }
}
