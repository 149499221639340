.TestReports {
  background-color: @c24-color-white;
  border-top: @c24-border-default;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;

  &.is-detailsPage {
    padding: 0;
    border: none;
  }
}

.TestReports-container {
  padding: 15px 20px;
  border-bottom: @c24-border-default;
  display: flex;

  &.is-last {
    border: none;
  }
}

.TestReports-content {
  flex: 1;
}

.TestReports-logo {
  width: 120px;
  margin-right: 30px;
  height: 100%;
}

.TestReports-logoTooltip {
  .Tooltip {
    width: 220px;
    padding: 15px;
    padding-right: 25px;
  }
}

.TestReports-TooltipLogo {
  width: 100%;
}

.TestReports-reportDate {
  color: @c24-color-black;
  margin-bottom: 5px;
}

.TestReports-title {
  color: @c24-color-black;
  margin-bottom: 5px;
  font-weight: 700;
}

.TestReports-text {
  box-sizing: border-box;
  line-height: 1.42;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.TestReports-text p,
.TestReports-text ol,
.TestReports-text ul,
.TestReports-text pre,
.TestReports-text blockquote,
.TestReports-text h1,
.TestReports-text h2,
.TestReports-text h3,
.TestReports-text h4,
.TestReports-text h5,
.TestReports-text h6 {
  margin: auto 0;
  padding: auto 0;
}

.TestReports-closeButton {
  float: right;
  margin-top: 10px;
}

.TestReports-closeIcon {
  height: 15px;
  width: 15px;

  path {
    fill: @c24-color-nobel-grey;
  }
}
