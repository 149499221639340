.InputChips {
  padding-top: 10px;
  margin-bottom: -10px;
}

.InputChips--withoutPadding {
  padding-top: 0px;
}

.InputChip {
  height: 30px;
  border-radius: 15px;
  padding: 5px 9px;
  font-size: 13px;
  background-color: @c24-color-white;
  cursor: pointer;
  user-select: none;
  border: @c24-border-gainsboro-grey;
  transition: border-color 0.3s;
  will-change: border-color;
  cursor: pointer;

  margin-bottom: 10px;

  &.is-selected {
    background-color: @c24-color-light-blue;
    border: @c24-border-dark-blue;

    &.is-recommended {
      border-color: @c24-color-dark-green;
      background-color: @c24-color-dark-green-alpha-15;
    }
  }

  &.is-selected:hover {
    border: @c24-border-dark-blue;
  }

  &:hover {
    border: @c24-border-input-hover;
  }
}

.InputChip-arrow {
  width: 100%;
  height: 100%;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: @c24-color-gainsboro-grey;
  background-color: @c24-color-white;
  background-size: cover;
  position: absolute;

  &.is-selected {
    border-color: @c24-color-dark-green;
    background-color: @c24-color-dark-green-alpha-15;
  }
}

.InputChip-arrowBackground {
  width: 14px;
  height: 14px;
  transform: rotate(44deg);
  position: relative;
  top: -3px;
  background-color: @c24-color-white;
}

.InputChip-wrapper {
  display: inline-flex;
  margin-right: 10px;
  flex-direction: column;
  align-items: center;

  &:last-of-type {
    margin-right: 0px;
  }
}
