.ComparisonDetails {
  border: @c24-border-comparison-features;
  border-bottom: none;
  border-top: none;
}

.ComparisonDetails-feature {
  border-bottom: @c24-border-comparison-features;
  display: flex;
  position: relative;

  &:hover {
    box-shadow: 0 0 5px 0 #999999;
    z-index: 500;
  }
}

.ComparisonDetails-featureTitle {
  background-color: @c24-color-background-dark-grey;
  position: relative;
  display: flex;
  flex: 1;
  font-size: 11px;
  padding-top: 10px;

  @media @c24-wide {
    font-size: 12px;
  }
}

.ComparisonDetails-featureName {
  width: 98%;
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
  display: flex;
}

.ComparisonDetails-tariffFeature {
  background-color: @c24-color-white;
  display: flex;
  align-items: flex-start;
  font-size: 11px;
  cursor: default;
  border-left: @c24-border-comparison-features;
  flex: 1;

  > div > svg {
    margin-top: 6px;
  }

  @media @c24-wide {
    font-size: 12px;
  }

  .Tooltip-Root {
    width: 60%;
    height: 100%;
    align-items: center;
  }
}

.ComparisonDetails-featureValue {
  display: inline-flex;
  display: flex;
  padding: 10px;

  > p {
    margin: 0px;
  }
}

.ComparisonDetails-numValue {
  margin-left: 4px;
  min-width: 52px;
  height: 20px;
  display: flex;
  justify-content: center;
}

.ComparisonDetails-tooltip {
  right: 5px;
  cursor: help;
}

.ComparisonDetails-tooltipIcon {
  width: 16px;
  height: 16px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
  margin-right: 1px;
}

.ComparisonDetails-tooltipIcon * {
  fill: @c24-color-question-mark;
}

.hover {
  .ComparisonDetails-tooltipIcon {
    fill: @c24-color-icon-active;
  }

  .ComparisonDetails-tooltipIcon * {
    fill: @c24-color-icon-active;
  }
}

.ComparisonDetails-featureIcon {
  > svg {
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 16px;
    margin-left: 20px;
  }
}
