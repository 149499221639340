.YearEndBanner {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
}

.YearEndBanner-banner {
  display: flex;
  color: @c24-color-black;
  position: relative;
  height: 95px;
  flex-grow: 1;
}

.YearEndBanner-image {
  width: 76px;
  height: 91px;
}

.YearEndBanner-contentBox {
  -webkit-text-size-adjust: none; // disable font resize in safari
  flex-grow: 1;
  color: @c24-color-white;
  background-color: @c24-color-gold;
  display: flex;
  align-items: center;
  height: 68px;
  font-size: 14px;
  margin: 0px 0px 0px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Page.is-redesign {
  .YearEndBanner-content {
    font-size: 12px;
  }
}

.YearEndBanner-content {
  width: 100%;
}
