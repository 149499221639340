.TariffListOverlayProgressBar {
  .ProgressBar .ProgressBar-percent {
    font-size: 16px;
  }
}

.TariffListOverlayProgressBar-text {
  font-size: 22px;
  margin: 0;
  color: @c24-color-black;
}

.TariffListOverlayProgressBar-footer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 15px;
}

.TariffListOverlayProgressBar-footerContainer {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;

  .TariffListOverlayProgressBar-logoContainer:last-child {
    margin-right: 0;
  }
}

.TariffListOverlayProgressBar-logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TariffListOverlayProgressBar-logo {
  height: 75px;
  width: 85px;
}

.TariffListOverlayProgressBar-logoText {
  font-size: 16px;
  color: @c24-color-black;
  text-align: center;
  margin: 0;
}
