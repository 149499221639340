.EFeedbackRating-container {
  display: flex;
  color: @c24-color-dark-blue;
  cursor: pointer;
  margin-bottom: 10px;

  &.is-active {
    color: @c24-color-yellow;
  }
}

.EFeedbackRating-starsLabel {
  width: 60px;
}

.EFeedbackRating-bar {
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
  background-color: @c24-color-background-grey;
}

.EFeedbackRating-innerBar {
  background-color: @c24-color-dark-blue;
  height: 100%;

  &.is-active {
    background-color: @c24-color-yellow;
  }
}

.EFeedbackRating-ratingCount {
  width: 40px;
}
