@import '../../../styles/variables/colors.less';

.TariffListOverlayContactBox-contactBoxHeadline {
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 5px;
}

.TariffListOverlayContactBox-phoneSvg {
  fill: @c24-color-icon-default;
  height: 24px;
  width: 17px;
  margin-right: 11px;
  margin-left: 10px;
  margin-bottom: 0px;
}

.TariffListOverlayContactBox-emailSvg {
  fill: @c24-color-icon-default;
  height: 19px;
  width: 26px;
  margin-right: 10px;
  margin-top: 3px;
}

.TariffListOverlayContactBox-emailContainer {
  display: flex;
  margin-top: 8px;
}

.TariffListOverlayContactBox-contactBox {
  margin-top: 20px;
}

.TariffListOverlayContactBox-contactTextContainer {
  display: flex;
  align-items: center;
}

.TariffListOverlayContactBox-workingTimes {
  color: @c24-color-malaga-grey;
  line-height: 14px;
  margin-top: 3px;
  font-size: 12px;
}

.TariffListOverlayContactBox-email {
  font-size: 14px;
}
