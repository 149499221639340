.ResultTariffsSkeleton-tariffs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ResultTariffsSkeleton-tariff {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background-color: @c24-color-white;
  border-radius: 3px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.ResultTariffsSkeleton-tariffHeader {
  width: 261px;
  height: 24px;
  margin-bottom: 12px;
}

.ResultTariffsSkeleton-tariffBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 43px;
}

.ResultTariffsSkeleton-tariffLogo {
  width: 172px;
  height: 69px;
}

.ResultTariffsSkeleton-tariffGrade {
  width: 80px;
  height: 92px;
}

.ResultTariffsSkeleton-tariffFeatures {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ResultTariffsSkeleton-tariffFeatureRow1 {
  width: 208px;
  height: 36px;
}

.ResultTariffsSkeleton-tariffFeatureRow2 {
  width: 164px;
  height: 16px;
}

.ResultTariffsSkeleton-tariffFeatureRow3 {
  width: 257px;
  height: 19px;
}

.ResultTariffsSkeleton-tariffFeatureRow4 {
  width: 151px;
  height: 19px;
}

.ResultTariffsSkeleton-tariffPension {
  width: 171px;
  height: 79px;
}

.ResultTariffsSkeleton-tariffFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.ResultTariffsSkeleton-tariffFooterContent {
  width: 647px;
  height: 29px;
}

.ResultTariffsSkeleton-tariffButton {
  width: 197px;
  height: 42px;
}

.ResultTariffsSkeleton-skeletonBase {
  border-radius: 5px;
  background: @c24-color-athens-gray;

  &.is-animated {
    background: linear-gradient(
        120deg,
        rgba(221, 221, 221, 0) 30%,
        rgba(221, 221, 221, 0.5) 50%,
        rgba(221, 221, 221, 0) 70%
      )
      @c24-color-athens-gray;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s skeleton-loading ease-in-out infinite;
  }
}

@keyframes skeleton-loading {
  to {
    background-position-x: -20%;
  }
}
