.FeatureCategory {
  margin-bottom: 20px;
}

.FeatureCategory-headline {
  color: @c24-color-dark-blue;
  font-size: 14px;
  padding: 10px 10px 10px 0;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: @c24-color-tariff-details-tab-background;
  border: @c24-border-default;
  border-bottom: 0;
  padding-left: 10px;
}

.FeatureCategory-title {
  flex: 1;
}

.FeatureCategory-arrow {
  display: block;
  width: 16px;
  height: 16px;
  fill: @c24-color-dark-blue;

  &.is-open {
    transform: rotate(180deg);
  }
}

.FeatureCategory-detailsContainer {
  border-top: @c24-border-default;
  border-right: @c24-border-default;
  border-left: @c24-border-default;
}
