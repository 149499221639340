@import 'styles/variables/colors';
@import 'styles/variables/box-shadows';
@import 'styles/variables/borders';
@import 'styles/variables/backgrounds';

.Button {
  display: block;
  width: 100%;
  background-color: @c24-color-wireframe-dark-blue;
  padding: 10px;
  color: @c24-color-button-text;
  border-radius: 3px;
  font-size: 14px;
  font-family: Arial, Helvetica, Sans-Serif;
  font-weight: 700;
  border: 0 none;
  cursor: pointer;
  pointer-events: auto;
  text-align: center;

  // Button--secondary is never set by a prop on the Button element, rather it is set in 3 places with explicit class names
  // on the custom button components; ComparisonInquiryButton and InquiryButton.
  // For now lets leave this here and consider refactoring those buttons when the desktop redesign happens.
  &.Button--secondary {
    border: @c24-border-light-grey;
    background-color: @c24-color-white;
    color: @c24-color-malaga-grey;

    &:active,
    &:active:focus,
    &:hover,
    &:focus {
      background-color: @c24-color-background-light-grey;
      text-decoration: none;
      box-shadow: none;
      border: @c24-border-light-grey;
    }
  }

  &:focus,
  &:active,
  &:active:focus,
  &:hover {
    outline: none;
    border: 0 none;
    box-shadow: @c24-box-button-shadow;
    text-decoration: none;
  }

  &:hover {
    background-color: @c24-color-button-hover;
  }

  &.is-disabled {
    color: @c24-color-button-disabled;
    background-color: @c24-color-button-disabled-background;
    cursor: default;

    &.is-recommendation {
      height: 40px;
    }

    &:focus,
    &:active,
    &:active:focus,
    &:hover {
      box-shadow: none;
    }
  }

  &.Button--light {
    background-color: white;
    color: @c24-color-tariff-selection-bar-button-text;
    border: @c24-border-light-button;

    &:active,
    &:active:focus,
    &:hover,
    &:focus {
      background-color: @c24-color-tariff-selection-bar-button-hover-background;
      text-decoration: none;
    }
  }

  &.Button--inverted {
    background-color: @c24-color-inverted-button-background;
    color: @c24-color-light-button-text;
    border: @c24-border-inverted-button;
    font-size: 13px;
    font-weight: bold;
    padding: 0 6px;

    &:active,
    &:active:focus,
    &:hover,
    &:focus {
      outline: none;
      text-decoration: none;
      color: @c24-color-inverted-button-text;
      box-shadow: @c24-box-button-shadow;
      border: @c24-border-inverted-button;
    }
  }

  .Button-navigationArrow {
    position: relative;
    display: inline-block;
    width: 12px;
    padding-left: 4px;

    &:after {
      content: '\00BB';
    }
  }

  .Button-navigationSpinner {
    display: inline-block;
    position: relative;
    top: 0;
    height: 10px;
    width: 12px;
    margin-top: -10px;
    border-width: 4px;
    margin-right: -13px;
    margin-left: 13px;

    &:after {
      height: 24px;
      width: 24px;
      margin-left: -12px;
      margin-top: -12px;
      border-width: 4px;
      content: '';
      border-color: #fff rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4);
      top: 50%;
      box-sizing: border-box;
      display: block;
      position: absolute;
      left: 50%;
      border-style: solid;
      border-radius: 50%;
      animation: spinAround 0.7s infinite linear;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 24px;
        width: 24px;
        /* IE spinner jumps, thus reset border and use svg as background image */
        border: 0;
        border-radius: 0;
        background-image: url(/button-spinner.svg);
        background-size: 24px;
      }
    }
  }

  &.Button--light .Button-navigationSpinner:after {
    border-color: @c24-color-dark-blue rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      background-image: url(/button-spinner-inverted.svg);
    }
  }

  &.Button--inverted .Button-navigationSpinner:after {
    border-color: @c24-color-dark-blue rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      background-image: url(/button-spinner-inverted.svg);
    }
  }

  &.Button--secondary .Button-navigationSpinner:after {
    border-color: @c24-color-dark-blue rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      background-image: url(/button-spinner-inverted.svg);
    }
  }
}
