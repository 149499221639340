.EFeedbackRatings-ratingsContainer {
  padding-top: 20px;
  flex: 1;
}

.EFeedbackRatings-resetButton {
  display: flex;
  align-items: center;
  text-align: left;
  color: @c24-color-dark-blue;

  &:hover {
    text-decoration: underline;
  }
}

.EFeedbackRatings-undoIcon {
  fill: @c24-color-dark-blue;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
