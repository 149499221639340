.OnboardingQuestionnaireModal {
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: right;
  width: 850px;
  margin-bottom: 10px;
  font-size: 14px;

  .ModalDialog {
    height: 90%;
  }

  .ModalDialog-headerClose {
    right: 15px;
    top: 15px;
  }
}

.OnboardingQuestionnaireModal-modalBody {
  height: 100%;
  padding-right: 1px;
  padding-top: 25px;
  padding-bottom: 0;
  overflow: hidden;
}

.OnboardingQuestionnaireModal-iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
