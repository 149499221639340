@import '../../../styles/variables/colors';
@import '../../../styles/variables/backgrounds';

.ModalDialog-container {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15000;
  outline: 0;
  overflow: hidden;
}

.ModalDialog {
  width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 0.4px));
  background-color: @c24-color-modal-dialog-background;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-clip: padding-box;
  outline: 0;
}

.ModalDialog-header {
  border: none;
  padding: 25px 25px 0 25px;
  border-bottom: none;
  min-height: 16px;
}

.ModalDialog-headerTitle {
  color: @c24-color-headline;
  font-size: 18px;
  margin: 0;
  line-height: 1.4;
  text-align: left;
}

.ModalDialog-headerClose {
  z-index: 99;
  width: 20px;
  height: 20px;
  cursor: pointer;
  pointer-events: auto;
}

.ModalDialog-headerCloseButton {
  z-index: 99;
  padding: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 5px;
  top: -30px;
  cursor: pointer;
  pointer-events: auto;

  &:focus-within > svg > path {
    fill: @c24-color-malaga-blue;
  }

  &.is-hidden {
    display: none;
  }
}

.ModalDialog-body {
  font-size: 14px;
  line-height: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 20px;
}
