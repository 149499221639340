.overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.is-blurred::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1;
    pointer-events: none;
  }
}

.ResultZeroGridItem {
  background-color: @c24-color-white;
  border: @c24-border-light-grey;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 0px 13px 0;

  &:hover {
    border-color: @c24-color-grey;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }

  &.color-gold {
    border: 1px solid @c24-color-gold;
  }

  &.color-blue {
    border: 1px solid @c24-color-catalina-blue;
  }

  &.color-green {
    border: 1px solid @c24-color-dark-green;
  }

  &.color-orange {
    border: 1px solid @c24-color-vivid-orange;
  }

  &.is-expandPanelOpen {
    &:hover {
      .ResultZeroGridEFeedback.is-selected {
        padding-bottom: 4px;
      }

      .ResultZeroGridTestReports.is-selected {
        padding-bottom: 4px;
      }

      .ResultZeroGridEFeedback-tabHeader,
      .ResultZeroGridTestReports-tabHeader {
        border-color: @c24-color-grey;
      }
    }
  }

  &.is-one-half {
    .ResultZeroGridItem-insuranceLogo {
      max-width: 180px;
    }

    .ResultZeroGridItem-possiblePrice {
      font-size: 22px;
    }

    .ResultZeroGridItem-guaranteedPrice {
      font-size: 16px;
    }
  }

  &.is-one-third {
    .ResultZeroGridItem-grade {
      height: 70px;
      width: 60px;

      .Grade-icon {
        height: 70px;
        width: 60px;
        background-size: 60px 70px;
      }

      .Grade-calculatedValue {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 21px;
        font-size: 20px;
        font-weight: 700;
      }

      .Grade-gradeText,
      .Grade-subText {
        position: absolute;
        width: 100%;
        text-align: center;
        font-size: 9px;
      }

      .Grade-subText {
        top: 13px;
      }

      .Grade-gradeText {
        top: 55px;
        color: rgb(255, 255, 255);
        overflow: hidden;

        &.is-bold {
          font-weight: 700;
        }
      }
    }
  }

  @media @c24-tablet-l-wide {
    &:nth-child(3) {
      &.is-one-third {
        display: none;
      }
    }
  }
}

.ResultZeroGridItem-header {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  border-bottom: 1px solid @c24-color-divider-grey;
  padding-bottom: 6px;
}

.ResultZeroGridItem-insuranceContainer {
  margin-right: 10px;
}

.ResultZeroGridItem-insuranceLogo {
  display: block;
  height: 60px;
  max-width: 135px;
}

.ResultZeroGridItem-tariffName {
  margin-top: 10px;
  height: 35px;
}

.ResultZeroGridItem-price {
  line-height: 18px;
  text-align: right;
  margin-bottom: 5px;

  .Tooltip {
    text-align: start;
  }
}

.ResultZeroGridItem-possiblePrice {
  font-size: 18px;
  font-weight: bold;
  color: @c24-color-price;
  margin-bottom: 6px;
  white-space: nowrap;
}

.ResultZeroGridItem-guaranteedPrice {
  font-size: 15px;
  font-weight: bold;
  color: @c24-color-black;
  margin-top: 7px;
  margin-bottom: 2px;
}

.ResultZeroGridItem-priceText {
  font-size: 11px;
  line-height: 16px;
}

.ResultZeroGridItem-possiblePriceText {
  color: @c24-color-dark-green;
}

.ResultZeroGridItem-priceDescription {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.ResultZeroGridItem-body {
  flex: 2;
  border-bottom: 1px solid @c24-color-divider-grey;
  margin-bottom: 12px;
  padding-bottom: 6px;
}

.ResultZeroGridItem-bodyRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.ResultZeroGridItemUsps-container {
  font-size: 13px;
  padding-right: 5px;
}

.ResultZeroGridItem-actionButton {
  padding: 5px;
  height: 40px;
  width: 120px;

  @media @c24-wide {
    font-size: 15px;
  }
}

.ResultZeroGridItem-tariffSelectionCheckbox {
  display: flex;
  align-self: flex-end;
}

.ResultZeroGridItem-footerActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
}

.ResultZeroGridItem-footerTabs {
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1px;
}

.ResultZeroGridItem-gradeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ResultZeroGridItem-labelContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: flex-start;
}

.ResultZeroGridItem-labelSlot {
  min-height: 24px;
  margin-top: 5px;
}
