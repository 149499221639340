.OnboardingPaymentExceededLimitWarning-paymentAboveSalaryWarning {
  display: flex;
  margin-top: 5px;
}

.OnboardingPaymentExceededLimitWarning-paymentAboveSalaryWarning {
  .OnboardingPaymentExceededLimitWarning-warningIcon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 2px;
  }
}
