.ResultTariffListItemEfeedbackTab-tabHeader {
  color: @c24-color-black;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  width: fit-content;

  &.is-clickable {
    cursor: pointer;
  }

  &.is-selected {
    padding-bottom: 5px;
    border-bottom: 3px solid @c24-color-malaga-grey !important;
  }

  &.is-disabled {
    color: @c24-color-grey;
    cursor: default;
  }

  &:hover {
    &.is-disabled {
      text-decoration: none;
    }

    text-decoration: underline;
  }
}

.ResultTariffListItemEfeedbackTab-tabArrow {
  display: block;
  width: 17px;
  height: 17px;
  fill: @c24-color-nobel-grey;

  transition: transform 0.3s ease;
  transform: rotate(90deg);

  &.is-open {
    transform: rotate(270deg);
  }
}

.ResultTariffListItemEfeedbackTab-EFeedbackTooltip {
  .Tooltip {
    width: 380px;
    text-align: left;
    cursor: default;
  }
}

.ResultTariffListItemEfeedbackTab-ratingStarsNumber {
  margin-right: 5px;
}

.ResultTariffListItemEfeedbackTab-tabHeaderLabel {
  padding-right: 5px;
}
