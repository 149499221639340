.EFeedbackRatingStars {
  width: 90px;
  white-space: nowrap;
  margin-top: -1px;
  margin-right: 5px;
  height: 18px;
  position: relative;
}

.EFeedbackRatingStars-starsContainer {
  position: absolute;
  top: 0px;
  height: 18px;
  overflow: hidden;
}

.EFeedbackRatingStars-star {
  height: 18px;
  width: 18px;
  fill: @c24-color-gainsboro-grey;

  &.is-overlay {
    fill: @c24-color-light-orange;
  }

  &.is-disabled {
    fill: @c24-color-light-grey;
  }
}
