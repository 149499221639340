@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';
@import '../../../styles/variables/component-sizes';
@import '../../../styles/variables/backgrounds';

.SideHelpText {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  max-height: 47px;
  font-size: 12px;

  //NOTE: this page has a very particular width when it's in small view
  &.is-personal-data-page {
    @media @c24-tablet-l-wide {
      .SideHelpText-panel {
        right: 24px;
      }
    }
  }
}

.SideHelpText-text {
  position: absolute;
  width: @c24-side-help-text;
  left: 69px;
  top: calc(50% - 8px);

  @media @c24-tablet-l-wide {
    width: 150px;
  }
}

.SideHelpText-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 38px;
  top: calc(50% - 10px);
  border-top: 1px solid @c24-color-lighter-grey;
  border-right: 1px solid @c24-color-lighter-grey;

  transform: rotate(-135deg);
  background-color: @c24-color-side-help-text-arrow-background;
}

.SideHelpText-link {
  cursor: help;
  display: flex;
}

.SideHelpText-panel {
  height: 100%;
  position: relative;
}

.SideHelpText-questionMark {
  width: 20px;
  height: 20px;
  fill: @c24-color-question-mark;
  display: inline-block;

  &:hover {
    fill: @c24-color-icon-hover;
  }

  &.is-active {
    fill: @c24-color-icon-active;
  }
}

.SideHelpText-headline {
  font-weight: bold;
  margin-bottom: 8px;
}
