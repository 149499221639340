@import '../styles/variables/colors';
@import '../styles/variables/borders';
@import '../styles/variables/component-sizes';

.OnboardingPage-headline {
  color: @c24-color-headline;
  font-size: 21px;
  margin-bottom: 20px;
  font-weight: 700;
}

.OnboardingPage-inputContainer {
  width: 56%;
}

.OnboardingPage-actionButton {
  width: 200px;
  font-size: 14px;
  margin-left: auto;
  height: 40px;
}

.OnboardingPage-ctaSection {
  padding: 32px 48px 32px 48px;
  display: flex;
  justify-content: space-between;
}

.OnboardingPage-additionalInformation {
  display: flex;
  width: 810px;
  justify-content: space-between;

  &.is-fullWidth {
    width: 994px;
  }

  &.is-leftAligned {
    justify-content: end;
  }
}

.OnboardingPage-nextPageButton {
  width: 150px;
  height: 45px;
  font-size: 14px;
  margin-left: auto;
  margin-bottom: 9px;
}

.OnboardingPage-recalcHintIcon {
  width: 50px;
  height: 50px;

  fill: @c24-color-icon-default;
  margin-right: 20px;
}

.OnboardingPage-recalcHintHeadlineText {
  font-weight: bold;
}

.OnboardingPage-recalcHint {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 13px;
  border: @c24-border-recalc-hint;
  padding: 10px 20px;

  position: relative;
}

.OnboardingPage-recalcHint:after,
.OnboardingPage-recalcHint:before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  content: ' ';
  border: solid transparent;
}

.OnboardingPage-recalcHint:after {
  border: 15px solid transparent;
  border-bottom: @c24-border-recalc-hint-after;
  margin-left: 1px;
}

.OnboardingPage-recalcHint:before {
  border: 16px solid transparent;
  border-bottom: @c24-border-recalc-hint-before;
}

.OnboardingPage-recalcHintClose {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  fill: @c24-color-close;
  cursor: pointer;
}

.OnBoardingPage-debugButton {
  background-color: @c24-color-button-debug;
  width: 130px;
  margin-right: 5px;
  &:hover {
    background-color: @c24-color-button-debug;
  }
}

.OnboardingPage-disclaimer {
  margin-bottom: 10px;
  font-size: 9px;
}
