.ComparisonPage {
  max-width: @c24-max-page-size-wide;
  padding: 20px 10px 0px;
}

.ComparisonPage-detailsButton {
  border-bottom: @c24-border-default;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  flex: 1;

  &.is-centered {
    justify-content: center;
  }

  &.is-footer {
    padding: 16px 16px 16px;
    border-bottom: none;
  }

  &.no-border {
    border-bottom: none;
  }

  &.is-large {
    min-height: 110px;
  }
}

.ComparisonPage-spacer {
  background-color: @c24-color-comparison-spacer-background;
  flex: 1;
  padding: 0 14px;
}

.ComparisonPage-backButton {
  line-height: 28px;
  height: 32px;
  width: 90%;
  margin-top: 5px;
  margin-bottom: 15px;
}

.ComparisonPage-stickyContainer {
  position: relative;
  z-index: 149;
}

.ComparisonPage-infoFooter {
  display: none;
}

.ComparisonPage-legendContainer {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.ComparisonPage-legendItem {
  display: flex;
  align-items: center;
  margin: 10px;
  svg {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
}
