@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';
@import '../../../styles/variables/backgrounds';

.FormInput {
  position: relative;
  width: 100%;

  &.is-currency {
    &:after {
      position: absolute;
      top: 17px;
      content: '€';
      right: 10px;
    }

    &.ResultCurrencyInput {
      &:after {
        top: 12px;
      }
    }
  }
}

.FormInput-field {
  width: 100%;
  height: 47px;
  min-height: 47px;
  color: @c24-color-input;
  border: @c24-border-redesign-input;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  padding: 14px 0 0 16px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
  will-change: border-color;

  &.ResultCurrencyInput-field {
    height: 40px;
    min-height: 40px;
    border-radius: 3px;
    font-size: 13px;
    padding: 14px 0 0 7px;
  }

  &:hover:enabled {
    border: @c24-border-input-hover;
  }

  &:focus,
  &:active,
  &:focus:hover {
    border: @c24-border-input-focus;
  }

  &.has-overlay {
    padding-left: 33px;
  }

  &.is-error,
  &.is-error:focus,
  &.is-error:active,
  &.is-error:hover,
  &.is-error:focus:hover {
    border: @c24-border-error;
  }

  &.is-disabled {
    color: @c24-color-input-disabled;
    background-color: @c24-color-disabled-background;
  }

  &:read-only {
    color: @c24-color-input-disabled;
    background-color: @c24-color-disabled-background;
  }

  &.no-label {
    padding-top: 0px;
  }
}

.FormInput-fieldLabel {
  font-size: 14px;
  color: @c24-color-grey;
  position: absolute;
  padding-left: 16px;
  left: 0px;
  top: calc(50% - 9px);
  transform-origin: top;
  transition:
    top 150ms,
    font-size 150ms,
    color 150ms;
  will-change: top, font-size;
  z-index: 1;
  pointer-events: none;

  &.is-top {
    top: 6px;
    font-size: 12px;
  }

  &.is-top.is-error {
    color: @c24-color-is-error;
  }
}

.FormInput-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: @c24-color-is-error;
  font-size: 12px;
}

.FormInput-info {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 5px 0 0 0;
  color: @c24-color-link;
  font-size: 12px;
}

.FormInput-infoText {
  margin-top: 6px;
  margin-bottom: 0px;
  color: @c24-color-grey;
  font-size: 12px;
}
