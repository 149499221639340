@import '../../../styles/variables/colors.less';

.TariffListOverlayRecommendation {
  padding: 10px;
  font-size: 14px;
  min-width: 480px;
  color: @c24-color-black;
}

.TariffListOverlayRecommendation-footer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.TariffListOverlayRecommendation-checkmarkItem {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.TariffListOverlayRecommendation-checkmarkSvg {
  fill: @c24-color-green;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.TariffListOverlayRecommendation-cta {
  margin-top: 30px;
  max-width: 300px;
  align-self: center;
}

.TariffListOverlayRecommendation-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: -2px;
  top: -2px;
  cursor: pointer;
  fill: @c24-color-grey;
}
