@import '../../styles/variables/colors';

.ThankyouContact {
  padding: 30px 30px 25px 20px;
  width: 280px;
}

.ThankyouContact-headline {
  color: @c24-color-headline;
  font-size: 18px;
  margin-bottom: 30px;
}

.ThankyouContact-contactContainer {
  margin-top: 15px;
}

.ThankyouContact-phoneNumberContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ThankyouContact-phoneSvg {
  fill: @c24-color-icon-default;
  height: 33px;
  width: 24px;
  margin-right: 15px;
  margin-left: 5px;
}

.ThankyouContact-phoneNumber {
  color: @c24-color-label;
  font-size: 18px;
  font-weight: 700;
}

.ThankyouContact-workingTimes {
  color: @c24-color-black;
  font-size: 12px;
}

.ThankyouContact-emailContainer {
  display: flex;
  align-items: center;
}

.ThankyouContact-emailSvg {
  fill: @c24-color-icon-default;
  height: 25px;
  width: 35px;
  margin-right: 10px;
}

.ThankyouContact-email {
  color: @c24-color-label;
  font-weight: 700;
}
