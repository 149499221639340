.ResultTariffListItemTariffDetailsTab-tabHeader {
  color: @c24-color-black;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  width: fit-content;
  margin-right: 55px;

  @media @c24-tablet-l-wide {
    margin-right: 22px;
  }

  &.is-clickable {
    cursor: pointer;
  }

  &.is-selected {
    padding-bottom: 5px;
    border-bottom: 3px solid @c24-color-malaga-grey !important;
  }

  &:hover {
    &.is-disabled {
      text-decoration: none;
    }

    text-decoration: underline;
  }
}

.ResultTariffListItemTariffDetailsTab-tabArrow {
  display: block;
  width: 17px;
  height: 17px;
  fill: @c24-color-nobel-grey;

  transition: transform 0.3s ease;
  transform: rotate(90deg);

  &.is-open {
    transform: rotate(270deg);
  }
}

.ResultTariffListItemTariffDetailsTab-tabHeaderLabel {
  padding-right: 5px;
}
