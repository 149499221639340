.ComparisonButtons-tooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}

.ComparisonButtons-tooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: @c24-color-checked;
  position: relative;
  top: 4px;
}
