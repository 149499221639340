.ThankyouSteps {
  width: 548px;
  border-radius: 3px;
  background-color: @c24-color-white;
  box-shadow: @c24-section-box-shadow;
  padding-left: 47px;
  padding-top: 23px;
  padding-bottom: 23px;
  margin-bottom: 24px;
  padding-right: 50px;
}

.ThankyouSteps-headline {
  font-size: 16px;
  font-weight: bold;
}

.ThankyouSteps-numberSvg {
  height: 22px;
  width: 22px;
  margin-right: 11px;
}

.ThankyouSteps-item {
  margin-top: 15px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ThankyouSteps-stepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.ThankyouSteps-headerText {
  font-weight: bold;
  font-size: 14px;
}

.ThankyouSteps-stepText {
  margin-left: 34px;
  font-size: 12px;
}
