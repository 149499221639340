.ResultSustainabilityFilterInfoBox {
  display: flex;
  background-color: @c24-color-nobel-green;
  padding: 8px 15px;
  margin-bottom: 12px;
  color: @c24-color-black;
  border: 1px solid @c24-color-green;
  align-items: center;
}

.ResultSustainabilityFilterInfoBox-leaf {
  fill: #7ab51d;
  width: 22px;
  height: 22px;
  margin-right: 15px;
}

.ResultSustainabilityFilterInfoBox-link {
  padding-left: 5px;
}
