.ProgressBar {
  display: flex;
  align-items: center;
}

.ProgressBar-bar {
  background-color: #ebeef1;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;
  width: 500px;
  height: 15px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  ); // fixes safari + border-radius + overflow:hidden + transition in child issue
}

.ProgressBar-progress {
  background: @c24-color-catalina-blue;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  transform: translate3d(-99%, 0, 0);
}

.ProgressBar-percent {
  width: 40px;
  padding-left: 5px;
  float: left;
  color: @c24-color-catalina-blue;
  text-align: left;
}
