@import './variables/colors';
@import './variables/backgrounds';

select {
  color: @c24-color-input;
  background-color: @c24-color-input-background;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;

  -webkit-appearance: none; /*Removes default chrome and safari style*/
  -moz-appearance: none; /*Removes default style Firefox*/
  text-indent: 0.01px;

  &::-ms-expand {
    display: none;
  }
}
