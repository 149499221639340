.OnboardingProductSelectionCalculationTip-productCalculationHintWrapper {
  display: flex;
  margin-top: 16px;
  color: @c24-color-dark-blue;
  font-size: 14px;
}

.OnboardingProductSelectionCalculationTip-productCalculationHint {
  padding-left: 5px;
}

.OnboardingProductSelectionCalculationTip-modalBody {
  padding: 25px;
}

.OnboardingProductSelectionCalculationTip-modalHeading {
  font-size: 18px;
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 10px;
}

.OnboardingProductSelectionCalculationTip-modalText {
  font-size: 14px;
  margin: 0px;
}

.OnboardingProductSelectionCalculationTip-modalTable {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
}

.OnboardingProductSelectionCalculationTip-modalTableHeader {
  min-width: 170px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-left: 1px solid @c24-color-nobel-grey;

  &.is-disabled {
    color: @c24-color-grey;
  }
}

.OnboardingProductSelectionCalculationTip-emptyCell {
  border-left: none;
  border-top: none;
}

.OnboardingProductSelectionCalculationTip-modalCustomerCalculationDataWrapper {
  display: flex;
  justify-content: center;
}

.OnboardingProductSelectionCalculationTip-modalCustomerCalculationHeader {
  color: @c24-color-dark-green;
}

.OnboardingProductSelectionCalculationTip-modalCustomerCalculationData {
  display: flex;
  flex-direction: column;
  color: @c24-color-dark-green;

  > span {
    padding: 5px;
  }

  > span:first-of-type {
    border-bottom: 1px solid @c24-color-dark-green;
  }
}

.OnboardingProductSelectionCalculationTip-modalCustomerCalculationDataPercentage {
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: @c24-color-dark-green;
}

.OnboardingProductSelectionCalculationTip-disclaimer {
  font-size: 10px;
  line-height: normal;
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid @c24-color-nobel-grey;
}
