@import '../../../styles/variables/colors';

.ContactTrustFooter {
  display: flex;
  margin-top: 20px;
  margin-bottom: 32px;
  padding: 18px 25px;
  border-radius: 5px;
  background-color: @c24-color-trust-footer;
  box-shadow: @c24-section-box-shadow;
  max-width: 850px;

  &.is-error {
    position: absolute;
    bottom: 125px;
    width: 994px;
  }
  &.is-wide {
    max-width: 100%;
    margin-bottom: 25px;
    padding: 25px 58px;
  }

  &.has-border {
    border: @c24-border-default;
  }
}

.ContactTrustFooter-headline {
  color: @c24-color-headline;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 15px;

  &.is-larger {
    font-size: 18px;
  }
}

.ContactTrustFooter-consultant {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.is-wide {
    margin-left: 60px;
  }
}

.ContactTrustFooter-customerPromise {
  margin-right: 15px;
}

.ContactTrustFooter-section {
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.ContactTrustFooter-sectionTitle {
  font-size: 14px;
  color: @c24-color-black;
}

.ContactTrustFooter-logoContainer {
  width: 40px;
  height: 35px;
  margin-right: 16px;
}

.ContactTrustFooter-logo {
  fill: @c24-color-catalina-blue;
  max-height: 35px;
  max-width: 40px;
  pointer-events: none;
}

.ContactTrustFooter-contactContainer {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between;
  align-items: flex-end;
}

.ContactTrustFooter-consultantContainer {
  display: flex;
  flex-direction: row;
}

.ContactTrustFooter-consultantPhoto {
  display: inline;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-position: top center;
  background-size: cover;
}

.ContactTrustFooter-quote {
  height: 85px;
  font-size: 12px;
  border-radius: 3px;
  border: @c24-border-divider-grey;
  background-color: @c24-color-white;
  color: @c24-color-black;
  padding: 9px;
  margin-left: 35px;
  max-width: 255px;
  position: relative;
  display: flex;
  align-items: center;

  &.is-wide {
    max-width: 315px;
  }
}

.ContactTrustFooter-arrow {
  width: 16px;
  height: 16px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: @c24-color-gainsboro-grey;
  background-color: @c24-color-white;
  background-size: cover;
  transform: rotate(-44deg);
  position: absolute;
  top: calc(50% - 8px);
  left: -9px;
}

.ContactTrustFooter-contactInfo {
  max-width: 200px;
  height: 64px;
}

.ContactTrustFooter-phoneEmailInfo {
  margin-left: 10px;
  height: 64px;
}

.ContactTrustFooter-consultantName {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 4px;
}

.ContactTrustFooter-consultantTitle {
  font-size: 14px;
  color: @c24-color-grey;
}

.ContactTrustFooter-phoneNumberContainer {
  display: flex;
  align-items: center;
}

.ContactTrustFooter-phoneSvg {
  fill: @c24-color-icon-default;
  height: 20px;
  width: 15px;
  margin-right: 10px;
  margin-left: 5px;
}

.ContactTrustFooter-phoneNumber {
  color: @c24-color-label;
  font-size: 14px;
}

.ContactTrustFooter-workingTimes {
  color: @c24-color-malaga-grey;
  font-size: 12px;
}

.ContactTrustFooter-emailContainer {
  display: flex;
  padding-top: 7px;
}

.ContactTrustFooter-emailSvg {
  fill: @c24-color-icon-default;
  height: 15px;
  width: 20px;
  margin-right: 10px;
  margin-top: 4px;
}

.ContactTrustFooter-email {
  color: @c24-color-label;
  font-size: 14px;
}

.ContactTrustFooter-container {
  width: 100%;
  display: flex;

  @media @is-IE11 {
    width: 85%;
  }

  @media @c24-wide {
    .ResultPage &,
    .ComparisonPage & {
      flex: 65;
    }
  }
}

.ContactTrustFooter-extraSpace {
  margin-top: auto;
}

.ContactTrustFooter-customerPromiseContainer {
  width: 50%;
  border-right: @c24-border-divider-grey;
}

.ContactTrustFooter-tooltip .Tooltip {
  width: 720px;
}
