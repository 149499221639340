.PlaceholderTariff {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PlaceholderTariff-headerImage {
  height: 200px;
}

.PlaceholderTariff-headerText {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}

.PlaceholderTariff-descriptionText {
  max-width: 600px;
  margin-top: 15px;
  font-size: 13px;
  text-align: center;
}

.PlaceholderTariff-cta {
  margin-top: 20px;
}

.PlaceholderTariff-ctaButton {
  width: 260px;
}

.PlaceholderTariff-hint {
  color: @c24-color-button-hint;
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.PlaceholderTariff-checkIcon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
