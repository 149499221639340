::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: @c24-color-nobel-grey;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: @c24-color-nobel-grey;
  opacity: 1 !important;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: @c24-color-nobel-grey;
  opacity: 1 !important;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: @c24-color-nobel-grey !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-ms-clear {
  display: none;
}
