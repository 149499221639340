.ResultSummary {
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
}

.ResultSummary-priceRange {
  display: inline;
}

.ResultSummary-possiblePensionRangeValue {
  font-weight: bold;
  color: @c24-color-dark-green;
}

.ResultSummary-guaranteedPensionRangeValue {
  font-weight: bold;
  color: @c24-color-black;
}
