.TariffPromotionHeader {
  padding-left: 10px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 14px;
  width: max-content;

  .Tooltip {
    width: 420px;
  }
}

.TariffPromotionHeader-text {
  -webkit-text-size-adjust: none; // disable font resize in safari
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
}

.TariffPromotionHeader-tooltipIcon {
  margin-left: 5px;
  margin-top: 5px;
  height: 14px;
  width: 14px;
  pointer-events: auto;
  cursor: help;
}

.TariffPromotionHeader-tooltipContentList {
  list-style-type: disc;
}
