.ThankyouErrorPage {
  font-size: 13px;
}

.ThankyouErrorPage-container {
  display: flex;
  margin-bottom: 40px;
}

.ThankyouErrorPage-left {
  display: block;
  margin-top: 15px;
  padding-left: 20px;
  flex: 1;
}

.ThankyouErrorPage-headline {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.ThankyouErrorPage-crossSvg {
  width: 28px;
  height: 22px;
  margin-right: 10px;
  fill: @c24-color-checked;
}

.ThankyouErrorPage-headlineText {
  color: @c24-color-headline;
  font-size: 20px;
}

.ThankyouErrorPage-subtitle {
  color: @c24-color-headline;
  margin-top: 30px;
  font-size: 16px;
}

.ThankyouErrorPage-helpText {
  padding-left: 25px;
  width: 230px;
}

.ThankyouErrorPage-phoneNumberContainer {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.ThankyouErrorPage-phoneSvg {
  fill: @c24-color-icon-default;
  height: 24px;
  width: 24px;
  margin-right: 5px;
  margin-top: 5px;
}

.ThankyouErrorPage-phoneNumber {
  color: @c24-color-label;
  font-size: 20px;
}

.ThankyouErrorPage-workingTimes {
  color: @c24-color-black;
  font-size: 14px;
}

.ThankyouErrorPage-backCont {
  border-top: @c24-border-default;
  margin-top: 60px;
  padding-top: 15px;
}
