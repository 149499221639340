.PersonalDataPageAgreementSection {
  padding: 0 @c24-form-side-gap 5px;
  font-size: 12px;

  p {
    margin-top: 0;
    margin-bottom: 11px;
  }
}

.PersonalDataPageAgreementSection-link {
  text-decoration: underline;
}

.PersonalDataPageAgreementSection-newTerms {
  border-bottom: @c24-border-light-grey;
  padding-bottom: 20px;
}
