.OnlineContractBox {
  border: 1px solid @c24-color-light-grey;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 20px;
}

.OnlineContractBox-title {
  font-weight: bold;
  font-size: 15px;
}

.OnlineContractBox-hint {
  display: flex;
  align-items: center;
  max-width: 700px;
}

.OnlineContractBox-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OnlineContractBox-separator {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.OnlineContractBox-line {
  height: 1px;
  background: @c24-color-divider-grey;
  flex-grow: 1;
}

.OnlineContractBox-separatorText {
  margin: 0 5px;
  color: @c24-color-grey;
}

.OnlineContractBox-contentDefault {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.OnlineContractBox-tooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}

.OnlineContractBox-tooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: @c24-color-checked;
  position: relative;
  top: 4px;
}
