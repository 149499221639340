.extend-clickable-area(@width, @right_width: @width) {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -@width;
    left: -@width;
    right: -@right_width;
    bottom: -@width;
  }
}

.hover-link:hover {
  color: @c24-color-orange;
}

.long-hover-link:hover {
  color: @c24-color-orange;
  text-decoration: none;
}

.nowrap {
  white-space: nowrap;
}
