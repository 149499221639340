.TariffSummaryContactDetails {
  border-radius: 3px;
  background-color: @c24-color-box-background;
  padding: 24px 24px;
  box-shadow: @c24-section-box-shadow;
  margin-top: 24px;

  @media @c24-tablet-l-wide {
    margin-top: 10px;
  }
}

.TariffSummaryContactDetails-questions {
  font-size: 16px;
}

.TariffSummaryContactDetails-inquiryInfo {
  margin-top: 10px;
  color: @c24-color-black;
}

.TariffSummaryContactDetails-contactContainer {
  margin-top: 15px;
}

.TariffSummaryContactDetails-phoneNumberContainer {
  display: flex;
  align-items: center;
}

.TariffSummaryContactDetails-phoneSvg {
  fill: @c24-color-icon-default;
  height: 20px;
  width: 15px;
  margin-right: 10px;
  margin-left: 5px;
}

.TariffSummaryContactDetails-phoneNumber {
  color: @c24-color-label;
  font-size: 14px;
}

.TariffSummaryContactDetails-workingTimes {
  color: @c24-color-malaga-grey;
  font-size: 11px;
}

.TariffSummaryContactDetails-emailContainer {
  display: flex;
  padding-top: 3px;
}

.TariffSummaryContactDetails-emailSvg {
  fill: @c24-color-icon-default;
  height: 15px;
  width: 20px;
  margin-right: 10px;
  margin-top: 4px;
}

.TariffSummaryContactDetails-email {
  color: @c24-color-label;
  font-size: 14px;
}

.TariffSummaryContactDetails-consultant {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.TariffSummaryContactDetails-consultantPhoto {
  display: inline;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  background-position: top center;
  background-size: cover;
}

.TariffSummaryContactDetails-consultantInfo {
  margin-left: 10px;
}

.TariffSummaryContactDetails-consultantName {
  font-size: 14px;
}

.TariffSummaryContactDetails-consultantTitle {
  margin-top: 4px;
  font-size: 11px;
  color: @c24-color-grey;
}
