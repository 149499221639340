.ExpandableResultSortingItem {
  position: relative;
  cursor: pointer;
  padding: 0;
  flex-grow: 1;
  width: 25%;
  text-align: center;
  color: @c24-color-black;
  font-size: 13px;

  &:before {
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    bottom: -1px;
  }

  &:hover {
    background-color: @c24-color-light-grey;
  }

  &:hover:before {
    width: 1px;
    background-color: @c24-color-light-grey;
  }

  &.is-selected {
    background-color: @c24-color-selected-sorting-item;
    border-bottom: 2px solid @c24-border-selected-sorting-item;
    margin-left: -1px;
    margin-bottom: -1px;
    border-left: 1px solid white;
    border-right: 1px solid white;
    color: @c24-color-black;

    &:hover:before {
      width: 0;
    }

    .ExpandableResultSortingItem-expandableArrow {
      fill: @c24-color-dark-blue;
    }
  }
}

.ExpandableResultSortingItem-text {
  margin: 4px 0;
  padding: 4px 0;
  height: 26px;
  border-right: @c24-border-divider-grey;

  &.is-last,
  &.is-selected {
    border-right: none;
  }
}

.ExpandableResultSortingItem-submenu {
  position: absolute;
  width: 100%;
  background: white;
  z-index: 1;
  border: @c24-border-light-grey;
  border-top: none;
  margin-top: -3px;

  &.is-selected {
    margin-top: -1px;
  }
}

.ExpandableResultSortingItem-expandableArrow {
  width: 10px;
  height: 8px;
  margin-left: 8px;
  fill: @c24-color-dark-grey;

  &.is-expanded {
    transform: rotate(180deg);
  }
}
