.TariffUsps-featureTooltip {
  max-width: 290px;

  &:after {
    left: 30% !important;
  }

  &:before {
    left: 30% !important;
  }
}

.TariffUsps-tooltipAnchor {
  position: absolute;
  left: 30px;
  top: 5px;
}

.TariffUsps-container {
  margin-bottom: 10px;
  flex: 1;
  min-width: 40%;
  font-size: 13px;
  padding-right: 5px;
}

.TariffUsps-feature {
  margin-bottom: 6px;
  display: flex;
  cursor: default;
  position: relative;

  &.has-helpText {
    cursor: help;
  }
}

.TariffUsps-feature {
  &.is-last {
    margin-bottom: 10px;
  }
}

.TariffUsps-featureIcon {
  margin-right: 6px;
  position: relative;
  top: 2px;

  > svg {
    width: 13px;
    height: 13px;
  }
}
