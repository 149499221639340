.SofortTip {
  padding-left: 10px;
  padding-right: 10px;
  background-color: @c24-color-dark-green-alpha-15;
  display: flex;
  margin-top: 6px;
  border-radius: 5px;
  max-height: 120px;

  /* animation setup */
  height: 0;
  animation: slide 0.5s forwards;
  animation-delay: 0.3s;
  overflow: hidden;
}

.SofortTip-icon {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 3px;

  svg {
    width: 25px;
    height: 20px;

    path {
      fill: @c24-color-dark-green;
    }
  }
}

.SofortTip-header {
  font-weight: 600;
  margin-bottom: 5px;
}

.SofortTip-content {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.SofortTip-text {
  margin-bottom: 8px;
}

.SofortTip-link {
  font-weight: 600;
}

@-webkit-keyframes slide {
  100% {
    height: 142px;
  }
}

@keyframes slide {
  100% {
    height: 142px;
  }
}
