.TariffFunds-fundValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TariffFunds-fundsInfoSvg {
  height: 14px;
  width: 14px;
  cursor: help;
  fill: @c24-color-nobel-grey;
  margin-left: 3px;
}

.TariffFunds-fundTooltip {
  min-width: 18px;
  margin-left: 5px;
  .Tooltip {
    width: 435px;
  }
}

.TariffFunds-fundTooltipInfo {
  margin-top: 10px;
}
