.Checkbox {
  position: relative;
  padding-left: 27px;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    top: 0px;
    left: 0px;
    margin: 0px;
    padding: 0px;
    z-index: 1;

    &:focus:not(:hover) + label:not(.checked) {
      .Checkbox-icon {
        border: @c24-border-dark-blue;
      }
    }
    &:hover + label:not(.checked) {
      .Checkbox-icon {
        border: @c24-border-dark-grey;
      }
    }
  }

  label {
    cursor: pointer;
    font-size: 13px;
    .Checkbox-icon {
      position: absolute;
      left: 0;
      content: ' ';
      width: 17px;
      height: 17px;
      border: @c24-border-nobel-grey;
      border-radius: 3px;
      overflow: hidden;
    }

    .Checkbox-checkIcon {
      opacity: 0;
      width: 16px;
      height: 16px;

      * {
        fill: @c24-color-dark-blue;
      }
    }

    &.checked {
      .Checkbox-icon {
        border: @c24-border-input-focus;
      }

      .Checkbox-checkIcon {
        display: block;
        opacity: 1;
      }
    }

    &.is-multiline {
      .Checkbox-icon {
        margin-top: 3px;
      }
    }
  }

  &.is-disabled {
    input {
      & + label {
        color: @c24-color-grey;
        .Checkbox-icon {
          border: @c24-border-grey;
          background-color: @c24-color-background-light-grey;
        }
      }

      &:focus:not(:hover) + label,
      &:hover + label {
        .Checkbox-icon {
          border: @c24-border-grey;
        }
      }
    }
  }
}
