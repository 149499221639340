.PersonalDataAddress-postalCodeCity {
  display: flex;
}

.PersonalDataAddress-postalCode {
  flex: 1;
}

.PersonalDataAddress-city {
  flex: 2;
  margin-left: 10px;
}

.PersonalDataAddress-streetHouseNumber {
  display: flex;
}

.PersonalDataAddress-street {
  flex: 2;
}

.PersonalDataAddress-houseNumber {
  flex: 1;
  margin-left: 10px;
}
