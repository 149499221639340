.TariffDetailsButtonsBox {
  border: 1px solid @c24-color-light-grey;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 20px;
}

.TariffDetailsButtonsBox-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.TariffDetailsButtonsBox-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TariffDetailsButtonsBox-inquiryButton {
  &.has-top-margin {
    margin-top: 10px;
  }
}

.TariffDetailsButtonsBox-backLink {
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
}

.TariffDetailsButtonsBox-tooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}

.TariffDetailsButtonsBox-tooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: @c24-color-checked;
  position: relative;
  top: 4px;
}
