@import '../styles/variables/colors';
@import '../styles/variables/borders';
@import '../styles/variables/component-sizes';

@form-width: 780px;

.PersonalDataPage-securityHint {
  display: flex;
  justify-content: space-between;
  width: @form-width;
}

.PersonalDataPage-columns {
  display: flex;
  justify-content: space-between;

  @media @c24-tablet-l-wide {
    justify-content: initial;
  }
}
