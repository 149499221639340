.ResultZeroGridClosed {
  font-size: 13px;
  display: flex;
  align-items: baseline;
  margin-top: 5px;
}

.ResultZeroGridClosed-arrow {
  display: block;
  width: 10px;
  height: 10px;
  fill: @c24-color-dark-blue;
  margin-left: 5px;
  cursor: pointer;
}
