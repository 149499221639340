.AppointmentBookingToolTimeSlots-timeSlotButton {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  border: @c24-border-divider-grey;
  margin: 4px 5px 0px 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;

  &:hover {
    border-color: @c24-color-dark-grey;
  }

  &.is-selected {
    background-color: @c24-color-wireframe-dark-blue;
    color: @c24-color-white;
  }

  &.is-disabled {
    background-color: @c24-color-background-grey;
    cursor: default;

    &:hover {
      border: @c24-border-divider-grey;
    }
  }
}

.AppointmentBookingToolTimeSlots-timeSlotInput {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
}

.AppointmentBookingToolTimeSlots-timeSlotLabel {
  font-size: 12px;
  cursor: pointer;

  &.is-disabled {
    cursor: default;
  }
}
