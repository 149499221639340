@import '../../styles/variables/borders';

.OnboardingSofortSection-label {
  font-size: 15px;
  width: 250px;
  align-items: center;
  height: 40px;
  display: flex;
}

.OnboardingSofortSection-input {
  display: block;
  width: @c24-form-input;
}

.OnboardingSofortSection-yesNoQuestion {
  .YesNoQuestion-question {
    width: 250px;
  }
}
