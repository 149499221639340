.InquiryButton-wrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
  font-weight: normal;
}

.InquiryButton-hint {
  color: @c24-color-button-hint;
  display: flex;
  margin-top: 6px;
  width: 100%;
}

.InquiryButton-checkIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: @c24-color-checked;
}

.InquiryButton-tooltip {
  .Tooltip {
    width: auto;
    white-space: nowrap;
  }

  &.OnlineContractButton-tooltip {
    .Tooltip {
      width: auto;
    }
  }
}

.InquiryButton-container {
  pointer-events: none;
}
