.AppointmentBookingErrorModal-header {
  font-size: 13px;
  font-size: 18px;
  display: flex;
  margin-bottom: 20px;
  svg {
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }
}

.AppointmentBookingErrorModal-ctaButton {
  margin-top: 18px;
  width: 255px;
  float: right;
}
