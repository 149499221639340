.InsuranceList {
  ul {
    li {
      list-style-type: disc;
    }
  }
}

.InsuranceList-headline {
  margin-top: 16px;
  color: @c24-color-black;
  font-size: 26px;
  text-align: center;
}

.InsuranceList-subHeadline {
  padding-top: 20px;
  font-size: 22px;
  color: @c24-color-black;
}

.InsuranceList-description {
  font-size: 16px;
  color: @c24-color-black;
  margin-bottom: 50px;
}

.InsuranceList-backButton {
  font-family: Arial;
  font-size: 13px;
  color: @c24-color-dark-blue;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: '\AB';
    display: inline-block;
    margin-right: 5px;
  }
}

.InsuranceList-link {
  display: block;
  padding: 0;
  color: #005ea8;
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.InsuranceList-dateOfValidity {
  color: @c24-color-black;
  font-size: 16px;
}

.InsuranceList-table {
  margin-top: 25px;
  width: 100%;

  .InsuranceList-row:last-child {
    .InsuranceList-label {
      border-bottom: none;
    }

    .InsuranceList-column {
      border-bottom: none;
    }
  }

  .InsuranceList-row:first-child {
    .InsuranceList-label {
      border-bottom: none;
    }

    .InsuranceList-column {
      border-bottom: none;
    }
  }
}

.InsuranceList-row {
  width: 100%;
  display: flex;
}

.InsuranceList-column {
  flex: 1;
  padding: 20px;
  justify-content: center;
  display: flex;
  border-bottom: 1px dashed @c24-color-grey;

  &:not(:last-of-type) {
    border-right: dashed 1px @c24-color-grey;
  }
}

.InsuranceList-cell {
  width: 170px;
  ul {
    margin: 0px;
  }
}

.InsuranceList-label {
  font-weight: bold;
  color: @c24-color-black;
  padding: 20px 10px;
  border-right: 1px solid @c24-color-grey;
  width: 120px;
  border-bottom: 1px dashed @c24-color-grey;
}

.InsuranceList-header {
  font-weight: bold;
  color: @c24-color-black;
  text-align: center;

  .InsuranceList-column {
    margin-top: 25px;
    border-bottom: none;
    padding-top: 10px;
  }

  .InsuranceList-label {
    border-bottom: none;
  }
}

.InsuranceList-main-header {
  border-bottom: 1px solid @c24-color-grey;
  border-top: none;
}

.InsuranceList-main-header * {
  padding: 0 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.InsuranceList-firstInfoContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.InsuranceList-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: relative;
  top: 4px;
}

.InsuranceList-downloadContainer {
  font-size: 16px;
}

.InsuranceList-list {
  width: 200px;
}
