.Dropdown {
  width: 100%;
  position: relative;
  border: @c24-border-input;
  border-radius: 5px;
  font-size: 13px;
  padding: 0 7px;
  color: @c24-color-input;

  cursor: pointer;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  min-height: 47px;

  &:hover {
    border: @c24-border-input-hover;
  }

  &:focus,
  &:active {
    border: @c24-border-input-focus;
  }

  &.is-active {
    border: @c24-border-input-focus;
  }

  &.is-error {
    border: @c24-border-error;
    .Dropdown-dropdownIndicator * {
      fill: @c24-color-is-error;
    }
  }

  &.is-disabled {
    background: @c24-color-disabled-background;
    color: @c24-color-input-placeholder;
  }
}

.Dropdown-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: @c24-color-is-error;
  font-size: 12px;
}

.Dropdown-dropdownIndicator {
  transform: rotate(0deg);
  width: 15px;
  height: 7px;
  * {
    fill: @c24-color-grey;
  }
}

&.is-active {
  .Dropdown-dropdownIndicator {
    transform: rotate(180deg);
    * {
      fill: @c24-color-dark-blue;
    }
  }
}
