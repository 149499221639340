.ResultZeroHeader {
  background-color: @c24-result-zero-header;
  padding-left: 10px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  width: fit-content;

  &.color-gold {
    background-color: @c24-color-partiboi-gold;
  }

  &.color-blue {
    background-color: @c24-color-mystic-blue;
  }

  &.color-green {
    background-color: @c24-color-booster-green-lighter;
  }

  &.color-orange {
    background-color: #fdebd9;
  }
}

.ResultZeroHeader-text {
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  color: @c24-color-catalina-blue;

  &.color-gold {
    color: @c24-color-gold;
  }

  &.color-blue {
    color: @c24-color-catalina-blue;
  }

  &.color-green {
    color: @c24-color-dark-green;
  }

  &.color-orange {
    color: @c24-color-vivid-orange;
  }
}

.ResultZeroHeader-infoContainer {
  margin-top: 5px;
  margin-left: 5px;
  height: 14px;
  width: 14px;
  pointer-events: auto;
  cursor: help;
  fill: @c24-result-zero-header-icon;

  &.color-gold {
    fill: @c24-color-gold;
  }

  &.color-blue {
    fill: @c24-color-catalina-blue;
  }

  &.color-green {
    fill: @c24-color-dark-green;
  }

  &.color-orange {
    fill: @c24-color-vivid-orange;
  }
}

.ResultZeroHeader-accentWord {
  color: @c24-result-zero-header-text;
}
