@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';
@import '../../../styles/variables/backgrounds';

.SelectBox {
  width: 100%;
  position: relative;
  border: @c24-border-redesign-input;
  border-radius: 5px;
  font-size: 14px;
  padding: 14px 0 0 16px;
  color: @c24-color-input;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCA3Ij4KICAgIDxwb2x5Z29uIGZpbGw9IiM5OTkiCiAgICAgICAgICAgICBwb2ludHM9IjYuOTUgNC45OSAxLjEyIDAgMCAwLjk4IDYuOTUgNi44NiAxNCAwLjk4IDEyLjg4IDAgNi45NSA0Ljk5Ii8+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-size: 14px 7px;
  background-position: right 16px center;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  height: 47px;
  min-height: 47px;
  transition: border-color 0.3s;
  will-change: border-color;
  padding-right: 30px;
  text-overflow: ellipsis;

  &.ResultSelectBox {
    border-radius: 3px;
    font-size: 13px;
    padding: 14px 30px 0 7px;
    background-position: right 9px center;
    height: 40px;
    min-height: 40px;
  }

  &:hover {
    border: @c24-border-input-hover;
  }

  &:focus,
  &:active {
    border: @c24-border-input-focus;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCA3Ij4KICAgIDxwb2x5Z29uIGZpbGw9IiM5OTkiCiAgICAgICAgICAgICBwb2ludHM9IjEyLjksNi45IDE0LDUuOSA2LjksMCAwLDUuOSAxLjEsNi45IDYuOSwxLjkiLz4KPC9zdmc+Cg==);
  }

  &.is-error {
    border: @c24-border-error;
  }

  &.is-disabled {
    background: @c24-color-disabled-background;
    color: @c24-color-input-disabled;
  }

  &.is-disabled:hover {
    border: @c24-border-redesign-input;
  }

  &.no-label {
    padding-top: 0px;
  }
}

.SelectBox-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: @c24-color-is-error;
  font-size: 12px;
}

.SelectBox-fieldLabel {
  font-size: 12px;
  color: @c24-color-grey;
  position: absolute;
  padding-left: 16px;
  left: 0px;
  top: 6px;
  transform-origin: top;
  transition:
    top 150ms,
    font-size 150ms,
    color 150ms;
  will-change: top, font-size;
  z-index: 1;
  pointer-events: none;

  &.is-error {
    color: @c24-color-is-error;
  }
}
