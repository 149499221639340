.TariffSummaryPensionDetails-header {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

.TariffSummaryPensionDetails-rowGroup {
  margin: 8px 0 0;
}

.TariffSummaryPensionDetails-row {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.TariffSummaryPensionDetails-rowLabel {
  color: @c24-color-grey;
  display: flex;
  align-items: center;
}

.TariffSummaryPensionDetails-tooltip {
  margin-left: 5px;
}

.TariffSummaryPensionDetails-possibleYearlyPensionAmount {
  font-size: 22px;
  color: @c24-color-dark-green;
  white-space: nowrap;
  margin: 0;
}

.TariffSummaryPensionDetails-guaranteedYearlyPensionAmount {
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
}

.TariffSummaryPensionDetails-infoSvgIcon {
  height: 14px;
  width: 14px;
  pointer-events: auto;
  cursor: help;
  fill: @c24-color-nobel-grey;
}

.TariffSummaryPensionDetails-separator {
  margin: 16px 0px;
  background-color: @c24-color-divider-grey;
  border: none;
  height: 1px;
}

.TariffSummaryPensionDetails-pointsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TariffSummaryPensionDetails-points {
  margin-left: 38px;
}
