.BoosterMessage {
  display: flex;
  padding-top: 6px;
}

.BoosterMessage-icon {
  padding-top: 4px;
  margin-right: 10px;
  min-width: 30px;

  svg {
    fill: @c24-color-dark-green;
  }
}

.BoosterMessage-text {
  color: @c24-color-dark-green;
  font-size: 11px;
  align-self: center;
  font-size: 14px;
}
