.TariffLabels-labelTooltip {
  max-width: 290px;

  &:after {
    left: 30% !important;
  }

  &:before {
    left: 30% !important;
  }
}

.TariffLabels-labelWrapper {
  display: flex;
  cursor: help;
  margin-top: 5px;
  margin-bottom: 0px;
}

.TariffLabels-label {
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 12px;
  margin-top: 1px;
  margin-bottom: 1px;
}
