.Tipp {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.Tipp-box {
  background-color: #cae7a8;
  width: 10%;
  text-align: center;
  color: #005ea8;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  margin-right: 15px;
  flex: 10%;
}

.Tipp-box:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  position: absolute;
  pointer-events: none;
  border-left-color: #cae7a8;
  border-width: 10px;
  margin-top: -10px;
}

.Tipp-text {
  margin-left: 10px;
  font-size: 15px;
  flex: 85%;
  width: 85%;
}
