.ResultTariffDetailsFeatureCategory-headline {
  font-size: 13px;
  padding: 10px 10px 5px 0;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 0;
  padding-left: 10px;
}

.ResultTariffDetailsFeatureCategory-title {
  flex: 1;
}

.ResultTariffDetailsFeatureCategory-detailsContainer {
  margin-left: 30px;
  margin-right: 20px;

  & :last-child {
    border-bottom: none;
  }
}
