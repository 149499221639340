@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';
@import '../../../styles/variables/backgrounds';
@import '../form-input/form-input';

.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  .FormInput-field;
}

.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
  margin-top: 8px;
}

.react-autosuggest__suggestions-container--open {
  width: 100%;
  min-height: 26px;
  line-height: 2;
  display: block;
  position: absolute;
  top: 38px;
  border: @c24-border-redesign-input;
  border-top: none;
  background-color: @c24-color-input-background;
  font-size: 13px;
  z-index: 2;
  max-height: 265px;
  overflow-y: auto;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px @c24-color-nobel-grey;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0 0 0 7px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: @c24-color-highlighted-input-background;
  color: white;
}

.react-autosuggest__suggestion-match {
  color: @c24-border-dark-grey;
  font-weight: bold;
}
