@import '../../styles/variables/colors';

.CseTeasers {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.CseTeasers-headline {
  font-size: 17px;
  font-weight: 400;
  color: @c24-color-headline;
  margin-top: 0;
  margin-bottom: 16px;
  margin-left: 8px;
}

.CseTeasers-container {
  display: flex;
  flex-direction: column;
  width: @c24-form-width;
  font-size: 11px;
  line-height: 16px;
  color: #666;

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }
}
