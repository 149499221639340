.ThankyouOnlineContractStep {
  padding: 24px 48px;
  border-radius: 5px;
  box-shadow: @c24-section-box-shadow;
  width: @c24-form-width;
  background-color: @c24-color-white;
  margin-bottom: 24px;
}

.ThankyouOnlineContractStep-headerContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.ThankyouOnlineContractStep-booster {
  margin-bottom: 15px;
}

.ThankyouOnlineContractStep-inputWrapper {
  margin-bottom: 24px;
}

.ThankyouOnlineContractStep-header {
  display: flex;
  margin-bottom: 16px;
}

.ThankyouOnlineContractStep-headerIcon {
  margin-right: 8px;
  vertical-align: middle;

  > svg {
    height: 20px;
    width: 31px;
  }
}

.ThankyouOnlineContractStep-headerText {
  font-size: 16px;
  margin: 0;
}

.ThankyouOnlineContractStep-headerSubtext {
  font-size: 12px;
  margin: 0;
}

.ThankyouOnlineContractStep-inputRow {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;
}

.ThankyouOnlineContractStep-label {
  font-size: 16px;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 16px;
}

.ThankyouOnlineContractStep-input {
  padding-right: 36px;
}

.ThankyouOnlineContractStep-actionRow {
  padding-right: 36px;
  display: flex;
  justify-content: flex-end;
}

.ThankyouOnlineContractStep-nextPageButton {
  width: 240px;
  margin-bottom: 10px;
}

.ThankyouOnlineContractStep-errorSection {
  color: @c24-color-red;
  text-align: right;
  font-size: 16px;
  margin: 15px 40px 15px 0px;
}
