.CheckHint {
  display: flex;
  align-items: flex-end;
  margin-top: 2px;
  color: @c24-color-security-hint;
}

.CheckHint-checkIcon {
  width: inherit;
  width: 12px;
  height: 12px;
  margin-right: 7px;
  align-self: center;
  fill: @c24-color-security-hint;
}
