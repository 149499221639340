.OnboardingProductSelectionSection-subtitle {
  font-size: 14px;
}

.OnboardingProductSelectionSection-productSelectionOptions {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.OnboardingProductSelectionSection-hintCheckSvgWrapper {
  margin-top: 5px;
}

.OnboardingProductSelectionSection-hintText {
  margin-left: 10px;
  font-size: 14px;
  color: @c24-color-black;
}

.OnboardingProductSelectionSection-infoBox {
  padding: 5px 10px;
  font-size: 14px;
  background-color: @c24-color-vivid-orange-alpha-15;
  border-left: 5px solid @c24-color-vivid-orange;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 12px;
}
