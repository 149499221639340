@import '../../../styles/variables/colors';

.ResultLeftSideInput-label {
  margin-bottom: 4px;
  margin-right: 10px;
  font-weight: bold;
  color: @c24-color-result-label;
  font-size: 12px;
  display: inline-block;
}
