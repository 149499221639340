@banner-height: 75px; // max visible height of banner

.Page.is-redesign {
  .PensionGapBanner {
    margin-top: -16px;
  }
}

.PensionGapBanner {
  height: @banner-height;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &.is-tabletApp {
    margin-top: 5px;
  }
}

.PensionGapBanner-banner {
  height: @banner-height;
  display: flex;
  align-items: center;
  color: @c24-color-black;
  position: relative;
  flex-grow: 1;
}

.PensionGapBanner-contentBox {
  -webkit-text-size-adjust: none; // disable font resize in safari
  flex-grow: 1;
  color: @c24-color-catalina-blue;
  background-color: @c24-color-light-orange;
  display: flex;
  align-items: center;
  margin: 0px (@banner-height / 2) 0px (@banner-height / 2);
  padding: 14px 0;
}

.Page.is-redesign {
  .PensionGapBanner-content {
    font-size: 12px;
  }
}

.PensionGapBanner-content {
  width: 100%;
  text-align: center;
}

.PensionGapBanner-calendarImg {
  @calendar-image-inner-width: 1200px; // pixel count of actual logo
  @calendar-image-border-width: 60px; // pixel count of backdrop added
  @calendar-image-gap: @banner-height * @calendar-image-border-width / @calendar-image-inner-width; // gap scaled to banner height
  position: absolute;
  left: 0px;
  width: @banner-height + (@calendar-image-gap * 2);
  transform: translate(-@calendar-image-gap, 0);
}

.PensionGapBanner-countdownImg {
  @countdown-image-inner-width: 1200px; // pixel count of actual logo
  @countdown-image-border-width: 60px; // pixel count of backdrop added
  @countdown-image-gap: @banner-height * @countdown-image-border-width / @countdown-image-inner-width; // gap scaled to banner height
  position: absolute;
  left: 0px;
  width: @banner-height + (@countdown-image-gap * 2);
  transform: translate(-@countdown-image-gap, 0);
}

.PensionGapBanner-sloganImg {
  @slogan-image-inner-width: 1150px; // pixel count of actual logo
  @slogan-image-border-width: 180px; // pixel count of backdrop added
  @slogan-image-gap: @banner-height * @slogan-image-border-width / @slogan-image-inner-width; // gap scaled to banner height
  position: absolute;
  right: 0px;
  width: @banner-height + (@slogan-image-gap * 2);
  transform: translate(@slogan-image-gap, 0);
}

.PensionGapBanner-logos {
  margin-left: 30px;

  @media @c24-tablet-l-wide {
    display: none;
  }
}

.PensionGapBanner-daysLeft {
  font-size: 12px;
  color: @c24-color-catalina-blue;
  position: absolute;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  width: @banner-height;
  transform: scale(0.8);
}

.PensionGapBanner-days {
  font-size: 15px;
}

.PensionGapBanner-daysLeft-days {
  font-size: 25px;
  color: @c24-color-light-orange;
}

.PensionGapBanner-daysLeft-hours {
  font-size: 25px;
  font-weight: 700;
  color: @c24-color-light-orange;
  margin-bottom: 2px;
}

.PensionGapBanner-daysLeft-minutes {
  font-size: 14px;
  font-weight: 700;
  color: @c24-color-catalina-blue;
}
