@import '../../styles/variables/borders';

.ComparisonSelectedTariffsFooter {
  display: flex;
  border: @c24-border-default;

  & .ComparisonPage-spacer {
    padding: 0px;
  }

  .ComparisonPage-detailsButton {
    border-bottom: none;
    padding: 16px 0;
    border-left: @c24-border-default;
  }
}

.ComparisonSelectedTariffsFooter-container {
  background-color: @c24-color-white;
  border-left: @c24-border-default;
  position: relative;
  flex: 1;
}
