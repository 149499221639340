.PersonalLogin-iframeContainer {
  min-height: 480px;
  display: flex;
  justify-content: center;
}

.PersonalLogin-iframe {
  display: block;
  width: 548px;
}
