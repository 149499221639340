.ResultTariffDetailsDocumentsCategory-document {
  display: flex;
  align-items: center;
  &:last-of-type {
    margin-bottom: 6px;
  }
}

.ResultTariffDetailsDocumentsCategory-icon {
  height: 20px;
  width: 20px;
  margin-right: 32px;
  position: relative;
}
