.EFeedbackComment-container {
  border-top: @c24-border-default;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.EFeedbackComment-header {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.EFeedbackComment-name {
  font-weight: bold;
  flex: 1;
  margin-right: 5px;
}

.EFeedbackComment-thumbsUpContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.EFeedbackComment-thumbsUp {
  display: flex;
  font-size: 12px;
  color: @c24-color-dark-blue;
  cursor: pointer;
}

.EFeedbackComment-thumbsUpSvg {
  display: flex;
  width: 18px;
  height: 18px;
  margin: -3px 4px 0px 4px;
  fill: @c24-color-dark-blue;
}
