.ThankyouPage {
  font-size: 12px;
}

.ThankyouPage-columns {
  display: flex;
  justify-content: space-between;
}

.ThankyouPage-loadingSpinner {
  margin: auto;
}
