.EFeedbackTooltip-headLine {
  display: flex;
  align-items: center;
}

.EFeedbackTooltip-text {
  margin-bottom: 10px;
}

.EFeedbackTooltip-container {
  display: flex;
  color: @c24-color-dark-blue;
  cursor: pointer;
  margin-bottom: 6px;
}

.EFeedbackTooltip-starsLabel {
  width: 60px;
}

.EFeedbackTooltip-bar {
  flex: 1;
  margin: 4px 15px;
  background-color: @c24-color-background-grey;
  overflow: hidden;
}

.EFeedbackTooltip-innerBar {
  background-color: @c24-color-dark-blue;
  height: 100%;
}

.EFeedbackTooltip-ratingCount {
  width: 40px;
}

.EFeedbackTooltip-openAllButton {
  background: transparent !important;
  color: @c24-color-dark-blue;
  text-align: right;
  font-size: 12px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: normal;
  box-shadow: none !important;
}

.EFeedbackTooltip.hover .EFeedbackTooltip-innerBar {
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}
