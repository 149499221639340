.ConsultantBox-container {
  padding: 20px;
  background-color: @c24-color-background-light-grey;
  border: 1px solid @c24-color-divider-grey;
  border-radius: 3px;
}

.ConsultantBox-photoAndQuote {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 35px;
}

.ConsultantBox-personalDataAndContact {
  display: flex;
}

.ConsultantBox-quote {
  width: 100%;
  margin-left: 25px;
  font-size: 12px;
  border-radius: 3px;
  line-height: 18px;
  border: 1px solid @c24-color-divider-grey;
  background-color: @c24-color-light-blue;
  color: @c24-color-black;
  max-width: 285px;
  position: relative;
}

.ConsultantBox-quoteText {
  background-color: @c24-color-light-blue;
  min-height: 55px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 12px;
  padding-left: 15px;
}

.ConsultantBox-arrow {
  width: 25px;
  height: 25px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: @c24-color-divider-grey;
  background-color: @c24-color-light-blue;
  background-size: cover;
  transform: rotate(-44deg);
  position: absolute;
  top: calc(50% - 16px);
  left: -14px;
}

.ConsultantBox-photo {
  display: inline;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background-position: top center;
  background-size: cover;
}

.ConsultantBox-name {
  font-size: 18px;
  line-height: 18px;
  text-align: center;

  &.firstname {
    padding-bottom: 3px;
  }
}

.ConsultantBox-experience {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.ConsultantBox-contactBoxHeadline {
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 5px;
}

.ConsultantBox-phoneSvg {
  fill: @c24-color-icon-default;
  height: 21px;
  width: 14px;
  margin-right: 11px;
  margin-left: 10px;
}

.ConsultantBox-emailSvg {
  fill: @c24-color-icon-default;
  height: 19px;
  width: 26px;
  margin-right: 10px;
}

.ConsultantBox-emailContainer {
  display: flex;
  margin-top: 8px;
}

.ConsultantBox-contactBox {
  margin-left: 52px;
}

.ConsultantBox-contactTextContainer {
  display: flex;
  align-items: center;
}

.ConsultantBox-workingTimes {
  color: @c24-color-grey;
  line-height: 14px;
  margin-top: 9px;
}
