.PrintPageHeader {
  display: none;
}

@media print {
  .PrintPageHeader {
    display: block;
    margin-bottom: 10px;
  }

  .PrintPageHeader-headline {
    display: block;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 20px;
    color: @c24-color-headline;
    text-align: right;
  }

  .PrintPageHeader-contentBox {
    display: flex;
    font-size: 12px;
    margin: 0 auto;
    justify-content: space-between;
  }

  .PrintPageHeader-text {
    max-width: 500px;

    @media @c24-s-wide {
      max-width: 700px;
    }
  }

  .PrintPageHeader-printShortUrl {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    float: left;
    max-width: 400px;
  }

  .PrintPageHeader-searchDataText {
    font-size: 14px;
    text-align: right;
    margin: 0px;
  }

  .c24-cookie-consent-wrapper {
    display: none;
  }
}
