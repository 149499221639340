.PersonalDataSection {
  margin: 0px @c24-form-side-gap @c24-form-section-gap;
  padding-bottom: @c24-form-section-gap;
  border-bottom: 1px solid #dcdcdc;

  @media @c24-tablet-l-wide {
    margin: 0 24px 32px;
  }
}

.PersonalDataSection-row {
  width: 100%;
  margin-bottom: @c24-form-row-gap;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.PersonalDataSection-label {
  font-size: 15px;
  width: 40%;
  margin-top: 10px;
  &.has-twolines {
    margin-top: 0px;
  }
}

.PersonalDataSection-labelSubtitle {
  font-size: 11px;
  margin-top: 6px;
  color: @c24-color-grey;
}

.PersonalDataSection-input {
  width: 100%;
  position: relative;
  padding-right: @c24-form-side-help-text-gap;
}

.PersonalDataSectionBirthday-input {
  .PersonalDataSection-input;

  font-size: 13px;
  margin-left: 10px;
  margin-top: 10px;
}

.PersonalDataSection-bestSso {
  margin-bottom: 20px;
  display: flex;
}

.PersonalDataSection-bestSsoPersonalDataContainer {
  display: flex;
  padding: 16px 24px;
  flex-grow: 1;
  border: 1px solid @c24-color-light-grey;
  border-radius: 5px;
  padding: 16px 24px;
}

.PersonalDataSection-bestSsoFullName {
  font-weight: bold;
}

.PersonalDataSection-bestSsoPersonalData {
  flex: 1;
  font-size: 14px;
}

.PersonalDataSection-bestSsoChange {
  font-size: 12px;
  height: max-content;
  color: @c24-color-dark-blue;
  padding-left: 0;
  padding-right: 0;
  &:hover {
    text-decoration: underline;
    color: @c24-color-orange;
  }
  &:focus {
    border: @c24-border-input-focus;
  }
}

.PersonalDataSection-bestSsoSideHelpText {
  position: relative;
  padding-left: 18px;
  padding-top: 17px;
}

.PersonalDataSection-agreementLink {
  text-decoration: underline;
}

.PersonalDataSection-updateSsoData .Checkbox-labelText {
  font-size: 15px;
}

.PersonalDataSection-updateSsoDataSideHelpText {
  top: -12px;
}
