.ResultTariffDetails {
  min-width: 100%;
  background-color: @c24-color-white;
  border-top: 1px solid #ccc;
  border-radius: 3px;
}

.ResultTariffDetails-legendItem {
  display: flex;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
}

.ResultTariffDetails-legendIcon {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.ResultTariffDetails-legendContainer {
  display: flex;
  justify-content: center;
}

.ResultTariffDetails-closeButton {
  float: right;
  margin-top: 10px;
  margin-bottom: 5px;
}

.ResultTariffDetails-closeIcon {
  height: 15px;
  width: 15px;

  path {
    fill: @c24-color-nobel-grey;
  }
}

.ResultTariffDetails-featureList {
  padding-top: 8px;
}
