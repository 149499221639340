.TariffSustainabilityFilter {
  position: relative;
  display: block;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.TariffSustainabilityFilter-content {
  display: flex;
  flex-direction: column;
}

.TariffSustainabilityFilter-buttons {
  justify-content: space-between;
  display: flex;
  padding: 5px;
  margin: 0;
  height: 40px;
  max-height: 40px;
  text-align: center;
  cursor: pointer;
  border: @c24-border-divider-grey;
  z-index: 0;
  padding-bottom: 10px;
  padding-top: 10px;

  &:first-child {
    position: static;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:nth-child(2) {
    position: static;
    margin-top: -1px;
  }

  &:last-child {
    position: static;
    margin-top: -1px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    border: @c24-border-dark-blue;
    z-index: 1;
  }

  &.is-selected {
    background-color: @c24-color-light-blue;
    border: @c24-border-dark-blue;
    z-index: 1;
  }
}

.TariffSustainabilityFilter-leafSvgContainer {
  height: 18px;
  margin-right: 10px;
}

.TariffSustainabilityFilter-leafSvg {
  height: 18px;
  width: 18px;

  &.TariffSustainabilityFilter-leafSvg--green {
    fill: @c24-color-green;
  }

  &.TariffSustainabilityFilter-leafSvg--grey {
    fill: @c24-color-nobel-grey;
  }

  &.TariffSustainabilityFilter-leafSvg--first {
    margin-right: 7px;
  }
}

.TariffSustainabilityFilter-value {
  display: flex;
}

.TariffSustainabilityFilter-maxPossiblePension {
  font-size: 11px;
  color: @c24-color-grey;
}
