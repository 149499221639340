.TariffSummaryPaymentDetails-header {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

.TariffSummaryPaymentDetails-rowGroup {
  margin: 16px 0 0;
}

.TariffSummaryPaymentDetails-row {
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.TariffSummaryPaymentDetails-rowLabel {
  color: @c24-color-grey;
  display: flex;
  align-items: center;
}

.TariffSummaryPaymentDetails-rowValue {
  margin: 0px;
}

.TariffSummaryPaymentDetails-tariffFundsRow {
  display: flex;
  align-items: center;
}

.TariffSummaryPaymentDetails-infoSvgIcon {
  padding-left: 3px;
  height: 14px;
  width: 14px;
  pointer-events: auto;
  cursor: help;
  fill: @c24-color-nobel-grey;
}
