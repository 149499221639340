.OnboardingPersonalDataPage {
  display: flex;
  margin-bottom: 12px;
}

.OnboardingPersonalDataPage-formContainer {
  border-radius: 5px;
  box-shadow: @c24-section-box-shadow;
  width: @c24-input-form;
  background-color: @c24-color-white;
}

.OnboardingPersonalDataPage-link {
  max-width: 322px;
}
