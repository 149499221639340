.ResultInsuranceFilter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 10px;
}

.ResultInsuranceFilter-chipsList {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: scroll;
  scroll-behavior: smooth;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: -10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ResultInsuranceFilter-chipsList::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ResultInsuranceFilter-chipsList {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ResultInsuranceFilter-carouselSide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  background-color: @c24-color-background-light-grey;
  background-size: cover;
  cursor: pointer;
  z-index: 1;

  &.right {
    position: absolute;
    right: 0px;

    &.is-disabled {
      &::before {
        background-image: none;
      }
      > svg {
        * {
          fill: @c24-color-grey;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      right: 40px;
      height: 100%;
      width: 40px;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fafafa);
    }
  }

  &.left {
    position: absolute;
    left: 0px;

    &.is-filter-applied {
      left: 83px;
    }

    &.is-disabled {
      &::before {
        background-image: none;
      }
      > svg {
        * {
          fill: @c24-color-grey;
        }
      }
    }

    > svg {
      transform: rotate(180deg);
    }

    &::before {
      content: '';
      position: absolute;
      left: 40px;
      height: 100%;
      width: 40px;
      background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #fafafa);
    }
  }
}

.ResultInsuranceFilter-resetFilterLink {
  font-size: 12px;
  color: @c24-color-dark-blue;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    text-decoration: underline;
  }
}
