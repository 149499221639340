.ShareIcon--ios {
  fill: @c24-color-dark-blue;
  width: 17px;
  height: 22px;
}

.ShareIcon--android {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: @c24-color-dark-blue;
}
