.ConsultantCarouselItem-phoneNumber {
  font-size: 14px;
  color: @c24-color-dark-blue;
  display: flex;
  justify-content: center;
}

.ConsultantCarouselItem-blurbWrapper {
  display: flex;
  justify-content: space-between;
}

.ConsultantCarouselItem-container {
  position: relative;
  background-color: @c24-color-white;
  padding: 10px 15px;
  border-radius: 3px;
}

.ConsultantCarouselItem-consultantDetails {
  margin-top: 10px;
}

.ConsultantCarouselItem-phoneSvg {
  fill: @c24-color-icon-default;
  width: 11px;
  height: 16px;
  margin-right: 5px;
}

.ConsultantCarouselItem-additionalText {
  display: flex;
  align-items: flex-end;
  color: @c24-color-dark-blue;
  fill: @c24-color-dark-blue;
  font-size: 12px;
  width: 75px;
  justify-content: flex-end;
}

.ConsultantCarouselItem-expandCollapseArrow {
  width: 13px;
  height: 7px;
  margin-left: 5px;
  bottom: 6px;
  position: relative;
}

.ConsultantCarouselItem-consultantDetails {
  display: flex;
  justify-content: space-between;
}

.ConsultantCarouselItem-infoQuote {
  font-size: 12px;
  color: @c24-color-grey;
}

.ConsultantCarouselItem-infoTitle {
  font-size: 14px;
  color: @c24-color-black;
}

.ConsultantCarouselItem-name {
  text-align: center;
  font-size: 16px;
  color: @c24-color-black;
  margin-top: 6px;
  max-width: 100px;
}

.ConsultantCarouselItem-photoAndQuote {
  display: flex;
  flex-direction: column;
  flex-shrink: 100;
  padding-left: 30px;
}

.ConsultantCarouselItem-photoAndQuoteContainer {
  min-width: 100px;
  display: flex;
  margin-right: 30px;
}

.ConsultantCarouselItem-languages {
  width: 250px;
  margin-right: -20px;
}

.ConsultantCarouselItem-photo {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.ConsultantCarouselItem-quote {
  border-radius: 5px;
  padding: 20px;
  font-size: 18px;
  line-height: 22px;
  background-color: @c24-color-background-grey;
  color: @c24-color-black;
  position: relative;
  height: min-content;
  margin-left: 20px;
  align-self: center;
}

.ConsultantCarouselItem-arrow {
  display: block;
  position: absolute;
  top: 50%;
  left: -16px;
  width: 11px;
  height: 0;

  &:after {
    content: ' ';
    border-color: transparent @c24-color-background-grey;
    border-image: none;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    width: 1px;
    position: absolute;
    margin-top: -12px;
    top: 50%;
  }
}
