.HorizontalNavigation-wrapper {
  width: 100%;
  max-width: @c24-input-form;
  margin-bottom: 14px;
}

.HorizontalNavigation-pages {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 18px 0 42px;
}

.HorizontalNavigation-page {
  max-width: 26px;
  margin: 0 8px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: default;

  &.selected {
    .HorizontalNavigation-pageIcon {
      border: 2px solid @c24-color-dark-blue;
      background-color: @c24-color-dark-blue;
      color: @c24-color-white;
    }

    .HorizontalNavigation-pageName {
      color: @c24-color-black;
    }
  }

  &.completed {
    cursor: pointer;
    .HorizontalNavigation-pageIcon {
      border-color: @c24-color-dark-blue;
      color: @c24-color-dark-blue;

      svg {
        fill: @c24-color-dark-blue;
      }
    }

    .HorizontalNavigation-pageName {
      color: @c24-color-black;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.HorizontalNavigation-pageIcon {
  width: 26px;
  height: 26px;
  border: 2px solid @c24-color-nobel-grey;
  border-radius: 13px;
  background-color: @c24-color-white;
  color: @c24-color-nobel-grey;
  font-size: 14px;
  text-align: center;
  padding-top: 1px;
  margin-bottom: 5px;

  svg {
    height: 14px;
    width: 14px;
    margin-top: 3px;
    fill: @c24-color-nobel-grey;
  }
}

.HorizontalNavigation-resultPageIcon {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: @c24-color-white;
  color: @c24-color-nobel-grey;
  font-size: 14px;
  text-align: center;
  padding-top: 1px;
  margin-bottom: 5px;

  svg {
    height: 26px;
    width: 26px;
    fill: @c24-color-nobel-grey;
  }
}

.HorizontalNavigation-pageName {
  text-align: center;
  color: @c24-color-nobel-grey;
}

.HorizontalNavigation-line {
  height: 1px;
  background: @c24-color-nobel-grey;
  flex-grow: 1;
  margin-top: 12px;

  &.completed {
    background: @c24-color-dark-blue;
  }
}
