.Spinner {
  &:after {
    content: '';
    display: inline-block;
    height: 60px;
    width: 60px;
    border-style: solid;
    border-color: @c24-color-dark-blue rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-width: 10px;
    animation: spinAround 800ms infinite linear;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Spinner::after {
    height: 80px;
    width: 80px;
    /* IE spinner jumps, thus reset border and use svg as background image */
    border: 0;
    border-radius: 0;
    background-image: url(/spinner.svg);
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
