@import '../../../styles/variables/colors';

.EkomiLogo {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
}

.EkomiLogo-svg {
  cursor: pointer;
  pointer-events: auto;
  width: 231px;
  height: 58px;

  @media @c24-tablet-l-wide {
    width: 220px;
  }
}

.EkomiLogo-header {
  font-weight: 700;
  color: @c24-color-black;
  margin-bottom: 15px;
}

.EkomiLogo-tooltipSubHeadline {
  font-weight: bold;
  color: @c24-color-headline;
}

.EkomiLogo-tooltipAddressHeadline {
  margin: 8px 0;
}

.EkomiLogo-tooltipContent {
  color: @c24-color-popup-content;
}

.EkomiLogo-tooltip .Tooltip {
  width: 475px;
}
