.EFeedbackRatingPercentage {
  display: flex;

  svg > {
    width: 16px;
    height: 16px;
  }

  p > {
    margin: 0;
    padding-left: 7px;
  }

  .EFeedbackRatingPercentage-tooltipHeading {
    font-size: 11px;
    padding-bottom: 7px;
    margin: 0;
  }

  .EFeedbackRatingPercentage-tooltipSubText {
    font-size: 11px;
    margin: 0;
    padding: 0;
  }
}
