.EFeedbackCommentLabel-container {
  max-height: 55px;
  position: relative;
  overflow-y: hidden;

  &.is-fullHeight {
    max-height: none;
  }
}

.EFeedbackCommentLabel-moreButton {
  color: @c24-color-black;
  position: absolute;
  right: 0px;
  padding: 0 0 0 6px;
}
