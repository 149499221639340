.PointsAmountLogo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PointsAmountLogo-icon {
  width: 50px;
  height: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.PointsAmountLogo-number {
  position: absolute;
  font-size: 10px;
  top: 12px;
  left: 8px;
  font-weight: bold;
  font-style: italic;
  color: @c24-color-black;
  font-family: Verdana;
  line-height: normal;
}

.PointsAmountLogo-tooltip {
  .Tooltip {
    width: 363px;
  }
}

.PointsAmountLogo-tooltipMessage {
  margin-top: 20px;
}

.PointsAmountLogo-tooltipIcons {
  margin-top: 20px;
  display: flex;
}

.PointsAmountLogo-tooltipFooter {
  margin-top: 20px;
}

.PointsAmountLogo-tooltipIcon {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.PointsAmountLogo-tooltipIconImage {
  svg {
    height: 25px;
    width: auto;

    * {
      fill: @c24-color-catalina-blue;
    }
  }
}

.PointsAmountLogo-amount {
  white-space: nowrap;
}
