.FeatureDetails {
  display: flex;
  position: relative;

  &:hover {
    box-shadow: 0 0 5px 0 #999999;
    z-index: 900;
  }
}

.FeatureDetails-featureTitle {
  display: flex;
  position: relative;
  min-width: 41%;
  border-bottom: @c24-border-default;
}

.FeatureDetails-featureTitleText {
  width: 100%;
  padding: 10px 35px 10px 10px;
  display: flex;
}

.FeatureDetails-tooltip {
  position: absolute;
  right: 12px;
  top: 12px;
}

.FeatureDetails-featureContent {
  display: flex;
  border-bottom: @c24-border-default;
  padding: 10px 10px 5px 10px;
  flex-grow: 1;
}

.FeatureDetails-featureValue {
  width: 100px;
  padding-right: 10px;
}

.FeatureDetails-featureText {
  padding-bottom: 5px;
  width: 100%;
}

.FeatureDetails-documentValue {
  min-width: 150px;
}

.FeatureDetails-featureIcon {
  > svg {
    width: 20px;
    height: 20px;
  }
}
