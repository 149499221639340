@import '../../../styles/variables/colors';

.TrustFooter {
  display: flex;
  margin-top: 20px;
  margin-bottom: 32px;
  padding: 18px 25px;
  border-radius: 5px;
  background-color: @c24-color-trust-footer;
  box-shadow: @c24-section-box-shadow;
  max-width: 850px;

  &.is-error {
    position: absolute;
    bottom: 125px;
    width: 994px;
  }
  &.is-wide {
    max-width: 100%;
    margin-bottom: 25px;
    padding: 20px 58px;
  }
  &.has-border {
    border: @c24-border-default;
  }
}

.TrustFooter-headline {
  color: @c24-color-headline;
  font-size: 16px;
  font-weight: 300;

  &.is-larger {
    font-size: 20px;
  }
}

.TrustFooter-customerPromise {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}

.TrustFooter-section {
  font-size: 13px;
  max-width: 180px;
  text-align: center;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
}

.TrustFooter-logo {
  height: 90px;
  width: 100%;
  pointer-events: none;
}

.TrustFooter-text {
  font-size: 14px;
}

.TrustFooter-sectionText {
  margin: 0px;
  margin-top: 10px;
  font-size: 12px;

  &.smaller {
    max-width: 160px;
  }
}

.TrustFooter-sectionHeadline {
  color: @c24-color-headline;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
}

.TrustFooter-container {
  width: 100%;
  justify-content: space-between;

  @media @is-IE11 {
    width: 85%;
  }

  @media @c24-wide {
    .ResultPage &,
    .ComparisonPage & {
      flex: 65;
    }
  }
}

.TrustFooter-extraSpace {
  margin-top: auto;
}
