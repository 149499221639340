@import '../../styles/variables/colors';

.ThankyouInfo {
  border-radius: 5px;
  box-shadow: @c24-section-box-shadow;
  width: @c24-form-width;
  margin-bottom: 24px;
  padding: 24px 48px;
  background-color: @c24-color-white;
}

.ThankyouInfo-headline {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
}

.ThankyouInfo-checkSvg {
  width: 28px;
  height: 22px;
  margin-right: 15px;
  margin-top: 5px;
  fill: @c24-color-dark-green;
}

.ThankyouInfo-headlineText {
  color: @c24-color-dark-green;
  font-size: 22px;
  font-weight: normal;
  margin: 0px;
}

.ThankyouInfo-emailConfirmation {
  font-size: 12px;
  margin: 0;
}
