.TariffContractSummary {
  width: @c24-side-bar-width;

  @media @c24-tablet-l-wide {
    margin-right: 10px;
  }
}

.TariffContractSummary-separator {
  margin: 16px 0px;
  background-color: @c24-color-divider-grey;
  border: none;
  height: 1px;
}

.TariffContractSummary-pensionDetails {
  border-radius: 3px;
  background-color: @c24-color-box-background;
  padding: 16px 24px 24px;
  box-shadow: @c24-section-box-shadow;
  margin-bottom: 24px;

  @media @c24-tablet-l-wide {
    margin-bottom: 10px;
  }
}

.TariffContractSummary-tariffDetails {
  position: relative;
  border-radius: 3px;
  background-color: @c24-color-box-background;
  padding: 24px;
  box-shadow: @c24-section-box-shadow;
}

.TariffContractSummary-labels {
  margin-top: -15px;
  margin-left: -15px;
  padding-bottom: 5px;
}

.TariffContractSummary-eFeedback {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.TariffContractSummary-resultZero {
  margin-bottom: 7px;
}

.TariffContractSummary-promotion {
  margin-bottom: 7px;
}
