.OnboardingProductSelectionOption-productSelectionOption {
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  border: @c24-border-gainsboro-grey;
  transition: border-color 0.3s;
  will-change: border-color;
  cursor: pointer;

  &:hover {
    border: @c24-border-input-hover;
  }

  &.is-selected {
    border: @c24-border-dark-blue;
  }

  &.is-disabled {
    background-color: @c24-color-background-light-grey;
    cursor: default;
    &:hover {
      border: @c24-border-gainsboro-grey;
    }
  }
}

.OnboardingProductSelectionOption-productSelectionOptionBadge {
  width: max-content;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 10px;
  color: @c24-color-malaga-grey;
  background-color: @c24-color-malaga-grey-alpha-15;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.is-recommended {
    color: @c24-color-dark-green;
    background-color: @c24-color-dark-green-alpha-15;
  }

  &.is-disabled {
    color: @c24-color-grey;
  }

  .Tooltip-Root {
    width: 18px;
    height: 20px;
  }
}

.OnboardingProductSelectionOption-productSelectionOptionBadgeIcon {
  margin-left: 5px;
  width: 13px;
  height: 13px;
  fill: @c24-color-dark-green;
}

.OnboardingProductSelectionOption-productSelectionOptionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.OnboardingProductSelectionOption-productSelectionOptionTooltip {
  margin-top: 4px;
  cursor: help;
}

.OnboardingProductSelectionOption-questionMarkIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-nobel-grey;
}

.OnboardingProductSelectionOption-productSelectionOptionRadio {
  margin-top: 10px;
  margin-bottom: 10px;

  .RadioInput-optionLabel {
    font-size: 15px;
  }
}

.OnboardingProductSelectionOption-divider {
  height: 1px;
  width: 100%;
  background-color: @c24-color-gainsboro-grey;
  transition: background-color 0.3s;
  will-change: background-color;

  &.is-selected {
    background-color: @c24-color-dark-blue;
  }
}

.OnboardingProductSelectionOption-hintContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
  margin-bottom: 10px;
  min-height: 130px;

  .highlight {
    white-space: nowrap;
    font-weight: bold;
  }

  &.is-recommended {
    .highlight {
      color: @c24-color-dark-green;
    }
  }
}

.OnboardingProductSelectionOption-hint {
  display: flex;
  flex-direction: row;
}

.OnboardingProductSelectionOption-hintCheckSvgWrapper {
  margin-top: 5px;
}

.OnboardingProductSelectionOption-hintCheckSvg {
  width: 19px;
  height: 15px;
  fill: @c24-color-dark-green;

  &.is-grey {
    fill: @c24-color-malaga-grey;
  }
}

.OnboardingProductSelectionOption-hintText {
  margin-left: 10px;
  font-size: 14px;
  color: @c24-color-black;

  &.is-disabled {
    color: @c24-color-grey;
  }
}

.OnboardingProductSelectionOption-productSelectionOptionDetails {
  margin-top: 14px;
}

.OnboardingProductSelectionOption-detailsList {
  display: flex;
  flex-flow: column;
  gap: 8px;

  height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  &.is-open {
    margin-bottom: 15px;
    padding-right: 5px;
    height: 176px;
  }
}

.OnboardingProductSelectionOption-detailsBulletPoint {
  min-width: 6px;
  height: 6px;
  margin-top: 7px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: @c24-color-dark-grey;
}

.OnboardingProductSelectionOption-detailsListItem {
  display: flex;
  flex-direction: row;
  gap: 17px;
}

.OnboardingProductSelectionOption-detailsListItemText {
  font-size: 14px;
}

.OnboardingProductSelectionOption-showDetailsButton {
  font-size: 14px;
  color: @c24-color-dark-blue;
  cursor: pointer;
  text-decoration: none;
  text-align: right;

  &:hover {
    text-decoration: underline;
  }
}

.OnboardingProductSelectionOption-showDetailsButtonIcon {
  width: 15px;
  height: 8px;
  fill: @c24-color-dark-blue;
  margin-right: 10px;
  rotate: 180deg;
  transition: all 0.3s ease-in-out;
  transition-delay: 150ms;

  &.is-up {
    rotate: 360deg;
  }
}

.OnboardingProductSelectionOption-hintInfoSvgWrapper {
  margin-top: 5px;
}

.OnboardingProductSelectionOption-hintInfoSvg {
  width: 18px;
  fill: @c24-color-grey;
}

.OnboardingProductSelectionOption-hintWarningSvg {
  width: 19px;
  height: 19px;
  fill: @c24-color-malaga-grey;
}
