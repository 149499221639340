@import '../../../styles/variables/colors';

.SecurityHint {
  display: flex;
  color: @c24-color-security-hint;
  font-size: 12px;
  min-width: max-content;
  margin-top: 8px;
}

.SecurityHint-lockIcon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  position: relative;
  fill: @c24-color-security-hint;
}
