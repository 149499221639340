.AppointmentBookingPhoneModal-content {
  padding: 5px 30px;
}

.AppointmentBookingPhoneModal-header {
  font-size: 13px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  svg {
    height: 23px;
    width: 17px;
    margin-right: 10px;
    fill: @c24-color-dark-blue;
  }
}

.AppointmentBookingPhoneModal-message {
  font-size: 14px;
}

.AppointmentBookingPhoneModal-ctaButton {
  margin-top: 18px;
  width: 255px;
  float: right;
}

.AppointmentBookingPhoneModal-phoneNumber {
  margin-top: 15px;
}
