.EFeedback-container {
  background-color: @c24-color-white;
  border-top: @c24-border-default;
  border-radius: 3px;
  min-height: 362px;
  display: flex;
  padding: 10px;

  @media @is-IE11 {
    height: 400px;
  }
}

.EFeedback-loadingSpinner {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EFeedback-leftContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 10px;

  & > div {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.EFeedback-ratingsHeader {
  font-size: 12px;
  font-weight: bold;
}

.EFeedback-ratingStarsContainer {
  display: flex;
  margin-bottom: 5px;
  align-items: center;

  & > div {
    margin-right: 5px;
  }
}

.EFeedback-rightContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: @c24-border-default;
  padding-left: 10px;
}

.EFeedback-commentsHeader {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.EFeedback-commentsHeaderText {
  flex: 1;
}

.EFeedback-closeIcon {
  height: 15px;
  width: 15px;

  path {
    fill: @c24-color-nobel-grey;
  }
}
