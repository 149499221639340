.RadioInput {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 34px;

  &.is-vertical {
    padding: 14px 0px 14px 0px;
  }

  &:focus,
  &:focus-within {
    border: @c24-border-input-focus;
    border-radius: 5px;
  }

  &.is-disabled {
    pointer-events: none;
    color: @c24-color-grey;
  }

  &.is-error {
    border-color: @c24-color-red;
  }

  &.is-selected {
    border-color: @c24-color-dark-blue;
  }
}

.RadioInput-optionInput {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
}

.RadioInput-optionLabelDiv {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  height: 100%;
  justify-content: space-between;

  &.is-vertical {
    margin-left: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
  }

  &.is-error {
    border-bottom-color: @c24-color-red;
  }

  &.is-selected {
    border-bottom-color: @c24-color-dark-blue;
  }
}

.RadioInput-optionLabel {
  font-size: 14px;
  cursor: pointer;

  &.is-vertical:before {
    left: 0px;
    top: 15px;
  }

  &.is-vertical:after {
    left: 6px;
    top: 21px;
  }

  &.is-disabled:before {
    background-color: @c24-color-background-light-grey;
  }

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: @c24-border-input;
    left: 0px;
    top: 7px;
    transition: 0.3s border-color;
    border-color: @c24-color-nobel-grey;
    background-color: @c24-color-white;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 8px;
    height: 8px;
    border-radius: 4px;
    top: 13px;
    left: 6px;
    background-color: @c24-color-dark-blue;
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: center center;
    transition:
      opacity 0.3s,
      transform 0.3s cubic-bezier(0.5, -0.8, 0.5, 1.8);
  }

  &.is-selected:after {
    opacity: 1;
    transform: scale(1, 1);
  }

  &.is-selected:before {
    border-color: @c24-color-dark-blue;
  }

  &.is-error:before {
    border-color: @c24-color-is-error;
  }

  &.is-error:after {
    border-color: @c24-color-is-error;
  }
}
