.AppointmentBookingTool-container {
  border-radius: 5px;
  box-shadow: @c24-section-box-shadow;
  width: @c24-form-width;
  margin-bottom: 24px;
  background-color: @c24-color-white;
  padding-top: 20px;
}

.AppointmentBookingTool-timeSlotsSpinner {
  height: 460px;
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppointmentBookingTool-calendarWrapper {
  margin-right: 18px;
}

.AppointmentBookingTool-calendar {
  margin-right: 0;
  width: 100%;
  border-radius: 3px;
  border: @c24-border-divider-grey;
  border-spacing: 0px;
  padding-bottom: 5px;
  display: block;

  thead,
  tbody {
    th,
    td {
      width: 88px;
      min-width: 88px;
    }
  }

  th {
    border-right: @c24-border-divider-grey;
    padding: 0;
    font-weight: 100;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 7px;
    padding-right: 10px;
  }

  th:last-of-type {
    border-right: none;
  }

  tr {
    display: block;
  }

  thead {
    border-bottom: @c24-border-divider-grey;
    display: block;
  }

  tbody {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    position: relative;

    td {
      padding: 0px;
    }

    &.has-footer {
      min-width: 280px;
      max-height: 400px;
      &::after {
        content: '';
        position: sticky;
        display: block;
        bottom: 0px;
        height: 10px;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
      }
    }
  }

  tfoot {
    display: block;
    width: 100%;
    position: relative;
    padding-top: 5px;

    tr {
      display: block;
      width: 100%;
      padding-right: 16px;

      td {
        display: block;
        width: 100%;
      }
    }
  }
}

.AppointmentBookingTool-scrollToBottom {
  margin: 0 auto;
  display: block;

  &:disabled {
    .AppointmentBookingTool-scrollToBottomIcon {
      * {
        fill: @c24-color-grey;
      }
    }
  }
}

.AppointmentBookingTool-scrollToBottomIcon {
  width: 17px;
  height: 9px;
  * {
    fill: @c24-color-dark-blue;
  }
}

.AppointmentBookingTool-section {
  padding: 0px 48px 24px 48px;
}

.AppointmentBookingTool-headingWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 15px;
}

.AppointmentBookingTool-calendarSvg {
  width: 23px;
  height: 23px;
  margin-right: 12px;
  fill: @c24-color-dark-blue;
}

.AppointmentBookingTool-heading {
  font-size: 16px;
  margin: 0px;
}

.AppointmentBookingTool-textWrapper {
  display: flex;
  font-size: 12px;
  align-items: flex-start;
  margin-top: 16px;
}

.AppointmentBookingTool-text {
  margin: 0;
  font-size: 12px;
}

.AppointmentBookingTool-bookingWrapper {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
}

.AppointmentBookingTool-emailContactHeader {
  margin: 0px;
  font-size: 12px;
  margin-bottom: 6px;
}

.AppointmentBookingTool-emailContactText {
  margin: 0px;
  margin-bottom: 9px;
}

.AppointmentBookingTool-emailWrapper {
  display: flex;
  align-items: center;
}

.AppointmentBookingTool-emailSvg {
  fill: @c24-color-icon-default;
  height: 16px;
  width: 22px;
  margin-right: 10px;
}
