@import '../../../styles/variables/colors';

.ResultLeftSide {
  pointer-events: none;

  &.is-inactive {
    filter: grayscale(0.8);
    opacity: 0.5;

    & * {
      pointer-events: none;
    }
  }
}

.ResultLeftSide-input {
  position: relative;
  display: block;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ResultLeftSide-recalcButton {
  padding: 6px;
  font-size: 15px;
}

.ResultLeftSide-box {
  margin-bottom: 15px;
}

.ResultLeftSide-phone {
  font-size: 15px;
  color: @c24-color-label;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.ResultLeftSide-phoneSvg {
  fill: @c24-color-icon-default;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.ResultLeftSide-mail {
  .ResultLeftSide-phone;
  cursor: pointer;
}

.ResultLeftSide-mailAtSvg {
  .ResultLeftSide-phoneSvg;
}

.ResultLeftSide-officeHours {
  margin-left: 20px;
  margin-top: 10px;
}

.ResultLeftSide-tooltipIcon {
  width: 16px;
  height: 16px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
  vertical-align: top;
}

.ResultLeftSide-tooltipIcon * {
  fill: @c24-color-question-mark;
}

.hover {
  .ResultLeftSide-tooltipIcon {
    fill: @c24-color-icon-active;
  }

  .ResultLeftSide-tooltipIcon * {
    fill: @c24-color-icon-active;
  }
}

.ResultLeftSide-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  cursor: help;
}

.ResultLeftSide-consultantRecalcWithNoFiltersButton {
  margin-top: 10px;
  background-color: @c24-color-dark-blue;
}
