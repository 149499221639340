.AppointmentBookingConfirmation-container {
  width: @c24-form-width;
  padding: 24px 48px 24px 48px;
  border-radius: 3px;
  box-shadow: @c24-section-box-shadow;
  margin-bottom: 24px;
  background-color: @c24-color-white;
}

.AppointmentBookingTool-confirmationMessageBox {
  display: flex;
  align-items: flex-start;
}

.AppointmentBookingConfirmation-checkSvg {
  width: 28px;
  height: 22px;
  fill: @c24-color-dark-green;
  margin-right: 15px;
  margin-top: 5px;
}

.AppointmentBookingConfirmation-confirmationMessageBox {
  color: @c24-color-dark-green;
  font-size: 22px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}

.AppointmentBookingConfirmation-confirmationMessage {
  color: @c24-color-dark-green;
  font-size: 22px;
}

.AppointmentBookingConfirmation-emailConfirmationInfo {
  font-size: 12px;
}

.AppointmentBookingConfirmation-appointmentDateDetailBox {
  display: flex;
  margin-top: 16px;
  align-items: center;
}

.AppointmentBookingConfirmation-appointmentDateDetail {
  font-size: 12px;
  font-weight: bold;
}

.AppointmentBookingConfirmation-calendarSvg {
  width: 23px;
  height: 23px;
  fill: @c24-color-dark-blue;
  margin-right: 7px;
}
