@import '../../styles/variables/colors';
@import '../../styles/variables/borders';

.ComparisonSelectedTariffsHeader {
  display: flex;
  border-left: @c24-border-default;
  border-top: @c24-border-default;
}

.ComparisonSelectedTariffsHeader-tariffBlock {
  border-left: @c24-border-default;
  background-color: @c24-color-white;
  flex: 1;
  position: relative;
  padding: 0 14px 8px;
  min-width: 1px; /* This is because a bug in firefox with long tariff names */

  &:last-of-type {
    border-right: @c24-border-default;
  }

  &.is-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
  }
}

.ComparisonSelectedTariffsHeader-possiblePension {
  display: block;
  color: @c24-color-price;
  font-size: 22px;
  font-weight: 700;
}

.ComparisonSelectedTariffsHeader-guaranteedPension {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #333;
}

.ComparisonSelectedTariffsHeader-pensionDescription {
  display: block;
  font-size: 11px;
  &.ComparisonSelectedTariffsHeader-possiblePensionDescription {
    color: @c24-color-dark-green;
  }
}

.ComparisonSelectedTariffsHeader-insuranceDetailsContainer {
  margin-top: 10px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ComparisonSelectedTariffsHeader-insuranceLogo {
  display: block;
  height: 50px;
  max-width: 200px;

  @media @c24-s-wide {
    max-height: 55px;
  }
}

.ComparisonSelectedTariffsHeader-close {
  position: absolute;
  right: 16px;
  top: 11px;
  pointer-events: auto;
  width: 14px;
  height: 14px;
  fill: @c24-color-close;
  cursor: pointer;

  @media @c24-tablet-m-wide {
    right: 5px;
  }
}

.ComparisonSelectedTariffsHeader-tariffName {
  text-align: center;
  color: @c24-color-insurance-name-text;
  margin: 10px 0 0 0;
  font-size: 14px;
  font-weight: 300;
}

.ComparisonSelectedTariffsHeader-pensionAmountContainer {
  text-align: center;
}

.ComparisonSelectedTariffsHeader-addTariff {
  width: 62px;
  height: 62px;
  fill: @c24-color-icon-default;

  &:hover {
    fill: @c24-color-button-hover;
    cursor: pointer;
  }
}

.ComparisonSelectedTariffsHeader-labels {
  margin-top: 7px;
  min-height: 24px;
}

.ComparisonSelectedTariffsHeader-resultZeroHeader {
  padding-right: 5px;
  padding-left: 5px;
}

.ComparisonSelectedTariffsHeader-labelSlot {
  min-height: 24px;
  margin-bottom: 7px;
}

.ComparisonSelectedTariffsHeader-tariffPromotionHeader {
  padding-left: 5px;
  padding-right: 5px;
}

.ComparisonSelectedTariffsHeader-wishListHeartContainer {
  position: absolute;
  top: 36px;
  right: 13px;

  @media @c24-tablet-m-wide {
    right: 3px;
  }
}
