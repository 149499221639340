.ResultTariffDetailsFeatureDetails {
  display: flex;
  position: relative;
  border-bottom: @c24-border-default;
  padding: 4px;
}

.ResultTariffDetailsFeatureDetails-featureTitle {
  display: flex;
  position: relative;
  min-width: 41%;
  max-width: 41%;
  margin-right: 30px;
}

.ResultTariffDetailsFeatureDetails-featureTitleText {
  min-width: 90%;
  max-width: 90%;
  display: flex;
}

.ResultTariffDetailsFeatureDetails-featureContent {
  display: flex;
  flex-grow: 1;
}

.ResultTariffDetailsFeatureDetails-featureValue {
  width: 50px;
  margin-right: 8px;
}

.ResultTariffDetailsFeatureDetails-featureText {
  width: 100%;
}

.ResultTariffDetailsFeatureDetails-documentValue {
  min-width: 150px;
}

.ResultTariffDetailsFeatureDetails-featureIcon {
  > svg {
    width: 16px;
    height: 16px;
  }
}

.ResultTariffDetailsFeatureDetails-tooltip {
  margin-left: 3px;
}

.ResultTariffDetailsFeatureDetails-tooltipIcon {
  width: 16px;
  height: 16px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
  margin-right: 1px;
}

.ResultTariffDetailsFeatureDetails-tooltipIcon * {
  fill: @c24-color-question-mark;
}

.hover {
  .ResultTariffDetailsFeatureDetails-tooltipIcon {
    fill: @c24-color-icon-active;
  }

  .ResultTariffDetailsFeatureDetails-tooltipIcon * {
    fill: @c24-color-icon-active;
  }
}
