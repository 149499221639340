.ConsultantCarousel-wrapper {
  min-width: 750px;
  margin-top: 15px;
  border-radius: 3px;
  box-shadow: @c24-section-box-shadow;
  background-color: @c24-color-white;
  max-width: 1005px;
  min-height: 205px;
  position: relative;

  .slick-prev,
  .slick-next {
    z-index: 1 !important;
    top: 80px !important;
    fill: @c24-color-light-grey;
    height: 36px !important;
    width: 10px !important;

    &:before {
      content: '' !important;
      position: absolute !important;
      top: -15px !important;
      left: -15px !important;
      right: -15px !important;
      bottom: -30px !important;
    }
  }

  .slick-next {
    right: 15px !important;
  }

  .slick-prev {
    left: 15px !important;
  }

  .nextArrow,
  .prevArrow {
    height: 36px;
    width: 10px;
  }
}
