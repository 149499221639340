.ComparisonInquiryButton-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

.ComparisonInquiryButton-container {
  pointer-events: none;
  &.is-secondary {
    margin-top: 20px;
  }
}

.ComparisonInquiryButton-tooltip {
  .Tooltip {
    width: auto;
    white-space: nowrap;
  }
}

.ComparisonInquiryButton-onlineContractHint {
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 1px;
  background-color: @c24-color-cyan-green;
  color: @c24-color-black;
}
