.PensionGapBannerContent {
  font-size: 15px;
}

.PensionGapBannerContent-tooltip {
  font-size: 12px;
  margin-left: 10px;
  .Tooltip {
    width: 465px;
  }
}

.PensionGapBannerContent-tooltipContentSection {
  margin-bottom: 15px;
  font-weight: initial;

  &:last-child {
    margin-bottom: 0;
  }
}

.PensionGapBannerContent-infoIcon {
  width: 13px;
  height: 13px;
  fill: @c24-color-catalina-blue;
}

.PensionGapBannerContent-hint {
  display: inline-block;
}
