.ConsultantCarouselAdditionalText-container {
  background-color: @c24-color-white;
  padding: 30px 15px 25px 15px;
  border-top: @c24-border-light-grey;
  position: relative;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.ConsultantCarouselAdditionalText-closeButton {
  position: absolute;
  top: 10px;
  height: 10px;
  right: 15px;
  width: 12px;
  fill: @c24-color-nobel-grey;
  cursor: pointer;
}

.ConsultantCarouselAdditionalText-header {
  font-size: 16px;
  color: @c24-color-black;
  margin-bottom: 6px;
  font-weight: bold;
}

.ConsultantCarouselAdditionalText-headlineRow {
  display: flex;
  margin-top: 25px;
}

.ConsultantCarouselAdditionalText-headlineIcon {
  width: 80px;
  text-align: center;
  margin-right: 10px;
}

.ConsultantCarouselAdditionalText-headlineHeader {
  font-size: 15px;
  color: @c24-color-black;
  font-weight: bold;
}

.ConsultantCarouselAdditionalText-headlineInfoText {
  font-size: 13px;
  line-height: 20px;
  color: @c24-color-black;
}

.ConsultantCarouselAdditionalText-icon {
  width: 60px;
  height: 60px;
  position: relative;
  top: 3px;

  &.is-cobrowsing {
    width: 75px;
    height: 35px;
  }
}

.ConsultantCarouselAdditionalText-headlineInfo {
  flex: 1;
}
