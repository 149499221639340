@import '../../styles/variables/borders';

.ComparisonSelectedTariffsInfo {
  display: flex;
  border-left: @c24-border-default;
}

.ComparisonSelectedTariffsInfo-container {
  background-color: @c24-color-white;
  margin: 0;
  padding: 16px 0 0;
  border-left: @c24-border-default;
  flex: 1;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    border-right: @c24-border-default;
  }
}

.ComparisonSelectedTariffsInfo-grade {
  display: flex;
  height: 100px;
  justify-content: center;
  border-bottom: @c24-border-default;
  padding-top: 10px;

  @media @c24-wide {
    height: 110px;
  }
}

.ComparisonSelectedTariffsInfo-headlineContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: @c24-color-background-dark-grey;
}

.ComparisonSelectedTariffsInfo-headlineButtons {
  display: flex;
  padding-top: 16px;
  background-color: @c24-color-background-dark-grey;
  height: 55px;
  border-bottom: @c24-border-default;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
}

.ComparisonSelectedTariffsInfo-headlineGrade {
  display: flex;
  border-bottom: @c24-border-default;

  width: 100%;
  min-height: 100px;
  padding: 0 12px 0 16px;
  color: @c24-color-black;

  @media @c24-wide {
    min-height: 110px;
  }
}

.ComparisonSelectedTariffsInfo-headlineGradeTitle {
  font-size: 14px;
  margin-top: 14px;
}

.ComparisonSelectedTariffsInfoButton-add {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  color: @c24-color-dark-blue;
  cursor: pointer;
  padding: 10px;
  display: block;
}
