.LifelongPensionBox-container {
  background-color: @c24-color-booster-green-lighter;
  box-shadow: @c24-section-box-shadow;
  border-radius: 3px;
  padding: 7px 10px 7px 10px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.LifelongPensionBox-text {
  padding-top: 4px;
  font-size: 13px;
  margin-right: 10px;
}

.LifelongPensionBox-icon {
  min-width: 36px;
  height: 30px;
  display: block;
  margin-bottom: 2px;
  margin-right: 16px;
}

.LifelongPensionBox-tooltip {
  margin-bottom: 6px;
}

.LifelongPensionBox-questionMarkIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-nobel-grey;
}
