.ResultHighestPensionGuaranteeBox {
  width: 100%;
  margin-bottom: 25px;
  position: relative;
}

.ResultHighestPensionGuaranteeBox-contentContainer {
  width: 100%;
  padding: 15px;
  background: linear-gradient(to right, @c24-color-catalina-blue, @c24-color-dark-blue);
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.ResultHighestPensionGuaranteeBox-Title {
  font-size: 16px;
  font-weight: 700;
  color: @c24-color-white;
  margin: 0;
  margin-left: 79px;
  margin-right: 4px;
}

.ResultHighestPensionGuaranteeBox-sealContainer {
  top: -5px;
  left: 10px;
  position: absolute;
}

.ResultHighestPensionGuaranteeBox-seal {
  width: 64px;
  height: 77px;
}

.ResultHighestPensionGuaranteeBox-infoIcon {
  fill: @c24-color-white;
  width: 15px;
  height: 15px;
  margin-left: 3px;
}

.ResultHighestPensionGuaranteeBox-tooltip {
  margin-top: 4px;
  & .Tooltip-content {
    & .Tooltip {
      width: 500px;
    }
  }
}

.ResultHighestPensionGuaranteeBox-tooltipContentSection {
  margin-bottom: 15px;
  font-weight: initial;

  &:last-child {
    margin-bottom: 0;
  }
}
