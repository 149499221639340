@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';

.PageHeader {
  margin-bottom: 14px;
}

.ResultPageHeader {
  border-bottom: @c24-border-divider-grey;
}

.PageHeader-contentBox {
  display: flex;
  font-size: 12px;
  margin: 0 auto;
  justify-content: space-between;
}

@media print {
  .PageHeader-contentBox {
    display: none;
  }
}

.PageHeader-text {
  max-width: 500px;

  @media @c24-s-wide {
    max-width: 700px;
  }
}

.PageHeader-headline {
  display: flex;
  font-size: 23px;
  font-weight: bold;
  color: @c24-color-headline;
}

.PageHeader-searchCriteriaText {
  display: none;
  color: @c24-color-result-label;
  margin-bottom: 12px;

  &.is-result {
    display: block;
  }
}

.PageHeader-backLinkWrapper {
  margin-top: 10px;
}

.PageHeader-backLink {
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  text-wrap: nowrap;
  white-space: nowrap;

  &.is-inactive {
    filter: grayscale(0.8);
    opacity: 0.5;
    pointer-events: none;
  }

  @media @c24-tablet-l-wide {
    font-size: 12px;
  }
}

.PageHeader-onboardingLink {
  color: @c24-color-dark-blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.PageHeader-images {
  display: flex;
}

.PageHeader-savePageAsPdfBox {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.PageHeader-savePageAsPdf {
  align-items: center;
  align-self: center;
  width: 240px;
  height: 30px;
  margin-right: 15px;
}

.PageHeader-printResultMessage {
  margin-top: 5px;
  margin-bottom: 0px;

  &.is-success {
    color: @c24-color-green;
  }

  &.is-error {
    color: @c24-color-red;
  }
}

.PageHeader-documentLink {
  height: 30px;
  width: 30px;
  margin-left: 15px;
  cursor: pointer;
  svg {
    fill: @c24-color-dark-blue;
  }
}

.PageHeader-navigationBox {
  display: flex;
  align-items: baseline;
  margin-top: 10px;
}

.PageHeader-shareCurrentPageIconContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-right: 24px;

  & > p {
    margin: 0;
    color: @c24-color-dark-blue;
    font-size: 12px;
    margin-top: 4px;
  }
}
