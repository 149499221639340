.ResultTariffList-singleRow {
  position: relative;
  margin-bottom: 15px;

  @media @c24-wide {
    font-size: 13px;
  }

  @media @c24-m-max-wide {
    font-size: 12px;
  }
}

.ResultTariffList-comparisonHintBoxTextContainer {
  background-color: @c24-color-light-blue;
  box-shadow: @c24-hint-box-shadow;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
}

.ResultTariffList-comparisonHintBox {
  width: 100%;
  color: @c24-color-black;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  margin-top: 15px;
  justify-content: space-between;
  cursor: pointer;

  @media @c24-tablet-l-wide {
    font-size: 15px;
  }

  @media @c24-tablet-m-wide {
    font-size: 14px;
  }

  @media @c24-tablet-s-wide {
    font-size: 12px;
  }
}

.ResultTariffList-comparisonHint {
  font-weight: 700;
}

.ResultTariffList-comparisonIcon {
  color: @c24-color-dark-blue;
  min-width: 45px;
  min-height: 48px;
  margin-right: 6px;
  margin-left: 6px;
  margin-top: -11px;
}

.ResultTariffList-leftContainer {
  display: flex;
}

.ResultTariffList-comparisonHintButton {
  width: 150px;
  height: 23px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #005ea8;
  font-weight: 400;
  text-decoration: none solid rgb(0, 94, 168);
  text-align: right;
}

.ResultTariffList-comparisonSvg {
  transform: rotate(90deg);
  height: 15px;
  margin-right: 15px;
  vertical-align: text-bottom;

  @media @c24-tablet-m-wide {
    margin-right: 5px;
  }
}

.ResultTariffList-CheckIcon {
  width: 19px;
  min-width: 19px;
  height: 16px;
  margin-top: 3px;
  margin-right: 10px;
  fill: @c24-color-green;
}

.ResultTariffList-riesterMessage {
  padding-left: 15px;
  border: solid @c24-color-dark-green 1px;
  background-color: #e5f2e5;
  margin-bottom: 20px;
  margin-top: 15px;
}
.ResultTariffList-riesterMessageHeadline {
  font-size: 15px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.ResultTariffList-riesterMessageList {
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding-right: 15px;
  list-style-type: none;
  padding-left: 0px;
}
.ResultTariffList-riesterMessageListItem {
  display: flex;
  list-style-type: none;
  padding-bottom: 5px;
}
.ResultTariffList-riesterMessageListItemText {
  padding: 0px;
  margin: 0px;
}

.ResultTariffListItem-rowTabsContainer {
  display: flex;
  padding-right: 10px;
}

.ResultTariffListItem-bulletsContainer {
  flex: 1;
}
