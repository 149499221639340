.InquiryJourneyTypeSelector {
  margin-bottom: 24px;
  margin: 0px @c24-form-side-gap @c24-form-row-gap;

  @media @c24-tablet-l-wide {
    margin: 24px;
  }
}

.InquiryJourneyTypeSelector-options {
  display: flex;
  gap: 8px;
}

.InquiryJourneyTypeSelector-option {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid @c24-color-gainsboro-grey;
  border-radius: 5px;
  padding: 14px;
  padding-right: 6px;
  width: 100%;

  &:hover {
    border: @c24-border-input-hover;
  }
  &.is-selected {
    border: 1px solid @c24-color-dark-blue;
  }
}

.InquiryJourneyTypeSelector-radioInput {
  .RadioInput-optionLabelDiv {
    justify-content: center;
  }
  .RadioInput-optionLabel {
    font-size: 16px;
  }
}

.InquiryJourneyTypeSelector-contentContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-top: 1px solid @c24-color-gainsboro-grey;
  padding-top: 8px;
}

.InquiryJourneyTypeSelector-bulletPoint {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: @c24-color-black;
}

.InquiryJourneyTypeSelector-checkIcon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  fill: @c24-color-dark-green;
}
