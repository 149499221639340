.AppointmentBookingExpiredSession-container {
  width: 548px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px 48px 24px 48px;
  border-radius: 3px;
  box-shadow: @c24-section-box-shadow;
  background-color: @c24-color-white;
}

.AppointmentBookingExpiredSession-expiredSessionInfoBox {
  display: flex;
  margin-bottom: 18px;
}

.AppointmentBookingExpiredSession-expiredSessionInfo {
  margin-left: 10px;
}
