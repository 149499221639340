@import '../../styles/variables/borders';

.OnboardingSection {
  display: block;
  border-bottom: @c24-border-section;
  padding: 32px 0px 32px 0px;
  margin-right: 48px;
  margin-left: 48px;

  &.OnboardingSection--noPaddingBottom {
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 24px;
  }
}

.OnboardingSection-header {
  font-size: 22px;
  color: @c24-color-headline;
  margin-bottom: 24px;
  line-height: 23px;
  display: flex;
}

.OnboardingSection-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding-right: 36px;
  margin-bottom: 24px;

  &.OnboardingSection-row--withBiggerMarginBottom {
    margin-bottom: 35px;
  }

  &.OnboardingSection-row--noMarginBottom {
    margin-bottom: 0px;
  }

  &.OnboardingSection-row--withMarginTop {
    margin-top: 24px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.OnboardingSection-inlineRow {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-right: 43px;
  margin-bottom: 24px;

  &.OnboardingSection-inlineRow--noMarginBottom {
    margin-bottom: 0px;
  }
}

.OnboardingSection-inlineRow-label {
  font-size: 15px;
  align-items: center;
  height: 40px;
  display: flex;
  margin-bottom: 24px;
}

.OnboardingSection-label {
  font-size: 15px;
  width: @c24-form-label;
  align-items: center;
  height: 40px;
  display: flex;
}

.OnboardingSection-input {
  display: block;
  width: @c24-form-input;
}

.OnboardingSection-documentLink {
  margin-left: 8px;
  cursor: pointer;
  svg {
    fill: @c24-color-dark-blue !important;
    width: 22px;
    height: 22px;
  }
}
