.Grade {
  position: relative;
  height: 80px;
  float: right;
  cursor: default;

  &.has-overlay {
    cursor: help;
  }

  @media @c24-wide {
    height: 90px;
    width: 78px;
  }
}

.Grade-icon {
  height: 80px;
  width: 68px;
  background-repeat: no-repeat;
  background-size: 68px 80px;
  background-image: url(/grey-grade.svg);
  display: block;

  &.is-golden {
    background-image: url(/gold-grade.svg);
  }

  @media @c24-wide {
    background-size: 78px 90px;
    height: 90px;
    width: 78px;
  }
}

.Grade-calculatedValue {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 25px;
  font-size: 24px;
  font-weight: 700;
  color: @c24-color-black;

  @media @c24-wide {
    top: 28px;
    font-size: 28px;
  }
}

.Grade-subText {
  position: absolute;
  top: 17px;
  width: 100%;
  text-align: center;
  font-size: 9px;
  color: @c24-color-black;

  @media @c24-wide {
    top: 18px;
    font-size: 12px;
  }
}

.Grade-gradeText {
  position: absolute;
  font-size: 10px;
  top: 62px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: @c24-color-grade-text;

  @media @c24-wide {
    top: 71px;
    font-size: 10px;
  }

  &.is-bold {
    font-weight: 700;
  }
}

//NOTE: Remove when golden banner campaign ends
body:has(.YearEndBanner) .Grade-icon {
  background-image: url(/grey-grade.svg);
}
