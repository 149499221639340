@import '../../styles/variables/colors';
@import '../../styles/variables/borders';

.ResultSorting {
  background-color: @c24-color-white;
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
  border-top: @c24-border-divider-grey;
  border-bottom: @c24-border-divider-grey;
  width: 100%;
  height: 36px;

  &.is-inactive {
    filter: grayscale(0.8);
    opacity: 0.5;

    & * {
      pointer-events: none;
    }
  }
}

.ResultSorting-item {
  position: relative;
  cursor: pointer;
  padding: 0px;
  flex-grow: 1;
  width: 25%;
  text-align: center;
  color: @c24-color-black;
  font-size: 13px;

  &:before {
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    bottom: -1px;
  }

  &:hover {
    background-color: @c24-color-light-grey;
  }

  &:hover:before {
    width: 1px;
    background-color: @c24-color-light-grey;
  }

  &.is-selected {
    background-color: @c24-color-selected-sorting-item;
    border-bottom: 2px solid @c24-border-selected-sorting-item;
    margin-left: -1px;
    margin-bottom: -1px;
    border-left: 1px solid white;
    border-right: 1px solid white;
    color: @c24-color-black;

    &:hover:before {
      width: 0;
    }
  }

  &:last-child {
    .ResultSorting-itemText {
      border: none;
    }
  }
}

.ResultSorting-itemText {
  margin: 4px 0;
  padding: 4px 0;
  height: 26px;
  border-right: @c24-border-default;

  &.is-last,
  &.is-selected {
    border-right: none;
  }
}

.ResultSorting-submenuItem {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    background: @c24-color-background-grey;
  }
}

.ResultSorting-submenuItemSelectedIcon {
  position: absolute;
  height: 12px;
  width: 15px;
  fill: @c24-color-dark-blue;
  left: -25px;
  top: 3px;
}

.ResultSorting-submenuItemText {
  position: relative;
}
