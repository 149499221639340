.Tooltip-content {
  position: absolute;
  z-index: 10000;
  display: block;
  opacity: 0;
  pointer-events: none !important;
  transition: opacity 250ms;
}

.Tooltip-Root {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  align-self: flex-start;
  align-items: center;
}

.hover {
  overflow: visible !important;

  .Tooltip-icon {
    fill: @c24-color-icon-active !important;
  }

  .Tooltip-content {
    opacity: 1 !important;
  }

  .Tooltip {
    pointer-events: all !important;
    opacity: 1 !important;
  }

  .Tooltip-arrow {
    opacity: 1 !important;
  }

  .Tooltip-arrow-shadow {
    opacity: 1 !important;
  }
}

.Tooltip-arrow {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #fff;
  transform: rotate(315deg);
  z-index: 15;
  opacity: 0 !important;
}

.Tooltip-arrow-shadow {
  width: 12px;
  height: 12px;
  position: absolute;
  transform: rotate(315deg);
  z-index: -10;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.45);
  opacity: 0 !important;
}

.Tooltip {
  position: relative;
  z-index: 10;
  border-radius: 5px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.45);
  width: 320px;
  pointer-events: auto;
  font-size: 12px !important;
  line-height: 1.6;
  color: @c24-color-tooltip-content !important;
  font-weight: 300;
  pointer-events: none;
  text-align: left;
  opacity: 0 !important;
  padding-right: 25px;
  transform: translate3d(0, 0, 0);
}

.Tooltip-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 9px;
  top: 9px;
  cursor: pointer;
  fill: @c24-color-grey;
}

.Tooltip-close.hidden {
  opacity: 0;
}

.Tooltip-headline {
  color: @c24-color-headline;
  font-weight: bold;
}

.Tooltip-hoverRef {
  position: relative;
}
