.OnboardingProductSelectionCalculationTipTableRow-headerCol {
  text-align: left;
  border-top: 1px solid @c24-color-nobel-grey;
  border-left: none;
  padding-left: 0px;
  font-weight: normal;
  padding-right: 10px;
  min-width: 170px;
  padding-top: 12px;
  padding-bottom: 12px;

  &.no-border-top {
    border-top: none;
    padding-top: 0px;
  }
}

.OnboardingProductSelectionCalculationTipTableRow-riesterCol {
  min-width: 170px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  border-left: 1px solid @c24-color-nobel-grey;
  border-top: 1px solid @c24-color-nobel-grey;

  &.no-border-top {
    border-top: none;
    padding-top: 0px;
  }

  &.is-disabled {
    color: @c24-color-grey;
  }
}

.OnboardingProductSelectionCalculationTipTableRow-dataCol {
  min-width: 170px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  border-left: 1px solid @c24-color-nobel-grey;
  border-top: 1px solid @c24-color-nobel-grey;

  &.no-border-top {
    border-top: none;
    padding-top: 0px;
  }
}
