@import '../../../styles/variables/colors';

.TariffSelectionCheckbox-tooltipContent {
  font-size: 12px;
  color: @c24-color-tooltip-content;
}

.TariffSelectionCheckbox-tooltipClose {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 16px;
  width: 16px;
  fill: @c24-color-close;

  &:hover {
    fill: @c24-color-icon-hover;
  }
}

.TariffSelectionCheckbox-tooltipIcon {
  display: inline-block;
}

.TariffSelectionCheckbox-tooltip {
  position: absolute;
  background-color: white;
  margin-left: -249px;
  margin-top: -69px;
  text-align: left;
  border-radius: 3px;
  width: 240px;
  opacity: 1 !important;
  pointer-events: all !important;
  padding-right: 15px !important;

  &:before {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    right: -8px;
    top: 50%;
    margin-top: -5px;
    border-left: 8px solid @c24-color-dark-blue;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
  }

  &:after {
    border-left: 6px solid white;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: -6px;
    top: 50%;
    margin-top: -4px;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
  }
}

.TariffSelectionCheckbox-checkbox label {
  font-size: 12px;

  @media @c24-wide {
    font-size: 13px;
  }

  & .Checkbox-label {
    align-self: center;
  }
}

.TariffSelectionCheckbox-looperTooltip {
  position: absolute;
  color: @c24-color-black !important;
  background-color: @c24-color-light-blue;
  border: 1px solid @c24-color-dark-blue;
  margin-left: -180px;
  margin-top: -35px;
  text-align: left;
  font-size: 14px;
  width: 168px;
  line-height: 1.3;
  padding: 14px 8px;
  opacity: 1 !important;
  pointer-events: all !important;
  padding-right: 15px !important;
  box-shadow: none !important;
  &:before {
    border-bottom: 9px solid transparent;
    border-top: 9px solid transparent;
    border-left: 7px solid @c24-color-dark-blue;
    top: 30px;
    left: 166px;
    margin-top: -10px;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
  }

  &:after {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 6px solid @c24-color-light-blue;
    top: 29px;
    left: 166px;
    margin-top: -8px;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
  }
}

.TariffSelectionCheckbox-looperTooltipClose {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 5px;
  height: 10px;
  width: 10px;
  fill: @c24-color-nobel-grey;
  & > path {
    fill: @c24-color-nobel-grey;
  }

  &:hover {
    fill: @c24-color-icon-hover;
  }
}

.TariffSelectionCheckbox-looperTooltipTip {
  margin-right: 8px;
  float: left;
  width: 25px;
  height: 25px;
}
