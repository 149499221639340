.ResultRankingBox {
  display: flex;
  background-color: @c24-color-divider-grey;
  padding: 8px 15px;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: flex-start;
  color: @c24-color-black;
}

.ResultRankingBox-closeButton {
  margin: 3px 0px 0px 12px;
  padding: 0;
  display: flex;
  align-items: center;
}

.ResultRankingBox-closeIcon {
  height: 12px;
  width: 12px;
  * {
    fill: @c24-color-nobel-grey;
  }

  *:hover {
    fill: @c24-color-silver-grey;
  }
}
