.TariffSummaryContractDetails-header {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

.TariffSummaryContractDetails-rowGroup {
  margin: 16px 0 0;
}

.TariffSummaryContractDetails-row {
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.TariffSummaryContractDetails-rowLabel {
  color: @c24-color-grey;
}

.TariffSummaryContractDetails-rowValue {
  margin: 0px;
}

.TariffSummaryContractDetails-tariffFundsRow {
  display: flex;
  align-items: center;
}
