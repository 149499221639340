.ResultPage-filter {
  flex: 0 0 230px;
  margin-right: 15px;
  z-index: 2;

  @media @c24-wide {
    margin-right: 25px;
  }

  .ResultPage-stationaryFilter {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  &.sticky {
    .ResultPage-stationaryFilter {
      opacity: 0;
    }
    .ResultPage-stickyFilter {
      position: fixed;
      width: 250px;
      top: 0px;
      animation: resultPageFadeIn 0.3s ease-in-out;
      animation-fill-mode: forwards;
    }
  }
  .ResultPage-stickyFilter {
    animation: resultPageFadeOut 0.3s ease-in-out;
    animation-fill-mode: forwards;
  }
}

.ResultPage-stickyFilter {
  display: none;
  padding-top: 15px;
  z-index: 1;
  height: 0;
}

@keyframes resultPageFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes resultPageFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
