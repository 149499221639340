body {
  display: block !important; // disable nextjs fouc

  &.disableScrolling {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
}

.c24-notification,
#c24-header-bottom {
  display: none;
}

.c24-customer {
  &.c24-customer-guest {
    &.is-hidden {
      display: none;
    }
  }

  &.c24-customer-user {
    &.is-hidden {
      display: none;
    }
  }

  &.c24-customer-expired {
    &.is-hidden {
      display: none;
    }
  }
}

.c24-notification-layer-hover {
  right: 25px !important;

  &.is-loggedIn {
    right: 15px !important;
  }
}

.c24-notification-layer-corner {
  right: 180px !important;
}

.c24-customer {
  &.c24-customer-guest {
    &.is-hidden {
      display: none;
    }
  }

  &.c24-customer-user {
    &.is-hidden {
      display: none;
    }
  }
}

.c24-contact-heading {
  font-weight: bold;
  font-size: 12px;
  color: @c24-color-black;
}

.c24-contact-text {
  margin-top: 20px;
  font-size: 12px;
  color: @c24-color-black;
  line-height: 15px;
}

.c24-contact-phoneNumber {
  font-weight: bold;
  font-size: 12px;
  color: @c24-color-dark-blue;
  line-height: 30px;
}

.c24-contact-openingTimes {
  font-size: 12px;
  color: @c24-color-black;
}

.c24-contact-email {
  font-size: 12px;
  color: @c24-color-dark-blue;
}

.c24-footer-top.is-tabletApp {
  width: 100%;
}

.c24-footer {
  padding-bottom: env(safe-area-inset-bottom);
}

.c24-page-container.c24-page-container-content.is-tabletApp {
  padding-bottom: 64px !important;
}

.c24-page-container {
  &.has-grey-background {
    background-color: @c24-color-background-light-grey;
  }
}

.c24-grid-flex-content {
  @media @c24-wide {
    max-width: 1290px !important;
  }
}

#c24-page-container-content {
  flex-grow: 0;
}

.c24-footer-bottom {
  color: @c24-color-black !important;
}

.c24-footer-bottom-links ul li a {
  color: @c24-color-black !important;
}
