.ResultLeftSideNotification-container {
  cursor: pointer;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  position: fixed;
  color: @c24-color-white;
  background: @c24-color-tangaroa-blue;
  width: 236px;
  margin-left: -2px;
  z-index: 10;
  padding: 15px 15px 20px;

  opacity: 0;
  bottom: -75px;

  &.is-visible {
    animation: NotificationBox 6s ease-in;
  }
}

@keyframes NotificationBox {
  0% {
    opacity: 0;
    bottom: -75px;
  }
  10% {
    opacity: 0.5;
    bottom: 15px;
  }
  20% {
    opacity: 0.9;
    bottom: 15px;
  }
  90% {
    opacity: 0.9;
    bottom: 15px;
  }
  100% {
    opacity: 0;
    bottom: -75px;
  }
}

.ResultLeftSideNotification-header {
  font-weight: 700;
  font-size: 13px;
}

.ResultLeftSideNotification-body {
  font-size: 12px;
  margin-top: 5px;
}
