@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';
@import '../../../styles/variables/backgrounds';

.ResultLeftSideBox {
  padding: 0;
  border: @c24-border-divider-grey;
  pointer-events: all;
}

.ResultLeftSideBox-title {
  font-size: 15px;
  padding: 6px 8px;
  background-color: @c24-color-white;
  border-bottom: @c24-border-divider-grey;
}

.ResultLeftSideBox-body {
  background-color: @c24-color-white;
  padding: 15px 10px 15px 10px;
}
