.InvestmentTypeSelect-container {
  display: flex;
  margin-top: 5px;
  justify-content: space-around;
  padding: 0 12px;

  .InvestmentTypeSelect-buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .InvestmentTypeSelect-iconContainer {
      display: flex;
      width: 50px;
      height: 48px;
      box-shadow: 1px 1px 5px 0 @c24-color-grey;
      border-radius: 5px;
      justify-content: center;
      align-items: flex-end;
      border: 1px solid transparent;
      padding: 6px 0;
      transition-duration: 200ms;

      &:hover {
        border: 1px solid @c24-color-dark-grey;
      }

      &.is-selected {
        background-color: @c24-color-dark-blue;

        svg {
          fill: @c24-color-white;
        }

        &:hover {
          border: 1px solid transparent;
        }
      }

      svg {
        fill: @c24-color-grey;
        transition-duration: 200ms;
        &.large {
          width: 28px;
          height: 34px;
        }

        &.small {
          width: 15px;
          height: 20px;
        }
      }
    }

    .InvestmentTypeSelect-buttonTitle {
      margin-top: 5px;
    }
  }
}

.InvestmentTypeSelect-tooltip .Tooltip {
  width: 353px;
}
