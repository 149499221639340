.AppointmentBookingToolSummary-selectedAppointmentDetails {
  display: flex;
  flex-direction: column;
}

.AppointmentBookingToolSummary-selectedAppointmentWrapper {
  margin-top: 25px;
  padding-right: 8px;
}

.AppointmentBookingToolSummary-selectedAppointmentHeading {
  font-size: 12px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AppointmentBookingToolSummary-selectedAppointmentContent {
  display: flex;
}

.AppointmentBookingToolSummary-selectedAppointmentCtaSection {
  flex: 1;
}

.AppointmentBookingToolSummary-selectedAppointment {
  padding: 15px 15px;
  font-size: 12px;
  border: @c24-border-divider-grey;
  border-radius: 3px;
  margin-top: 7px;
  text-align: center;
  height: 90px;
  width: 200px;
  line-height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppointmentBookingToolSummary-selectedAppointmentButton {
  margin-top: 16px;
}

.AppointmentBookingToolSummary-noSelectionError {
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 0;
  color: @c24-color-red;
  max-width: 200px;
}
