.OnboardingContributionInsuranceStartRiester-infoBox {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: @c24-color-dark-green-alpha-15;
  color: @c24-color-black;
  font-size: 14px;
  border-left: 5px solid @c24-color-dark-green;
  margin-right: 36px;
  border-radius: 0px 5px 5px 0px;

  &.OnboardingContributionInsuranceStartRiester-boosterBox {
    margin-bottom: 24px;

    .OnboardingContributionInsuranceStartRiester-boosterBoxIcon {
      width: 23px;
      height: 23px;
      margin-right: 10px;
    }
  }

  &.OnboardingContributionInsuranceStartRiester-noOneTimePaymentBoosterBox {
    .OnboardingContributionInsuranceStartRiester-boosterCheckIconSvg {
      margin-right: 10px;
      svg {
        width: 22px;
        height: 16px;
        fill: @c24-color-dark-green;
      }
    }
  }
}

.OnboardingContributionInsuranceStartRiester-insuranceStartTypes {
  display: flex;
  position: relative;
  padding-right: 36px;
}

.OnboardingContributionInsuranceStartRiester-sideHelpText {
  .SideHelpText-questionMark {
    margin-top: 0px;
  }
}

.OnboardingContributionInsuranceStartRiester-insuranceStartOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.OnboardingContributionInsuranceStartRiester-insuranceStartOption {
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  border: @c24-border-gainsboro-grey;
  transition: border-color 0.3s;
  will-change: border-color;
  cursor: pointer;

  &:hover {
    border: @c24-border-input-hover;
  }

  &.is-selected {
    border: @c24-border-dark-blue;
  }
}

.OnboardingContributionInsuranceStartRiester-insuranceStartOptionBadge {
  width: max-content;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 10px;
  color: @c24-color-malaga-grey;
  background-color: @c24-color-malaga-grey-alpha-15;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.is-recommended {
    color: @c24-color-dark-green;
    background-color: @c24-color-dark-green-alpha-15;
  }

  .Tooltip-Root {
    width: 18px;
    height: 20px;
  }
}

.OnboardingContributionInsuranceStartRiester-insuranceStartOptionBadgeIcon {
  margin-left: 5px;
  width: 13px;
  height: 13px;
  fill: @c24-color-dark-green;
}

.OnboardingContributionInsuranceStartRiester-insuranceStartOptionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.OnboardingContributionInsuranceStartRiester-insuranceStartOptionRadio {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .RadioInput-optionLabel {
    font-size: 15px;
  }
}

.OnboardingContributionInsuranceStartRiester-divider {
  height: 1px;
  width: 100%;
  background-color: @c24-color-gainsboro-grey;
  transition: background-color 0.3s;
  will-change: background-color;

  &.is-selected {
    background-color: @c24-color-dark-blue;
  }
}

.OnboardingContributionInsuranceStartRiester-hintContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;

  .highlight {
    white-space: nowrap;
    font-weight: bold;
  }

  &.is-recommended {
    .highlight {
      color: @c24-color-dark-green;
    }
  }
}

.OnboardingContributionInsuranceStartRiester-hint {
  display: flex;
  flex-direction: row;
}

.OnboardingContributionInsuranceStartRiester-hintCheckSvgWrapper {
  margin-top: 5px;
}

.OnboardingContributionInsuranceStartRiester-hintCheckSvg {
  width: 19px;
  height: 15px;
  fill: @c24-color-malaga-grey;

  &.is-recommended {
    fill: @c24-color-dark-green;
  }
}

.OnboardingContributionInsuranceStartRiester-hintText {
  margin-left: 10px;
  font-size: 14px;
  color: @c24-color-black;
}
