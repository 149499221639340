@media print {
  @page {
    size: auto;
    margin: 3px 0;
  }

  .Main {
    background-color: transparent !important;
  }

  body {
    -webkit-print-color-adjust: exact;
    margin: 0;
    zoom: 80% !important;
  }

  svg,
  div {
    display: block;
  }

  .c24-page-container,
  .c24-cookie-consent-notice {
    display: none !important;
  }

  .ComparisonDetails-featureTitle,
  .ComparisonDetails-tariffFeature {
    border: none;
    outline: @c24-border-comparison-features;
  }

  .ComparisonSelectedTariffsFeatures-categories {
    border: none;

    &.has-lineBreakOnPrinting {
      page-break-before: always;
    }
  }

  .Page {
    max-width: none;
    width: 100%;
    padding: 10px 30px !important;
  }

  .CampaignBanner {
    display: none;
  }

  .TrustFooter {
    display: none !important;
  }

  .PageHeader-images {
    display: none !important;
  }

  .PageHeader-headline {
    margin-bottom: 0;
  }

  .PageHeader-text {
    text-align: right;
    font-size: 14px;
  }

  .PageHeader-check24Logo {
    display: block;
    height: 45px;
    width: auto;
  }

  .PageHeader-printButtonContainer,
  .PageHeader-backLink {
    display: none;
  }

  .PageHeader-searchCriteriaText {
    display: block;
  }

  .Grade-icon {
    border: 1px solid @c24-color-black !important;

    &.is-golden {
      border: 1px solid #ffca04 !important;
    }
  }

  .ComparisonStickyHeader {
    display: none !important;
  }

  .ComparisonSelectedTariffsFeatures-filterContainer,
  .ComparisonSelectedTariffsFeatures-categoriesTariffConditions {
    display: none;
  }

  .ComparisonSelectedTariffsFeatures {
    margin: 0;
  }

  .ComparisonSelectedTariffsFooter,
  .ComparisonSelectedTariffsHeader-close {
    display: none !important;
  }

  .ComparisonSelectedTariffsInfo-headlineButtons {
    height: auto !important;
  }

  .ComparisonPage-backButton,
  .OnlineContractButton,
  .ComparisonPage-detailsButton > .Button,
  .ComparisonPage-detailsButton > a {
    visibility: hidden;
  }

  .ComparisonSelectedTariffsInfo-container,
  .ComparisonPage-detailsButton,
  .ComparisonSelectedTariffsInfo-headlineButtons {
    padding-top: 0 !important;
  }

  .ComparisonSelectedTariffsHeader-insuranceDetailsContainer {
    height: 100px !important;
  }

  .ComparisonDetails-tooltipContainer {
    display: none;
  }

  .ComparisonPage-infoFooter {
    display: block !important;
    font-size: 14px;
    bottom: 20px;
    position: fixed;
  }

  .ComparisonSelectedTariffsFeatures-selectedFilterText {
    display: block !important;
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
    color: @c24-color-dark-blue;
  }

  .ComparisonSelectedTariffsFeatures-filterContainer {
    display: none !important;
  }

  .ConsultantHeader {
    display: none;
  }

  .ResultZeroHeader-infoContainer {
    display: none;
  }

  .ComparisonDetails-tariffFeatureTooltip {
    &:before {
      content: none;
    }
  }

  .ComparisonInquiryButton-wrapper {
    visibility: hidden;
  }

  .ComparisonSelectedTariffsFeatures-categories,
  .ComparisonPage-legendContainer,
  .ComparisonPage-disclaimerFooter,
  .ComparisonPage-infoFooter {
    page-break-inside: avoid;
  }

  .ComparisonSelectedTariffsHeader-wishListHeartContainer {
    display: none;
  }
}
