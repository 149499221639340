.ThankyouTariffSummary-summary {
  width: @c24-side-bar-width;
}

.ThankyouTariffSummary-separator {
  margin: 16px 0px;
  background-color: @c24-color-divider-grey;
  border: none;
  height: 1px;
}

.ThankyouTariffSummary-tariffDetails {
  border-radius: 3px;
  background-color: @c24-color-box-background;
  padding: 24px;
  box-shadow: @c24-section-box-shadow;
}

.ThankyouTariffSummary-tariffInfoRow {
  display: flex;
  margin-bottom: 5px;
}

.ThankyouTariffSummary-tariffInfoLabel {
  width: 150px;
}

.ThankyouTariffSummary-tariffInfoDescription {
  font-weight: 400;
}

.ThankyouTariffSummary-tariffInfoHeader {
  margin-bottom: 5px;
}
