.OnboardingTooltip-infoIcon {
  width: 15px;
  height: 15px;
  fill: @c24-color-nobel-grey;
}

.OnboardingTooltip-questionMarkIcon {
  width: 18px;
  height: 18px;
  fill: @c24-color-nobel-grey;
  position: relative;
  margin-top: 2px;
}

.OnboardingTooltip-anchoredTooltip {
  width: auto;
  position: absolute !important;
  right: 15px;
  text-align: left;
  top: 35%;

  &.Tooltip.type-light.place-top:after {
    border-top-width: 7px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    bottom: -7px;
    margin-left: -9px;
    height: 1px;
  }

  &.Tooltip.type-light.place-bottom:after {
    border-bottom-width: 7px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    top: -7px;
    margin-left: -9px;
    height: 1px;
  }
}
