.EFeedbackComments-container {
  display: flex;
  flex-direction: column;
  border-left: @c24-border-default;
  padding-left: 10px;
}

.EFeedbackComments-loadingSpinner {
  .EFeedback-loadingSpinner;
}

.EFeedbackComments-commentsContainer {
  flex: 1;

  &.has-noComments {
    display: flex;
    align-items: center;
  }
}

.EFeedbackComments-noCommentsLabel {
  font-size: 16px;
  color: @c24-color-light-grey;
  flex: 1;
  text-align: center;
}

.EFeedbackComments-navigationContainer {
  display: flex;
  justify-content: flex-end;
}

.EFeedbackComments-button {
  color: @c24-color-dark-blue;

  &:disabled {
    text-decoration: none;
    color: @c24-color-light-grey;
    cursor: not-allowed;
  }
}

.EFeedbackComments-prevButton {
  .EFeedbackComments-button;

  &:before {
    content: '\00AB';
    margin-right: 2px;
  }
}

.EFeedbackComments-nextButton {
  .EFeedbackComments-button;

  &:after {
    content: ' \00BB';
    margin-left: 2px;
  }
}
