@import './variables/colors';

.Page {
  padding-top: 32px;
  color: @c24-color-page-text;
  font-family: Verdana, Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.428571429; // 20/14;
  min-width: 1px;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  max-width: @c24-max-page-size;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.is-tabletApp {
    max-width: @c24-max-page-size-wide;
    padding: 0 10px;
  }

  &.is-redesign {
    background-color: @c24-color-background-light-grey;
    padding-top: 32px;
    max-width: @c24-redesign-max-page-size;

    @media @c24-tablet-l-wide {
      max-width: @c24-redesign-max-tablet-page-size;
      margin-left: 15px;
    }
  }
}
