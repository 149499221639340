.ResultPage {
  max-width: @c24-max-page-size-wide;
  padding: 20px 10px 0px;
}

.ResultPage-container {
  display: flex;
  margin-bottom: 6px;
}

.ResultPage-resultSummaryRow {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  min-width: 700px;

  @media @c24-tablet-l-wide {
    margin-left: 5px;
    font-size: 12px;
  }
}

.ResultPage-tariffs {
  position: relative;
  width: 100%;
  min-width: 750px;
  max-width: 1005px;
  padding-bottom: 90px;
}

.ResultPage-tariffsSkeleton {
  position: relative;
  width: 100%;
  min-width: 750px;
  max-width: 1005px;
  padding-bottom: 90px;
}

.ResultPage-disclaimerFooter {
  position: absolute;
  bottom: 0px;
}

.BackToTopButton {
  display: none;
  @media @c24-wide {
    display: inline;
    position: fixed;
    bottom: 105px;
    margin-left: 1020px;
  }
}
