@import '../../../styles/variables/colors.less';

.TariffHeaderBox {
  margin-bottom: 15px;

  .EFeedback-container {
    border: @c24-border-default;
    margin-top: -0.5px; // iOS fix;
  }
}

.TariffHeaderBox-tariff {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: @c24-color-header;
  padding: 15px;

  &.has-eFeedback {
    padding-bottom: 0px;
  }
}

.TariffHeaderBox-row {
  display: flex;
}

.TariffHeaderBox-selectButton {
  margin-top: 8px;
}

.TariffHeaderBox-left {
  flex: 30;
  width: 200px;
  height: 80px;
}

.TariffHeaderBox-center {
  flex: 40;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.TariffHeaderBox-right {
  flex: 30;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.TariffHeaderBox-insuranceLogo {
  max-width: 180px;
  margin-bottom: 5px;
  max-height: 80px;
}

.TariffHeaderBox-tariffName {
  margin-bottom: 5px;
}

.TariffHeaderBox-possiblePrice {
  margin-top: -6px;
  font-size: 24px;
  font-weight: bold;
  color: @c24-color-price;
}

.TariffHeaderBox-guaranteedPrice {
  font-size: 16px;
  font-weight: bold;
  color: @c24-color-black;
  margin-top: 7px;
  text-align: right;
}

.TariffHeaderBox-priceDescription {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:last-of-type {
    margin-bottom: 10px;
  }
}

.TariffHeaderBox-infoSvgIcon {
  height: 12px;
  width: 12px;
  pointer-events: auto;
  cursor: help;
  fill: @c24-color-nobel-grey;
  &.TariffHeaderBox-infoSvgIcon-possiblePensionInfoSvgIcon > * {
    fill: @c24-color-dark-green !important;
  }
}

.TariffHeaderBox-infoSvgIcon * {
  fill: @c24-color-nobel-grey !important;
}

.TariffHeaderBox-priceText {
  margin-right: 5px;
  &.TariffHeaderBox-possiblePriceText {
    color: @c24-color-dark-green;
  }
}

.TariffHeaderBox-centerItem {
  margin-top: 5px;
  display: flex;

  &:first-of-type {
    margin-top: 0;
  }
}

.TariffHeaderBox-centerItemLabel {
  width: 130px;
  display: inline-block;
}

.TariffHeaderBox-centerItemValue {
  font-weight: bold;
  margin-left: 5px;
  width: 170px;
}

.TariffHeaderBox-gradeContainer {
  margin-right: 65px;
  margin-left: 35px;
}

.TariffHeaderBox-tabHeader {
  color: @c24-color-dark-blue;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 10px;
  &:hover {
    text-decoration: underline;
  }

  &.is-open {
    background-color: @c24-color-white;
    border: @c24-border-default;
    border-bottom: none;
    padding: 9px 9px 10px;
  }
}

.TariffHeaderBox-tabArrow {
  display: block;
  width: 10px;
  height: 10px;
  fill: @c24-color-dark-blue;
  margin-left: 5px;

  &.is-open {
    transform: rotate(180deg);
  }
}

.TariffHeaderBox-EFeedbackTabHeader {
  position: relative;
  bottom: -1px;
  margin-left: -10px;

  .Tooltip {
    width: 380px;
    text-align: left;
    cursor: default;
  }
}

.TariffHeaderBox-topRow {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.TariffHeaderBox-resultZero {
  margin-right: 15px;
}

.TariffHeaderBox-promotion {
  margin-right: 15px;
}

.TariffHeaderBox-wishListHeart {
  margin-left: auto;
}
