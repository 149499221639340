.TrustBox {
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;

  @media @is-IE11 {
    flex: 1 0 0;
  }
}
.TrustBox-infoContainer {
  margin-top: 10px;
  text-align: right;
}

.TrustBox-slider {
  width: 208px;

  .slick-prev,
  .slick-next {
    width: 14px;
    height: 50px;
    fill: @c24-color-gainsboro-grey;
    z-index: 1;
  }

  .slick-next {
    right: 5px;
  }

  .slick-prev {
    left: 5px;
  }
}

.TrustBox-serviceRating {
  height: 103px;
}

.TrustBox-vertical {
  height: 132px;
}
.TrustBox-svg {
  height: 60px;
  max-width: 120px;
}

.TrustBox-sliderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 132px;
}

.TrustBox-infoContainer {
  text-align: right;
  margin-top: 10px;
  margin-right: 5px;
}
