.hover-link:hover {
  color: #c05702;
}
.long-hover-link:hover {
  color: #c05702;
  text-decoration: none;
}
.nowrap {
  white-space: nowrap;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #b4b4b4;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b4b4b4;
  opacity: 1 !important;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b4b4b4;
  opacity: 1 !important;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b4b4b4 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input::-ms-clear {
  display: none;
}
.Main {
  color: #333333;
  font-size: 12px;
  line-height: 1.42857143;
  text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.Main * {
  box-sizing: border-box;
}
.Main.has-grey-background {
  background-color: #fafafa;
}
.Main > * {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
button {
  color: inherit;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  cursor: pointer;
}
button::-moz-focus-inner {
  border: 0;
}
.Page {
  padding-top: 32px;
  color: #333333;
  font-family: Verdana, Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.42857143;
  min-width: 1px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  max-width: 994px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.Page.is-tabletApp {
  max-width: 1280px;
  padding: 0 10px;
}
.Page.is-redesign {
  background-color: #fafafa;
  padding-top: 32px;
  max-width: 850px;
}
@media (max-width: 1279px) {
  .Page.is-redesign {
    max-width: 828px;
    margin-left: 15px;
  }
}
select {
  color: #333333;
  background-color: #ffffff;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  -webkit-appearance: none;
  /*Removes default chrome and safari style*/
  -moz-appearance: none;
  /*Removes default style Firefox*/
  text-indent: 0.01px;
}
select::-ms-expand {
  display: none;
}
.basicTransition-enter {
  opacity: 0.01;
}
.basicTransition-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.basicTransition-leave {
  opacity: 1;
}
.basicTransition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
body {
  display: block !important;
}
body.disableScrolling {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.c24-notification,
#c24-header-bottom {
  display: none;
}
.c24-customer.c24-customer-guest.is-hidden {
  display: none;
}
.c24-customer.c24-customer-user.is-hidden {
  display: none;
}
.c24-customer.c24-customer-expired.is-hidden {
  display: none;
}
.c24-notification-layer-hover {
  right: 25px !important;
}
.c24-notification-layer-hover.is-loggedIn {
  right: 15px !important;
}
.c24-notification-layer-corner {
  right: 180px !important;
}
.c24-customer.c24-customer-guest.is-hidden {
  display: none;
}
.c24-customer.c24-customer-user.is-hidden {
  display: none;
}
.c24-contact-heading {
  font-weight: bold;
  font-size: 12px;
  color: #333333;
}
.c24-contact-text {
  margin-top: 20px;
  font-size: 12px;
  color: #333333;
  line-height: 15px;
}
.c24-contact-phoneNumber {
  font-weight: bold;
  font-size: 12px;
  color: #005ea8;
  line-height: 30px;
}
.c24-contact-openingTimes {
  font-size: 12px;
  color: #333333;
}
.c24-contact-email {
  font-size: 12px;
  color: #005ea8;
}
.c24-footer-top.is-tabletApp {
  width: 100%;
}
.c24-footer {
  padding-bottom: env(safe-area-inset-bottom);
}
.c24-page-container.c24-page-container-content.is-tabletApp {
  padding-bottom: 64px !important;
}
.c24-page-container.has-grey-background {
  background-color: #fafafa;
}
@media (min-width: 1280px) {
  .c24-grid-flex-content {
    max-width: 1290px !important;
  }
}
#c24-page-container-content {
  flex-grow: 0;
}
.c24-footer-bottom {
  color: #333333 !important;
}
.c24-footer-bottom-links ul li a {
  color: #333333 !important;
}
.PensionGapBannerContent {
  font-size: 15px;
}
.PensionGapBannerContent-tooltip {
  font-size: 12px;
  margin-left: 10px;
}
.PensionGapBannerContent-tooltip .Tooltip {
  width: 465px;
}
.PensionGapBannerContent-tooltipContentSection {
  margin-bottom: 15px;
  font-weight: initial;
}
.PensionGapBannerContent-tooltipContentSection:last-child {
  margin-bottom: 0;
}
.PensionGapBannerContent-infoIcon {
  width: 13px;
  height: 13px;
  fill: #063773;
}
.PensionGapBannerContent-hint {
  display: inline-block;
}
.Page.is-redesign .PensionGapBanner {
  margin-top: -16px;
}
.PensionGapBanner {
  height: 75px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.PensionGapBanner.is-tabletApp {
  margin-top: 5px;
}
.PensionGapBanner-banner {
  height: 75px;
  display: flex;
  align-items: center;
  color: #333333;
  position: relative;
  flex-grow: 1;
}
.PensionGapBanner-contentBox {
  -webkit-text-size-adjust: none;
  flex-grow: 1;
  color: #063773;
  background-color: #f6b800;
  display: flex;
  align-items: center;
  margin: 0px 37.5px 0px 37.5px;
  padding: 14px 0;
}
.Page.is-redesign .PensionGapBanner-content {
  font-size: 12px;
}
.PensionGapBanner-content {
  width: 100%;
  text-align: center;
}
.PensionGapBanner-calendarImg {
  position: absolute;
  left: 0px;
  width: 82.5px;
  transform: translate(-3.75px, 0);
}
.PensionGapBanner-countdownImg {
  position: absolute;
  left: 0px;
  width: 82.5px;
  transform: translate(-3.75px, 0);
}
.PensionGapBanner-sloganImg {
  position: absolute;
  right: 0px;
  width: 98.47826087px;
  transform: translate(11.73913043px, 0);
}
.PensionGapBanner-logos {
  margin-left: 30px;
}
@media (max-width: 1279px) {
  .PensionGapBanner-logos {
    display: none;
  }
}
.PensionGapBanner-daysLeft {
  font-size: 12px;
  color: #063773;
  position: absolute;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  width: 75px;
  transform: scale(0.8);
}
.PensionGapBanner-days {
  font-size: 15px;
}
.PensionGapBanner-daysLeft-days {
  font-size: 25px;
  color: #f6b800;
}
.PensionGapBanner-daysLeft-hours {
  font-size: 25px;
  font-weight: 700;
  color: #f6b800;
  margin-bottom: 2px;
}
.PensionGapBanner-daysLeft-minutes {
  font-size: 14px;
  font-weight: 700;
  color: #063773;
}
.YearEndBannerContent {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.YearEndBannerContent-description {
  margin: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 22px;
}
.YearEndBannerContent-tooltip {
  font-size: 12px;
  align-self: center !important;
}
.YearEndBannerContent-tooltip .Tooltip {
  width: 433px;
}
.YearEndBannerContent-infoIcon {
  width: 22px;
  height: 13px;
  fill: #ffffff;
}
.YearEndBannerContent-hint {
  display: inline-block;
}
.YearEndBanner-countDown {
  display: flex;
  align-items: center;
  padding-right: 14px;
  padding-top: 6px;
  padding-bottom: 4px;
}
.YearEndBanner-timeBoxContent {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.YearEndBanner-timeBoxTimeValue {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.YearEndBanner-timeBoxTimeValue p {
  color: #333333;
  margin: 0;
  font-size: 20px;
}
.YearEndBanner-timeBoxContainer {
  display: flex;
  align-items: center;
}
.YearEndBanner-timeBoxContainer:not(:last-child)::after {
  content: ':';
  font-size: 20px;
  margin: 0 5px;
  margin-bottom: 20px;
}
.YearEndBanner-timeBoxTimeDescription {
  font-size: 12px;
  margin: 0;
  max-width: 43px;
  text-align: center;
}
.YearEndBanner-timeBoxSeparator {
  font-size: 20px;
  margin: 0 5px;
}
.YearEndBannerContent-tooltipContentSection {
  margin-bottom: 15px;
  font-weight: initial;
}
.YearEndBannerContent-tooltipContentSection:last-child {
  margin-bottom: 0;
}
.YearEndBanner {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
}
.YearEndBanner-banner {
  display: flex;
  color: #333333;
  position: relative;
  height: 95px;
  flex-grow: 1;
}
.YearEndBanner-image {
  width: 76px;
  height: 91px;
}
.YearEndBanner-contentBox {
  -webkit-text-size-adjust: none;
  flex-grow: 1;
  color: #ffffff;
  background-color: #b39656;
  display: flex;
  align-items: center;
  height: 68px;
  font-size: 14px;
  margin: 0px 0px 0px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.Page.is-redesign .YearEndBanner-content {
  font-size: 12px;
}
.YearEndBanner-content {
  width: 100%;
}
.PrintPageHeader {
  display: none;
}
@media print {
  .PrintPageHeader {
    display: block;
    margin-bottom: 10px;
  }
  .PrintPageHeader-headline {
    display: block;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333333;
    text-align: right;
  }
  .PrintPageHeader-contentBox {
    display: flex;
    font-size: 12px;
    margin: 0 auto;
    justify-content: space-between;
  }
  .PrintPageHeader-text {
    max-width: 500px;
  }
  .PrintPageHeader-printShortUrl {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    float: left;
    max-width: 400px;
  }
  .PrintPageHeader-searchDataText {
    font-size: 14px;
    text-align: right;
    margin: 0px;
  }
  .c24-cookie-consent-wrapper {
    display: none;
  }
}
@media print and (min-width: 1200px) {
  .PrintPageHeader-text {
    max-width: 700px;
  }
}
.BackToTopButton {
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1;
}
.Booster {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: #008300;
  background-color: #00830026;
  border-radius: 15px;
  padding: 2px 8px 2px 8px;
}
.BoosterMessage {
  display: flex;
  padding-top: 6px;
}
.BoosterMessage-icon {
  padding-top: 4px;
  margin-right: 10px;
  min-width: 30px;
}
.BoosterMessage-icon svg {
  fill: #008300;
}
.BoosterMessage-text {
  color: #008300;
  font-size: 11px;
  align-self: center;
  font-size: 14px;
}
.Button {
  display: block;
  width: 100%;
  background-color: #0271c2;
  padding: 10px;
  color: #ffffff;
  border-radius: 3px;
  font-size: 14px;
  font-family: Arial, Helvetica, Sans-Serif;
  font-weight: 700;
  border: 0 none;
  cursor: pointer;
  pointer-events: auto;
  text-align: center;
}
.Button.Button--secondary {
  border: 1px solid #cccccc;
  background-color: #ffffff;
  color: #666666;
}
.Button.Button--secondary:active,
.Button.Button--secondary:active:focus,
.Button.Button--secondary:hover,
.Button.Button--secondary:focus {
  background-color: #fafafa;
  text-decoration: none;
  box-shadow: none;
  border: 1px solid #cccccc;
}
.Button:focus,
.Button:active,
.Button:active:focus,
.Button:hover {
  outline: none;
  border: 0 none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-decoration: none;
}
.Button:hover {
  background-color: #015fa4;
}
.Button.is-disabled {
  color: #ffffff;
  background-color: #cccccc;
  cursor: default;
}
.Button.is-disabled.is-recommendation {
  height: 40px;
}
.Button.is-disabled:focus,
.Button.is-disabled:active,
.Button.is-disabled:active:focus,
.Button.is-disabled:hover {
  box-shadow: none;
}
.Button.Button--light {
  background-color: white;
  color: #1980d0;
  border: 1px solid #005ea8;
}
.Button.Button--light:active,
.Button.Button--light:active:focus,
.Button.Button--light:hover,
.Button.Button--light:focus {
  background-color: #cccccc;
  text-decoration: none;
}
.Button.Button--inverted {
  background-color: #ffffff;
  color: #005ea8;
  border: 1px solid #005ea8;
  font-size: 13px;
  font-weight: bold;
  padding: 0 6px;
}
.Button.Button--inverted:active,
.Button.Button--inverted:active:focus,
.Button.Button--inverted:hover,
.Button.Button--inverted:focus {
  outline: none;
  text-decoration: none;
  color: #005ea8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #005ea8;
}
.Button .Button-navigationArrow {
  position: relative;
  display: inline-block;
  width: 12px;
  padding-left: 4px;
}
.Button .Button-navigationArrow:after {
  content: '\00BB';
}
.Button .Button-navigationSpinner {
  display: inline-block;
  position: relative;
  top: 0;
  height: 10px;
  width: 12px;
  margin-top: -10px;
  border-width: 4px;
  margin-right: -13px;
  margin-left: 13px;
}
.Button .Button-navigationSpinner:after {
  height: 24px;
  width: 24px;
  margin-left: -12px;
  margin-top: -12px;
  border-width: 4px;
  content: '';
  border-color: #fff rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.4);
  top: 50%;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: 50%;
  border-style: solid;
  border-radius: 50%;
  animation: spinAround 0.7s infinite linear;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Button .Button-navigationSpinner:after {
    height: 24px;
    width: 24px;
    /* IE spinner jumps, thus reset border and use svg as background image */
    border: 0;
    border-radius: 0;
    background-image: url(/button-spinner.svg);
    background-size: 24px;
  }
}
.Button.Button--light .Button-navigationSpinner:after {
  border-color: #005ea8 rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Button.Button--light .Button-navigationSpinner:after {
    background-image: url(/button-spinner-inverted.svg);
  }
}
.Button.Button--inverted .Button-navigationSpinner:after {
  border-color: #005ea8 rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Button.Button--inverted .Button-navigationSpinner:after {
    background-image: url(/button-spinner-inverted.svg);
  }
}
.Button.Button--secondary .Button-navigationSpinner:after {
  border-color: #005ea8 rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Button.Button--secondary .Button-navigationSpinner:after {
    background-image: url(/button-spinner-inverted.svg);
  }
}
.CheckHint {
  display: flex;
  align-items: flex-end;
  margin-top: 2px;
  color: #008300;
}
.CheckHint-checkIcon {
  width: inherit;
  width: 12px;
  height: 12px;
  margin-right: 7px;
  align-self: center;
  fill: #008300;
}
.ConsultantHeader {
  text-align: center;
  background-color: #ffefd5;
  font-size: 15px;
  font-weight: 700;
}
.ConsultantBox-container {
  padding: 20px;
  background-color: #fafafa;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}
.ConsultantBox-photoAndQuote {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-left: 35px;
}
.ConsultantBox-personalDataAndContact {
  display: flex;
}
.ConsultantBox-quote {
  width: 100%;
  margin-left: 25px;
  font-size: 12px;
  border-radius: 3px;
  line-height: 18px;
  border: 1px solid #dcdcdc;
  background-color: #ecf7fd;
  color: #333333;
  max-width: 285px;
  position: relative;
}
.ConsultantBox-quoteText {
  background-color: #ecf7fd;
  min-height: 55px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 12px;
  padding-left: 15px;
}
.ConsultantBox-arrow {
  width: 25px;
  height: 25px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: #dcdcdc;
  background-color: #ecf7fd;
  background-size: cover;
  transform: rotate(-44deg);
  position: absolute;
  top: calc(50% - 16px);
  left: -14px;
}
.ConsultantBox-photo {
  display: inline;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background-position: top center;
  background-size: cover;
}
.ConsultantBox-name {
  font-size: 18px;
  line-height: 18px;
  text-align: center;
}
.ConsultantBox-name.firstname {
  padding-bottom: 3px;
}
.ConsultantBox-experience {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}
.ConsultantBox-contactBoxHeadline {
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 5px;
}
.ConsultantBox-phoneSvg {
  fill: #005ea8;
  height: 21px;
  width: 14px;
  margin-right: 11px;
  margin-left: 10px;
}
.ConsultantBox-emailSvg {
  fill: #005ea8;
  height: 19px;
  width: 26px;
  margin-right: 10px;
}
.ConsultantBox-emailContainer {
  display: flex;
  margin-top: 8px;
}
.ConsultantBox-contactBox {
  margin-left: 52px;
}
.ConsultantBox-contactTextContainer {
  display: flex;
  align-items: center;
}
.ConsultantBox-workingTimes {
  color: #999999;
  line-height: 14px;
  margin-top: 9px;
}
.Disclaimer {
  margin-top: 10px;
  font-size: 9px;
}
.EFeedbackCommentLabel-container {
  max-height: 55px;
  position: relative;
  overflow-y: hidden;
}
.EFeedbackCommentLabel-container.is-fullHeight {
  max-height: none;
}
.EFeedbackCommentLabel-moreButton {
  color: #333333;
  position: absolute;
  right: 0px;
  padding: 0 0 0 6px;
}
.EFeedbackComment-container {
  border-top: 1px solid #cccccc;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}
.EFeedbackComment-header {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}
.EFeedbackComment-name {
  font-weight: bold;
  flex: 1;
  margin-right: 5px;
}
.EFeedbackComment-thumbsUpContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.EFeedbackComment-thumbsUp {
  display: flex;
  font-size: 12px;
  color: #005ea8;
  cursor: pointer;
}
.EFeedbackComment-thumbsUpSvg {
  display: flex;
  width: 18px;
  height: 18px;
  margin: -3px 4px 0px 4px;
  fill: #005ea8;
}
.EFeedbackComments-container {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #cccccc;
  padding-left: 10px;
}
.EFeedbackComments-loadingSpinner {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.EFeedbackComments-commentsContainer {
  flex: 1;
}
.EFeedbackComments-commentsContainer.has-noComments {
  display: flex;
  align-items: center;
}
.EFeedbackComments-noCommentsLabel {
  font-size: 16px;
  color: #cccccc;
  flex: 1;
  text-align: center;
}
.EFeedbackComments-navigationContainer {
  display: flex;
  justify-content: flex-end;
}
.EFeedbackComments-button {
  color: #005ea8;
}
.EFeedbackComments-button:disabled {
  text-decoration: none;
  color: #cccccc;
  cursor: not-allowed;
}
.EFeedbackComments-prevButton {
  color: #005ea8;
}
.EFeedbackComments-prevButton:disabled {
  text-decoration: none;
  color: #cccccc;
  cursor: not-allowed;
}
.EFeedbackComments-prevButton:before {
  content: '\00AB';
  margin-right: 2px;
}
.EFeedbackComments-nextButton {
  color: #005ea8;
}
.EFeedbackComments-nextButton:disabled {
  text-decoration: none;
  color: #cccccc;
  cursor: not-allowed;
}
.EFeedbackComments-nextButton:after {
  content: ' \00BB';
  margin-left: 2px;
}
.EFeedbackRatingPercentage {
  display: flex;
}
.EFeedbackRatingPercentage svg {
  width: 16px;
  height: 16px;
}
.EFeedbackRatingPercentage p {
  margin: 0;
  padding-left: 7px;
}
.EFeedbackRatingPercentage .EFeedbackRatingPercentage-tooltipHeading {
  font-size: 11px;
  padding-bottom: 7px;
  margin: 0;
}
.EFeedbackRatingPercentage .EFeedbackRatingPercentage-tooltipSubText {
  font-size: 11px;
  margin: 0;
  padding: 0;
}
.EFeedbackRatingStars {
  width: 90px;
  white-space: nowrap;
  margin-top: -1px;
  margin-right: 5px;
  height: 18px;
  position: relative;
}
.EFeedbackRatingStars-starsContainer {
  position: absolute;
  top: 0px;
  height: 18px;
  overflow: hidden;
}
.EFeedbackRatingStars-star {
  height: 18px;
  width: 18px;
  fill: #dcdcdc;
}
.EFeedbackRatingStars-star.is-overlay {
  fill: #f6b800;
}
.EFeedbackRatingStars-star.is-disabled {
  fill: #cccccc;
}
.EFeedbackRating-container {
  display: flex;
  color: #005ea8;
  cursor: pointer;
  margin-bottom: 10px;
}
.EFeedbackRating-container.is-active {
  color: #f3bd00;
}
.EFeedbackRating-starsLabel {
  width: 60px;
}
.EFeedbackRating-bar {
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #f4f4f4;
}
.EFeedbackRating-innerBar {
  background-color: #005ea8;
  height: 100%;
}
.EFeedbackRating-innerBar.is-active {
  background-color: #f3bd00;
}
.EFeedbackRating-ratingCount {
  width: 40px;
}
.EFeedbackRatings-ratingsContainer {
  padding-top: 20px;
  flex: 1;
}
.EFeedbackRatings-resetButton {
  display: flex;
  align-items: center;
  text-align: left;
  color: #005ea8;
}
.EFeedbackRatings-resetButton:hover {
  text-decoration: underline;
}
.EFeedbackRatings-undoIcon {
  fill: #005ea8;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.EFeedbackTooltip-headLine {
  display: flex;
  align-items: center;
}
.EFeedbackTooltip-text {
  margin-bottom: 10px;
}
.EFeedbackTooltip-container {
  display: flex;
  color: #005ea8;
  cursor: pointer;
  margin-bottom: 6px;
}
.EFeedbackTooltip-starsLabel {
  width: 60px;
}
.EFeedbackTooltip-bar {
  flex: 1;
  margin: 4px 15px;
  background-color: #f4f4f4;
  overflow: hidden;
}
.EFeedbackTooltip-innerBar {
  background-color: #005ea8;
  height: 100%;
}
.EFeedbackTooltip-ratingCount {
  width: 40px;
}
.EFeedbackTooltip-openAllButton {
  background: transparent !important;
  color: #005ea8;
  text-align: right;
  font-size: 12px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: normal;
  box-shadow: none !important;
}
.EFeedbackTooltip.hover .EFeedbackTooltip-innerBar {
  animation: slideIn 1s forwards;
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
.EFeedback-container {
  background-color: #ffffff;
  border-top: 1px solid #cccccc;
  border-radius: 3px;
  min-height: 362px;
  display: flex;
  padding: 10px;
}
@media all and (-ms-high-contrast: none) {
  .EFeedback-container {
    height: 400px;
  }
}
.EFeedback-loadingSpinner {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.EFeedback-leftContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}
.EFeedback-leftContainer > div {
  margin-bottom: 10px;
}
.EFeedback-leftContainer > div:last-of-type {
  margin-bottom: 0;
}
.EFeedback-ratingsHeader {
  font-size: 12px;
  font-weight: bold;
}
.EFeedback-ratingStarsContainer {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}
.EFeedback-ratingStarsContainer > div {
  margin-right: 5px;
}
.EFeedback-rightContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #cccccc;
  padding-left: 10px;
}
.EFeedback-commentsHeader {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}
.EFeedback-commentsHeaderText {
  flex: 1;
}
.EFeedback-closeIcon {
  height: 15px;
  width: 15px;
}
.EFeedback-closeIcon path {
  fill: #b4b4b4;
}
.EkomiLogo {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
}
.EkomiLogo-svg {
  cursor: pointer;
  pointer-events: auto;
  width: 231px;
  height: 58px;
}
@media (max-width: 1279px) {
  .EkomiLogo-svg {
    width: 220px;
  }
}
.EkomiLogo-header {
  font-weight: 700;
  color: #333333;
  margin-bottom: 15px;
}
.EkomiLogo-tooltipSubHeadline {
  font-weight: bold;
  color: #333333;
}
.EkomiLogo-tooltipAddressHeadline {
  margin: 8px 0;
}
.EkomiLogo-tooltipContent {
  color: #333333;
}
.EkomiLogo-tooltip .Tooltip {
  width: 475px;
}
.ErrorMessage {
  max-width: 994px;
  width: 100%;
  height: calc(100vh - 240px);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ErrorMessage-header {
  font-size: 30px;
  color: #666666;
  margin-bottom: 0px;
}
.ErrorMessage-text {
  font-size: 15px;
  color: #666666;
}
.ErrorMessage-image {
  width: 752px;
}
.Grade {
  position: relative;
  height: 80px;
  float: right;
  cursor: default;
}
.Grade.has-overlay {
  cursor: help;
}
@media (min-width: 1280px) {
  .Grade {
    height: 90px;
    width: 78px;
  }
}
.Grade-icon {
  height: 80px;
  width: 68px;
  background-repeat: no-repeat;
  background-size: 68px 80px;
  background-image: url(/grey-grade.svg);
  display: block;
}
.Grade-icon.is-golden {
  background-image: url(/gold-grade.svg);
}
@media (min-width: 1280px) {
  .Grade-icon {
    background-size: 78px 90px;
    height: 90px;
    width: 78px;
  }
}
.Grade-calculatedValue {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 25px;
  font-size: 24px;
  font-weight: 700;
  color: #333333;
}
@media (min-width: 1280px) {
  .Grade-calculatedValue {
    top: 28px;
    font-size: 28px;
  }
}
.Grade-subText {
  position: absolute;
  top: 17px;
  width: 100%;
  text-align: center;
  font-size: 9px;
  color: #333333;
}
@media (min-width: 1280px) {
  .Grade-subText {
    top: 18px;
    font-size: 12px;
  }
}
.Grade-gradeText {
  position: absolute;
  font-size: 10px;
  top: 62px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: #ffffff;
}
@media (min-width: 1280px) {
  .Grade-gradeText {
    top: 71px;
    font-size: 10px;
  }
}
.Grade-gradeText.is-bold {
  font-weight: 700;
}
body:has(.YearEndBanner) .Grade-icon {
  background-image: url(/grey-grade.svg);
}
.GradeFeatureList {
  width: 450px;
  transition-duration: 500ms;
}
.GradeFeatureList.is-expanded {
  width: 650px;
}
.GradeFeatureList.is-expanded .GradeFeatureList-listItemHead {
  cursor: pointer;
}
.GradeFeatureList-header {
  padding: 15px;
  background-color: #f4f4f4;
}
.GradeFeatureList-content {
  display: block;
}
.GradeFeatureList-headerTitle {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}
.GradeFeatureList-headerContent {
  display: flex;
  align-items: center;
}
.GradeFeatureList-contentText {
  width: 76%;
}
.GradeFeatureList-points {
  width: 24%;
  text-align: right;
  margin-right: 22px;
  font-weight: bold;
}
.GradeFeatureList-points.is-good span {
  color: #f3bd00;
}
.GradeFeatureList-points.is-veryGood span {
  color: #77b616;
}
.GradeFeatureList-points.is-bad span {
  color: #e30613;
}
.GradeFeatureList-content {
  background-color: #ffffff;
}
.GradeFeatureList-listItemHead {
  position: relative;
  padding: 9px 0;
  display: flex;
  align-items: center;
}
.GradeFeatureList-listItem {
  margin: 0 15px;
  border-bottom: 1px solid #cccccc;
}
.GradeFeatureList-listItem.is-last {
  border-bottom: none;
}
.GradeFeatureList-listItem.is-last .GradeFeatureList-listItemHead {
  cursor: initial !important;
}
.GradeFeatureList-listItem.is-expanded .GradeFeatureList-categoryArrow {
  transform: rotate(180deg);
}
.GradeFeatureList-featureName {
  font-weight: bold;
}
.GradeFeatureList-featureItem {
  display: flex;
  margin-bottom: 10px;
}
.GradeFeatureList-featureValuesCont {
  display: flex;
  flex-grow: 1;
  text-align: right;
  justify-content: flex-end;
  width: 65px;
  white-space: nowrap;
}
.GradeFeatureList-featureFormattedValue {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
}
.GradeFeatureList-featureValues {
  display: flex;
  margin-right: 22px;
  width: 135px;
}
.GradeFeatureList-featureValues svg {
  width: 18px;
  height: 18px;
}
.GradeFeatureList-listItemDetails {
  transition-duration: 500ms;
  max-height: 0;
  overflow: hidden;
}
.GradeFeatureList-featureDescription {
  flex: 1;
}
.GradeFeatureList-title {
  font-weight: 700;
  width: 80%;
  font-size: 14px;
}
.GradeFeatureList-title.is-normalText {
  font-weight: normal;
  font-size: 12px;
}
.GradeFeatureList-footer {
  display: flex;
  justify-content: center;
  padding: 16px 10px 16px;
  background-color: #f4f4f4;
}
.GradeFeatureList-gradesExplanation {
  padding-bottom: 10px;
  background-color: #f4f4f4;
}
.GradeFeatureList-footerItem {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.GradeFeatureList-footerColor {
  width: 19px;
  height: 15px;
}
.GradeFeatureList-footerColor--green {
  background-color: #77b616;
}
.GradeFeatureList-footerColor--yellow {
  background-color: #f3bd00;
}
.GradeFeatureList-footerColor--red {
  background-color: #e30613;
}
.GradeFeatureList-footerText {
  margin-left: 5px;
}
.GradeFeatureList-tooltip .Tooltip {
  width: auto;
  border: 1px solid #ccc;
  cursor: default;
  padding: 0;
  background: transparent;
  line-height: inherit;
}
.GradeFeatureList-tooltip .Tooltip-connector {
  max-height: 120%;
}
.GradeFeatureList-tooltip .Tooltip-arrow {
  background: #f4f4f4;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.GradeFeatureList-expandButton {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #005ea8;
  user-select: none;
  cursor: pointer;
}
.GradeFeatureList-expandButton.is-expanded svg {
  transform: rotate(90deg);
}
.GradeFeatureList-expandButton svg {
  width: 14px;
  height: 8px;
  transform: rotate(270deg);
  margin-left: 6px;
  transition-duration: 500ms;
}
.GradeFeatureList-expandButton svg * {
  fill: #005ea8;
}
.GradeFeatureList-categoryArrow {
  width: 18px;
  height: 8px;
  position: absolute;
  right: 0;
  transition-duration: 500ms;
}
.GradeFeatureList-categoryArrow * {
  fill: #005ea8;
}
.GradeFeatureList-legendContainer {
  display: flex;
  justify-content: center;
}
.GradeFeatureList-legendItem {
  display: flex;
  align-items: center;
  margin: 7px;
  margin-bottom: 20px;
}
.GradeFeatureList-legendIcon {
  height: 18px;
  width: 18px;
  margin-right: 5px;
}
.HorizontalNavigation-wrapper {
  width: 100%;
  max-width: 850px;
  margin-bottom: 14px;
}
.HorizontalNavigation-pages {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 18px 0 42px;
}
.HorizontalNavigation-page {
  max-width: 26px;
  margin: 0 8px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: default;
}
.HorizontalNavigation-page.selected .HorizontalNavigation-pageIcon {
  border: 2px solid #005ea8;
  background-color: #005ea8;
  color: #ffffff;
}
.HorizontalNavigation-page.selected .HorizontalNavigation-pageName {
  color: #333333;
}
.HorizontalNavigation-page.completed {
  cursor: pointer;
}
.HorizontalNavigation-page.completed .HorizontalNavigation-pageIcon {
  border-color: #005ea8;
  color: #005ea8;
}
.HorizontalNavigation-page.completed .HorizontalNavigation-pageIcon svg {
  fill: #005ea8;
}
.HorizontalNavigation-page.completed .HorizontalNavigation-pageName {
  color: #333333;
}
.HorizontalNavigation-page:hover {
  text-decoration: none;
}
.HorizontalNavigation-pageIcon {
  width: 26px;
  height: 26px;
  border: 2px solid #b4b4b4;
  border-radius: 13px;
  background-color: #ffffff;
  color: #b4b4b4;
  font-size: 14px;
  text-align: center;
  padding-top: 1px;
  margin-bottom: 5px;
}
.HorizontalNavigation-pageIcon svg {
  height: 14px;
  width: 14px;
  margin-top: 3px;
  fill: #b4b4b4;
}
.HorizontalNavigation-resultPageIcon {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: #ffffff;
  color: #b4b4b4;
  font-size: 14px;
  text-align: center;
  padding-top: 1px;
  margin-bottom: 5px;
}
.HorizontalNavigation-resultPageIcon svg {
  height: 26px;
  width: 26px;
  fill: #b4b4b4;
}
.HorizontalNavigation-pageName {
  text-align: center;
  color: #b4b4b4;
}
.HorizontalNavigation-line {
  height: 1px;
  background: #b4b4b4;
  flex-grow: 1;
  margin-top: 12px;
}
.HorizontalNavigation-line.completed {
  background: #005ea8;
}
.InitialInformationModal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(51, 51, 51, 0.4);
  z-index: 100;
  align-items: center;
  overflow-x: auto;
}
.InitialInformationModal-body {
  z-index: 101;
  background-color: #fff;
  border-radius: 6px;
  margin: 46px 6px 6px;
  max-width: min(520px, 95vw);
  min-height: 0px;
}
.InitialInformationModal-header {
  text-align: center;
  font-size: 24px;
  margin-top: -30px;
}
.InitialInformationModal-list {
  display: flex;
  justify-content: center;
  margin: 10px;
  font-size: 16px;
  line-height: 26px;
}
.InitialInformationModal-footer {
  background-color: #f4f4f4;
  text-align: center;
  padding: 24px 35px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}
.InitialInformationModal-footerInitialInformationModal-Link {
  margin: 15px 0;
}
.InitialInformationModal-check {
  fill: #005ea8;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.InitialInformationModal-logo {
  top: 0;
  left: 50%;
  height: 80px;
  width: 80px;
  padding: 5px;
  position: relative;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: #063773;
}
.InitialInformationModal-logo * {
  fill: white;
}
.InitialInformationModal-footerLink {
  margin-top: 10px;
}
.InitialInformationModal-pdf {
  height: 20px;
  width: 20px;
  margin-right: 7px;
  position: relative;
  top: 3px;
}
.InitialInformationModal-button {
  margin-top: 20px;
  width: 300px;
}
.InitialInformationOverlay-pageWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.InitialInformationOverlay-pageWrapper.is-blurred {
  filter: blur(5px);
  z-index: 100;
  height: 100vh;
  overflow: hidden;
}
.InlineValidation {
  position: relative;
  display: flex;
}
.InlineValidation-bar {
  position: absolute;
  height: 9px;
  width: 0;
  bottom: 0px;
  z-index: 35;
  background-color: transparent;
  box-sizing: border-box;
  transition-property: width, background-color;
  transition-duration: 0.3s, 0s;
  clip-path: inset(7px 0px 0px 0px);
  border-radius: 5px;
}
.InlineValidation-bar.is-hidden {
  width: 0;
}
.InlineValidation-bar.is-error {
  background-color: #c82d2d;
  width: 100%;
}
.InlineValidation-bar.is-valid {
  background-color: #008300;
  width: 100%;
}
.InputChips {
  padding-top: 10px;
  margin-bottom: -10px;
}
.InputChips--withoutPadding {
  padding-top: 0px;
}
.InputChip {
  height: 30px;
  border-radius: 15px;
  padding: 5px 9px;
  font-size: 13px;
  background-color: #ffffff;
  user-select: none;
  border: 1px solid #dcdcdc;
  transition: border-color 0.3s;
  will-change: border-color;
  cursor: pointer;
  margin-bottom: 10px;
}
.InputChip.is-selected {
  background-color: #ecf7fd;
  border: 1px solid #005ea8;
}
.InputChip.is-selected.is-recommended {
  border-color: #008300;
  background-color: #00830026;
}
.InputChip.is-selected:hover {
  border: 1px solid #005ea8;
}
.InputChip:hover {
  border: 1px solid #575757;
}
.InputChip-arrow {
  width: 100%;
  height: 100%;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: #dcdcdc;
  background-color: #ffffff;
  background-size: cover;
  position: absolute;
}
.InputChip-arrow.is-selected {
  border-color: #008300;
  background-color: #00830026;
}
.InputChip-arrowBackground {
  width: 14px;
  height: 14px;
  transform: rotate(44deg);
  position: relative;
  top: -3px;
  background-color: #ffffff;
}
.InputChip-wrapper {
  display: inline-flex;
  margin-right: 10px;
  flex-direction: column;
  align-items: center;
}
.InputChip-wrapper:last-of-type {
  margin-right: 0px;
}
.LoadingOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 400px);
}
.ModalDialog-container {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15000;
  outline: 0;
  overflow: hidden;
}
.ModalDialog {
  width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 0.4px));
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-clip: padding-box;
  outline: 0;
}
.ModalDialog-header {
  border: none;
  padding: 25px 25px 0 25px;
  border-bottom: none;
  min-height: 16px;
}
.ModalDialog-headerTitle {
  color: #333333;
  font-size: 18px;
  margin: 0;
  line-height: 1.4;
  text-align: left;
}
.ModalDialog-headerClose {
  z-index: 99;
  width: 20px;
  height: 20px;
  cursor: pointer;
  pointer-events: auto;
}
.ModalDialog-headerCloseButton {
  z-index: 99;
  padding: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 5px;
  top: -30px;
  cursor: pointer;
  pointer-events: auto;
}
.ModalDialog-headerCloseButton:focus-within > svg > path {
  fill: #1980d0;
}
.ModalDialog-headerCloseButton.is-hidden {
  display: none;
}
.ModalDialog-body {
  font-size: 14px;
  line-height: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 20px;
}
.PageHeader {
  margin-bottom: 14px;
}
.ResultPageHeader {
  border-bottom: 1px solid #dcdcdc;
}
.PageHeader-contentBox {
  display: flex;
  font-size: 12px;
  margin: 0 auto;
  justify-content: space-between;
}
@media print {
  .PageHeader-contentBox {
    display: none;
  }
}
.PageHeader-text {
  max-width: 500px;
}
@media (min-width: 1200px) {
  .PageHeader-text {
    max-width: 700px;
  }
}
.PageHeader-headline {
  display: flex;
  font-size: 23px;
  font-weight: bold;
  color: #333333;
}
.PageHeader-searchCriteriaText {
  display: none;
  color: #333333;
  margin-bottom: 12px;
}
.PageHeader-searchCriteriaText.is-result {
  display: block;
}
.PageHeader-backLinkWrapper {
  margin-top: 10px;
}
.PageHeader-backLink {
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  text-wrap: nowrap;
  white-space: nowrap;
}
.PageHeader-backLink.is-inactive {
  filter: grayscale(0.8);
  opacity: 0.5;
  pointer-events: none;
}
@media (max-width: 1279px) {
  .PageHeader-backLink {
    font-size: 12px;
  }
}
.PageHeader-onboardingLink {
  color: #005ea8;
  cursor: pointer;
}
.PageHeader-onboardingLink:hover {
  text-decoration: underline;
}
.PageHeader-images {
  display: flex;
}
.PageHeader-savePageAsPdfBox {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.PageHeader-savePageAsPdf {
  align-items: center;
  align-self: center;
  width: 240px;
  height: 30px;
  margin-right: 15px;
}
.PageHeader-printResultMessage {
  margin-top: 5px;
  margin-bottom: 0px;
}
.PageHeader-printResultMessage.is-success {
  color: #7ab51d;
}
.PageHeader-printResultMessage.is-error {
  color: #c82d2d;
}
.PageHeader-documentLink {
  height: 30px;
  width: 30px;
  margin-left: 15px;
  cursor: pointer;
}
.PageHeader-documentLink svg {
  fill: #005ea8;
}
.PageHeader-navigationBox {
  display: flex;
  align-items: baseline;
  margin-top: 10px;
}
.PageHeader-shareCurrentPageIconContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}
.PageHeader-shareCurrentPageIconContainer > p {
  margin: 0;
  color: #005ea8;
  font-size: 12px;
  margin-top: 4px;
}
.PaymentChips {
  margin-top: 10px;
  width: max-content;
}
.PaymentChips-tooltip {
  top: 11px;
}
.PaymentChips-tooltip .Tooltip {
  width: 227px;
  font-size: 11px;
  color: #333333;
}
.PaymentChips-tooltip.Tooltip-Root {
  position: static;
  align-self: auto;
}
.PaymentChips-infoIcon {
  width: 20px;
  height: 20px;
  fill: #bbbbbb;
  margin-left: 3px;
  margin-top: 3px;
  cursor: pointer;
}
.PaymentChips-infoIcon.is-selected {
  fill: #008300;
}
.PaymentChips-tooltipTitle {
  font-weight: bold;
}
.PaymentChips-recommendation {
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 8px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}
.PaymentChips-recommendation.is-selected {
  border-color: #008300;
  background-color: #00830026;
}
.PositionBottom {
  position: sticky;
  top: 100vh;
}
.ProgressBar {
  display: flex;
  align-items: center;
}
.ProgressBar-bar {
  background-color: #ebeef1;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;
  width: 500px;
  height: 15px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
.ProgressBar-progress {
  background: #063773;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  transform: translate3d(-99%, 0, 0);
}
.ProgressBar-percent {
  width: 40px;
  padding-left: 5px;
  float: left;
  color: #063773;
  text-align: left;
}
.ResultZeroHeader {
  background-color: #dae1ea;
  padding-left: 10px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  width: fit-content;
}
.ResultZeroHeader.color-gold {
  background-color: #f4efe6;
}
.ResultZeroHeader.color-blue {
  background-color: #dae1ea;
}
.ResultZeroHeader.color-green {
  background-color: #d9edd9;
}
.ResultZeroHeader.color-orange {
  background-color: #fdebd9;
}
.ResultZeroHeader-text {
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  color: #063773;
}
.ResultZeroHeader-text.color-gold {
  color: #b39656;
}
.ResultZeroHeader-text.color-blue {
  color: #063773;
}
.ResultZeroHeader-text.color-green {
  color: #008300;
}
.ResultZeroHeader-text.color-orange {
  color: #f07c00;
}
.ResultZeroHeader-infoContainer {
  margin-top: 5px;
  margin-left: 5px;
  height: 14px;
  width: 14px;
  pointer-events: auto;
  cursor: help;
  fill: #063773;
}
.ResultZeroHeader-infoContainer.color-gold {
  fill: #b39656;
}
.ResultZeroHeader-infoContainer.color-blue {
  fill: #063773;
}
.ResultZeroHeader-infoContainer.color-green {
  fill: #008300;
}
.ResultZeroHeader-infoContainer.color-orange {
  fill: #f07c00;
}
.ResultZeroHeader-accentWord {
  color: #063773;
}
.SecurityHint {
  display: flex;
  color: #008300;
  font-size: 12px;
  min-width: max-content;
  margin-top: 8px;
}
.SecurityHint-lockIcon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  position: relative;
  fill: #008300;
}
.ShareCurrentPageModal-header {
  font-size: 18px;
  display: flex;
  margin-bottom: 30px;
}
.ShareCurrentPageModal-buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ShareCurrentPageModal-button {
  font-size: 14px;
  color: #005ea8;
  border: 1px solid #005ea8;
  border-radius: 3px;
  width: 275px;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ShareCurrentPageModal-button:hover {
  background: #ecf7fd;
  color: #005ea8;
  text-decoration: none;
}
.ShareCurrentPageModal-emailIcon {
  width: 40px;
  height: 30px;
  margin-right: 15px;
  fill: #005ea8;
}
.ShareCurrentPageModal-copyIcon {
  width: 30px;
  height: 30px;
  margin-right: 25px;
  fill: #005ea8;
}
.ShareIcon--ios {
  fill: #005ea8;
  width: 17px;
  height: 22px;
}
.ShareIcon--android {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: #005ea8;
}
.SideHelpText {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  max-height: 47px;
  font-size: 12px;
}
@media (max-width: 1279px) {
  .SideHelpText.is-personal-data-page .SideHelpText-panel {
    right: 24px;
  }
}
.SideHelpText-text {
  position: absolute;
  width: 184px;
  left: 69px;
  top: calc(50% - 8px);
}
@media (max-width: 1279px) {
  .SideHelpText-text {
    width: 150px;
  }
}
.SideHelpText-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 38px;
  top: calc(50% - 10px);
  border-top: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  transform: rotate(-135deg);
  background-color: #fafafa;
}
.SideHelpText-link {
  cursor: help;
  display: flex;
}
.SideHelpText-panel {
  height: 100%;
  position: relative;
}
.SideHelpText-questionMark {
  width: 20px;
  height: 20px;
  fill: #b4b4b4;
  display: inline-block;
}
.SideHelpText-questionMark:hover {
  fill: #999999;
}
.SideHelpText-questionMark.is-active {
  fill: #005ea8;
}
.SideHelpText-headline {
  font-weight: bold;
  margin-bottom: 8px;
}
.SofortTip {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #00830026;
  display: flex;
  margin-top: 6px;
  border-radius: 5px;
  max-height: 120px;
  /* animation setup */
  height: 0;
  animation: slide 0.5s forwards;
  animation-delay: 0.3s;
  overflow: hidden;
}
.SofortTip-icon {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 3px;
}
.SofortTip-icon svg {
  width: 25px;
  height: 20px;
}
.SofortTip-icon svg path {
  fill: #008300;
}
.SofortTip-header {
  font-weight: 600;
  margin-bottom: 5px;
}
.SofortTip-content {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.SofortTip-text {
  margin-bottom: 8px;
}
.SofortTip-link {
  font-weight: 600;
}
@-webkit-keyframes slide {
  100% {
    height: 142px;
  }
}
@keyframes slide {
  100% {
    height: 142px;
  }
}
.Spinner:after {
  content: '';
  display: inline-block;
  height: 60px;
  width: 60px;
  border-style: solid;
  border-color: #005ea8 rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-width: 10px;
  animation: spinAround 800ms infinite linear;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Spinner::after {
    height: 80px;
    width: 80px;
    /* IE spinner jumps, thus reset border and use svg as background image */
    border: 0;
    border-radius: 0;
    background-image: url(/spinner.svg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.TariffFunds-fundValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.TariffFunds-fundsInfoSvg {
  height: 14px;
  width: 14px;
  cursor: help;
  fill: #b4b4b4;
  margin-left: 3px;
}
.TariffFunds-fundTooltip {
  min-width: 18px;
  margin-left: 5px;
}
.TariffFunds-fundTooltip .Tooltip {
  width: 435px;
}
.TariffFunds-fundTooltipInfo {
  margin-top: 10px;
}
.TariffHeaderBox {
  margin-bottom: 15px;
}
.TariffHeaderBox .EFeedback-container {
  border: 1px solid #cccccc;
  margin-top: -0.5px;
}
.TariffHeaderBox-tariff {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ecf7fd;
  padding: 15px;
}
.TariffHeaderBox-tariff.has-eFeedback {
  padding-bottom: 0px;
}
.TariffHeaderBox-row {
  display: flex;
}
.TariffHeaderBox-selectButton {
  margin-top: 8px;
}
.TariffHeaderBox-left {
  flex: 30;
  width: 200px;
  height: 80px;
}
.TariffHeaderBox-center {
  flex: 40;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.TariffHeaderBox-right {
  flex: 30;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.TariffHeaderBox-insuranceLogo {
  max-width: 180px;
  margin-bottom: 5px;
  max-height: 80px;
}
.TariffHeaderBox-tariffName {
  margin-bottom: 5px;
}
.TariffHeaderBox-possiblePrice {
  margin-top: -6px;
  font-size: 24px;
  font-weight: bold;
  color: #008300;
}
.TariffHeaderBox-guaranteedPrice {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-top: 7px;
  text-align: right;
}
.TariffHeaderBox-priceDescription {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.TariffHeaderBox-priceDescription:last-of-type {
  margin-bottom: 10px;
}
.TariffHeaderBox-infoSvgIcon {
  height: 12px;
  width: 12px;
  pointer-events: auto;
  cursor: help;
  fill: #b4b4b4;
}
.TariffHeaderBox-infoSvgIcon.TariffHeaderBox-infoSvgIcon-possiblePensionInfoSvgIcon > * {
  fill: #008300 !important;
}
.TariffHeaderBox-infoSvgIcon * {
  fill: #b4b4b4 !important;
}
.TariffHeaderBox-priceText {
  margin-right: 5px;
}
.TariffHeaderBox-priceText.TariffHeaderBox-possiblePriceText {
  color: #008300;
}
.TariffHeaderBox-centerItem {
  margin-top: 5px;
  display: flex;
}
.TariffHeaderBox-centerItem:first-of-type {
  margin-top: 0;
}
.TariffHeaderBox-centerItemLabel {
  width: 130px;
  display: inline-block;
}
.TariffHeaderBox-centerItemValue {
  font-weight: bold;
  margin-left: 5px;
  width: 170px;
}
.TariffHeaderBox-gradeContainer {
  margin-right: 65px;
  margin-left: 35px;
}
.TariffHeaderBox-tabHeader {
  color: #005ea8;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 10px;
}
.TariffHeaderBox-tabHeader:hover {
  text-decoration: underline;
}
.TariffHeaderBox-tabHeader.is-open {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-bottom: none;
  padding: 9px 9px 10px;
}
.TariffHeaderBox-tabArrow {
  display: block;
  width: 10px;
  height: 10px;
  fill: #005ea8;
  margin-left: 5px;
}
.TariffHeaderBox-tabArrow.is-open {
  transform: rotate(180deg);
}
.TariffHeaderBox-EFeedbackTabHeader {
  position: relative;
  bottom: -1px;
  margin-left: -10px;
}
.TariffHeaderBox-EFeedbackTabHeader .Tooltip {
  width: 380px;
  text-align: left;
  cursor: default;
}
.TariffHeaderBox-topRow {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.TariffHeaderBox-resultZero {
  margin-right: 15px;
}
.TariffHeaderBox-promotion {
  margin-right: 15px;
}
.TariffHeaderBox-wishListHeart {
  margin-left: auto;
}
.TariffLabels-labelTooltip {
  max-width: 290px;
}
.TariffLabels-labelTooltip:after {
  left: 30% !important;
}
.TariffLabels-labelTooltip:before {
  left: 30% !important;
}
.TariffLabels-labelWrapper {
  display: flex;
  cursor: help;
  margin-top: 5px;
  margin-bottom: 0px;
}
.TariffLabels-label {
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 12px;
  margin-top: 1px;
  margin-bottom: 1px;
}
.TariffPromotionHeader {
  padding-left: 10px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 14px;
  width: max-content;
}
.TariffPromotionHeader .Tooltip {
  width: 420px;
}
.TariffPromotionHeader-text {
  -webkit-text-size-adjust: none;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
}
.TariffPromotionHeader-tooltipIcon {
  margin-left: 5px;
  margin-top: 5px;
  height: 14px;
  width: 14px;
  pointer-events: auto;
  cursor: help;
}
.TariffPromotionHeader-tooltipContentList {
  list-style-type: disc;
}
.TariffContractSummary {
  width: 270px;
}
@media (max-width: 1279px) {
  .TariffContractSummary {
    margin-right: 10px;
  }
}
.TariffContractSummary-separator {
  margin: 16px 0px;
  background-color: #dcdcdc;
  border: none;
  height: 1px;
}
.TariffContractSummary-pensionDetails {
  border-radius: 3px;
  background-color: #ffffff;
  padding: 16px 24px 24px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}
@media (max-width: 1279px) {
  .TariffContractSummary-pensionDetails {
    margin-bottom: 10px;
  }
}
.TariffContractSummary-tariffDetails {
  position: relative;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 24px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.TariffContractSummary-labels {
  margin-top: -15px;
  margin-left: -15px;
  padding-bottom: 5px;
}
.TariffContractSummary-eFeedback {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.TariffContractSummary-resultZero {
  margin-bottom: 7px;
}
.TariffContractSummary-promotion {
  margin-bottom: 7px;
}
.TariffSummaryContactDetails {
  border-radius: 3px;
  background-color: #ffffff;
  padding: 24px 24px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  margin-top: 24px;
}
@media (max-width: 1279px) {
  .TariffSummaryContactDetails {
    margin-top: 10px;
  }
}
.TariffSummaryContactDetails-questions {
  font-size: 16px;
}
.TariffSummaryContactDetails-inquiryInfo {
  margin-top: 10px;
  color: #333333;
}
.TariffSummaryContactDetails-contactContainer {
  margin-top: 15px;
}
.TariffSummaryContactDetails-phoneNumberContainer {
  display: flex;
  align-items: center;
}
.TariffSummaryContactDetails-phoneSvg {
  fill: #005ea8;
  height: 20px;
  width: 15px;
  margin-right: 10px;
  margin-left: 5px;
}
.TariffSummaryContactDetails-phoneNumber {
  color: #005ea8;
  font-size: 14px;
}
.TariffSummaryContactDetails-workingTimes {
  color: #666666;
  font-size: 11px;
}
.TariffSummaryContactDetails-emailContainer {
  display: flex;
  padding-top: 3px;
}
.TariffSummaryContactDetails-emailSvg {
  fill: #005ea8;
  height: 15px;
  width: 20px;
  margin-right: 10px;
  margin-top: 4px;
}
.TariffSummaryContactDetails-email {
  color: #005ea8;
  font-size: 14px;
}
.TariffSummaryContactDetails-consultant {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.TariffSummaryContactDetails-consultantPhoto {
  display: inline;
  width: 63px;
  height: 63px;
  border-radius: 50%;
  background-position: top center;
  background-size: cover;
}
.TariffSummaryContactDetails-consultantInfo {
  margin-left: 10px;
}
.TariffSummaryContactDetails-consultantName {
  font-size: 14px;
}
.TariffSummaryContactDetails-consultantTitle {
  margin-top: 4px;
  font-size: 11px;
  color: #999999;
}
.TariffSummaryContractDetails-header {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}
.TariffSummaryContractDetails-rowGroup {
  margin: 16px 0 0;
}
.TariffSummaryContractDetails-row {
  margin-bottom: 10px;
}
.TariffSummaryContractDetails-row:last-of-type {
  margin-bottom: 0;
}
.TariffSummaryContractDetails-rowLabel {
  color: #999999;
}
.TariffSummaryContractDetails-rowValue {
  margin: 0px;
}
.TariffSummaryContractDetails-tariffFundsRow {
  display: flex;
  align-items: center;
}
.TariffSummaryPaymentDetails-header {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}
.TariffSummaryPaymentDetails-rowGroup {
  margin: 16px 0 0;
}
.TariffSummaryPaymentDetails-row {
  margin-bottom: 10px;
}
.TariffSummaryPaymentDetails-row:last-of-type {
  margin-bottom: 0;
}
.TariffSummaryPaymentDetails-rowLabel {
  color: #999999;
  display: flex;
  align-items: center;
}
.TariffSummaryPaymentDetails-rowValue {
  margin: 0px;
}
.TariffSummaryPaymentDetails-tariffFundsRow {
  display: flex;
  align-items: center;
}
.TariffSummaryPaymentDetails-infoSvgIcon {
  padding-left: 3px;
  height: 14px;
  width: 14px;
  pointer-events: auto;
  cursor: help;
  fill: #b4b4b4;
}
.TariffSummaryPensionDetails-header {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}
.TariffSummaryPensionDetails-rowGroup {
  margin: 8px 0 0;
}
.TariffSummaryPensionDetails-row {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TariffSummaryPensionDetails-row:last-of-type {
  margin-bottom: 0;
}
.TariffSummaryPensionDetails-rowLabel {
  color: #999999;
  display: flex;
  align-items: center;
}
.TariffSummaryPensionDetails-tooltip {
  margin-left: 5px;
}
.TariffSummaryPensionDetails-possibleYearlyPensionAmount {
  font-size: 22px;
  color: #008300;
  white-space: nowrap;
  margin: 0;
}
.TariffSummaryPensionDetails-guaranteedYearlyPensionAmount {
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
}
.TariffSummaryPensionDetails-infoSvgIcon {
  height: 14px;
  width: 14px;
  pointer-events: auto;
  cursor: help;
  fill: #b4b4b4;
}
.TariffSummaryPensionDetails-separator {
  margin: 16px 0px;
  background-color: #dcdcdc;
  border: none;
  height: 1px;
}
.TariffSummaryPensionDetails-pointsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TariffSummaryPensionDetails-points {
  margin-left: 38px;
}
.TariffSummaryTariffDetails-header {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}
.TariffSummaryTariffDetails-row {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.TariffSummaryTariffDetails-logoNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-right: 20px;
}
.TariffSummaryTariffDetails-insuranceLogo {
  max-height: 30px;
  align-self: flex-start;
}
.TariffSummaryTariffDetails-name {
  margin: 10px 0 0;
}
.TariffSummaryTariffDetails-grade.Grade {
  height: 78px;
  width: auto;
}
.TariffSummaryTariffDetails-grade .Grade-icon {
  height: 78px;
  width: 67px;
  background-size: 67px 78px;
}
.TariffSummaryTariffDetails-grade .Grade-calculatedValue {
  top: 25px;
  font-size: 24px;
}
.TariffSummaryTariffDetails-grade .Grade-subText {
  top: 17px;
  font-size: 9px;
}
.TariffSummaryTariffDetails-grade .Grade-gradeText {
  top: 60px;
  font-size: 10px;
}
.TariffSummaryTariffDetails-grade .Grade-gradeText.is-bold {
  font-weight: 700;
}
.TariffUsps-featureTooltip {
  max-width: 290px;
}
.TariffUsps-featureTooltip:after {
  left: 30% !important;
}
.TariffUsps-featureTooltip:before {
  left: 30% !important;
}
.TariffUsps-tooltipAnchor {
  position: absolute;
  left: 30px;
  top: 5px;
}
.TariffUsps-container {
  margin-bottom: 10px;
  flex: 1;
  min-width: 40%;
  font-size: 13px;
  padding-right: 5px;
}
.TariffUsps-feature {
  margin-bottom: 6px;
  display: flex;
  cursor: default;
  position: relative;
}
.TariffUsps-feature.has-helpText {
  cursor: help;
}
.TariffUsps-feature.is-last {
  margin-bottom: 10px;
}
.TariffUsps-featureIcon {
  margin-right: 6px;
  position: relative;
  top: 2px;
}
.TariffUsps-featureIcon > svg {
  width: 13px;
  height: 13px;
}
.TestReports {
  background-color: #ffffff;
  border-top: 1px solid #cccccc;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
}
.TestReports.is-detailsPage {
  padding: 0;
  border: none;
}
.TestReports-container {
  padding: 15px 20px;
  border-bottom: 1px solid #cccccc;
  display: flex;
}
.TestReports-container.is-last {
  border: none;
}
.TestReports-content {
  flex: 1;
}
.TestReports-logo {
  width: 120px;
  margin-right: 30px;
  height: 100%;
}
.TestReports-logoTooltip .Tooltip {
  width: 220px;
  padding: 15px;
  padding-right: 25px;
}
.TestReports-TooltipLogo {
  width: 100%;
}
.TestReports-reportDate {
  color: #333333;
  margin-bottom: 5px;
}
.TestReports-title {
  color: #333333;
  margin-bottom: 5px;
  font-weight: 700;
}
.TestReports-text {
  box-sizing: border-box;
  line-height: 1.42;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.TestReports-text p,
.TestReports-text ol,
.TestReports-text ul,
.TestReports-text pre,
.TestReports-text blockquote,
.TestReports-text h1,
.TestReports-text h2,
.TestReports-text h3,
.TestReports-text h4,
.TestReports-text h5,
.TestReports-text h6 {
  margin: auto 0;
  padding: auto 0;
}
.TestReports-closeButton {
  float: right;
  margin-top: 10px;
}
.TestReports-closeIcon {
  height: 15px;
  width: 15px;
}
.TestReports-closeIcon path {
  fill: #b4b4b4;
}
.Tipp {
  display: flex;
  align-items: center;
  margin-top: 35px;
}
.Tipp-box {
  background-color: #cae7a8;
  width: 10%;
  text-align: center;
  color: #005ea8;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  margin-right: 15px;
  flex: 10%;
}
.Tipp-box:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  position: absolute;
  pointer-events: none;
  border-left-color: #cae7a8;
  border-width: 10px;
  margin-top: -10px;
}
.Tipp-text {
  margin-left: 10px;
  font-size: 15px;
  flex: 85%;
  width: 85%;
}
.Tooltip-content {
  position: absolute;
  z-index: 10000;
  display: block;
  opacity: 0;
  pointer-events: none !important;
  transition: opacity 250ms;
}
.Tooltip-Root {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  align-self: flex-start;
  align-items: center;
}
.hover {
  overflow: visible !important;
}
.hover .Tooltip-icon {
  fill: #005ea8 !important;
}
.hover .Tooltip-content {
  opacity: 1 !important;
}
.hover .Tooltip {
  pointer-events: all !important;
  opacity: 1 !important;
}
.hover .Tooltip-arrow {
  opacity: 1 !important;
}
.hover .Tooltip-arrow-shadow {
  opacity: 1 !important;
}
.Tooltip-arrow {
  width: 12px;
  height: 12px;
  position: absolute;
  background: #fff;
  transform: rotate(315deg);
  z-index: 15;
  opacity: 0 !important;
}
.Tooltip-arrow-shadow {
  width: 12px;
  height: 12px;
  position: absolute;
  transform: rotate(315deg);
  z-index: -10;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.45);
  opacity: 0 !important;
}
.Tooltip {
  position: relative;
  z-index: 10;
  border-radius: 5px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.45);
  width: 320px;
  pointer-events: auto;
  font-size: 12px !important;
  line-height: 1.6;
  color: #333333 !important;
  font-weight: 300;
  pointer-events: none;
  text-align: left;
  opacity: 0 !important;
  padding-right: 25px;
  transform: translate3d(0, 0, 0);
}
.Tooltip-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 9px;
  top: 9px;
  cursor: pointer;
  fill: #999999;
}
.Tooltip-close.hidden {
  opacity: 0;
}
.Tooltip-headline {
  color: #333333;
  font-weight: bold;
}
.Tooltip-hoverRef {
  position: relative;
}
.TrustBox {
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}
@media all and (-ms-high-contrast: none) {
  .TrustBox {
    flex: 1 0 0;
  }
}
.TrustBox-infoContainer {
  margin-top: 10px;
  text-align: right;
}
.TrustBox-slider {
  width: 208px;
}
.TrustBox-slider .slick-prev,
.TrustBox-slider .slick-next {
  width: 14px;
  height: 50px;
  fill: #dcdcdc;
  z-index: 1;
}
.TrustBox-slider .slick-next {
  right: 5px;
}
.TrustBox-slider .slick-prev {
  left: 5px;
}
.TrustBox-serviceRating {
  height: 103px;
}
.TrustBox-vertical {
  height: 132px;
}
.TrustBox-svg {
  height: 60px;
  max-width: 120px;
}
.TrustBox-sliderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 132px;
}
.TrustBox-infoContainer {
  text-align: right;
  margin-top: 10px;
  margin-right: 5px;
}
.ContactTrustFooter {
  display: flex;
  margin-top: 20px;
  margin-bottom: 32px;
  padding: 18px 25px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  max-width: 850px;
}
.ContactTrustFooter.is-error {
  position: absolute;
  bottom: 125px;
  width: 994px;
}
.ContactTrustFooter.is-wide {
  max-width: 100%;
  margin-bottom: 25px;
  padding: 25px 58px;
}
.ContactTrustFooter.has-border {
  border: 1px solid #cccccc;
}
.ContactTrustFooter-headline {
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 15px;
}
.ContactTrustFooter-headline.is-larger {
  font-size: 18px;
}
.ContactTrustFooter-consultant {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ContactTrustFooter-consultant.is-wide {
  margin-left: 60px;
}
.ContactTrustFooter-customerPromise {
  margin-right: 15px;
}
.ContactTrustFooter-section {
  display: flex;
  align-items: center;
}
.ContactTrustFooter-section:last-child {
  margin-bottom: 0;
}
.ContactTrustFooter-sectionTitle {
  font-size: 14px;
  color: #333333;
}
.ContactTrustFooter-logoContainer {
  width: 40px;
  height: 35px;
  margin-right: 16px;
}
.ContactTrustFooter-logo {
  fill: #063773;
  max-height: 35px;
  max-width: 40px;
  pointer-events: none;
}
.ContactTrustFooter-contactContainer {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  justify-content: space-between;
  align-items: flex-end;
}
.ContactTrustFooter-consultantContainer {
  display: flex;
  flex-direction: row;
}
.ContactTrustFooter-consultantPhoto {
  display: inline;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-position: top center;
  background-size: cover;
}
.ContactTrustFooter-quote {
  height: 85px;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  color: #333333;
  padding: 9px;
  margin-left: 35px;
  max-width: 255px;
  position: relative;
  display: flex;
  align-items: center;
}
.ContactTrustFooter-quote.is-wide {
  max-width: 315px;
}
.ContactTrustFooter-arrow {
  width: 16px;
  height: 16px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: #dcdcdc;
  background-color: #ffffff;
  background-size: cover;
  transform: rotate(-44deg);
  position: absolute;
  top: calc(50% - 8px);
  left: -9px;
}
.ContactTrustFooter-contactInfo {
  max-width: 200px;
  height: 64px;
}
.ContactTrustFooter-phoneEmailInfo {
  margin-left: 10px;
  height: 64px;
}
.ContactTrustFooter-consultantName {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 4px;
}
.ContactTrustFooter-consultantTitle {
  font-size: 14px;
  color: #999999;
}
.ContactTrustFooter-phoneNumberContainer {
  display: flex;
  align-items: center;
}
.ContactTrustFooter-phoneSvg {
  fill: #005ea8;
  height: 20px;
  width: 15px;
  margin-right: 10px;
  margin-left: 5px;
}
.ContactTrustFooter-phoneNumber {
  color: #005ea8;
  font-size: 14px;
}
.ContactTrustFooter-workingTimes {
  color: #666666;
  font-size: 12px;
}
.ContactTrustFooter-emailContainer {
  display: flex;
  padding-top: 7px;
}
.ContactTrustFooter-emailSvg {
  fill: #005ea8;
  height: 15px;
  width: 20px;
  margin-right: 10px;
  margin-top: 4px;
}
.ContactTrustFooter-email {
  color: #005ea8;
  font-size: 14px;
}
.ContactTrustFooter-container {
  width: 100%;
  display: flex;
}
@media all and (-ms-high-contrast: none) {
  .ContactTrustFooter-container {
    width: 85%;
  }
}
@media (min-width: 1280px) {
  .ResultPage .ContactTrustFooter-container,
  .ComparisonPage .ContactTrustFooter-container {
    flex: 65;
  }
}
.ContactTrustFooter-extraSpace {
  margin-top: auto;
}
.ContactTrustFooter-customerPromiseContainer {
  width: 50%;
  border-right: 1px solid #dcdcdc;
}
.ContactTrustFooter-tooltip .Tooltip {
  width: 720px;
}
.TrustFooter {
  display: flex;
  margin-top: 20px;
  margin-bottom: 32px;
  padding: 18px 25px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  max-width: 850px;
}
.TrustFooter.is-error {
  position: absolute;
  bottom: 125px;
  width: 994px;
}
.TrustFooter.is-wide {
  max-width: 100%;
  margin-bottom: 25px;
  padding: 20px 58px;
}
.TrustFooter.has-border {
  border: 1px solid #cccccc;
}
.TrustFooter-headline {
  color: #333333;
  font-size: 16px;
  font-weight: 300;
}
.TrustFooter-headline.is-larger {
  font-size: 20px;
}
.TrustFooter-customerPromise {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}
.TrustFooter-section {
  font-size: 13px;
  max-width: 180px;
  text-align: center;
  margin-right: 20px;
}
.TrustFooter-section:last-of-type {
  margin-right: 0;
}
.TrustFooter-logo {
  height: 90px;
  width: 100%;
  pointer-events: none;
}
.TrustFooter-text {
  font-size: 14px;
}
.TrustFooter-sectionText {
  margin: 0px;
  margin-top: 10px;
  font-size: 12px;
}
.TrustFooter-sectionText.smaller {
  max-width: 160px;
}
.TrustFooter-sectionHeadline {
  color: #333333;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
}
.TrustFooter-container {
  width: 100%;
  justify-content: space-between;
}
@media all and (-ms-high-contrast: none) {
  .TrustFooter-container {
    width: 85%;
  }
}
@media (min-width: 1280px) {
  .ResultPage .TrustFooter-container,
  .ComparisonPage .TrustFooter-container {
    flex: 65;
  }
}
.TrustFooter-extraSpace {
  margin-top: auto;
}
.TuevLogo {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
}
.TuevLogo-svg {
  width: 102px;
  height: 55px;
  cursor: pointer;
  pointer-events: auto;
}
.TuevLogo-tooltipContent {
  color: #333333;
}
.TuevLogo-tooltipHeadline {
  font-size: 14px;
  font-weight: bold;
}
.TuevLogo-tooltipSubHeadline {
  font-weight: bold;
  color: #333333;
}
.TuevLogo-tooltipAddressHeadline {
  margin: 8px 0;
}
.TuevLogo-header {
  font-weight: 700;
  color: #333333;
  margin-bottom: 15px;
}
.TuevLogo-tooltip .Tooltip {
  width: 475px;
}
.OnlineContractEnabledCheck-maxPossiblePension {
  color: #999999;
  font-size: 11px;
}
.PossibleETFSelectionCheck-maxPossiblePension {
  color: #999999;
  font-size: 11px;
}
.PossibleGreenFundsSelectionCheck-maxPossiblePension {
  color: #999999;
  font-size: 11px;
}
.PossibleGreenFundsSelectionCheck-wrapper {
  padding-right: 10px;
}
.TariffFilter {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.TariffFilter:last-child {
  margin-bottom: 0px;
}
.TariffFilter-content {
  display: flex;
  flex-direction: column;
}
.TariffFilter-buttons {
  justify-content: space-between;
  padding: 8px;
  margin: 0;
  height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  z-index: 0;
}
.TariffFilter-buttons:first-child {
  position: static;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.TariffFilter-buttons:nth-child(2) {
  position: static;
  margin-top: -1px;
}
.TariffFilter-buttons:last-child {
  position: static;
  margin-top: -1px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.TariffFilter-buttons:hover {
  border: 1px solid #005ea8;
  z-index: 1;
}
.TariffFilter-buttons.is-selected {
  background-color: #ecf7fd;
  border: 1px solid #005ea8;
  z-index: 1;
}
.TariffFilter-maxPossiblePension {
  font-size: 11px;
  color: #999999;
}
.TariffSustainabilityFilter {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.TariffSustainabilityFilter:last-child {
  margin-bottom: 0px;
}
.TariffSustainabilityFilter-content {
  display: flex;
  flex-direction: column;
}
.TariffSustainabilityFilter-buttons {
  justify-content: space-between;
  display: flex;
  padding: 5px;
  margin: 0;
  height: 40px;
  max-height: 40px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  z-index: 0;
  padding-bottom: 10px;
  padding-top: 10px;
}
.TariffSustainabilityFilter-buttons:first-child {
  position: static;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.TariffSustainabilityFilter-buttons:nth-child(2) {
  position: static;
  margin-top: -1px;
}
.TariffSustainabilityFilter-buttons:last-child {
  position: static;
  margin-top: -1px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.TariffSustainabilityFilter-buttons:hover {
  border: 1px solid #005ea8;
  z-index: 1;
}
.TariffSustainabilityFilter-buttons.is-selected {
  background-color: #ecf7fd;
  border: 1px solid #005ea8;
  z-index: 1;
}
.TariffSustainabilityFilter-leafSvgContainer {
  height: 18px;
  margin-right: 10px;
}
.TariffSustainabilityFilter-leafSvg {
  height: 18px;
  width: 18px;
}
.TariffSustainabilityFilter-leafSvg.TariffSustainabilityFilter-leafSvg--green {
  fill: #7ab51d;
}
.TariffSustainabilityFilter-leafSvg.TariffSustainabilityFilter-leafSvg--grey {
  fill: #b4b4b4;
}
.TariffSustainabilityFilter-leafSvg.TariffSustainabilityFilter-leafSvg--first {
  margin-right: 7px;
}
.TariffSustainabilityFilter-value {
  display: flex;
}
.TariffSustainabilityFilter-maxPossiblePension {
  font-size: 11px;
  color: #999999;
}
.InvestmentTypeSelect-container {
  display: flex;
  margin-top: 5px;
  justify-content: space-around;
  padding: 0 12px;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer .InvestmentTypeSelect-iconContainer {
  display: flex;
  width: 50px;
  height: 48px;
  box-shadow: 1px 1px 5px 0 #999999;
  border-radius: 5px;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid transparent;
  padding: 6px 0;
  transition-duration: 200ms;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer .InvestmentTypeSelect-iconContainer:hover {
  border: 1px solid #575757;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer .InvestmentTypeSelect-iconContainer.is-selected {
  background-color: #005ea8;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer .InvestmentTypeSelect-iconContainer.is-selected svg {
  fill: #ffffff;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer .InvestmentTypeSelect-iconContainer.is-selected:hover {
  border: 1px solid transparent;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer .InvestmentTypeSelect-iconContainer svg {
  fill: #999999;
  transition-duration: 200ms;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer .InvestmentTypeSelect-iconContainer svg.large {
  width: 28px;
  height: 34px;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer .InvestmentTypeSelect-iconContainer svg.small {
  width: 15px;
  height: 20px;
}
.InvestmentTypeSelect-container .InvestmentTypeSelect-buttonContainer .InvestmentTypeSelect-buttonTitle {
  margin-top: 5px;
}
.InvestmentTypeSelect-tooltip .Tooltip {
  width: 353px;
}
.AppointmentBookingConfirmation-container {
  width: 548px;
  padding: 24px 48px 24px 48px;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  background-color: #ffffff;
}
.AppointmentBookingTool-confirmationMessageBox {
  display: flex;
  align-items: flex-start;
}
.AppointmentBookingConfirmation-checkSvg {
  width: 28px;
  height: 22px;
  fill: #008300;
  margin-right: 15px;
  margin-top: 5px;
}
.AppointmentBookingConfirmation-confirmationMessageBox {
  color: #008300;
  font-size: 22px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}
.AppointmentBookingConfirmation-confirmationMessage {
  color: #008300;
  font-size: 22px;
}
.AppointmentBookingConfirmation-emailConfirmationInfo {
  font-size: 12px;
}
.AppointmentBookingConfirmation-appointmentDateDetailBox {
  display: flex;
  margin-top: 16px;
  align-items: center;
}
.AppointmentBookingConfirmation-appointmentDateDetail {
  font-size: 12px;
  font-weight: bold;
}
.AppointmentBookingConfirmation-calendarSvg {
  width: 23px;
  height: 23px;
  fill: #005ea8;
  margin-right: 7px;
}
.AppointmentBookingConsultantInfo-container {
  width: 548px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px 48px 24px 48px;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.AppointmentBookingConsultantInfo-headline {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 16px;
}
.AppointmentBookingCta-button {
  margin-top: 7px;
  margin-bottom: 10px;
}
.AppointmentBookingCta-button .Button-navigationSpinner {
  margin-left: 10px;
  margin-right: 0;
}
.AppointmentBookingErrorModal-header {
  font-size: 13px;
  font-size: 18px;
  display: flex;
  margin-bottom: 20px;
}
.AppointmentBookingErrorModal-header svg {
  height: 22px;
  width: 22px;
  margin-right: 10px;
}
.AppointmentBookingErrorModal-ctaButton {
  margin-top: 18px;
  width: 255px;
  float: right;
}
.AppointmentBookingExpiredSession-container {
  width: 548px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px 48px 24px 48px;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.AppointmentBookingExpiredSession-expiredSessionInfoBox {
  display: flex;
  margin-bottom: 18px;
}
.AppointmentBookingExpiredSession-expiredSessionInfo {
  margin-left: 10px;
}
.AppointmentBookingMessage-container {
  display: flex;
  flex-direction: column;
}
.AppointmentBookingMessage-header {
  margin-top: 16px;
}
.AppointmentBookingMessage-input {
  margin-top: 16px;
}
.AppointmentBookingMessage-tintedInput.TextAreaInput-field {
  color: #999999;
}
.AppointmentBookingMessage-cta {
  width: 200px;
  align-self: flex-end;
  margin-top: 16px;
}
.AppointmentBookingMessage-savedMessage {
  display: flex;
  margin-top: 16px;
}
.AppointmentBookingMessage-savedIcon {
  width: 15px;
  min-width: 15px;
  height: 12px;
  fill: #008300;
  margin-right: 15px;
  margin-top: 4px;
}
.AppointmentBookingPhoneModal-content {
  padding: 5px 30px;
}
.AppointmentBookingPhoneModal-header {
  font-size: 13px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.AppointmentBookingPhoneModal-header svg {
  height: 23px;
  width: 17px;
  margin-right: 10px;
  fill: #005ea8;
}
.AppointmentBookingPhoneModal-message {
  font-size: 14px;
}
.AppointmentBookingPhoneModal-ctaButton {
  margin-top: 18px;
  width: 255px;
  float: right;
}
.AppointmentBookingPhoneModal-phoneNumber {
  margin-top: 15px;
}
.AppointmentBookingToolDays-calendarHeaderRow {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}
.AppointmentBookingToolDays-calendarHeaderRowDate {
  font-weight: 700;
}
.AppointmentBookingToolSummary-selectedAppointmentDetails {
  display: flex;
  flex-direction: column;
}
.AppointmentBookingToolSummary-selectedAppointmentWrapper {
  margin-top: 25px;
  padding-right: 8px;
}
.AppointmentBookingToolSummary-selectedAppointmentHeading {
  font-size: 12px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.AppointmentBookingToolSummary-selectedAppointmentContent {
  display: flex;
}
.AppointmentBookingToolSummary-selectedAppointmentCtaSection {
  flex: 1;
}
.AppointmentBookingToolSummary-selectedAppointment {
  padding: 15px 15px;
  font-size: 12px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  margin-top: 7px;
  text-align: center;
  height: 90px;
  width: 200px;
  line-height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AppointmentBookingToolSummary-selectedAppointmentButton {
  margin-top: 16px;
}
.AppointmentBookingToolSummary-noSelectionError {
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 0;
  color: #c82d2d;
  max-width: 200px;
}
.AppointmentBookingToolTimeSlots-timeSlotButton {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  margin: 4px 5px 0px 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}
.AppointmentBookingToolTimeSlots-timeSlotButton:hover {
  border-color: #575757;
}
.AppointmentBookingToolTimeSlots-timeSlotButton.is-selected {
  background-color: #0271c2;
  color: #ffffff;
}
.AppointmentBookingToolTimeSlots-timeSlotButton.is-disabled {
  background-color: #f4f4f4;
  cursor: default;
}
.AppointmentBookingToolTimeSlots-timeSlotButton.is-disabled:hover {
  border: 1px solid #dcdcdc;
}
.AppointmentBookingToolTimeSlots-timeSlotInput {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
}
.AppointmentBookingToolTimeSlots-timeSlotLabel {
  font-size: 12px;
  cursor: pointer;
}
.AppointmentBookingToolTimeSlots-timeSlotLabel.is-disabled {
  cursor: default;
}
.AppointmentBookingTool-container {
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  width: 548px;
  margin-bottom: 24px;
  background-color: #ffffff;
  padding-top: 20px;
}
.AppointmentBookingTool-timeSlotsSpinner {
  height: 460px;
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AppointmentBookingTool-calendarWrapper {
  margin-right: 18px;
}
.AppointmentBookingTool-calendar {
  margin-right: 0;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  border-spacing: 0px;
  padding-bottom: 5px;
  display: block;
}
.AppointmentBookingTool-calendar thead th,
.AppointmentBookingTool-calendar tbody th,
.AppointmentBookingTool-calendar thead td,
.AppointmentBookingTool-calendar tbody td {
  width: 88px;
  min-width: 88px;
}
.AppointmentBookingTool-calendar th {
  border-right: 1px solid #dcdcdc;
  padding: 0;
  font-weight: 100;
  font-size: 12px;
  padding-top: 6px;
  padding-bottom: 7px;
  padding-right: 10px;
}
.AppointmentBookingTool-calendar th:last-of-type {
  border-right: none;
}
.AppointmentBookingTool-calendar tr {
  display: block;
}
.AppointmentBookingTool-calendar thead {
  border-bottom: 1px solid #dcdcdc;
  display: block;
}
.AppointmentBookingTool-calendar tbody {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;
}
.AppointmentBookingTool-calendar tbody td {
  padding: 0px;
}
.AppointmentBookingTool-calendar tbody.has-footer {
  min-width: 280px;
  max-height: 400px;
}
.AppointmentBookingTool-calendar tbody.has-footer::after {
  content: '';
  position: sticky;
  display: block;
  bottom: 0px;
  height: 10px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
}
.AppointmentBookingTool-calendar tfoot {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 5px;
}
.AppointmentBookingTool-calendar tfoot tr {
  display: block;
  width: 100%;
  padding-right: 16px;
}
.AppointmentBookingTool-calendar tfoot tr td {
  display: block;
  width: 100%;
}
.AppointmentBookingTool-scrollToBottom {
  margin: 0 auto;
  display: block;
}
.AppointmentBookingTool-scrollToBottom:disabled .AppointmentBookingTool-scrollToBottomIcon * {
  fill: #999999;
}
.AppointmentBookingTool-scrollToBottomIcon {
  width: 17px;
  height: 9px;
}
.AppointmentBookingTool-scrollToBottomIcon * {
  fill: #005ea8;
}
.AppointmentBookingTool-section {
  padding: 0px 48px 24px 48px;
}
.AppointmentBookingTool-headingWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 15px;
}
.AppointmentBookingTool-calendarSvg {
  width: 23px;
  height: 23px;
  margin-right: 12px;
  fill: #005ea8;
}
.AppointmentBookingTool-heading {
  font-size: 16px;
  margin: 0px;
}
.AppointmentBookingTool-textWrapper {
  display: flex;
  font-size: 12px;
  align-items: flex-start;
  margin-top: 16px;
}
.AppointmentBookingTool-text {
  margin: 0;
  font-size: 12px;
}
.AppointmentBookingTool-bookingWrapper {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
}
.AppointmentBookingTool-emailContactHeader {
  margin: 0px;
  font-size: 12px;
  margin-bottom: 6px;
}
.AppointmentBookingTool-emailContactText {
  margin: 0px;
  margin-bottom: 9px;
}
.AppointmentBookingTool-emailWrapper {
  display: flex;
  align-items: center;
}
.AppointmentBookingTool-emailSvg {
  fill: #005ea8;
  height: 16px;
  width: 22px;
  margin-right: 10px;
}
.AppointmentBooking-spinner {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}
.ComparisonButtons-tooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}
.ComparisonButtons-tooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: #7ab51d;
  position: relative;
  top: 4px;
}
.ComparisonDetails {
  border: 1px solid #cccccc;
  border-bottom: none;
  border-top: none;
}
.ComparisonDetails-feature {
  border-bottom: 1px solid #cccccc;
  display: flex;
  position: relative;
}
.ComparisonDetails-feature:hover {
  box-shadow: 0 0 5px 0 #999999;
  z-index: 500;
}
.ComparisonDetails-featureTitle {
  background-color: #ededed;
  position: relative;
  display: flex;
  flex: 1;
  font-size: 11px;
  padding-top: 10px;
}
@media (min-width: 1280px) {
  .ComparisonDetails-featureTitle {
    font-size: 12px;
  }
}
.ComparisonDetails-featureName {
  width: 98%;
  padding-left: 9px;
  padding-right: 20px;
  padding-bottom: 10px;
  display: flex;
}
.ComparisonDetails-tariffFeature {
  background-color: #ffffff;
  display: flex;
  align-items: flex-start;
  font-size: 11px;
  cursor: default;
  border-left: 1px solid #cccccc;
  flex: 1;
}
.ComparisonDetails-tariffFeature > div > svg {
  margin-top: 6px;
}
@media (min-width: 1280px) {
  .ComparisonDetails-tariffFeature {
    font-size: 12px;
  }
}
.ComparisonDetails-tariffFeature .Tooltip-Root {
  width: 60%;
  height: 100%;
  align-items: center;
}
.ComparisonDetails-featureValue {
  display: inline-flex;
  display: flex;
  padding: 10px;
}
.ComparisonDetails-featureValue > p {
  margin: 0px;
}
.ComparisonDetails-numValue {
  margin-left: 4px;
  min-width: 52px;
  height: 20px;
  display: flex;
  justify-content: center;
}
.ComparisonDetails-tooltip {
  right: 5px;
  cursor: help;
}
.ComparisonDetails-tooltipIcon {
  width: 16px;
  height: 16px;
  fill: #b4b4b4;
  transition-duration: 200ms;
  margin-right: 1px;
}
.ComparisonDetails-tooltipIcon * {
  fill: #b4b4b4;
}
.hover .ComparisonDetails-tooltipIcon {
  fill: #005ea8;
}
.hover .ComparisonDetails-tooltipIcon * {
  fill: #005ea8;
}
.ComparisonDetails-featureIcon > svg {
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 16px;
  margin-left: 20px;
}
.ComparisonDocuments-document {
  display: block;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  padding-left: 10px;
}
.ComparisonDocuments-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  position: relative;
  top: 4px;
}
.ComparisonDocuments-feature {
  display: flex;
  position: relative;
  border-bottom: 1px solid #cccccc;
}
.ComparisonDocuments-feature:hover {
  box-shadow: 0 0 5px 0 #999999;
  z-index: 900;
}
.ComparisonDocuments-tariffFeature {
  background-color: #ffffff;
  flex: 1;
  line-height: 40px;
  min-height: 40px;
  font-size: 11px;
  border-left: 1px solid #cccccc;
}
@media (min-width: 1280px) {
  .ComparisonDocuments-tariffFeature {
    font-size: 12px;
  }
}
.ComparisonDocuments-featureName {
  width: 98%;
  padding-bottom: 9px;
  padding-right: 20px;
  padding-left: 10px;
}
.ComparisonInquiryButton-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}
.ComparisonInquiryButton-container {
  pointer-events: none;
}
.ComparisonInquiryButton-container.is-secondary {
  margin-top: 20px;
}
.ComparisonInquiryButton-tooltip .Tooltip {
  width: auto;
  white-space: nowrap;
}
@media print {
  @page {
    size: auto;
    margin: 3px 0;
  }
  .Main {
    background-color: transparent !important;
  }
  body {
    -webkit-print-color-adjust: exact;
    margin: 0;
    zoom: 80% !important;
  }
  svg,
  div {
    display: block;
  }
  .c24-page-container,
  .c24-cookie-consent-notice {
    display: none !important;
  }
  .ComparisonDetails-featureTitle,
  .ComparisonDetails-tariffFeature {
    border: none;
    outline: 1px solid #cccccc;
  }
  .ComparisonSelectedTariffsFeatures-categories {
    border: none;
  }
  .ComparisonSelectedTariffsFeatures-categories.has-lineBreakOnPrinting {
    page-break-before: always;
  }
  .Page {
    max-width: none;
    width: 100%;
    padding: 10px 30px !important;
  }
  .CampaignBanner {
    display: none;
  }
  .TrustFooter {
    display: none !important;
  }
  .PageHeader-images {
    display: none !important;
  }
  .PageHeader-headline {
    margin-bottom: 0;
  }
  .PageHeader-text {
    text-align: right;
    font-size: 14px;
  }
  .PageHeader-check24Logo {
    display: block;
    height: 45px;
    width: auto;
  }
  .PageHeader-printButtonContainer,
  .PageHeader-backLink {
    display: none;
  }
  .PageHeader-searchCriteriaText {
    display: block;
  }
  .Grade-icon {
    border: 1px solid #333333 !important;
  }
  .Grade-icon.is-golden {
    border: 1px solid #ffca04 !important;
  }
  .ComparisonStickyHeader {
    display: none !important;
  }
  .ComparisonSelectedTariffsFeatures-filterContainer,
  .ComparisonSelectedTariffsFeatures-categoriesTariffConditions {
    display: none;
  }
  .ComparisonSelectedTariffsFeatures {
    margin: 0;
  }
  .ComparisonSelectedTariffsFooter,
  .ComparisonSelectedTariffsHeader-close {
    display: none !important;
  }
  .ComparisonSelectedTariffsInfo-headlineButtons {
    height: auto !important;
  }
  .ComparisonPage-backButton,
  .OnlineContractButton,
  .ComparisonPage-detailsButton > .Button,
  .ComparisonPage-detailsButton > a {
    visibility: hidden;
  }
  .ComparisonSelectedTariffsInfo-container,
  .ComparisonPage-detailsButton,
  .ComparisonSelectedTariffsInfo-headlineButtons {
    padding-top: 0 !important;
  }
  .ComparisonSelectedTariffsHeader-insuranceDetailsContainer {
    height: 100px !important;
  }
  .ComparisonDetails-tooltipContainer {
    display: none;
  }
  .ComparisonPage-infoFooter {
    display: block !important;
    font-size: 14px;
    bottom: 20px;
    position: fixed;
  }
  .ComparisonSelectedTariffsFeatures-selectedFilterText {
    display: block !important;
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
    color: #005ea8;
  }
  .ComparisonSelectedTariffsFeatures-filterContainer {
    display: none !important;
  }
  .ConsultantHeader {
    display: none;
  }
  .ResultZeroHeader-infoContainer {
    display: none;
  }
  .ComparisonDetails-tariffFeatureTooltip:before {
    content: none;
  }
  .ComparisonInquiryButton-wrapper {
    visibility: hidden;
  }
  .ComparisonSelectedTariffsFeatures-categories,
  .ComparisonPage-legendContainer,
  .ComparisonPage-disclaimerFooter,
  .ComparisonPage-infoFooter {
    page-break-inside: avoid;
  }
  .ComparisonSelectedTariffsHeader-wishListHeartContainer {
    display: none;
  }
}
.ComparisonSelectedTariffsFeatures {
  width: 100%;
  margin: 20px 0;
}
.ComparisonSelectedTariffsFeatures-filterContainer {
  display: flex;
  margin-left: 5px;
  margin-top: 50px;
}
.ComparisonSelectedTariffsFeatures-filterOptions {
  margin-top: -10px;
  margin-left: 10px;
  padding: 10px 0;
  border-radius: 30px;
  background-color: #ededed;
}
.ComparisonSelectedTariffsFeatures-option {
  color: #005ea8;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
}
.ComparisonSelectedTariffsFeatures-option.is-selected {
  color: #ffffff;
  background-color: #005ea8;
}
.ComparisonSelectedTariffsFeatures-categories {
  margin: 15px 0;
}
.ComparisonSelectedTariffsFeatures-categoryTitle {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 0;
  border-bottom: 1px solid #cccccc;
  color: #333333;
}
@media (min-width: 1200px) {
  .ComparisonSelectedTariffsFeatures-categoryTitle {
    font-size: 18px;
  }
}
.ComparisonSelectedTariffsFeatures-selectedFilterText {
  display: none;
}
.ComparisonSelectedTariffsFooter {
  display: flex;
  border: 1px solid #cccccc;
}
.ComparisonSelectedTariffsFooter .ComparisonPage-spacer {
  padding: 0px;
}
.ComparisonSelectedTariffsFooter .ComparisonPage-detailsButton {
  border-bottom: none;
  padding: 16px 0;
  border-left: 1px solid #cccccc;
}
.ComparisonSelectedTariffsFooter-container {
  background-color: #ffffff;
  border-left: 1px solid #cccccc;
  position: relative;
  flex: 1;
}
.ComparisonSelectedTariffsHeader {
  display: flex;
  border-left: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
}
.ComparisonSelectedTariffsHeader-tariffBlock {
  border-left: 1px solid #cccccc;
  background-color: #ffffff;
  flex: 1;
  position: relative;
  padding: 0 14px 8px;
  min-width: 1px;
  /* This is because a bug in firefox with long tariff names */
}
.ComparisonSelectedTariffsHeader-tariffBlock:last-of-type {
  border-right: 1px solid #cccccc;
}
.ComparisonSelectedTariffsHeader-tariffBlock.is-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}
.ComparisonSelectedTariffsHeader-possiblePension {
  display: block;
  color: #008300;
  font-size: 22px;
  font-weight: 700;
}
.ComparisonSelectedTariffsHeader-guaranteedPension {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #333;
}
.ComparisonSelectedTariffsHeader-pensionDescription {
  display: block;
  font-size: 11px;
}
.ComparisonSelectedTariffsHeader-pensionDescription.ComparisonSelectedTariffsHeader-possiblePensionDescription {
  color: #008300;
}
.ComparisonSelectedTariffsHeader-insuranceDetailsContainer {
  margin-top: 10px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ComparisonSelectedTariffsHeader-insuranceLogo {
  display: block;
  height: 50px;
  max-width: 200px;
}
@media (min-width: 1200px) {
  .ComparisonSelectedTariffsHeader-insuranceLogo {
    max-height: 55px;
  }
}
.ComparisonSelectedTariffsHeader-close {
  position: absolute;
  right: 16px;
  top: 11px;
  pointer-events: auto;
  width: 14px;
  height: 14px;
  fill: #b4b4b4;
  cursor: pointer;
}
@media (max-width: 1110px) {
  .ComparisonSelectedTariffsHeader-close {
    right: 5px;
  }
}
.ComparisonSelectedTariffsHeader-tariffName {
  text-align: center;
  color: #666666;
  margin: 10px 0 0 0;
  font-size: 14px;
  font-weight: 300;
}
.ComparisonSelectedTariffsHeader-pensionAmountContainer {
  text-align: center;
}
.ComparisonSelectedTariffsHeader-addTariff {
  width: 62px;
  height: 62px;
  fill: #005ea8;
}
.ComparisonSelectedTariffsHeader-addTariff:hover {
  fill: #015fa4;
  cursor: pointer;
}
.ComparisonSelectedTariffsHeader-labels {
  margin-top: 7px;
  min-height: 24px;
}
.ComparisonSelectedTariffsHeader-resultZeroHeader {
  padding-right: 5px;
  padding-left: 5px;
}
.ComparisonSelectedTariffsHeader-labelSlot {
  min-height: 24px;
  margin-bottom: 7px;
}
.ComparisonSelectedTariffsHeader-tariffPromotionHeader {
  padding-left: 5px;
  padding-right: 5px;
}
.ComparisonSelectedTariffsHeader-wishListHeartContainer {
  position: absolute;
  top: 36px;
  right: 13px;
}
@media (max-width: 1110px) {
  .ComparisonSelectedTariffsHeader-wishListHeartContainer {
    right: 3px;
  }
}
.ComparisonSelectedTariffsInfo {
  display: flex;
  border-left: 1px solid #cccccc;
}
.ComparisonSelectedTariffsInfo-container {
  background-color: #ffffff;
  margin: 0;
  padding: 16px 0 0;
  border-left: 1px solid #cccccc;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.ComparisonSelectedTariffsInfo-container:last-of-type {
  border-right: 1px solid #cccccc;
}
.ComparisonSelectedTariffsInfo-grade {
  display: flex;
  height: 100px;
  justify-content: center;
  border-bottom: 1px solid #cccccc;
  padding-top: 10px;
}
@media (min-width: 1280px) {
  .ComparisonSelectedTariffsInfo-grade {
    height: 110px;
  }
}
.ComparisonSelectedTariffsInfo-headlineContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ededed;
}
.ComparisonSelectedTariffsInfo-headlineButtons {
  display: flex;
  padding-top: 16px;
  background-color: #ededed;
  height: 55px;
  border-bottom: 1px solid #cccccc;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
}
.ComparisonSelectedTariffsInfo-headlineGrade {
  display: flex;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  min-height: 100px;
  padding: 0 12px 0 16px;
  color: #333333;
}
@media (min-width: 1280px) {
  .ComparisonSelectedTariffsInfo-headlineGrade {
    min-height: 110px;
  }
}
.ComparisonSelectedTariffsInfo-headlineGradeTitle {
  font-size: 14px;
  margin-top: 14px;
}
.ComparisonSelectedTariffsInfoButton-add {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  color: #005ea8;
  cursor: pointer;
  padding: 10px;
  display: block;
}
.ComparisonStickyHeader {
  background: white;
  position: absolute;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease;
}
.ComparisonPage-stickyContainer .sticky {
  background: white;
  z-index: 1500;
}
.ComparisonPage-stickyContainer .sticky .ComparisonStickyHeader {
  visibility: visible;
  opacity: 1;
  display: flex;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.ComparisonStickyHeader-insuranceLogo {
  max-width: 200px;
  height: 50px;
  margin-bottom: 11px;
  margin-top: 5px;
}
.ComparisonStickyHeader-priceContainer {
  text-align: right;
  min-width: 100px;
  line-height: 15px;
}
.ComparisonStickyHeader-insuranceNameAndPrice {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  height: 35px;
}
.ComparisonStickyHeader-insuranceName {
  color: #666666;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.ComparisonStickyHeader-tariffBlockDetails {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.ComparisonStickyHeader-tariffBlock {
  border-left: 1px solid #cccccc;
  background-color: #ffffff;
  flex: 1;
  position: relative;
  padding: 0 14px 8px;
  min-width: 1px;
  /* This is because a bug in firefox with long tariff names */
  padding: 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ComparisonStickyHeader-tariffBlock:last-of-type {
  border-right: 1px solid #cccccc;
}
.ComparisonStickyHeader-tariffBlock.is-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}
.ComparisonStickyHeader-tariffBlock:last-of-type {
  border-right: none;
}
.ComparisonStickyHeader-tariffBlock.is-empty {
  padding: 11px 10px;
  display: flex;
  align-items: center;
  pointer-events: auto;
  flex-direction: column;
  justify-content: center;
}
.ComparisonStickyHeader-tariffBlock .ComparisonPage-detailsButton {
  border-bottom: none;
}
.ComparisonStickyHeader-resultLinkContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  background-color: #ededed;
  padding: 10px 10px;
}
.ComparisonStickyHeader-addTariff {
  width: 52px;
  height: 52px;
  fill: #005ea8;
}
.ComparisonStickyHeader-addTariff:hover {
  fill: #015fa4;
  cursor: pointer;
}
.ComparisonStickyHeader-add {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  color: #005ea8;
  cursor: pointer;
  padding: 10px;
  display: block;
  padding: 0;
  margin-top: 5px;
  text-align: center;
}
.ComparisonStickyHeader-labels {
  margin-top: 7px;
  min-height: 24px;
}
.ComparisonStickyHeader-resultZeroHeader {
  padding-right: 5px;
  padding-left: 5px;
}
.ComparisonStickyHeader-labelSlot {
  min-height: 24px;
  margin-bottom: 5px;
}
.ComparisonStickyHeader-tariffPromotionHeader {
  padding-right: 5px;
  padding-left: 5px;
}
.ComparisonStickyHeader-close {
  position: absolute;
  right: 16px;
  top: 14px;
  pointer-events: auto;
  width: 14px;
  height: 14px;
  fill: #b4b4b4;
  cursor: pointer;
}
.ConsultantCarouselAdditionalText-container {
  background-color: #ffffff;
  padding: 30px 15px 25px 15px;
  border-top: 1px solid #cccccc;
  position: relative;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.ConsultantCarouselAdditionalText-closeButton {
  position: absolute;
  top: 10px;
  height: 10px;
  right: 15px;
  width: 12px;
  fill: #b4b4b4;
  cursor: pointer;
}
.ConsultantCarouselAdditionalText-header {
  font-size: 16px;
  color: #333333;
  margin-bottom: 6px;
  font-weight: bold;
}
.ConsultantCarouselAdditionalText-headlineRow {
  display: flex;
  margin-top: 25px;
}
.ConsultantCarouselAdditionalText-headlineIcon {
  width: 80px;
  text-align: center;
  margin-right: 10px;
}
.ConsultantCarouselAdditionalText-headlineHeader {
  font-size: 15px;
  color: #333333;
  font-weight: bold;
}
.ConsultantCarouselAdditionalText-headlineInfoText {
  font-size: 13px;
  line-height: 20px;
  color: #333333;
}
.ConsultantCarouselAdditionalText-icon {
  width: 60px;
  height: 60px;
  position: relative;
  top: 3px;
}
.ConsultantCarouselAdditionalText-icon.is-cobrowsing {
  width: 75px;
  height: 35px;
}
.ConsultantCarouselAdditionalText-headlineInfo {
  flex: 1;
}
.ConsultantCarouselItem-phoneNumber {
  font-size: 14px;
  color: #005ea8;
  display: flex;
  justify-content: center;
}
.ConsultantCarouselItem-blurbWrapper {
  display: flex;
  justify-content: space-between;
}
.ConsultantCarouselItem-container {
  position: relative;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 3px;
}
.ConsultantCarouselItem-consultantDetails {
  margin-top: 10px;
}
.ConsultantCarouselItem-phoneSvg {
  fill: #005ea8;
  width: 11px;
  height: 16px;
  margin-right: 5px;
}
.ConsultantCarouselItem-additionalText {
  display: flex;
  align-items: flex-end;
  color: #005ea8;
  fill: #005ea8;
  font-size: 12px;
  width: 75px;
  justify-content: flex-end;
}
.ConsultantCarouselItem-expandCollapseArrow {
  width: 13px;
  height: 7px;
  margin-left: 5px;
  bottom: 6px;
  position: relative;
}
.ConsultantCarouselItem-consultantDetails {
  display: flex;
  justify-content: space-between;
}
.ConsultantCarouselItem-infoQuote {
  font-size: 12px;
  color: #999999;
}
.ConsultantCarouselItem-infoTitle {
  font-size: 14px;
  color: #333333;
}
.ConsultantCarouselItem-name {
  text-align: center;
  font-size: 16px;
  color: #333333;
  margin-top: 6px;
  max-width: 100px;
}
.ConsultantCarouselItem-photoAndQuote {
  display: flex;
  flex-direction: column;
  flex-shrink: 100;
  padding-left: 30px;
}
.ConsultantCarouselItem-photoAndQuoteContainer {
  min-width: 100px;
  display: flex;
  margin-right: 30px;
}
.ConsultantCarouselItem-languages {
  width: 250px;
  margin-right: -20px;
}
.ConsultantCarouselItem-photo {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}
.ConsultantCarouselItem-quote {
  border-radius: 5px;
  padding: 20px;
  font-size: 18px;
  line-height: 22px;
  background-color: #f4f4f4;
  color: #333333;
  position: relative;
  height: min-content;
  margin-left: 20px;
  align-self: center;
}
.ConsultantCarouselItem-arrow {
  display: block;
  position: absolute;
  top: 50%;
  left: -16px;
  width: 11px;
  height: 0;
}
.ConsultantCarouselItem-arrow:after {
  content: ' ';
  border-color: transparent #f4f4f4;
  border-image: none;
  border-style: solid;
  border-width: 10px 15px 10px 0;
  width: 1px;
  position: absolute;
  margin-top: -12px;
  top: 50%;
}
.ConsultantCarousel-wrapper {
  min-width: 750px;
  margin-top: 15px;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  max-width: 1005px;
  min-height: 205px;
  position: relative;
}
.ConsultantCarousel-wrapper .slick-prev,
.ConsultantCarousel-wrapper .slick-next {
  z-index: 1 !important;
  top: 80px !important;
  fill: #cccccc;
  height: 36px !important;
  width: 10px !important;
}
.ConsultantCarousel-wrapper .slick-prev:before,
.ConsultantCarousel-wrapper .slick-next:before {
  content: '' !important;
  position: absolute !important;
  top: -15px !important;
  left: -15px !important;
  right: -15px !important;
  bottom: -30px !important;
}
.ConsultantCarousel-wrapper .slick-next {
  right: 15px !important;
}
.ConsultantCarousel-wrapper .slick-prev {
  left: 15px !important;
}
.ConsultantCarousel-wrapper .nextArrow,
.ConsultantCarousel-wrapper .prevArrow {
  height: 36px;
  width: 10px;
}
.DocumentsCategory-document {
  display: block;
}
.DocumentsCategory-document:last-of-type {
  margin-bottom: 6px;
}
.DocumentsCategory-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  position: relative;
  top: 4px;
}
.FeatureCategory {
  margin-bottom: 20px;
}
.FeatureCategory-headline {
  color: #005ea8;
  font-size: 14px;
  padding: 10px 10px 10px 0;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #f4f4f4;
  border: 1px solid #cccccc;
  border-bottom: 0;
  padding-left: 10px;
}
.FeatureCategory-title {
  flex: 1;
}
.FeatureCategory-arrow {
  display: block;
  width: 16px;
  height: 16px;
  fill: #005ea8;
}
.FeatureCategory-arrow.is-open {
  transform: rotate(180deg);
}
.FeatureCategory-detailsContainer {
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
}
.FeatureDetails {
  display: flex;
  position: relative;
}
.FeatureDetails:hover {
  box-shadow: 0 0 5px 0 #999999;
  z-index: 900;
}
.FeatureDetails-featureTitle {
  display: flex;
  position: relative;
  min-width: 41%;
  border-bottom: 1px solid #cccccc;
}
.FeatureDetails-featureTitleText {
  width: 100%;
  padding: 10px 35px 10px 10px;
  display: flex;
}
.FeatureDetails-tooltip {
  position: absolute;
  right: 12px;
  top: 12px;
}
.FeatureDetails-featureContent {
  display: flex;
  border-bottom: 1px solid #cccccc;
  padding: 10px 10px 5px 10px;
  flex-grow: 1;
}
.FeatureDetails-featureValue {
  width: 100px;
  padding-right: 10px;
}
.FeatureDetails-featureText {
  padding-bottom: 5px;
  width: 100%;
}
.FeatureDetails-documentValue {
  min-width: 150px;
}
.FeatureDetails-featureIcon > svg {
  width: 20px;
  height: 20px;
}
.GeneralTracking-image {
  display: none;
  visibility: hidden;
}
.InsuranceList ul li {
  list-style-type: disc;
}
.InsuranceList-headline {
  margin-top: 16px;
  color: #333333;
  font-size: 26px;
  text-align: center;
}
.InsuranceList-subHeadline {
  padding-top: 20px;
  font-size: 22px;
  color: #333333;
}
.InsuranceList-description {
  font-size: 16px;
  color: #333333;
  margin-bottom: 50px;
}
.InsuranceList-backButton {
  font-family: Arial;
  font-size: 13px;
  color: #005ea8;
  cursor: pointer;
  font-weight: bold;
}
.InsuranceList-backButton:hover {
  text-decoration: underline;
}
.InsuranceList-backButton:before {
  content: '\AB';
  display: inline-block;
  margin-right: 5px;
}
.InsuranceList-link {
  display: block;
  padding: 0;
  color: #005ea8;
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.InsuranceList-dateOfValidity {
  color: #333333;
  font-size: 16px;
}
.InsuranceList-table {
  margin-top: 25px;
  width: 100%;
}
.InsuranceList-table .InsuranceList-row:last-child .InsuranceList-label {
  border-bottom: none;
}
.InsuranceList-table .InsuranceList-row:last-child .InsuranceList-column {
  border-bottom: none;
}
.InsuranceList-table .InsuranceList-row:first-child .InsuranceList-label {
  border-bottom: none;
}
.InsuranceList-table .InsuranceList-row:first-child .InsuranceList-column {
  border-bottom: none;
}
.InsuranceList-row {
  width: 100%;
  display: flex;
}
.InsuranceList-column {
  flex: 1;
  padding: 20px;
  justify-content: center;
  display: flex;
  border-bottom: 1px dashed #999999;
}
.InsuranceList-column:not(:last-of-type) {
  border-right: dashed 1px #999999;
}
.InsuranceList-cell {
  width: 170px;
}
.InsuranceList-cell ul {
  margin: 0px;
}
.InsuranceList-label {
  font-weight: bold;
  color: #333333;
  padding: 20px 10px;
  border-right: 1px solid #999999;
  width: 120px;
  border-bottom: 1px dashed #999999;
}
.InsuranceList-header {
  font-weight: bold;
  color: #333333;
  text-align: center;
}
.InsuranceList-header .InsuranceList-column {
  margin-top: 25px;
  border-bottom: none;
  padding-top: 10px;
}
.InsuranceList-header .InsuranceList-label {
  border-bottom: none;
}
.InsuranceList-main-header {
  border-bottom: 1px solid #999999;
  border-top: none;
}
.InsuranceList-main-header * {
  padding: 0 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}
.InsuranceList-firstInfoContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.InsuranceList-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
.InsuranceList-downloadContainer {
  font-size: 16px;
}
.InsuranceList-list {
  width: 200px;
}
.OnboardingChildrenSection-childrenChips .InputChips {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 0px;
  margin-bottom: 0px;
}
.OnboardingChildrenSection-childrenChips .InputChips .InputChip {
  width: 90px;
  height: 49px;
  min-width: 64px;
  text-align: center;
  margin-right: 0px;
  margin-left: 10px;
  padding: 15px 26px;
  border-radius: 25px;
}
@media (max-width: 994px), only screen and (min-width: 994px) and (max-width: 1200px) {
  .OnboardingChildrenSection-childrenChips .InputChips .InputChip {
    margin-bottom: 10px;
  }
}
@media (min-width: 1280px) {
  .OnboardingChildrenSection-childrenChips .InputChips .InputChip {
    margin-bottom: 0px;
  }
}
.OnboardingChildrenSection-childrenChips .InputChips .InputChip:first-of-type {
  margin-left: 0px;
}
.OnboardingChildrenSection-phoneSvg {
  fill: #005ea8;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 5px;
  vertical-align: text-bottom;
}
.OnboardingChildrenSection-childrenList .OnboardingSection-row {
  margin-top: 24px;
}
.OnboardingChildrenSection-childrenList .OnboardingSection-row:last-of-type {
  margin-bottom: 0;
}
.OnboardingChildrenSection-booster .BoosterMessage-text {
  font-size: 12px;
}
.OnboardingPaymentExceededLimitWarning-paymentAboveSalaryWarning {
  display: flex;
  margin-top: 5px;
}
.OnboardingPaymentExceededLimitWarning-paymentAboveSalaryWarning .OnboardingPaymentExceededLimitWarning-warningIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 2px;
}
.OnboardingPersonalDataSection-booster .BoosterMessage-text {
  font-size: 12px;
}
.OnboardingSection {
  display: block;
  border-bottom: 1px solid #cccccc;
  padding: 32px 0px 32px 0px;
  margin-right: 48px;
  margin-left: 48px;
}
.OnboardingSection.OnboardingSection--noPaddingBottom {
  padding-bottom: 0;
}
.OnboardingSection:first-child {
  padding-top: 24px;
}
.OnboardingSection-header {
  font-size: 22px;
  color: #333333;
  margin-bottom: 24px;
  line-height: 23px;
  display: flex;
}
.OnboardingSection-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding-right: 36px;
  margin-bottom: 24px;
}
.OnboardingSection-row.OnboardingSection-row--withBiggerMarginBottom {
  margin-bottom: 35px;
}
.OnboardingSection-row.OnboardingSection-row--noMarginBottom {
  margin-bottom: 0px;
}
.OnboardingSection-row.OnboardingSection-row--withMarginTop {
  margin-top: 24px;
}
.OnboardingSection-row:last-of-type {
  margin-bottom: 0;
}
.OnboardingSection-inlineRow {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-right: 43px;
  margin-bottom: 24px;
}
.OnboardingSection-inlineRow.OnboardingSection-inlineRow--noMarginBottom {
  margin-bottom: 0px;
}
.OnboardingSection-inlineRow-label {
  font-size: 15px;
  align-items: center;
  height: 40px;
  display: flex;
  margin-bottom: 24px;
}
.OnboardingSection-label {
  font-size: 15px;
  width: 210px;
  align-items: center;
  height: 40px;
  display: flex;
}
.OnboardingSection-input {
  display: block;
  width: 440px;
}
.OnboardingSection-documentLink {
  margin-left: 8px;
  cursor: pointer;
}
.OnboardingSection-documentLink svg {
  fill: #005ea8 !important;
  width: 22px;
  height: 22px;
}
.OnboardingSofortSection-label {
  font-size: 15px;
  width: 250px;
  align-items: center;
  height: 40px;
  display: flex;
}
.OnboardingSofortSection-input {
  display: block;
  width: 440px;
}
.OnboardingSofortSection-yesNoQuestion .YesNoQuestion-question {
  width: 250px;
}
.OnboardingSpouseDataSection-wrapper .OnboardingSection-row:last-of-type {
  margin-bottom: 0;
}
.OnboardingTooltip-infoIcon {
  width: 15px;
  height: 15px;
  fill: #b4b4b4;
}
.OnboardingTooltip-questionMarkIcon {
  width: 18px;
  height: 18px;
  fill: #b4b4b4;
  position: relative;
  margin-top: 2px;
}
.OnboardingTooltip-anchoredTooltip {
  width: auto;
  position: absolute !important;
  right: 15px;
  text-align: left;
  top: 35%;
}
.OnboardingTooltip-anchoredTooltip.Tooltip.type-light.place-top:after {
  border-top-width: 7px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  bottom: -7px;
  margin-left: -9px;
  height: 1px;
}
.OnboardingTooltip-anchoredTooltip.Tooltip.type-light.place-bottom:after {
  border-bottom-width: 7px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  top: -7px;
  margin-left: -9px;
  height: 1px;
}
.OnlineContractBox {
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 20px;
}
.OnlineContractBox-title {
  font-weight: bold;
  font-size: 15px;
}
.OnlineContractBox-hint {
  display: flex;
  align-items: center;
  max-width: 700px;
}
.OnlineContractBox-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.OnlineContractBox-separator {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.OnlineContractBox-line {
  height: 1px;
  background: #dcdcdc;
  flex-grow: 1;
}
.OnlineContractBox-separatorText {
  margin: 0 5px;
  color: #999999;
}
.OnlineContractBox-contentDefault {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.OnlineContractBox-tooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}
.OnlineContractBox-tooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: #7ab51d;
  position: relative;
  top: 4px;
}
.OnlineContractButton-tooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}
.OnlineContractButton-tooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: #7ab51d;
  position: relative;
  top: 4px;
}
.PersonalDataAddress-postalCodeCity {
  display: flex;
}
.PersonalDataAddress-postalCode {
  flex: 1;
}
.PersonalDataAddress-city {
  flex: 2;
  margin-left: 10px;
}
.PersonalDataAddress-streetHouseNumber {
  display: flex;
}
.PersonalDataAddress-street {
  flex: 2;
}
.PersonalDataAddress-houseNumber {
  flex: 1;
  margin-left: 10px;
}
.PersonalDataPageAgreementSection {
  padding: 0 48px 5px;
  font-size: 12px;
}
.PersonalDataPageAgreementSection p {
  margin-top: 0;
  margin-bottom: 11px;
}
.PersonalDataPageAgreementSection-link {
  text-decoration: underline;
}
.PersonalDataPageAgreementSection-newTerms {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
}
.PersonalDataForm-headline {
  color: #333333;
  font-size: 23px;
  margin: 0px 48px 24px;
  font-weight: 400;
}
@media (max-width: 1279px) {
  .PersonalDataForm-headline {
    margin-left: 24px;
  }
}
.PersonalDataForm-formContainer {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  width: 548px;
  margin-bottom: 16px;
  padding-top: 24px;
  height: max-content;
}
.PersonalDataForm-formContainer.is-tabletApp {
  width: 100%;
}
.PersonalDataForm-section {
  margin-top: 20px;
  border-bottom: 1px solid #cccccc;
}
.PersonalDataForm-ctaSection {
  margin: 0 48px 32px;
  display: flex;
}
.PersonalDataForm-inputContainer {
  align-self: flex-start;
  flex: 1;
  min-width: 100px;
}
.PersonalDataForm-backLink {
  font-family: Arial;
  color: #005ea8;
  cursor: pointer;
  font-weight: bold;
}
.PersonalDataForm-backLink:hover {
  text-decoration: underline;
}
.PersonalDataForm-backLink:before {
  content: '\2039';
  display: inline-block;
  margin-right: 5px;
  font-size: 24px;
  font-weight: 400;
  vertical-align: -9%;
  margin-top: -10px;
  margin-bottom: -10px;
}
.PersonalDataForm-trustElementsContainer {
  display: flex;
  margin-bottom: 5px;
  margin-left: 25px;
  font-size: 13px;
}
.PersonalDataForm-checkSvg {
  margin-top: 2px;
  margin-right: 12px;
  width: 16px;
  height: 12px;
  fill: #7ab51d;
}
.PersonalDataForm-inquiryButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.PersonalDataForm-inquiryButton {
  width: 240px;
}
.PersonalDataForm-hint {
  color: #008300;
  display: flex;
  align-items: center;
  margin-top: 6px;
}
.PersonalDataForm-checkIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  align-items: center;
  fill: #008300;
}
.PersonalDataForm-errorSection {
  color: #c82d2d;
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
}
.PersonalDataForm-sectionInfo {
  width: 214px;
  position: relative;
}
.PersonalDataSection {
  margin: 0px 48px 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #dcdcdc;
}
@media (max-width: 1279px) {
  .PersonalDataSection {
    margin: 0 24px 32px;
  }
}
.PersonalDataSection-row {
  width: 100%;
  margin-bottom: 24px;
}
.PersonalDataSection-row:last-of-type {
  margin-bottom: 0;
}
.PersonalDataSection-label {
  font-size: 15px;
  width: 40%;
  margin-top: 10px;
}
.PersonalDataSection-label.has-twolines {
  margin-top: 0px;
}
.PersonalDataSection-labelSubtitle {
  font-size: 11px;
  margin-top: 6px;
  color: #999999;
}
.PersonalDataSection-input {
  width: 100%;
  position: relative;
  padding-right: 36px;
}
.PersonalDataSectionBirthday-input {
  width: 100%;
  position: relative;
  padding-right: 36px;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 10px;
}
.PersonalDataSection-bestSso {
  margin-bottom: 20px;
  display: flex;
}
.PersonalDataSection-bestSsoPersonalDataContainer {
  display: flex;
  flex-grow: 1;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 16px 24px;
}
.PersonalDataSection-bestSsoFullName {
  font-weight: bold;
}
.PersonalDataSection-bestSsoPersonalData {
  flex: 1;
  font-size: 14px;
}
.PersonalDataSection-bestSsoChange {
  font-size: 12px;
  height: max-content;
  color: #005ea8;
  padding-left: 0;
  padding-right: 0;
}
.PersonalDataSection-bestSsoChange:hover {
  text-decoration: underline;
  color: #c05702;
}
.PersonalDataSection-bestSsoChange:focus {
  border: 1px solid #005ea8;
}
.PersonalDataSection-bestSsoSideHelpText {
  position: relative;
  padding-left: 18px;
  padding-top: 17px;
}
.PersonalDataSection-agreementLink {
  text-decoration: underline;
}
.PersonalDataSection-updateSsoData .Checkbox-labelText {
  font-size: 15px;
}
.PersonalDataSection-updateSsoDataSideHelpText {
  top: -12px;
}
.PointsBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 100%;
  margin-top: 0px;
  font-family: Verdana;
  font-size: 11px;
  font-weight: bold;
  background-color: #c1e3e3;
  color: #005c61;
}
.PointsBar-wrapper {
  display: flex;
  width: 100%;
  margin-left: 50px;
}
@media (max-width: 1279px) {
  .PointsBar-wrapper {
    margin-left: 15px;
  }
}
.PointsBar-pointsIcon {
  flex-shrink: 0;
  width: 17px;
  height: 12px;
  fill: #005c61;
  margin-top: 2px;
  margin-right: 10px;
}
.PointsBar-text {
  margin-right: 10px;
}
.PointsBar-questionIcon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  fill: #005c61;
}
.PointsBar-questionIcon.is-mobile {
  cursor: pointer;
}
.PointsBar-tooltip {
  padding: 0px !important;
  pointer-events: auto !important;
}
.PointsBar-tooltipText {
  line-height: 1.3;
}
.ExpandableResultSortingItem {
  position: relative;
  cursor: pointer;
  padding: 0;
  flex-grow: 1;
  width: 25%;
  text-align: center;
  color: #333333;
  font-size: 13px;
}
.ExpandableResultSortingItem:before {
  content: '';
  position: absolute;
  left: -1px;
  top: 0;
  bottom: -1px;
}
.ExpandableResultSortingItem:hover {
  background-color: #cccccc;
}
.ExpandableResultSortingItem:hover:before {
  width: 1px;
  background-color: #cccccc;
}
.ExpandableResultSortingItem.is-selected {
  background-color: #ecf7fd;
  border-bottom: 2px solid 2px solid #005ea8;
  margin-left: -1px;
  margin-bottom: -1px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  color: #333333;
}
.ExpandableResultSortingItem.is-selected:hover:before {
  width: 0;
}
.ExpandableResultSortingItem.is-selected .ExpandableResultSortingItem-expandableArrow {
  fill: #005ea8;
}
.ExpandableResultSortingItem-text {
  margin: 4px 0;
  padding: 4px 0;
  height: 26px;
  border-right: 1px solid #dcdcdc;
}
.ExpandableResultSortingItem-text.is-last,
.ExpandableResultSortingItem-text.is-selected {
  border-right: none;
}
.ExpandableResultSortingItem-submenu {
  position: absolute;
  width: 100%;
  background: white;
  z-index: 1;
  border: 1px solid #cccccc;
  border-top: none;
  margin-top: -3px;
}
.ExpandableResultSortingItem-submenu.is-selected {
  margin-top: -1px;
}
.ExpandableResultSortingItem-expandableArrow {
  width: 10px;
  height: 8px;
  margin-left: 8px;
  fill: #575757;
}
.ExpandableResultSortingItem-expandableArrow.is-expanded {
  transform: rotate(180deg);
}
.ResultPage-filter {
  flex: 0 0 230px;
  margin-right: 15px;
  z-index: 2;
}
@media (min-width: 1280px) {
  .ResultPage-filter {
    margin-right: 25px;
  }
}
.ResultPage-filter .ResultPage-stationaryFilter {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.ResultPage-filter.sticky .ResultPage-stationaryFilter {
  opacity: 0;
}
.ResultPage-filter.sticky .ResultPage-stickyFilter {
  position: fixed;
  width: 250px;
  top: 0px;
  animation: resultPageFadeIn 0.3s ease-in-out;
  animation-fill-mode: forwards;
}
.ResultPage-filter .ResultPage-stickyFilter {
  animation: resultPageFadeOut 0.3s ease-in-out;
  animation-fill-mode: forwards;
}
.ResultPage-stickyFilter {
  display: none;
  padding-top: 15px;
  z-index: 1;
  height: 0;
}
@keyframes resultPageFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes resultPageFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ResultHighestPensionGuaranteeBox {
  width: 100%;
  margin-bottom: 25px;
  position: relative;
}
.ResultHighestPensionGuaranteeBox-contentContainer {
  width: 100%;
  padding: 15px;
  background: linear-gradient(to right, #063773, #005ea8);
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.ResultHighestPensionGuaranteeBox-Title {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  margin-left: 79px;
  margin-right: 4px;
}
.ResultHighestPensionGuaranteeBox-sealContainer {
  top: -5px;
  left: 10px;
  position: absolute;
}
.ResultHighestPensionGuaranteeBox-seal {
  width: 64px;
  height: 77px;
}
.ResultHighestPensionGuaranteeBox-infoIcon {
  fill: #ffffff;
  width: 15px;
  height: 15px;
  margin-left: 3px;
}
.ResultHighestPensionGuaranteeBox-tooltip {
  margin-top: 4px;
}
.ResultHighestPensionGuaranteeBox-tooltip .Tooltip-content .Tooltip {
  width: 500px;
}
.ResultHighestPensionGuaranteeBox-tooltipContentSection {
  margin-bottom: 15px;
  font-weight: initial;
}
.ResultHighestPensionGuaranteeBox-tooltipContentSection:last-child {
  margin-bottom: 0;
}
.ResultSorting {
  background-color: #ffffff;
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  height: 36px;
}
.ResultSorting.is-inactive {
  filter: grayscale(0.8);
  opacity: 0.5;
}
.ResultSorting.is-inactive * {
  pointer-events: none;
}
.ResultSorting-item {
  position: relative;
  cursor: pointer;
  padding: 0px;
  flex-grow: 1;
  width: 25%;
  text-align: center;
  color: #333333;
  font-size: 13px;
}
.ResultSorting-item:before {
  content: '';
  position: absolute;
  left: -1px;
  top: 0;
  bottom: -1px;
}
.ResultSorting-item:hover {
  background-color: #cccccc;
}
.ResultSorting-item:hover:before {
  width: 1px;
  background-color: #cccccc;
}
.ResultSorting-item.is-selected {
  background-color: #ecf7fd;
  border-bottom: 2px solid 2px solid #005ea8;
  margin-left: -1px;
  margin-bottom: -1px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  color: #333333;
}
.ResultSorting-item.is-selected:hover:before {
  width: 0;
}
.ResultSorting-item:last-child .ResultSorting-itemText {
  border: none;
}
.ResultSorting-itemText {
  margin: 4px 0;
  padding: 4px 0;
  height: 26px;
  border-right: 1px solid #cccccc;
}
.ResultSorting-itemText.is-last,
.ResultSorting-itemText.is-selected {
  border-right: none;
}
.ResultSorting-submenuItem {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ResultSorting-submenuItem:hover {
  background: #f4f4f4;
}
.ResultSorting-submenuItemSelectedIcon {
  position: absolute;
  height: 12px;
  width: 15px;
  fill: #005ea8;
  left: -25px;
  top: 3px;
}
.ResultSorting-submenuItemText {
  position: relative;
}
.ResultSummary {
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
}
.ResultSummary-priceRange {
  display: inline;
}
.ResultSummary-possiblePensionRangeValue {
  font-weight: bold;
  color: #008300;
}
.ResultSummary-guaranteedPensionRangeValue {
  font-weight: bold;
  color: #333333;
}
.ResultTariffListItemBullets-featureTooltip,
.ResultTariffListItemBullets-labelTooltip {
  max-width: 290px;
}
.ResultTariffListItemBullets-featureTooltip:after,
.ResultTariffListItemBullets-labelTooltip:after {
  left: 30% !important;
}
.ResultTariffListItemBullets-featureTooltip:before,
.ResultTariffListItemBullets-labelTooltip:before {
  left: 30% !important;
}
.ResultTariffListItemBullets-tooltipAnchor {
  position: absolute;
  left: 30px;
  top: 5px;
}
.ResultTariffListItemBullets-container {
  margin-bottom: 10px;
  flex: 1;
  min-width: 40%;
  font-size: 13px;
  padding-right: 5px;
}
.ResultTariffListItemBullets-feature {
  margin-bottom: 6px;
  display: flex;
  cursor: default;
  position: relative;
}
.ResultTariffListItemBullets-feature.has-helpText {
  cursor: help;
}
.ResultTariffListItemBullets-feature.is-last {
  margin-bottom: 10px;
}
.ResultTariffListItemBullets-featureIcon {
  margin-right: 6px;
  position: relative;
  top: 2px;
}
.ResultTariffListItemBullets-featureIcon > svg {
  width: 13px;
  height: 13px;
}
.ResultTariffListItemBullets-labelWrapper {
  display: flex;
  cursor: help;
  margin-bottom: 10px;
  margin-top: 10px;
}
.ResultTariffListItemBullets-labelWrapper:last-of-type {
  margin-bottom: 0;
}
.ResultTariffListItemBullets-label {
  border-radius: 2px;
  line-height: 16px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 11px;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 16px;
}
.ResultTariffListItemBullets-labelComment {
  font-size: 13px;
  line-height: 16px;
  padding-left: 6px;
  margin-top: 1px;
}
.ResultTariffListItemBullets-crkLabel {
  font-size: 13px;
  margin-bottom: 5px;
}
.ResultTariffListItem {
  padding: 7px 9px 9px 9px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: #ffffff;
}
.ResultTariffListItem.is-extraResultZero {
  background-color: #ecf7fd;
}
.ResultTariffListItem-container {
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  padding: 1px;
  background-color: #ffffff;
}
.ResultTariffListItem-container.color-gold {
  border: 1px solid #b39656;
}
.ResultTariffListItem-container.color-blue {
  border: 1px solid #063773;
}
.ResultTariffListItem-container.color-green {
  border: 1px solid #008300;
}
.ResultTariffListItem-container.color-orange {
  border: 1px solid #f07c00;
}
.ResultTariffListItem-container:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
.ResultTariffListItem-container:not(.color-blue):not(.color-gold):not(.color-green):not(.color-orange) {
  border-color: #999999;
}
.ResultTariffListItem-itemContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.ResultTariffListItem-left {
  flex: 1;
  flex-direction: column;
  display: flex;
}
.ResultTariffListItem-right {
  width: 21%;
}
.ResultTariffListItem-leftContainer {
  display: flex;
  flex-grow: 1;
  gap: 10px;
}
.ResultTariffListItem-priceContainer {
  border-left: 1px solid #ebebeb;
  height: 100%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.ResultTariffListItem-priceContainer .WishListHeart-heartContainer {
  margin-top: -2px;
}
.ResultTariffListItem-price {
  line-height: 18px;
  text-align: right;
  margin-bottom: 5px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ResultTariffListItem-price {
    right: 12px;
  }
}
.ResultTariffListItem-price .Tooltip {
  text-align: start;
}
.ResultTariffListItem-priceInterval {
  font-size: 13px;
  font-weight: normal;
  float: right;
  color: #333333;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ResultTariffListItem-priceInterval {
    right: 12px;
  }
}
.ResultTariffListItem-insuranceContainer {
  margin-left: 5px;
}
@media only screen and (min-width: 994px) and (max-width: 1200px) {
  .ResultTariffListItem-insuranceContainer {
    margin-left: 0px;
  }
}
@media (max-width: 994px) {
  .ResultTariffListItem-insuranceContainer {
    margin-left: 0px;
  }
}
.ResultTariffListItem-priceToolTip {
  position: relative;
}
.ResultTariffListItem-insuranceLogo {
  display: block;
  max-height: 50px;
  max-width: 170px;
  margin-right: 20px;
}
@media (max-width: 994px), only screen and (min-width: 994px) and (max-width: 1200px) {
  .ResultTariffListItem-insuranceLogo {
    max-width: 160px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1279px) {
  .ResultTariffListItem-insuranceLogo {
    max-height: 55px;
  }
}
@media (min-width: 1280px) {
  .ResultTariffListItem-insuranceLogo {
    max-height: 60px;
  }
}
.ResultTariffListItem-tariffName {
  max-width: 170px;
  margin-top: 10px;
  padding-bottom: 10px;
}
.ResultTariffListItem-gradeContainer {
  margin-right: 75px;
}
@media only screen and (min-width: 994px) and (max-width: 1200px) {
  .ResultTariffListItem-gradeContainer {
    margin-right: 60px;
  }
}
@media (max-width: 994px) {
  .ResultTariffListItem-gradeContainer {
    margin-right: 60px;
  }
}
.ResultTariffListItem-actionButton {
  padding: 5px;
  height: 40px;
}
@media (min-width: 1280px) {
  .ResultTariffListItem-actionButton {
    font-size: 15px;
  }
}
.ResultTariffListItem-cta {
  width: 100%;
  display: flex;
  margin-top: 5px;
}
.ResultTariffListItem-ctaContainer {
  display: flex;
  width: 20%;
  justify-content: flex-end;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ResultTariffListItem-ctaContainer {
    width: 22%;
  }
}
@-moz-document url-prefix() {
  .ResultTariffListItem-tabArrow {
    left: 95px;
  }
  @media (min-width: 1280px) {
    .ResultTariffListItem-tabArrow {
      left: 105px;
    }
  }
}
.ResultTariffListItem-debugInformationContainer {
  padding: 5px;
}
.ResultTariffListItem-possiblePrice {
  font-size: 22px;
  font-weight: bold;
  color: #008300;
  margin-bottom: 6px;
  white-space: nowrap;
}
.ResultTariffListItem-guaranteedPrice {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-top: 7px;
  margin-bottom: 2px;
}
.ResultTariffListItem-priceDescription {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.ResultTariffListItem-priceText {
  margin-right: 5px;
  font-size: 11px;
  line-height: 16px;
}
.ResultTariffListItem-priceText.ResultTariffListItem-possiblePriceText {
  color: #008300;
}
.ResultTariffListItem-infoSvgIcon {
  height: 12px;
  width: 12px;
  margin-right: 2px;
  pointer-events: auto;
  cursor: help;
  fill: #b4b4b4;
}
.ResultTariffListItem-infoSvgIcon.ResultTariffListItem-possiblePensionInfoSvgIcon > * {
  fill: #008300;
}
.ResultTariffListItem-priceToolTip {
  position: relative;
}
.ResultTariffListItem-rentenbezugsInfoSvgIcon {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-top: 2px;
  pointer-events: auto;
  cursor: help;
  fill: #b4b4b4;
}
.ResultTariffListItem-rentenbezugsInfoText {
  width: 160px;
  text-align: left;
}
.ResultTariffListItem-rentenbezugsformInfo {
  display: flex;
  margin-top: 12px;
}
.ResultTariffListItem-tariffSelectionCheckbox {
  padding: 8px 0px;
}
.ResultTariffListItem-expandPanel {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.ResultTariffListItem-topRow {
  display: flex;
  align-items: center;
}
.ResultTariffListItem-resultZeroHeader {
  margin-right: 9px;
}
.ResultTariffListItem-promotionHeader {
  margin-right: 9px;
}
.ResultTariffListItem-heartContainer {
  margin-left: auto;
}
.ResultTariffListItem-leftContainerColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: -10px;
}
.ResultTariffListItem-leftContainerColumn:first-child {
  width: 33%;
}
@media (max-width: 994px), only screen and (min-width: 994px) and (max-width: 1200px) {
  .ResultTariffListItem-leftContainerColumn:first-child {
    max-width: 170px;
  }
}
.ResultTariffList-singleRow {
  position: relative;
  margin-bottom: 15px;
}
@media (min-width: 1280px) {
  .ResultTariffList-singleRow {
    font-size: 13px;
  }
}
@media (max-width: 1199px) {
  .ResultTariffList-singleRow {
    font-size: 12px;
  }
}
.ResultTariffList-comparisonHintBoxTextContainer {
  background-color: #ecf7fd;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 10px;
  width: 100%;
}
.ResultTariffList-comparisonHintBox {
  width: 100%;
  color: #333333;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  margin-top: 15px;
  justify-content: space-between;
  cursor: pointer;
}
@media (max-width: 1279px) {
  .ResultTariffList-comparisonHintBox {
    font-size: 15px;
  }
}
@media (max-width: 1110px) {
  .ResultTariffList-comparisonHintBox {
    font-size: 14px;
  }
}
@media (max-width: 1050px) {
  .ResultTariffList-comparisonHintBox {
    font-size: 12px;
  }
}
.ResultTariffList-comparisonHint {
  font-weight: 700;
}
.ResultTariffList-comparisonIcon {
  color: #005ea8;
  min-width: 45px;
  min-height: 48px;
  margin-right: 6px;
  margin-left: 6px;
  margin-top: -11px;
}
.ResultTariffList-leftContainer {
  display: flex;
}
.ResultTariffList-comparisonHintButton {
  width: 150px;
  height: 23px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #005ea8;
  font-weight: 400;
  text-decoration: none solid #005ea8;
  text-align: right;
}
.ResultTariffList-comparisonSvg {
  transform: rotate(90deg);
  height: 15px;
  margin-right: 15px;
  vertical-align: text-bottom;
}
@media (max-width: 1110px) {
  .ResultTariffList-comparisonSvg {
    margin-right: 5px;
  }
}
.ResultTariffList-CheckIcon {
  width: 19px;
  min-width: 19px;
  height: 16px;
  margin-top: 3px;
  margin-right: 10px;
  fill: #7ab51d;
}
.ResultTariffList-riesterMessage {
  padding-left: 15px;
  border: solid #008300 1px;
  background-color: #e5f2e5;
  margin-bottom: 20px;
  margin-top: 15px;
}
.ResultTariffList-riesterMessageHeadline {
  font-size: 15px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.ResultTariffList-riesterMessageList {
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding-right: 15px;
  list-style-type: none;
  padding-left: 0px;
}
.ResultTariffList-riesterMessageListItem {
  display: flex;
  list-style-type: none;
  padding-bottom: 5px;
}
.ResultTariffList-riesterMessageListItemText {
  padding: 0px;
  margin: 0px;
}
.InquiryButton-wrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
  font-weight: normal;
}
.InquiryButton-hint {
  color: #7ab51d;
  display: flex;
  margin-top: 6px;
  width: 100%;
}
.InquiryButton-checkIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: #7ab51d;
}
.InquiryButton-tooltip .Tooltip {
  width: auto;
  white-space: nowrap;
}
.InquiryButton-tooltip.OnlineContractButton-tooltip .Tooltip {
  width: auto;
}
.InquiryButton-container {
  pointer-events: none;
}
.TariffDetailsButtonsBox {
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 20px;
}
.TariffDetailsButtonsBox-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.TariffDetailsButtonsBox-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.TariffDetailsButtonsBox-inquiryButton.has-top-margin {
  margin-top: 10px;
}
.TariffDetailsButtonsBox-backLink {
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
}
.TariffDetailsButtonsBox-tooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}
.TariffDetailsButtonsBox-tooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: #7ab51d;
  position: relative;
  top: 4px;
}
.CseTeasers {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}
.CseTeasers-headline {
  font-size: 17px;
  font-weight: 400;
  color: #333333;
  margin-top: 0;
  margin-bottom: 16px;
  margin-left: 8px;
}
.CseTeasers-container {
  display: flex;
  flex-direction: column;
  width: 548px;
  font-size: 11px;
  line-height: 16px;
  color: #666;
}
.CseTeasers-container fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.ThankyouContact {
  padding: 30px 30px 25px 20px;
  width: 280px;
}
.ThankyouContact-headline {
  color: #333333;
  font-size: 18px;
  margin-bottom: 30px;
}
.ThankyouContact-contactContainer {
  margin-top: 15px;
}
.ThankyouContact-phoneNumberContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ThankyouContact-phoneSvg {
  fill: #005ea8;
  height: 33px;
  width: 24px;
  margin-right: 15px;
  margin-left: 5px;
}
.ThankyouContact-phoneNumber {
  color: #005ea8;
  font-size: 18px;
  font-weight: 700;
}
.ThankyouContact-workingTimes {
  color: #333333;
  font-size: 12px;
}
.ThankyouContact-emailContainer {
  display: flex;
  align-items: center;
}
.ThankyouContact-emailSvg {
  fill: #005ea8;
  height: 25px;
  width: 35px;
  margin-right: 10px;
}
.ThankyouContact-email {
  color: #005ea8;
  font-weight: 700;
}
.ThankyouInfo {
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  width: 548px;
  margin-bottom: 24px;
  padding: 24px 48px;
  background-color: #ffffff;
}
.ThankyouInfo-headline {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
}
.ThankyouInfo-checkSvg {
  width: 28px;
  height: 22px;
  margin-right: 15px;
  margin-top: 5px;
  fill: #008300;
}
.ThankyouInfo-headlineText {
  color: #008300;
  font-size: 22px;
  font-weight: normal;
  margin: 0px;
}
.ThankyouInfo-emailConfirmation {
  font-size: 12px;
  margin: 0;
}
.ThankyouOnlineContractStep {
  padding: 24px 48px;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  width: 548px;
  background-color: #ffffff;
  margin-bottom: 24px;
}
.ThankyouOnlineContractStep-headerContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.ThankyouOnlineContractStep-booster {
  margin-bottom: 15px;
}
.ThankyouOnlineContractStep-inputWrapper {
  margin-bottom: 24px;
}
.ThankyouOnlineContractStep-header {
  display: flex;
  margin-bottom: 16px;
}
.ThankyouOnlineContractStep-headerIcon {
  margin-right: 8px;
  vertical-align: middle;
}
.ThankyouOnlineContractStep-headerIcon > svg {
  height: 20px;
  width: 31px;
}
.ThankyouOnlineContractStep-headerText {
  font-size: 16px;
  margin: 0;
}
.ThankyouOnlineContractStep-headerSubtext {
  font-size: 12px;
  margin: 0;
}
.ThankyouOnlineContractStep-inputRow {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;
}
.ThankyouOnlineContractStep-label {
  font-size: 16px;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 16px;
}
.ThankyouOnlineContractStep-input {
  padding-right: 36px;
}
.ThankyouOnlineContractStep-actionRow {
  padding-right: 36px;
  display: flex;
  justify-content: flex-end;
}
.ThankyouOnlineContractStep-nextPageButton {
  width: 240px;
  margin-bottom: 10px;
}
.ThankyouOnlineContractStep-errorSection {
  color: #c82d2d;
  text-align: right;
  font-size: 16px;
  margin: 15px 40px 15px 0px;
}
.ThankyouSteps {
  width: 548px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  padding-left: 47px;
  padding-top: 23px;
  padding-bottom: 23px;
  margin-bottom: 24px;
  padding-right: 50px;
}
.ThankyouSteps-headline {
  font-size: 16px;
  font-weight: bold;
}
.ThankyouSteps-numberSvg {
  height: 22px;
  width: 22px;
  margin-right: 11px;
}
.ThankyouSteps-item {
  margin-top: 15px;
  margin-bottom: 20px;
}
.ThankyouSteps-item:last-child {
  margin-bottom: 0;
}
.ThankyouSteps-stepHeader {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.ThankyouSteps-headerText {
  font-weight: bold;
  font-size: 14px;
}
.ThankyouSteps-stepText {
  margin-left: 34px;
  font-size: 12px;
}
.ThankyouTariffSummary-summary {
  width: 270px;
}
.ThankyouTariffSummary-separator {
  margin: 16px 0px;
  background-color: #dcdcdc;
  border: none;
  height: 1px;
}
.ThankyouTariffSummary-tariffDetails {
  border-radius: 3px;
  background-color: #ffffff;
  padding: 24px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.ThankyouTariffSummary-tariffInfoRow {
  display: flex;
  margin-bottom: 5px;
}
.ThankyouTariffSummary-tariffInfoLabel {
  width: 150px;
}
.ThankyouTariffSummary-tariffInfoDescription {
  font-weight: 400;
}
.ThankyouTariffSummary-tariffInfoHeader {
  margin-bottom: 5px;
}
.WishListHeart-heartSvg {
  height: 22px;
  width: 20px;
  cursor: pointer;
}
.WishListHeart-heartSvg.is-empty {
  fill: #333333;
}
.WishListHeart-heartSvg.is-filled path {
  fill: #c82d2d;
}
.WishListHeart-heartSvg.is-active {
  animation: heart 0.3s ease;
}
@keyframes heart {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  30% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  60% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.BirthdayInput {
  position: relative;
  width: 100%;
}
.BirthdayInput-field {
  width: 100%;
  height: 47px;
  min-height: 47px;
  color: #333333;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  padding: 14px 0 0 16px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
  will-change: border-color;
}
.BirthdayInput-field:hover:enabled {
  border: 1px solid #575757;
}
.BirthdayInput-field:focus,
.BirthdayInput-field:active,
.BirthdayInput-field:focus:hover {
  border: 1px solid #005ea8;
}
.BirthdayInput-field.has-overlay {
  padding-left: 33px;
}
.BirthdayInput-field.is-error,
.BirthdayInput-field.is-error:focus,
.BirthdayInput-field.is-error:active,
.BirthdayInput-field.is-error:hover,
.BirthdayInput-field.is-error:focus:hover {
  border: 1px solid #c82d2d;
}
.BirthdayInput-field.is-disabled {
  background-color: #fafafa;
}
.BirthdayInput-field.is-placeholder {
  color: #b4b4b4;
}
.BirthdayInput-field.is-placeholder-hidden {
  color: rgba(0, 0, 0, 0);
}
.BirthdayInput-field:read-only {
  background-color: #fafafa;
}
.BirthdayInput-field.no-label {
  padding-top: 0px;
}
.BirthdayInput-fieldLabel {
  font-size: 14px;
  color: #999999;
  position: absolute;
  padding-left: 16px;
  left: 0px;
  top: calc(50% - 9px);
  transform-origin: top;
  transition: top 150ms, font-size 150ms, color 150ms;
  will-change: top, font-size;
  z-index: 1;
  pointer-events: none;
}
.BirthdayInput-fieldLabel.is-top {
  top: 6px;
  font-size: 12px;
}
.BirthdayInput-fieldLabel.is-top.is-error {
  color: #c82d2d;
}
.BirthdayInput-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: #c82d2d;
  font-size: 12px;
}
.Checkbox {
  position: relative;
  padding-left: 27px;
}
.Checkbox input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
}
.Checkbox input:focus:not(:hover) + label:not(.checked) .Checkbox-icon {
  border: 1px solid #005ea8;
}
.Checkbox input:hover + label:not(.checked) .Checkbox-icon {
  border: 1px solid #575757;
}
.Checkbox label {
  cursor: pointer;
  font-size: 13px;
}
.Checkbox label .Checkbox-icon {
  position: absolute;
  left: 0;
  content: ' ';
  width: 17px;
  height: 17px;
  border: 1px solid #b4b4b4;
  border-radius: 3px;
  overflow: hidden;
}
.Checkbox label .Checkbox-checkIcon {
  opacity: 0;
  width: 16px;
  height: 16px;
}
.Checkbox label .Checkbox-checkIcon * {
  fill: #005ea8;
}
.Checkbox label.checked .Checkbox-icon {
  border: 1px solid #005ea8;
}
.Checkbox label.checked .Checkbox-checkIcon {
  display: block;
  opacity: 1;
}
.Checkbox label.is-multiline .Checkbox-icon {
  margin-top: 3px;
}
.Checkbox.is-disabled input + label {
  color: #999999;
}
.Checkbox.is-disabled input + label .Checkbox-icon {
  border: 1px solid #999999;
  background-color: #fafafa;
}
.Checkbox.is-disabled input:focus:not(:hover) + label .Checkbox-icon,
.Checkbox.is-disabled input:hover + label .Checkbox-icon {
  border: 1px solid #999999;
}
.Dropdown {
  width: 100%;
  position: relative;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  font-size: 13px;
  padding: 0 7px;
  color: #333333;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  min-height: 47px;
}
.Dropdown:hover {
  border: 1px solid #575757;
}
.Dropdown:focus,
.Dropdown:active {
  border: 1px solid #005ea8;
}
.Dropdown.is-active {
  border: 1px solid #005ea8;
}
.Dropdown.is-error {
  border: 1px solid #c82d2d;
}
.Dropdown.is-error .Dropdown-dropdownIndicator * {
  fill: #c82d2d;
}
.Dropdown.is-disabled {
  background: #fafafa;
  color: #b4b4b4;
}
.Dropdown-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: #c82d2d;
  font-size: 12px;
}
.Dropdown-dropdownIndicator {
  transform: rotate(0deg);
  width: 15px;
  height: 7px;
}
.Dropdown-dropdownIndicator * {
  fill: #999999;
}
.is-active .Dropdown-dropdownIndicator {
  transform: rotate(180deg);
}
.is-active .Dropdown-dropdownIndicator * {
  fill: #005ea8;
}
.FormInput {
  position: relative;
  width: 100%;
}
.FormInput.is-currency:after {
  position: absolute;
  top: 17px;
  content: '€';
  right: 10px;
}
.FormInput.is-currency.ResultCurrencyInput:after {
  top: 12px;
}
.FormInput-field {
  width: 100%;
  height: 47px;
  min-height: 47px;
  color: #333333;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  padding: 14px 0 0 16px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
  will-change: border-color;
}
.FormInput-field.ResultCurrencyInput-field {
  height: 40px;
  min-height: 40px;
  border-radius: 3px;
  font-size: 13px;
  padding: 14px 0 0 7px;
}
.FormInput-field:hover:enabled {
  border: 1px solid #575757;
}
.FormInput-field:focus,
.FormInput-field:active,
.FormInput-field:focus:hover {
  border: 1px solid #005ea8;
}
.FormInput-field.has-overlay {
  padding-left: 33px;
}
.FormInput-field.is-error,
.FormInput-field.is-error:focus,
.FormInput-field.is-error:active,
.FormInput-field.is-error:hover,
.FormInput-field.is-error:focus:hover {
  border: 1px solid #c82d2d;
}
.FormInput-field.is-disabled {
  color: #666666;
  background-color: #fafafa;
}
.FormInput-field:read-only {
  color: #666666;
  background-color: #fafafa;
}
.FormInput-field.no-label {
  padding-top: 0px;
}
.FormInput-fieldLabel {
  font-size: 14px;
  color: #999999;
  position: absolute;
  padding-left: 16px;
  left: 0px;
  top: calc(50% - 9px);
  transform-origin: top;
  transition: top 150ms, font-size 150ms, color 150ms;
  will-change: top, font-size;
  z-index: 1;
  pointer-events: none;
}
.FormInput-fieldLabel.is-top {
  top: 6px;
  font-size: 12px;
}
.FormInput-fieldLabel.is-top.is-error {
  color: #c82d2d;
}
.FormInput-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: #c82d2d;
  font-size: 12px;
}
.FormInput-info {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 5px 0 0 0;
  color: #005ea8;
  font-size: 12px;
}
.FormInput-infoText {
  margin-top: 6px;
  margin-bottom: 0px;
  color: #999999;
  font-size: 12px;
}
.react-autosuggest__container {
  position: relative;
  width: 100%;
}
.react-autosuggest__input {
  width: 100%;
  height: 47px;
  min-height: 47px;
  color: #333333;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  padding: 14px 0 0 16px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
  will-change: border-color;
}
.react-autosuggest__input.ResultCurrencyInput-field {
  height: 40px;
  min-height: 40px;
  border-radius: 3px;
  font-size: 13px;
  padding: 14px 0 0 7px;
}
.react-autosuggest__input:hover:enabled {
  border: 1px solid #575757;
}
.react-autosuggest__input:focus,
.react-autosuggest__input:active,
.react-autosuggest__input:focus:hover {
  border: 1px solid #005ea8;
}
.react-autosuggest__input.has-overlay {
  padding-left: 33px;
}
.react-autosuggest__input.is-error,
.react-autosuggest__input.is-error:focus,
.react-autosuggest__input.is-error:active,
.react-autosuggest__input.is-error:hover,
.react-autosuggest__input.is-error:focus:hover {
  border: 1px solid #c82d2d;
}
.react-autosuggest__input.is-disabled {
  color: #666666;
  background-color: #fafafa;
}
.react-autosuggest__input:read-only {
  color: #666666;
  background-color: #fafafa;
}
.react-autosuggest__input.no-label {
  padding-top: 0px;
}
.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
  margin-top: 8px;
}
.react-autosuggest__suggestions-container--open {
  width: 100%;
  min-height: 26px;
  line-height: 2;
  display: block;
  position: absolute;
  top: 38px;
  border: 1px solid #dcdcdc;
  border-top: none;
  background-color: #ffffff;
  font-size: 13px;
  z-index: 2;
  max-height: 265px;
  overflow-y: auto;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px #b4b4b4;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0 0 0 7px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #005ea8;
  color: white;
}
.react-autosuggest__suggestion-match {
  color: 1px solid #575757;
  font-weight: bold;
}
.RadioChipsGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.RadioChipsGroup-label {
  display: flex;
  align-self: center;
  color: #333333;
  font-size: 15px;
  width: 210px;
  padding-right: 2px;
}
.RadioChipsGroup-container {
  display: flex;
  border-radius: 5px;
}
.RadioChipsGroup-chip {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size: 14px;
  font-weight: normal;
  color: #333333;
  border: 1px solid #dcdcdc;
  border-radius: 25px;
  padding: 15px 32px 15px 32px;
  transition: border-color 0.3s;
  box-shadow: none;
  -webkit-appearance: none;
  will-change: border-color;
}
.RadioChipsGroup-chip label {
  cursor: pointer;
}
.RadioChipsGroup-chip:hover {
  border: 1px solid #575757;
}
.RadioChipsGroup-chip:focus,
.RadioChipsGroup-chip:focus-within,
.RadioChipsGroup-chip:active,
.RadioChipsGroup-chip:focus:hover {
  border: 1px solid #005ea8;
}
.RadioChipsGroup-chip.is-error,
.RadioChipsGroup-chip.is-error:focus,
.RadioChipsGroup-chip.is-error:active,
.RadioChipsGroup-chip.is-error:hover,
.RadioChipsGroup-chip.is-error:focus:hover {
  border: 1px solid #c82d2d;
}
.RadioChipsGroup-chip.is-selected {
  border: 1px solid #005ea8;
  background-color: #ecf7fd;
}
.RadioChipsGroup-chip.is-disabled {
  background-color: #fafafa;
}
.RadioChipsGroup-chip input {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
}
.RadioChipsGroup-icon {
  border: 0;
  margin-right: 0;
  margin-left: 6px;
}
.RadioGroup {
  width: 100%;
}
.RadioGroup-label {
  font-size: 16px;
  margin-bottom: 16px;
}
.RadioGroup-boxContainer {
  display: flex;
  width: 100%;
  min-height: 47px;
  font-size: 14px;
  border-radius: 5px;
  color: #333333;
  border: 1px solid #dcdcdc;
  transition: border-color 0.3s;
  will-change: border-color;
}
.RadioGroup-boxContainer.is-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0px;
  padding-left: 24px;
  padding-right: 24px;
}
.RadioGroup-boxContainer label {
  cursor: pointer;
}
.RadioGroup-boxContainer:hover {
  border: 1px solid #575757;
}
.RadioGroup-boxContainer.is-error,
.RadioGroup-boxContainer.is-error:focus,
.RadioGroup-boxContainer.is-error:active,
.RadioGroup-boxContainer.is-error:hover,
.RadioGroup-boxContainer.is-error:focus:hover {
  border: 1px solid #c82d2d;
}
.RadioGroup-boxContainer:focus {
  outline: 0 !important;
}
.RadioGroup-separator {
  background-color: #dcdcdc;
  margin: 8px 0;
  flex: 0 0 1px;
}
.RadioGroup-separator.is-vertical {
  margin: 0;
}
.RadioGroup-button {
  cursor: pointer;
  user-select: none;
  display: flex;
  padding: 5px;
  align-items: center;
  position: relative;
  width: 100%;
}
.RadioGroup-button.is-vertical {
  padding: 14px 0px 14px 0px;
}
.RadioGroup-button.is-vertical:last-child {
  padding: 14px 0px 14px 0px;
}
.RadioGroup-button:focus,
.RadioGroup-button:focus-within {
  border: 1px solid #005ea8;
  border-radius: 5px;
}
.RadioGroup-button.is-disabled {
  pointer-events: none;
  color: #dcdcdc;
}
.RadioGroup-button .RadioGroup-optionInput {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
}
.RadioGroup-optionLabelDiv {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 6px;
  height: 100%;
  justify-content: space-between;
}
.RadioGroup-optionLabelDiv.is-vertical {
  margin-left: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}
.RadioGroup-optionLabel {
  font-size: 14px;
}
.RadioGroup-optionLabel.is-vertical:before {
  left: 0px;
  top: 14px;
}
.RadioGroup-optionLabel.is-vertical:after {
  left: 6px;
  top: 20px;
}
.RadioGroup-optionLabel:before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #b4b4b4;
  left: 24px;
  top: 12px;
  transition: 0.3s border-color;
  border-color: #b4b4b4;
}
.RadioGroup-optionLabel:after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  top: 18px;
  left: 30px;
  background-color: #005ea8;
  opacity: 0;
  transform: scale(0, 0);
  transform-origin: center center;
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.5, -0.8, 0.5, 1.8);
}
.RadioGroup-optionLabel.is-selected:after {
  opacity: 1;
  transform: scale(1, 1);
}
.RadioGroup-optionLabel.is-selected:before {
  border-color: #005ea8;
}
.RadioGroup-optionLabel.is-error:before {
  border-color: #c82d2d;
}
.RadioGroup-optionLabel.is-error:after {
  border-color: #c82d2d;
}
.RadioGroup-boldLabel {
  font-weight: 700;
}
.RadioGroup-icon {
  border: 0;
  margin-right: 0;
  margin-left: 6px;
}
.RadioInput {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 34px;
}
.RadioInput.is-vertical {
  padding: 14px 0px 14px 0px;
}
.RadioInput:focus,
.RadioInput:focus-within {
  border: 1px solid #005ea8;
  border-radius: 5px;
}
.RadioInput.is-disabled {
  pointer-events: none;
  color: #999999;
}
.RadioInput.is-error {
  border-color: #c82d2d;
}
.RadioInput.is-selected {
  border-color: #005ea8;
}
.RadioInput-optionInput {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
}
.RadioInput-optionLabelDiv {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  height: 100%;
  justify-content: space-between;
}
.RadioInput-optionLabelDiv.is-vertical {
  margin-left: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}
.RadioInput-optionLabelDiv.is-error {
  border-bottom-color: #c82d2d;
}
.RadioInput-optionLabelDiv.is-selected {
  border-bottom-color: #005ea8;
}
.RadioInput-optionLabel {
  font-size: 14px;
  cursor: pointer;
}
.RadioInput-optionLabel.is-vertical:before {
  left: 0px;
  top: 15px;
}
.RadioInput-optionLabel.is-vertical:after {
  left: 6px;
  top: 21px;
}
.RadioInput-optionLabel.is-disabled:before {
  background-color: #fafafa;
}
.RadioInput-optionLabel:before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #b4b4b4;
  left: 0px;
  top: 7px;
  transition: 0.3s border-color;
  border-color: #b4b4b4;
  background-color: #ffffff;
}
.RadioInput-optionLabel:after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  top: 13px;
  left: 6px;
  background-color: #005ea8;
  opacity: 0;
  transform: scale(0, 0);
  transform-origin: center center;
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.5, -0.8, 0.5, 1.8);
}
.RadioInput-optionLabel.is-selected:after {
  opacity: 1;
  transform: scale(1, 1);
}
.RadioInput-optionLabel.is-selected:before {
  border-color: #005ea8;
}
.RadioInput-optionLabel.is-error:before {
  border-color: #c82d2d;
}
.RadioInput-optionLabel.is-error:after {
  border-color: #c82d2d;
}
.SelectBox {
  width: 100%;
  position: relative;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
  padding: 14px 0 0 16px;
  color: #333333;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCA3Ij4KICAgIDxwb2x5Z29uIGZpbGw9IiM5OTkiCiAgICAgICAgICAgICBwb2ludHM9IjYuOTUgNC45OSAxLjEyIDAgMCAwLjk4IDYuOTUgNi44NiAxNCAwLjk4IDEyLjg4IDAgNi45NSA0Ljk5Ii8+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-size: 14px 7px;
  background-position: right 16px center;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  height: 47px;
  min-height: 47px;
  transition: border-color 0.3s;
  will-change: border-color;
  padding-right: 30px;
  text-overflow: ellipsis;
}
.SelectBox.ResultSelectBox {
  border-radius: 3px;
  font-size: 13px;
  padding: 14px 30px 0 7px;
  background-position: right 9px center;
  height: 40px;
  min-height: 40px;
}
.SelectBox:hover {
  border: 1px solid #575757;
}
.SelectBox:focus,
.SelectBox:active {
  border: 1px solid #005ea8;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCA3Ij4KICAgIDxwb2x5Z29uIGZpbGw9IiM5OTkiCiAgICAgICAgICAgICBwb2ludHM9IjEyLjksNi45IDE0LDUuOSA2LjksMCAwLDUuOSAxLjEsNi45IDYuOSwxLjkiLz4KPC9zdmc+Cg==);
}
.SelectBox.is-error {
  border: 1px solid #c82d2d;
}
.SelectBox.is-disabled {
  background: #fafafa;
  color: #666666;
}
.SelectBox.is-disabled:hover {
  border: 1px solid #dcdcdc;
}
.SelectBox.no-label {
  padding-top: 0px;
}
.SelectBox-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: #c82d2d;
  font-size: 12px;
}
.SelectBox-fieldLabel {
  font-size: 12px;
  color: #999999;
  position: absolute;
  padding-left: 16px;
  left: 0px;
  top: 6px;
  transform-origin: top;
  transition: top 150ms, font-size 150ms, color 150ms;
  will-change: top, font-size;
  z-index: 1;
  pointer-events: none;
}
.SelectBox-fieldLabel.is-error {
  color: #c82d2d;
}
.TextAreaInput {
  position: relative;
  width: 100%;
}
.TextAreaInput-field {
  width: 100%;
  min-height: 47px;
  color: #333333;
  border: 1px solid #b4b4b4;
  border-radius: 5px;
  font-weight: normal;
  padding: 8px 11px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
  will-change: border-color;
  resize: none;
  font-family: inherit;
  line-height: 20px;
  font-size: 12px;
  vertical-align: top;
}
.TextAreaInput-field:hover:enabled {
  border: 1px solid #575757;
}
.TextAreaInput-field:focus,
.TextAreaInput-field:active,
.TextAreaInput-field:focus:hover {
  border: 1px solid #005ea8;
}
.TextAreaInput-field.is-error,
.TextAreaInput-field.is-error:focus,
.TextAreaInput-field.is-error:active,
.TextAreaInput-field.is-error:hover,
.TextAreaInput-field.is-error:focus:hover {
  border: 1px solid #c82d2d;
}
.TextAreaInput-field.is-disabled {
  background-color: #fafafa;
}
.TextAreaInput-field:read-only {
  background-color: #fafafa;
}
.TextAreaInput-error {
  width: 100%;
  font-weight: normal;
  font-style: normal;
  display: block;
  margin: 4px 0 0 0;
  color: #c82d2d;
  font-size: 12px;
}
.OnboardingContributionBooster {
  position: relative;
  display: flex;
  background: #00830026;
  color: #008300;
  font-size: 14px;
  float: right;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  margin-top: 25px;
  margin-right: 45px;
}
.OnboardingContributionBooster-wrapper {
  margin-bottom: 10px;
}
.OnboardingContributionBooster-thumbSvg {
  width: 16px;
  fill: #008300;
  margin-right: 5px;
}
.OnboardingContributionInsuranceStartRiester-infoBox {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #00830026;
  color: #333333;
  font-size: 14px;
  border-left: 5px solid #008300;
  margin-right: 36px;
  border-radius: 0px 5px 5px 0px;
}
.OnboardingContributionInsuranceStartRiester-infoBox.OnboardingContributionInsuranceStartRiester-boosterBox {
  margin-bottom: 24px;
}
.OnboardingContributionInsuranceStartRiester-infoBox.OnboardingContributionInsuranceStartRiester-boosterBox .OnboardingContributionInsuranceStartRiester-boosterBoxIcon {
  width: 23px;
  height: 23px;
  margin-right: 10px;
}
.OnboardingContributionInsuranceStartRiester-infoBox.OnboardingContributionInsuranceStartRiester-noOneTimePaymentBoosterBox .OnboardingContributionInsuranceStartRiester-boosterCheckIconSvg {
  margin-right: 10px;
}
.OnboardingContributionInsuranceStartRiester-infoBox.OnboardingContributionInsuranceStartRiester-noOneTimePaymentBoosterBox .OnboardingContributionInsuranceStartRiester-boosterCheckIconSvg svg {
  width: 22px;
  height: 16px;
  fill: #008300;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartTypes {
  display: flex;
  position: relative;
  padding-right: 36px;
}
.OnboardingContributionInsuranceStartRiester-sideHelpText .SideHelpText-questionMark {
  margin-top: 0px;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOption {
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  transition: border-color 0.3s;
  will-change: border-color;
  cursor: pointer;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOption:hover {
  border: 1px solid #575757;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOption.is-selected {
  border: 1px solid #005ea8;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOptionBadge {
  width: max-content;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 10px;
  color: #666666;
  background-color: #66666626;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOptionBadge.is-recommended {
  color: #008300;
  background-color: #00830026;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOptionBadge .Tooltip-Root {
  width: 18px;
  height: 20px;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOptionBadgeIcon {
  margin-left: 5px;
  width: 13px;
  height: 13px;
  fill: #008300;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOptionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOptionRadio {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.OnboardingContributionInsuranceStartRiester-insuranceStartOptionRadio .RadioInput-optionLabel {
  font-size: 15px;
}
.OnboardingContributionInsuranceStartRiester-divider {
  height: 1px;
  width: 100%;
  background-color: #dcdcdc;
  transition: background-color 0.3s;
  will-change: background-color;
}
.OnboardingContributionInsuranceStartRiester-divider.is-selected {
  background-color: #005ea8;
}
.OnboardingContributionInsuranceStartRiester-hintContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
}
.OnboardingContributionInsuranceStartRiester-hintContainer .highlight {
  white-space: nowrap;
  font-weight: bold;
}
.OnboardingContributionInsuranceStartRiester-hintContainer.is-recommended .highlight {
  color: #008300;
}
.OnboardingContributionInsuranceStartRiester-hint {
  display: flex;
  flex-direction: row;
}
.OnboardingContributionInsuranceStartRiester-hintCheckSvgWrapper {
  margin-top: 5px;
}
.OnboardingContributionInsuranceStartRiester-hintCheckSvg {
  width: 19px;
  height: 15px;
  fill: #666666;
}
.OnboardingContributionInsuranceStartRiester-hintCheckSvg.is-recommended {
  fill: #008300;
}
.OnboardingContributionInsuranceStartRiester-hintText {
  margin-left: 10px;
  font-size: 14px;
  color: #333333;
}
.OnboardingContributionSelectionRiester-contributionTypes {
  display: flex;
  position: relative;
  padding-right: 36px;
}
.OnboardingContributionSelectionRiester-sideHelpText .SideHelpText-questionMark {
  margin-top: 0px;
}
.OnboardingContributionSelectionRiester-contributionOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.OnboardingContributionSelectionRiester-contributionOption {
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  transition: border-color 0.3s;
  will-change: border-color;
  cursor: pointer;
}
.OnboardingContributionSelectionRiester-contributionOption:hover {
  border: 1px solid #575757;
}
.OnboardingContributionSelectionRiester-contributionOption.is-selected {
  border: 1px solid #005ea8;
}
.OnboardingContributionSelectionRiester-contributionOptionBadge {
  width: max-content;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 10px;
  color: #666666;
  background-color: #66666626;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.OnboardingContributionSelectionRiester-contributionOptionBadge.is-recommended {
  color: #008300;
  background-color: #00830026;
}
.OnboardingContributionSelectionRiester-contributionOptionBadge .Tooltip-Root {
  width: 18px;
  height: 20px;
}
.OnboardingContributionSelectionRiester-contributionOptionBadgeIcon {
  margin-left: 5px;
  width: 13px;
  height: 13px;
  fill: #008300;
}
.OnboardingContributionSelectionRiester-contributionOptionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.OnboardingContributionSelectionRiester-contributionOptionRadio {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.OnboardingContributionSelectionRiester-contributionOptionRadio .RadioInput-optionLabel {
  font-size: 15px;
}
.OnboardingContributionSelectionRiester-divider {
  height: 1px;
  width: 100%;
  background-color: #dcdcdc;
  transition: background-color 0.3s;
  will-change: background-color;
}
.OnboardingContributionSelectionRiester-divider.is-selected {
  background-color: #005ea8;
}
.OnboardingContributionSelectionRiester-hintContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
}
.OnboardingContributionSelectionRiester-hintContainer .highlight {
  white-space: nowrap;
  font-weight: bold;
}
.OnboardingContributionSelectionRiester-hintContainer.is-recommended .highlight {
  color: #008300;
}
.OnboardingContributionSelectionRiester-hint {
  display: flex;
  flex-direction: row;
}
.OnboardingContributionSelectionRiester-hintCheckSvgWrapper {
  margin-top: 5px;
}
.OnboardingContributionSelectionRiester-hintCheckSvg {
  width: 19px;
  height: 15px;
  fill: #666666;
}
.OnboardingContributionSelectionRiester-hintCheckSvg.is-recommended {
  fill: #008300;
}
.OnboardingContributionSelectionRiester-hintText {
  margin-left: 10px;
  font-size: 14px;
  color: #333333;
}
.OnboardingContributionSelectionRiester-monthlyPaymentInput {
  width: 100%;
  margin-top: 12px;
}
.OnboardingContributionSelectionRuerupCampaignHint-tooltip {
  top: 11px;
}
.OnboardingContributionSelectionRuerupCampaignHint-tooltip .Tooltip {
  width: 227px;
  font-size: 11px;
  color: #333333;
}
.OnboardingContributionSelectionRuerupCampaignHint-tooltip.Tooltip-Root {
  position: static;
  align-self: auto;
  overflow: visible;
}
.OnboardingContributionSelectionRuerupCampaignHint-infoIcon {
  width: 20px;
  height: 20px;
  fill: #b39656;
  margin-left: 3px;
  margin-top: 3px;
  cursor: pointer;
}
.OnboardingContributionSelectionRuerupCampaignHint-container {
  width: 85%;
  background-color: rgba(179, 150, 86, 0.15);
  border: 1px solid #b39656;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.OnboardingContributionSelectionRuerupCampaignHint-container .is-big {
  font-size: 15px;
}
.OnboardingContributionSelectionRuerupCampaignHint-arrowBackground {
  width: 14px;
  height: 14px;
  transform: rotate(44deg);
  position: relative;
  top: 7px;
  left: 28px;
  background-color: #ffffff;
}
.OnboardingContributionSelectionRuerupCampaignHint-arrow {
  width: 100%;
  height: 100%;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: #b39656;
  background-color: rgba(179, 150, 86, 0.15);
  background-size: cover;
  position: absolute;
}
.OnboardingInvestmentSelection-investmentSelectionOptions {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.OnboardingInvestmentSelection-investmentSelectionOption {
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  transition: border-color 0.3s;
  will-change: border-color;
  cursor: pointer;
}
.OnboardingInvestmentSelection-investmentSelectionOption:hover {
  border: 1px solid #575757;
}
.OnboardingInvestmentSelection-investmentSelectionOption.is-selected {
  border: 1px solid #005ea8;
}
.OnboardingInvestmentSelection-investmentSelectionOptionBadge {
  width: max-content;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 10px;
  color: #666666;
  background-color: #66666626;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.OnboardingInvestmentSelection-investmentSelectionOptionBadge.is-recommended {
  color: #008300;
  background-color: #00830026;
}
.OnboardingInvestmentSelection-investmentSelectionOptionBadge .Tooltip-Root {
  width: 18px;
  height: 20px;
}
.OnboardingInvestmentSelection-investmentSelectionOptionBadgeIcon {
  margin-left: 5px;
  width: 13px;
  height: 13px;
  fill: #008300;
}
.OnboardingInvestmentSelection-investmentSelectionOptionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.OnboardingInvestmentSelection-investmentSelectionOptionTooltip {
  margin-top: 4px;
  cursor: help;
}
.OnboardingInvestmentSelection-investmentSelectionOptionTooltip .Tooltip-headline {
  margin-bottom: 8px;
}
.OnboardingInvestmentSelection-questionMarkIcon {
  width: 20px;
  height: 20px;
  fill: #b4b4b4;
}
.OnboardingInvestmentSelection-investmentSelectionOptionRadio {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.OnboardingInvestmentSelection-investmentSelectionOptionRadio .RadioInput-optionLabel {
  font-size: 18px;
}
.OnboardingInvestmentSelection-shieldSvg {
  height: 23px;
  width: 21px;
  margin-left: 10px;
}
.OnboardingInvestmentSelection-shieldSvg > path {
  fill: #333333;
}
.OnboardingInvestmentSelection-chartLineSvg {
  height: 22px;
  width: 23px;
  margin-left: 10px;
}
.OnboardingInvestmentSelection-chartLineSvg > path {
  fill: #333333;
}
.OnboardingInvestmentSelection-divider {
  height: 1px;
  width: 100%;
  background-color: #dcdcdc;
  transition: background-color 0.3s;
  will-change: background-color;
}
.OnboardingInvestmentSelection-divider.is-selected {
  background-color: #005ea8;
}
.OnboardingInvestmentSelection-hintContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
}
.OnboardingInvestmentSelection-hintContainer .highlight {
  white-space: nowrap;
  font-weight: bold;
}
.OnboardingInvestmentSelection-hintContainer.is-recommended .highlight {
  color: #008300;
}
.OnboardingInvestmentSelection-hint {
  display: flex;
  flex-direction: row;
}
.OnboardingInvestmentSelection-hintCheckSvgWrapper {
  margin-top: 5px;
}
.OnboardingInvestmentSelection-hintCheckSvg {
  width: 19px;
  height: 15px;
  fill: #008300;
}
.OnboardingInvestmentSelection-hintText {
  margin-left: 10px;
  font-size: 14px;
  color: #333333;
}
.OnboardingQuestionnaireModal {
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: right;
  width: 850px;
  margin-bottom: 10px;
  font-size: 14px;
}
.OnboardingQuestionnaireModal .ModalDialog {
  height: 90%;
}
.OnboardingQuestionnaireModal .ModalDialog-headerClose {
  right: 15px;
  top: 15px;
}
.OnboardingQuestionnaireModal-modalBody {
  height: 100%;
  padding-right: 1px;
  padding-top: 25px;
  padding-bottom: 0;
  overflow: hidden;
}
.OnboardingQuestionnaireModal-iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
.OnboardingProductSelectionCalculationTipTableRow-headerCol {
  text-align: left;
  border-top: 1px solid #b4b4b4;
  border-left: none;
  padding-left: 0px;
  font-weight: normal;
  padding-right: 10px;
  min-width: 170px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.OnboardingProductSelectionCalculationTipTableRow-headerCol.no-border-top {
  border-top: none;
  padding-top: 0px;
}
.OnboardingProductSelectionCalculationTipTableRow-riesterCol {
  min-width: 170px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  border-left: 1px solid #b4b4b4;
  border-top: 1px solid #b4b4b4;
}
.OnboardingProductSelectionCalculationTipTableRow-riesterCol.no-border-top {
  border-top: none;
  padding-top: 0px;
}
.OnboardingProductSelectionCalculationTipTableRow-riesterCol.is-disabled {
  color: #999999;
}
.OnboardingProductSelectionCalculationTipTableRow-dataCol {
  min-width: 170px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  border-left: 1px solid #b4b4b4;
  border-top: 1px solid #b4b4b4;
}
.OnboardingProductSelectionCalculationTipTableRow-dataCol.no-border-top {
  border-top: none;
  padding-top: 0px;
}
.OnboardingProductSelectionCalculationTip-productCalculationHintWrapper {
  display: flex;
  margin-top: 16px;
  color: #005ea8;
  font-size: 14px;
}
.OnboardingProductSelectionCalculationTip-productCalculationHint {
  padding-left: 5px;
}
.OnboardingProductSelectionCalculationTip-modalBody {
  padding: 25px;
}
.OnboardingProductSelectionCalculationTip-modalHeading {
  font-size: 18px;
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 10px;
}
.OnboardingProductSelectionCalculationTip-modalText {
  font-size: 14px;
  margin: 0px;
}
.OnboardingProductSelectionCalculationTip-modalTable {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
}
.OnboardingProductSelectionCalculationTip-modalTableHeader {
  min-width: 170px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-left: 1px solid #b4b4b4;
}
.OnboardingProductSelectionCalculationTip-modalTableHeader.is-disabled {
  color: #999999;
}
.OnboardingProductSelectionCalculationTip-emptyCell {
  border-left: none;
  border-top: none;
}
.OnboardingProductSelectionCalculationTip-modalCustomerCalculationDataWrapper {
  display: flex;
  justify-content: center;
}
.OnboardingProductSelectionCalculationTip-modalCustomerCalculationHeader {
  color: #008300;
}
.OnboardingProductSelectionCalculationTip-modalCustomerCalculationData {
  display: flex;
  flex-direction: column;
  color: #008300;
}
.OnboardingProductSelectionCalculationTip-modalCustomerCalculationData > span {
  padding: 5px;
}
.OnboardingProductSelectionCalculationTip-modalCustomerCalculationData > span:first-of-type {
  border-bottom: 1px solid #008300;
}
.OnboardingProductSelectionCalculationTip-modalCustomerCalculationDataPercentage {
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #008300;
}
.OnboardingProductSelectionCalculationTip-disclaimer {
  font-size: 10px;
  line-height: normal;
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid #b4b4b4;
}
.OnboardingProductSelectionOptionHints-emphasizedText {
  font-weight: bold;
}
.OnboardingProductSelectionOption-productSelectionOption {
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  transition: border-color 0.3s;
  will-change: border-color;
  cursor: pointer;
}
.OnboardingProductSelectionOption-productSelectionOption:hover {
  border: 1px solid #575757;
}
.OnboardingProductSelectionOption-productSelectionOption.is-selected {
  border: 1px solid #005ea8;
}
.OnboardingProductSelectionOption-productSelectionOption.is-disabled {
  background-color: #fafafa;
  cursor: default;
}
.OnboardingProductSelectionOption-productSelectionOption.is-disabled:hover {
  border: 1px solid #dcdcdc;
}
.OnboardingProductSelectionOption-productSelectionOptionBadge {
  width: max-content;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 10px;
  color: #666666;
  background-color: #66666626;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.OnboardingProductSelectionOption-productSelectionOptionBadge.is-recommended {
  color: #008300;
  background-color: #00830026;
}
.OnboardingProductSelectionOption-productSelectionOptionBadge.is-disabled {
  color: #999999;
}
.OnboardingProductSelectionOption-productSelectionOptionBadge .Tooltip-Root {
  width: 18px;
  height: 20px;
}
.OnboardingProductSelectionOption-productSelectionOptionBadgeIcon {
  margin-left: 5px;
  width: 13px;
  height: 13px;
  fill: #008300;
}
.OnboardingProductSelectionOption-productSelectionOptionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.OnboardingProductSelectionOption-productSelectionOptionTooltip {
  margin-top: 4px;
  cursor: help;
}
.OnboardingProductSelectionOption-questionMarkIcon {
  width: 20px;
  height: 20px;
  fill: #b4b4b4;
}
.OnboardingProductSelectionOption-productSelectionOptionRadio {
  margin-top: 10px;
  margin-bottom: 10px;
}
.OnboardingProductSelectionOption-productSelectionOptionRadio .RadioInput-optionLabel {
  font-size: 15px;
}
.OnboardingProductSelectionOption-divider {
  height: 1px;
  width: 100%;
  background-color: #dcdcdc;
  transition: background-color 0.3s;
  will-change: background-color;
}
.OnboardingProductSelectionOption-divider.is-selected {
  background-color: #005ea8;
}
.OnboardingProductSelectionOption-hintContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
  margin-bottom: 10px;
  min-height: 130px;
}
.OnboardingProductSelectionOption-hintContainer .highlight {
  white-space: nowrap;
  font-weight: bold;
}
.OnboardingProductSelectionOption-hintContainer.is-recommended .highlight {
  color: #008300;
}
.OnboardingProductSelectionOption-hint {
  display: flex;
  flex-direction: row;
}
.OnboardingProductSelectionOption-hintCheckSvgWrapper {
  margin-top: 5px;
}
.OnboardingProductSelectionOption-hintCheckSvg {
  width: 19px;
  height: 15px;
  fill: #008300;
}
.OnboardingProductSelectionOption-hintCheckSvg.is-grey {
  fill: #666666;
}
.OnboardingProductSelectionOption-hintText {
  margin-left: 10px;
  font-size: 14px;
  color: #333333;
}
.OnboardingProductSelectionOption-hintText.is-disabled {
  color: #999999;
}
.OnboardingProductSelectionOption-productSelectionOptionDetails {
  margin-top: 14px;
}
.OnboardingProductSelectionOption-detailsList {
  display: flex;
  flex-flow: column;
  gap: 8px;
  height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.OnboardingProductSelectionOption-detailsList.is-open {
  margin-bottom: 15px;
  padding-right: 5px;
  height: 176px;
}
.OnboardingProductSelectionOption-detailsBulletPoint {
  min-width: 6px;
  height: 6px;
  margin-top: 7px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: #575757;
}
.OnboardingProductSelectionOption-detailsListItem {
  display: flex;
  flex-direction: row;
  gap: 17px;
}
.OnboardingProductSelectionOption-detailsListItemText {
  font-size: 14px;
}
.OnboardingProductSelectionOption-showDetailsButton {
  font-size: 14px;
  color: #005ea8;
  cursor: pointer;
  text-decoration: none;
  text-align: right;
}
.OnboardingProductSelectionOption-showDetailsButton:hover {
  text-decoration: underline;
}
.OnboardingProductSelectionOption-showDetailsButtonIcon {
  width: 15px;
  height: 8px;
  fill: #005ea8;
  margin-right: 10px;
  rotate: 180deg;
  transition: all 0.3s ease-in-out;
  transition-delay: 150ms;
}
.OnboardingProductSelectionOption-showDetailsButtonIcon.is-up {
  rotate: 360deg;
}
.OnboardingProductSelectionOption-hintInfoSvgWrapper {
  margin-top: 5px;
}
.OnboardingProductSelectionOption-hintInfoSvg {
  width: 18px;
  fill: #999999;
}
.OnboardingProductSelectionOption-hintWarningSvg {
  width: 19px;
  height: 19px;
  fill: #666666;
}
.OnboardingProductSelectionSection-subtitle {
  font-size: 14px;
}
.OnboardingProductSelectionSection-productSelectionOptions {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.OnboardingProductSelectionSection-hintCheckSvgWrapper {
  margin-top: 5px;
}
.OnboardingProductSelectionSection-hintText {
  margin-left: 10px;
  font-size: 14px;
  color: #333333;
}
.OnboardingProductSelectionSection-infoBox {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #f07c0026;
  border-left: 5px solid #f07c00;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 12px;
}
.LifelongPensionBox-container {
  background-color: #d9edd9;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 7px 10px 7px 10px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.LifelongPensionBox-text {
  padding-top: 4px;
  font-size: 13px;
  margin-right: 10px;
}
.LifelongPensionBox-icon {
  min-width: 36px;
  height: 30px;
  display: block;
  margin-bottom: 2px;
  margin-right: 16px;
}
.LifelongPensionBox-tooltip {
  margin-bottom: 6px;
}
.LifelongPensionBox-questionMarkIcon {
  width: 20px;
  height: 20px;
  fill: #b4b4b4;
}
.NoTariffsFound {
  padding: 15px;
  border: 1px solid #575757;
  background-color: #ffffff;
}
.NoTariffsFound-headline {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
}
.NoTariffsFound-text {
  margin-top: 10px;
  font-size: 13px;
  color: #333333;
}
.PlaceholderTariff {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PlaceholderTariff-headerImage {
  height: 200px;
}
.PlaceholderTariff-headerText {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}
.PlaceholderTariff-descriptionText {
  max-width: 600px;
  margin-top: 15px;
  font-size: 13px;
  text-align: center;
}
.PlaceholderTariff-cta {
  margin-top: 20px;
}
.PlaceholderTariff-ctaButton {
  width: 260px;
}
.PlaceholderTariff-hint {
  color: #7ab51d;
  font-size: 12px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.PlaceholderTariff-checkIcon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.PointsAmountLogo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.PointsAmountLogo-icon {
  width: 50px;
  height: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.PointsAmountLogo-number {
  position: absolute;
  font-size: 10px;
  top: 12px;
  left: 8px;
  font-weight: bold;
  font-style: italic;
  color: #333333;
  font-family: Verdana;
  line-height: normal;
}
.PointsAmountLogo-tooltip .Tooltip {
  width: 363px;
}
.PointsAmountLogo-tooltipMessage {
  margin-top: 20px;
}
.PointsAmountLogo-tooltipIcons {
  margin-top: 20px;
  display: flex;
}
.PointsAmountLogo-tooltipFooter {
  margin-top: 20px;
}
.PointsAmountLogo-tooltipIcon {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.PointsAmountLogo-tooltipIconImage svg {
  height: 25px;
  width: auto;
}
.PointsAmountLogo-tooltipIconImage svg * {
  fill: #063773;
}
.PointsAmountLogo-amount {
  white-space: nowrap;
}
.ResultFilterChips-selectedFiltersListContainer {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.ResultFilterChips-filterContainer {
  background-color: #ecf7fd;
  border: 1px solid #005ea8;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  padding: 0 10px;
  height: 28px;
  box-sizing: content-box;
  font-size: 12px;
}
.ResultFilterChips-filterContainer:hover {
  background-color: #d8eefb;
}
.ResultFilterChips-unselectFilterButton {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  fill: #005ea8;
}
.ResultFilterChips-unselectAllFiltersButton {
  font-size: 12px;
  line-height: 20px;
  padding-top: 10px;
  white-space: nowrap;
  cursor: pointer;
  color: #005ea8;
}
.ResultFilterChips-unselectAllFiltersButton:hover {
  text-decoration: underline;
}
.ResultInsuranceFilterChip-insuranceChip {
  display: flex;
  align-items: center;
  height: 35px;
  padding: 10px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}
.ResultInsuranceFilterChip-insuranceChip.is-not-available {
  cursor: default;
  pointer-events: none;
}
.ResultInsuranceFilterChip-insuranceChip.is-selected {
  border: 1px, solid, #005ea8;
  background-color: #ecf7fd;
  cursor: pointer;
  pointer-events: auto;
}
.ResultInsuranceFilterChip-insuranceChip:hover {
  border: 1px, solid, #005ea8;
}
.ResultInsuranceFilterChip-insuranceChip:last-of-type {
  margin-right: 40px;
}
.ResultInsuranceFilterChip-insuranceChip:first-of-type {
  margin-left: 40px;
}
.ResultInsuranceFilterChip-logo {
  height: 20px;
  max-width: 100px;
  margin-right: 10px;
}
.ResultInsuranceFilterChip-logo.is-not-available {
  filter: grayscale(1);
  cursor: default;
}
.ResultInsuranceFilterChip-closeIcon {
  width: 10px;
  height: 10px;
  fill: #005ea8;
  margin-left: 10px;
}
.ResultInsuranceFilterChip-closeIcon.is-selected {
  margin-left: 0;
}
.ResultInsuranceFilterChip-closeIcon.is-not-available {
  fill: #575757;
}
.ResultInsuranceFilterChip-notAvailableOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #999999;
  opacity: 0.3;
}
.ResultInsuranceFilterChip-maxPension {
  font-size: 12px;
  color: #999999;
  white-space: nowrap;
}
.ResultInsuranceFilter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 10px;
}
.ResultInsuranceFilter-chipsList {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: scroll;
  scroll-behavior: smooth;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: -10px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.ResultInsuranceFilter-chipsList::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.ResultInsuranceFilter-chipsList {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.ResultInsuranceFilter-carouselSide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  background-color: #fafafa;
  background-size: cover;
  cursor: pointer;
  z-index: 1;
}
.ResultInsuranceFilter-carouselSide.right {
  position: absolute;
  right: 0px;
}
.ResultInsuranceFilter-carouselSide.right.is-disabled::before {
  background-image: none;
}
.ResultInsuranceFilter-carouselSide.right.is-disabled > svg * {
  fill: #999999;
}
.ResultInsuranceFilter-carouselSide.right::before {
  content: '';
  position: absolute;
  right: 40px;
  height: 100%;
  width: 40px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fafafa);
}
.ResultInsuranceFilter-carouselSide.left {
  position: absolute;
  left: 0px;
}
.ResultInsuranceFilter-carouselSide.left.is-filter-applied {
  left: 83px;
}
.ResultInsuranceFilter-carouselSide.left.is-disabled::before {
  background-image: none;
}
.ResultInsuranceFilter-carouselSide.left.is-disabled > svg * {
  fill: #999999;
}
.ResultInsuranceFilter-carouselSide.left > svg {
  transform: rotate(180deg);
}
.ResultInsuranceFilter-carouselSide.left::before {
  content: '';
  position: absolute;
  left: 40px;
  height: 100%;
  width: 40px;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #fafafa);
}
.ResultInsuranceFilter-resetFilterLink {
  font-size: 12px;
  color: #005ea8;
  cursor: pointer;
  margin-right: 5px;
}
.ResultInsuranceFilter-resetFilterLink:hover {
  text-decoration: underline;
}
.ResultLeftSideBox {
  padding: 0;
  border: 1px solid #dcdcdc;
  pointer-events: all;
}
.ResultLeftSideBox-title {
  font-size: 15px;
  padding: 6px 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}
.ResultLeftSideBox-body {
  background-color: #ffffff;
  padding: 15px 10px 15px 10px;
}
.ResultLeftSideConsultantBox-input {
  position: relative;
  display: block;
  margin-bottom: 8px;
}
.ResultLeftSideConsultantBox-tooltipIcon {
  width: 16px;
  height: 16px;
  fill: #b4b4b4;
  transition-duration: 200ms;
}
.ResultLeftSideConsultantBox-tooltipIcon * {
  fill: #b4b4b4;
}
.hover .ResultLeftSideConsultantBox-tooltipIcon {
  fill: #005ea8;
}
.hover .ResultLeftSideConsultantBox-tooltipIcon * {
  fill: #005ea8;
}
.ResultLeftSideConsultantBox-tooltip {
  position: absolute;
  top: 12px;
  right: 0;
  cursor: help;
}
.ResultLeftSideInput-label {
  margin-bottom: 4px;
  margin-right: 10px;
  font-weight: bold;
  color: #333333;
  font-size: 12px;
  display: inline-block;
}
.ResultLeftSideNotification-container {
  cursor: pointer;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  position: fixed;
  color: #ffffff;
  background: #04153c;
  width: 236px;
  margin-left: -2px;
  z-index: 10;
  padding: 15px 15px 20px;
  opacity: 0;
  bottom: -75px;
}
.ResultLeftSideNotification-container.is-visible {
  animation: NotificationBox 6s ease-in;
}
@keyframes NotificationBox {
  0% {
    opacity: 0;
    bottom: -75px;
  }
  10% {
    opacity: 0.5;
    bottom: 15px;
  }
  20% {
    opacity: 0.9;
    bottom: 15px;
  }
  90% {
    opacity: 0.9;
    bottom: 15px;
  }
  100% {
    opacity: 0;
    bottom: -75px;
  }
}
.ResultLeftSideNotification-header {
  font-weight: 700;
  font-size: 13px;
}
.ResultLeftSideNotification-body {
  font-size: 12px;
  margin-top: 5px;
}
.ResultLeftSide {
  pointer-events: none;
}
.ResultLeftSide.is-inactive {
  filter: grayscale(0.8);
  opacity: 0.5;
}
.ResultLeftSide.is-inactive * {
  pointer-events: none;
}
.ResultLeftSide-input {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.ResultLeftSide-input:last-child {
  margin-bottom: 0;
}
.ResultLeftSide-recalcButton {
  padding: 6px;
  font-size: 15px;
}
.ResultLeftSide-box {
  margin-bottom: 15px;
}
.ResultLeftSide-phone {
  font-size: 15px;
  color: #005ea8;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.ResultLeftSide-phoneSvg {
  fill: #005ea8;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.ResultLeftSide-mail {
  font-size: 15px;
  color: #005ea8;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ResultLeftSide-mailAtSvg {
  fill: #005ea8;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.ResultLeftSide-officeHours {
  margin-left: 20px;
  margin-top: 10px;
}
.ResultLeftSide-tooltipIcon {
  width: 16px;
  height: 16px;
  fill: #b4b4b4;
  transition-duration: 200ms;
  vertical-align: top;
}
.ResultLeftSide-tooltipIcon * {
  fill: #b4b4b4;
}
.hover .ResultLeftSide-tooltipIcon {
  fill: #005ea8;
}
.hover .ResultLeftSide-tooltipIcon * {
  fill: #005ea8;
}
.ResultLeftSide-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  cursor: help;
}
.ResultLeftSide-consultantRecalcWithNoFiltersButton {
  margin-top: 10px;
  background-color: #005ea8;
}
.ResultRankingBox {
  display: flex;
  background-color: #dcdcdc;
  padding: 8px 15px;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: flex-start;
  color: #333333;
}
.ResultRankingBox-closeButton {
  margin: 3px 0px 0px 12px;
  padding: 0;
  display: flex;
  align-items: center;
}
.ResultRankingBox-closeIcon {
  height: 12px;
  width: 12px;
}
.ResultRankingBox-closeIcon * {
  fill: #b4b4b4;
}
.ResultRankingBox-closeIcon *:hover {
  fill: #9c9c9c;
}
.ResultSustainabilityFilterInfoBox {
  display: flex;
  background-color: #e4f0d2;
  padding: 8px 15px;
  margin-bottom: 12px;
  color: #333333;
  border: 1px solid #7ab51d;
  align-items: center;
}
.ResultSustainabilityFilterInfoBox-leaf {
  fill: #7ab51d;
  width: 22px;
  height: 22px;
  margin-right: 15px;
}
.ResultSustainabilityFilterInfoBox-link {
  padding-left: 5px;
}
.ResultTariffListItemEfeedbackTab-tabHeader {
  color: #333333;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  width: fit-content;
}
.ResultTariffListItemEfeedbackTab-tabHeader.is-clickable {
  cursor: pointer;
}
.ResultTariffListItemEfeedbackTab-tabHeader.is-selected {
  padding-bottom: 5px;
  border-bottom: 3px solid #666666 !important;
}
.ResultTariffListItemEfeedbackTab-tabHeader.is-disabled {
  color: #999999;
  cursor: default;
}
.ResultTariffListItemEfeedbackTab-tabHeader:hover {
  text-decoration: underline;
}
.ResultTariffListItemEfeedbackTab-tabHeader:hover.is-disabled {
  text-decoration: none;
}
.ResultTariffListItemEfeedbackTab-tabArrow {
  display: block;
  width: 17px;
  height: 17px;
  fill: #b4b4b4;
  transition: transform 0.3s ease;
  transform: rotate(90deg);
}
.ResultTariffListItemEfeedbackTab-tabArrow.is-open {
  transform: rotate(270deg);
}
.ResultTariffListItemEfeedbackTab-EFeedbackTooltip .Tooltip {
  width: 380px;
  text-align: left;
  cursor: default;
}
.ResultTariffListItemEfeedbackTab-ratingStarsNumber {
  margin-right: 5px;
}
.ResultTariffListItemEfeedbackTab-tabHeaderLabel {
  padding-right: 5px;
}
.ResultTariffListItemTestReportTab-tabHeader {
  color: #333333;
  display: flex;
  align-items: center;
  padding: 8px 0px;
  width: fit-content;
}
.ResultTariffListItemTestReportTab-tabHeader.is-clickable {
  cursor: pointer;
}
.ResultTariffListItemTestReportTab-tabHeader.is-selected {
  padding-bottom: 5px;
  border-bottom: 3px solid #666666 !important;
}
.ResultTariffListItemTestReportTab-tabHeader.is-disabled {
  color: #999999;
  cursor: default;
}
.ResultTariffListItemTestReportTab-tabHeader:hover {
  text-decoration: underline;
}
.ResultTariffListItemTestReportTab-tabHeader:hover.is-disabled {
  text-decoration: none;
}
.ResultTariffListItemTestReportTab-tabArrow {
  display: block;
  width: 17px;
  height: 17px;
  fill: #b4b4b4;
  transition: transform 0.3s ease;
  transform: rotate(90deg);
}
.ResultTariffListItemTestReportTab-tabArrow.is-open {
  transform: rotate(270deg);
}
.ResultTariffListItemTestReportTab-tabHeaderLabel {
  padding-right: 5px;
}
.ResultTariffsSkeleton-tariffs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.ResultTariffsSkeleton-tariff {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
.ResultTariffsSkeleton-tariffHeader {
  width: 261px;
  height: 24px;
  margin-bottom: 12px;
}
.ResultTariffsSkeleton-tariffBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 43px;
}
.ResultTariffsSkeleton-tariffLogo {
  width: 172px;
  height: 69px;
}
.ResultTariffsSkeleton-tariffGrade {
  width: 80px;
  height: 92px;
}
.ResultTariffsSkeleton-tariffFeatures {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ResultTariffsSkeleton-tariffFeatureRow1 {
  width: 208px;
  height: 36px;
}
.ResultTariffsSkeleton-tariffFeatureRow2 {
  width: 164px;
  height: 16px;
}
.ResultTariffsSkeleton-tariffFeatureRow3 {
  width: 257px;
  height: 19px;
}
.ResultTariffsSkeleton-tariffFeatureRow4 {
  width: 151px;
  height: 19px;
}
.ResultTariffsSkeleton-tariffPension {
  width: 171px;
  height: 79px;
}
.ResultTariffsSkeleton-tariffFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.ResultTariffsSkeleton-tariffFooterContent {
  width: 647px;
  height: 29px;
}
.ResultTariffsSkeleton-tariffButton {
  width: 197px;
  height: 42px;
}
.ResultTariffsSkeleton-skeletonBase {
  border-radius: 5px;
  background: #efeff4;
}
.ResultTariffsSkeleton-skeletonBase.is-animated {
  background: linear-gradient(120deg, rgba(221, 221, 221, 0) 30%, rgba(221, 221, 221, 0.5) 50%, rgba(221, 221, 221, 0) 70%) #efeff4;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s skeleton-loading ease-in-out infinite;
}
@keyframes skeleton-loading {
  to {
    background-position-x: -20%;
  }
}
.ResultZeroGridClosed {
  font-size: 13px;
  display: flex;
  align-items: baseline;
  margin-top: 5px;
}
.ResultZeroGridClosed-arrow {
  display: block;
  width: 10px;
  height: 10px;
  fill: #005ea8;
  margin-left: 5px;
  cursor: pointer;
}
.ResultZeroGridEFeedback-tabHeader {
  color: #333333;
  display: flex;
  align-items: center;
  padding: 10px 0px;
}
.ResultZeroGridEFeedback-tabHeader.is-clickable {
  cursor: pointer;
}
.ResultZeroGridEFeedback-tabHeader.is-selected {
  padding-bottom: 5px;
  border-bottom: 3px solid #666666 !important;
}
.ResultZeroGridEFeedback-tabHeader:hover {
  text-decoration: underline;
}
.ResultZeroGridEFeedback-tabArrow {
  display: block;
  width: 17px;
  height: 17px;
  fill: #b4b4b4;
  margin-left: 5px;
  transition: transform 0.3s ease;
  transform: rotate(90deg);
}
.ResultZeroGridEFeedback-tabArrow.is-open {
  transform: rotate(270deg);
}
.ResultZeroGridEFeedback-tabHeaderContent {
  display: flex;
  align-items: center;
}
.ResultZeroGridEFeedback-EFeedbackTooltip {
  align-self: center;
}
.ResultZeroGridEFeedback-EFeedbackTooltip .Tooltip {
  width: 380px;
  text-align: left;
  cursor: default;
}
.ResultZeroGridExpandPanel {
  margin-top: -1px;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.overlay.is-blurred::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  z-index: 1;
  pointer-events: none;
}
.ResultZeroGridItem {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 0px 13px 0;
}
.ResultZeroGridItem:hover {
  border-color: #999999;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
.ResultZeroGridItem.color-gold {
  border: 1px solid #b39656;
}
.ResultZeroGridItem.color-blue {
  border: 1px solid #063773;
}
.ResultZeroGridItem.color-green {
  border: 1px solid #008300;
}
.ResultZeroGridItem.color-orange {
  border: 1px solid #f07c00;
}
.ResultZeroGridItem.is-expandPanelOpen:hover .ResultZeroGridEFeedback.is-selected {
  padding-bottom: 4px;
}
.ResultZeroGridItem.is-expandPanelOpen:hover .ResultZeroGridTestReports.is-selected {
  padding-bottom: 4px;
}
.ResultZeroGridItem.is-expandPanelOpen:hover .ResultZeroGridEFeedback-tabHeader,
.ResultZeroGridItem.is-expandPanelOpen:hover .ResultZeroGridTestReports-tabHeader {
  border-color: #999999;
}
.ResultZeroGridItem.is-one-half .ResultZeroGridItem-insuranceLogo {
  max-width: 180px;
}
.ResultZeroGridItem.is-one-half .ResultZeroGridItem-possiblePrice {
  font-size: 22px;
}
.ResultZeroGridItem.is-one-half .ResultZeroGridItem-guaranteedPrice {
  font-size: 16px;
}
.ResultZeroGridItem.is-one-third .ResultZeroGridItem-grade {
  height: 70px;
  width: 60px;
}
.ResultZeroGridItem.is-one-third .ResultZeroGridItem-grade .Grade-icon {
  height: 70px;
  width: 60px;
  background-size: 60px 70px;
}
.ResultZeroGridItem.is-one-third .ResultZeroGridItem-grade .Grade-calculatedValue {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 21px;
  font-size: 20px;
  font-weight: 700;
}
.ResultZeroGridItem.is-one-third .ResultZeroGridItem-grade .Grade-gradeText,
.ResultZeroGridItem.is-one-third .ResultZeroGridItem-grade .Grade-subText {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 9px;
}
.ResultZeroGridItem.is-one-third .ResultZeroGridItem-grade .Grade-subText {
  top: 13px;
}
.ResultZeroGridItem.is-one-third .ResultZeroGridItem-grade .Grade-gradeText {
  top: 55px;
  color: #ffffff;
  overflow: hidden;
}
.ResultZeroGridItem.is-one-third .ResultZeroGridItem-grade .Grade-gradeText.is-bold {
  font-weight: 700;
}
@media (max-width: 1279px) {
  .ResultZeroGridItem:nth-child(3).is-one-third {
    display: none;
  }
}
.ResultZeroGridItem-header {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 6px;
}
.ResultZeroGridItem-insuranceContainer {
  margin-right: 10px;
}
.ResultZeroGridItem-insuranceLogo {
  display: block;
  height: 60px;
  max-width: 135px;
}
.ResultZeroGridItem-tariffName {
  margin-top: 10px;
  height: 35px;
}
.ResultZeroGridItem-price {
  line-height: 18px;
  text-align: right;
  margin-bottom: 5px;
}
.ResultZeroGridItem-price .Tooltip {
  text-align: start;
}
.ResultZeroGridItem-possiblePrice {
  font-size: 18px;
  font-weight: bold;
  color: #008300;
  margin-bottom: 6px;
  white-space: nowrap;
}
.ResultZeroGridItem-guaranteedPrice {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  margin-top: 7px;
  margin-bottom: 2px;
}
.ResultZeroGridItem-priceText {
  font-size: 11px;
  line-height: 16px;
}
.ResultZeroGridItem-possiblePriceText {
  color: #008300;
}
.ResultZeroGridItem-priceDescription {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}
.ResultZeroGridItem-body {
  flex: 2;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 12px;
  padding-bottom: 6px;
}
.ResultZeroGridItem-bodyRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}
.ResultZeroGridItemUsps-container {
  font-size: 13px;
  padding-right: 5px;
}
.ResultZeroGridItem-actionButton {
  padding: 5px;
  height: 40px;
  width: 120px;
}
@media (min-width: 1280px) {
  .ResultZeroGridItem-actionButton {
    font-size: 15px;
  }
}
.ResultZeroGridItem-tariffSelectionCheckbox {
  display: flex;
  align-self: flex-end;
}
.ResultZeroGridItem-footerActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
}
.ResultZeroGridItem-footerTabs {
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1px;
}
.ResultZeroGridItem-gradeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.ResultZeroGridItem-labelContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: flex-start;
}
.ResultZeroGridItem-labelSlot {
  min-height: 24px;
  margin-top: 5px;
}
.ResultZeroGridTestReports-tabHeader {
  color: #333333;
  display: flex;
  align-items: center;
  padding: 10px 0px;
}
.ResultZeroGridTestReports-tabHeader.is-clickable {
  cursor: pointer;
}
.ResultZeroGridTestReports-tabHeader.is-selected {
  padding-bottom: 5px;
  border-bottom: 3px solid #666666 !important;
}
.ResultZeroGridTestReports-tabHeader.is-disabled {
  color: #999999;
  cursor: default;
}
.ResultZeroGridTestReports-tabHeader:hover {
  text-decoration: underline;
}
.ResultZeroGridTestReports-tabHeader:hover.is-disabled {
  text-decoration: none;
}
.ResultZeroGridTestReports-tabHeaderContent {
  display: flex;
  align-items: center;
}
.ResultZeroGridTestReports-tabHeaderLabel {
  padding-right: 3px;
}
.ResultZeroGridTestReports-tabArrow {
  display: block;
  width: 17px;
  height: 17px;
  fill: #b4b4b4;
  transition: transform 0.3s ease;
  transform: rotate(90deg);
}
.ResultZeroGridTestReports-tabArrow.is-open {
  transform: rotate(270deg);
}
.ResultZeroGrid {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
}
.ResultZeroGrid-container {
  width: 100%;
}
.ResultZeroGrid-content {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 9px;
  grid-auto-columns: 1fr;
}
.TariffListOverlayContactBox-contactBoxHeadline {
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 5px;
}
.TariffListOverlayContactBox-phoneSvg {
  fill: #005ea8;
  height: 24px;
  width: 17px;
  margin-right: 11px;
  margin-left: 10px;
  margin-bottom: 0px;
}
.TariffListOverlayContactBox-emailSvg {
  fill: #005ea8;
  height: 19px;
  width: 26px;
  margin-right: 10px;
  margin-top: 3px;
}
.TariffListOverlayContactBox-emailContainer {
  display: flex;
  margin-top: 8px;
}
.TariffListOverlayContactBox-contactBox {
  margin-top: 20px;
}
.TariffListOverlayContactBox-contactTextContainer {
  display: flex;
  align-items: center;
}
.TariffListOverlayContactBox-workingTimes {
  color: #666666;
  line-height: 14px;
  margin-top: 3px;
  font-size: 12px;
}
.TariffListOverlayContactBox-email {
  font-size: 14px;
}
.TariffListOverlayProgressBar .ProgressBar .ProgressBar-percent {
  font-size: 16px;
}
.TariffListOverlayProgressBar-text {
  font-size: 22px;
  margin: 0;
  color: #333333;
}
.TariffListOverlayProgressBar-footer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 15px;
}
.TariffListOverlayProgressBar-footerContainer {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}
.TariffListOverlayProgressBar-footerContainer .TariffListOverlayProgressBar-logoContainer:last-child {
  margin-right: 0;
}
.TariffListOverlayProgressBar-logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TariffListOverlayProgressBar-logo {
  height: 75px;
  width: 85px;
}
.TariffListOverlayProgressBar-logoText {
  font-size: 16px;
  color: #333333;
  text-align: center;
  margin: 0;
}
.TariffListOverlayRecommendation {
  padding: 10px;
  font-size: 14px;
  min-width: 480px;
  color: #333333;
}
.TariffListOverlayRecommendation-footer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.TariffListOverlayRecommendation-checkmarkItem {
  display: flex;
  align-items: center;
}
.TariffListOverlayRecommendation-checkmarkItem:not(:last-child) {
  margin-bottom: 10px;
}
.TariffListOverlayRecommendation-checkmarkSvg {
  fill: #7ab51d;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.TariffListOverlayRecommendation-cta {
  margin-top: 30px;
  max-width: 300px;
  align-self: center;
}
.TariffListOverlayRecommendation-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: -2px;
  top: -2px;
  cursor: pointer;
  fill: #999999;
}
.TariffListOverlay {
  position: absolute;
  top: 74px;
  left: 0;
  height: 100%;
  width: 100%;
}
.TariffListOverlay.is-tabletApp {
  top: 0px;
}
.TariffListOverlay.is-visiblyHidden {
  visibility: hidden;
}
.TariffListOverlay-wrapper {
  position: fixed;
  z-index: 999;
  background-color: white;
  padding: 20px 37px 20px 30px;
  left: 53%;
  top: 50%;
  border-radius: 3px;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.TariffListOverlay-container {
  position: relative;
}
.TariffListOverlay-blocker {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: black;
}
.TariffListOverlay-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: -2px;
  top: -2px;
  cursor: pointer;
  fill: #999999;
}
.TariffListOverlay-missingTariffContainer {
  font-size: 14px;
  max-width: 480px;
  color: #333333;
}
.TariffListOverlay-missingTariffFooter {
  display: flex;
  flex-direction: column;
}
.TariffListOverlay-infoIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  fill: #f07c00;
}
.TariffListOverlay-infoBox {
  display: flex;
}
.TariffListOverlay-missingTariffCta {
  width: 300px;
  height: 49px;
  align-self: center;
  margin-top: 23px;
}
.TariffSelectionBar {
  position: fixed;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
  overflow: hidden;
  max-height: 0;
  bottom: 0;
}
.TariffSelectionBar.TariffSelectionBar-isShown {
  max-height: 100px;
  padding-bottom: calc(env(safe-area-inset-bottom) * 2 + 70px);
}
.TariffSelectionBar-container {
  display: flex;
  align-items: center;
  max-width: 1280px;
  height: 100px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .TariffSelectionBar-container {
    width: 1280px;
  }
}
.TariffSelectionBar-selectedTariffs {
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-width: 767px;
}
@media (min-width: 1280px) {
  .TariffSelectionBar-selectedTariffs {
    max-width: 1020px;
    font-size: 13px;
  }
}
.TariffSelectionBar-tariff {
  display: flex;
  width: 225px;
  height: 80px;
  margin-left: 10px;
  padding: 5px;
  background: #ffffff;
  background-origin: content-box;
  position: relative;
  align-items: flex-end;
}
.TariffSelectionBar-tariff.is-resultZero {
  background: #ecf7fd;
}
.TariffSelectionBar-tariff.TariffSelectionBar-tariff--empty {
  text-align: center;
  display: block;
  align-items: center;
  background-color: black;
  font-size: 25px;
  color: #cccccc;
  justify-content: center;
  padding-top: 13px;
}
.TariffSelectionBar-tariff.color-gold {
  background-color: #f4efe6;
}
.TariffSelectionBar-tariff.color-blue {
  background-color: #dae1ea;
}
.TariffSelectionBar-tariff.color-green {
  background-color: #d9edd9;
}
.TariffSelectionBar-tariff.color-orange {
  background-color: #fdebd9;
}
.TariffSelectionBar-closeButton {
  cursor: pointer;
  content: '';
  position: absolute;
  right: 5px;
  top: 5px;
  height: 16px;
  width: 16px;
  fill: #b4b4b4;
}
.TariffSelectionBar-closeButton:hover {
  fill: #999999;
}
.TariffSelectionBar-tariffName {
  margin-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  max-width: 105px;
}
.TariffSelectionBar-amountContainer {
  font-weight: bold;
  position: absolute;
  bottom: 3px;
  right: 5px;
}
.TariffSelectionBar-price {
  font-size: 16px;
  color: #008300;
}
.TariffSelectionBar-insuranceLogo {
  position: absolute;
  left: 5px;
  top: 5px;
  height: 45px;
  width: 120px;
  margin: 0 auto;
  max-width: 110px;
}
.TariffSelectionBar-insuranceLogo svg {
  max-height: 50px;
}
.TariffSelectionBar-options {
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  margin: 0 10px;
  justify-content: flex-end;
  width: 220px;
  position: relative;
}
.TariffSelectionBar-removeAllButtonContainer {
  text-align: right;
  flex-basis: 100%;
}
.TariffSelectionBar-removeAllButton {
  color: #cccccc;
}
.TariffSelectionBar-compareButton {
  position: absolute;
  left: 0;
  bottom: 0;
  border: none !important;
}
@media (min-width: 1280px) {
  .TariffSelectionBar-compareButton {
    font-size: 15px;
  }
}
.TariffSelectionBar-selectionText {
  display: none;
}
@media (min-width: 1200px) {
  .TariffSelectionBar-selectionText {
    flex: 0 0 230px;
    display: block;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 5px;
    width: 208px;
    min-width: 208px;
  }
}
@media (min-width: 1280px) {
  .TariffSelectionBar-selectionText {
    margin-right: 15px;
  }
}
.TariffSelectionBar-emptyText {
  font-size: 12px;
  color: #ffffff;
  margin-top: 10px;
}
.TariffSelectionCheckbox-tooltipContent {
  font-size: 12px;
  color: #333333;
}
.TariffSelectionCheckbox-tooltipClose {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 16px;
  width: 16px;
  fill: #b4b4b4;
}
.TariffSelectionCheckbox-tooltipClose:hover {
  fill: #999999;
}
.TariffSelectionCheckbox-tooltipIcon {
  display: inline-block;
}
.TariffSelectionCheckbox-tooltip {
  position: absolute;
  background-color: white;
  margin-left: -249px;
  margin-top: -69px;
  text-align: left;
  border-radius: 3px;
  width: 240px;
  opacity: 1 !important;
  pointer-events: all !important;
  padding-right: 15px !important;
}
.TariffSelectionCheckbox-tooltip:before {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  right: -8px;
  top: 50%;
  margin-top: -5px;
  border-left: 8px solid #005ea8;
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}
.TariffSelectionCheckbox-tooltip:after {
  border-left: 6px solid white;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  right: -6px;
  top: 50%;
  margin-top: -4px;
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}
.TariffSelectionCheckbox-checkbox label {
  font-size: 12px;
}
@media (min-width: 1280px) {
  .TariffSelectionCheckbox-checkbox label {
    font-size: 13px;
  }
}
.TariffSelectionCheckbox-checkbox label .Checkbox-label {
  align-self: center;
}
.TariffSelectionCheckbox-looperTooltip {
  position: absolute;
  color: #333333 !important;
  background-color: #ecf7fd;
  border: 1px solid #005ea8;
  margin-left: -180px;
  margin-top: -35px;
  text-align: left;
  font-size: 14px;
  width: 168px;
  line-height: 1.3;
  padding: 14px 8px;
  opacity: 1 !important;
  pointer-events: all !important;
  padding-right: 15px !important;
  box-shadow: none !important;
}
.TariffSelectionCheckbox-looperTooltip:before {
  border-bottom: 9px solid transparent;
  border-top: 9px solid transparent;
  border-left: 7px solid #005ea8;
  top: 30px;
  left: 166px;
  margin-top: -10px;
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}
.TariffSelectionCheckbox-looperTooltip:after {
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  border-left: 6px solid #ecf7fd;
  top: 29px;
  left: 166px;
  margin-top: -8px;
  content: '';
  width: 0;
  height: 0;
  position: absolute;
}
.TariffSelectionCheckbox-looperTooltipClose {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 5px;
  height: 10px;
  width: 10px;
  fill: #b4b4b4;
}
.TariffSelectionCheckbox-looperTooltipClose > path {
  fill: #b4b4b4;
}
.TariffSelectionCheckbox-looperTooltipClose:hover {
  fill: #999999;
}
.TariffSelectionCheckbox-looperTooltipTip {
  margin-right: 8px;
  float: left;
  width: 25px;
  height: 25px;
}
.ComparisonPage {
  max-width: 1280px;
  padding: 20px 10px 0px;
}
.ComparisonPage-detailsButton {
  border-bottom: 1px solid #cccccc;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.ComparisonPage-detailsButton.is-centered {
  justify-content: center;
}
.ComparisonPage-detailsButton.is-footer {
  padding: 16px 16px 16px;
  border-bottom: none;
}
.ComparisonPage-detailsButton.no-border {
  border-bottom: none;
}
.ComparisonPage-detailsButton.is-large {
  min-height: 110px;
}
.ComparisonPage-spacer {
  background-color: #ededed;
  flex: 1;
  padding: 0 14px;
}
.ComparisonPage-backButton {
  line-height: 28px;
  height: 32px;
  width: 90%;
  margin-top: 5px;
  margin-bottom: 15px;
}
.ComparisonPage-stickyContainer {
  position: relative;
  z-index: 149;
}
.ComparisonPage-infoFooter {
  display: none;
}
.ComparisonPage-legendContainer {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.ComparisonPage-legendItem {
  display: flex;
  align-items: center;
  margin: 10px;
}
.ComparisonPage-legendItem svg {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}
.GradesExplanationIframe {
  width: 100%;
  min-height: 800px;
  border: none;
}
.GradesExplanationPage {
  max-width: 1130px;
}
.OnboardingContribution {
  display: flex;
  margin-bottom: 16px;
}
.OnboardingContribution-formContainer {
  width: 850px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.OnboardingContribution-ctaContainer {
  display: flex;
  justify-content: space-between;
  margin: 32px 48px;
  align-items: center;
}
.OnboardingContribution-backButton {
  width: 190px;
  font-size: 13px;
  font-weight: bold;
}
.OnboardingContribution-goToResultsButton {
  width: 200px;
  height: 40px;
  font-size: 14px;
  margin-left: auto;
}
.OnboardingPersonalDataPage {
  display: flex;
  margin-bottom: 12px;
}
.OnboardingPersonalDataPage-formContainer {
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  width: 850px;
  background-color: #ffffff;
}
.OnboardingPersonalDataPage-link {
  max-width: 322px;
}
.OnboardingSofortPage {
  display: flex;
  margin-bottom: 16px;
  margin-top: 10px;
}
.OnboardingSofortPage-formContainer {
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  width: 850px;
  background-color: #ffffff;
}
.OnboardingSofortPage-additionalInformation {
  display: flex;
  width: 780px;
  justify-content: space-between;
}
.OnboardingSofortPage-goToResultsButton {
  width: 235px;
  height: 40px;
  font-size: 14px;
  margin-left: auto;
  margin-bottom: 12px;
}
.OnboardingSofortPage-link {
  max-width: 322px;
}
.OnboardingPage-headline {
  color: #333333;
  font-size: 21px;
  margin-bottom: 20px;
  font-weight: 700;
}
.OnboardingPage-inputContainer {
  width: 56%;
}
.OnboardingPage-actionButton {
  width: 200px;
  font-size: 14px;
  margin-left: auto;
  height: 40px;
}
.OnboardingPage-ctaSection {
  padding: 32px 48px 32px 48px;
  display: flex;
  justify-content: space-between;
}
.OnboardingPage-additionalInformation {
  display: flex;
  width: 810px;
  justify-content: space-between;
}
.OnboardingPage-additionalInformation.is-fullWidth {
  width: 994px;
}
.OnboardingPage-additionalInformation.is-leftAligned {
  justify-content: end;
}
.OnboardingPage-nextPageButton {
  width: 150px;
  height: 45px;
  font-size: 14px;
  margin-left: auto;
  margin-bottom: 9px;
}
.OnboardingPage-recalcHintIcon {
  width: 50px;
  height: 50px;
  fill: #005ea8;
  margin-right: 20px;
}
.OnboardingPage-recalcHintHeadlineText {
  font-weight: bold;
}
.OnboardingPage-recalcHint {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 13px;
  border: 1px solid #005ea8;
  padding: 10px 20px;
  position: relative;
}
.OnboardingPage-recalcHint:after,
.OnboardingPage-recalcHint:before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  content: ' ';
  border: solid transparent;
}
.OnboardingPage-recalcHint:after {
  border: 15px solid transparent;
  border-bottom: 15px solid #ffffff;
  margin-left: 1px;
}
.OnboardingPage-recalcHint:before {
  border: 16px solid transparent;
  border-bottom: 16px solid #005ea8;
}
.OnboardingPage-recalcHintClose {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 10px;
  fill: #b4b4b4;
  cursor: pointer;
}
.OnBoardingPage-debugButton {
  background-color: #c82d2d;
  width: 130px;
  margin-right: 5px;
}
.OnBoardingPage-debugButton:hover {
  background-color: #c82d2d;
}
.OnboardingPage-disclaimer {
  margin-bottom: 10px;
  font-size: 9px;
}
.PersonalDataPage-securityHint {
  display: flex;
  justify-content: space-between;
  width: 780px;
}
.PersonalDataPage-columns {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1279px) {
  .PersonalDataPage-columns {
    justify-content: initial;
  }
}
.PersonalLogin-iframeContainer {
  min-height: 480px;
  display: flex;
  justify-content: center;
}
.PersonalLogin-iframe {
  display: block;
  width: 548px;
}
.ResultPage {
  max-width: 1280px;
  padding: 20px 10px 0px;
}
.ResultPage-container {
  display: flex;
  margin-bottom: 6px;
}
.ResultPage-resultSummaryRow {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  min-width: 700px;
}
@media (max-width: 1279px) {
  .ResultPage-resultSummaryRow {
    margin-left: 5px;
    font-size: 12px;
  }
}
.ResultPage-tariffs {
  position: relative;
  width: 100%;
  min-width: 750px;
  max-width: 1005px;
  padding-bottom: 90px;
}
.ResultPage-tariffsSkeleton {
  position: relative;
  width: 100%;
  min-width: 750px;
  max-width: 1005px;
  padding-bottom: 90px;
}
.ResultPage-disclaimerFooter {
  position: absolute;
  bottom: 0px;
}
.BackToTopButton {
  display: none;
}
@media (min-width: 1280px) {
  .BackToTopButton {
    display: inline;
    position: fixed;
    bottom: 105px;
    margin-left: 1020px;
  }
}
.TariffDetailsPage {
  padding-top: 32px;
  color: #333333;
  font-family: Verdana, Arial, Helvetica, Sans-Serif;
  font-size: 12px;
  line-height: 1.42857143;
  min-width: 1px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  max-width: 994px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.TariffDetailsPage.is-tabletApp {
  max-width: 1280px;
  padding: 0 10px;
}
.TariffDetailsPage.is-redesign {
  background-color: #fafafa;
  padding-top: 32px;
  max-width: 850px;
}
@media (max-width: 1279px) {
  .TariffDetailsPage.is-redesign {
    max-width: 828px;
    margin-left: 15px;
  }
}
.TariffDetailsPage-tab {
  background-color: #f4f4f4;
  padding: 12px 0;
  width: 200px;
  margin-right: 4px;
  text-align: center;
  color: #005ea8;
  cursor: pointer;
}
.TariffDetailsPage-selectedTab {
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-bottom: none;
  bottom: -1px;
  position: relative;
}
.TariffDetailsPage-selectedTabPanel {
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  padding: 22px 25px 5px 25px;
}
.TariffDetailsPage-tabList {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #cccccc;
}
.TariffDetailsPage-inputContainer {
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 35px 20px 25px 25px;
}
.TariffDetailsPage-actionButton {
  width: 200px;
}
.TariffDetailsPage-backLink {
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
}
.TariffDetailsPage-checkIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: #7ab51d;
}
.TariffDetailsPage-actionButtonHint {
  color: #7ab51d;
  display: flex;
  align-items: center;
  margin-top: 6px;
}
.TariffDetailsPage-hint {
  font-size: 9px;
  display: block;
  margin-top: 10px;
}
.TariffDetailsPage-legendContainer {
  display: flex;
  justify-content: center;
}
.TariffDetailsPage-comparisonLink {
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  right: 20px;
}
.TariffDetailsPage-legendItem {
  display: flex;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
}
.TariffDetailsPage-legendIcon {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}
.TariffDetailsPage-linkContainer {
  position: relative;
  height: 50px;
}
.TariffDetailsPage-backLinkContainer {
  flex: 1;
}
.TariffDetailsPage-processOverview {
  border: 1px solid 1px solid #cccccc;
  margin-top: 20px;
}
.TariffDetailsPage-categoriesHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 20px;
  margin-bottom: 20px;
}
.TariffDetailsPage-categoriesTitle {
  font-size: 18px;
  font-weight: bold;
}
.TariffDetailsPage-inquiryButtonTooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}
.TariffDetailsPage-inquiryButtonTooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: #7ab51d;
  position: relative;
  top: 4px;
}
.ThankyouErrorPage {
  font-size: 13px;
}
.ThankyouErrorPage-container {
  display: flex;
  margin-bottom: 40px;
}
.ThankyouErrorPage-left {
  display: block;
  margin-top: 15px;
  padding-left: 20px;
  flex: 1;
}
.ThankyouErrorPage-headline {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
}
.ThankyouErrorPage-crossSvg {
  width: 28px;
  height: 22px;
  margin-right: 10px;
  fill: #7ab51d;
}
.ThankyouErrorPage-headlineText {
  color: #333333;
  font-size: 20px;
}
.ThankyouErrorPage-subtitle {
  color: #333333;
  margin-top: 30px;
  font-size: 16px;
}
.ThankyouErrorPage-helpText {
  padding-left: 25px;
  width: 230px;
}
.ThankyouErrorPage-phoneNumberContainer {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.ThankyouErrorPage-phoneSvg {
  fill: #005ea8;
  height: 24px;
  width: 24px;
  margin-right: 5px;
  margin-top: 5px;
}
.ThankyouErrorPage-phoneNumber {
  color: #005ea8;
  font-size: 20px;
}
.ThankyouErrorPage-workingTimes {
  color: #333333;
  font-size: 14px;
}
.ThankyouErrorPage-backCont {
  border-top: 1px solid #cccccc;
  margin-top: 60px;
  padding-top: 15px;
}
.ThankyouPage {
  font-size: 12px;
}
.ThankyouPage-columns {
  display: flex;
  justify-content: space-between;
}
.ThankyouPage-loadingSpinner {
  margin: auto;
}
