@import '../../styles/variables/colors';
@import '../../styles/variables/borders';

.ResultTariffListItemBullets-featureTooltip,
.ResultTariffListItemBullets-labelTooltip {
  max-width: 290px;

  &:after {
    left: 30% !important;
  }

  &:before {
    left: 30% !important;
  }
}

.ResultTariffListItemBullets-tooltipAnchor {
  position: absolute;
  left: 30px;
  top: 5px;
}

.ResultTariffListItemBullets-container {
  margin-bottom: 10px;
  flex: 1;
  min-width: 40%;
  font-size: 13px;
  padding-right: 5px;
}

.ResultTariffListItemBullets-feature {
  margin-bottom: 6px;
  display: flex;
  cursor: default;
  position: relative;

  &.has-helpText {
    cursor: help;
  }
}

.ResultTariffListItemBullets-feature {
  &.is-last {
    margin-bottom: 10px;
  }
}

.ResultTariffListItemBullets-featureIcon {
  margin-right: 6px;
  position: relative;
  top: 2px;

  > svg {
    width: 13px;
    height: 13px;
  }
}

.ResultTariffListItemBullets-labelWrapper {
  display: flex;
  cursor: help;
  margin-bottom: 10px;
  margin-top: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.ResultTariffListItemBullets-label {
  border-radius: 2px;
  line-height: 16px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 11px;
  margin-top: 1px;
  margin-bottom: 1px;
  height: 16px;
}

.ResultTariffListItemBullets-labelComment {
  font-size: 13px;
  line-height: 16px;
  padding-left: 6px;
  margin-top: 1px;
}

.ResultTariffListItemBullets-crkLabel {
  font-size: 13px;
  margin-bottom: 5px;
}
