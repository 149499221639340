@import '../../../styles/variables/colors.less';

.TariffListOverlay {
  position: absolute;
  top: 74px;
  left: 0;
  height: 100%;
  width: 100%;

  &.is-tabletApp {
    top: 0px;
  }

  &.is-visiblyHidden {
    visibility: hidden;
  }
}

.TariffListOverlay-wrapper {
  position: fixed;
  z-index: 999;
  background-color: white;
  padding: 20px 37px 20px 30px;
  left: 53%;
  top: 50%;
  border-radius: 3px;
  transform: translate(-50%, -50%);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}

.TariffListOverlay-container {
  position: relative;
}

.TariffListOverlay-blocker {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: black;
}

.TariffListOverlay-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: -2px;
  top: -2px;
  cursor: pointer;
  fill: @c24-color-grey;
}

.TariffListOverlay-missingTariffContainer {
  font-size: 14px;
  max-width: 480px;
  color: @c24-color-black;
}

.TariffListOverlay-missingTariffFooter {
  display: flex;
  flex-direction: column;
}

.TariffListOverlay-infoIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  fill: @c24-color-vivid-orange;
}

.TariffListOverlay-infoBox {
  display: flex;
}

.TariffListOverlay-missingTariffCta {
  width: 300px;
  height: 49px;
  align-self: center;
  margin-top: 23px;
}
