.OnboardingSofortPage {
  display: flex;
  margin-bottom: 16px;
  margin-top: 10px;
}

.OnboardingSofortPage-formContainer {
  border-radius: 5px;
  box-shadow: @c24-section-box-shadow;
  width: @c24-input-form;
  background-color: @c24-color-white;
}

.OnboardingSofortPage-additionalInformation {
  display: flex;
  width: 780px;
  justify-content: space-between;
}

.OnboardingSofortPage-goToResultsButton {
  width: 235px;
  height: 40px;
  font-size: 14px;
  margin-left: auto;
  margin-bottom: 12px;
}

.OnboardingSofortPage-link {
  max-width: 322px;
}
