.PaymentChips {
  margin-top: 10px;
  width: max-content;
}

.PaymentChips-tooltip {
  top: 11px;
  .Tooltip {
    width: 227px;
    font-size: 11px;
    color: @c24-color-black;
  }

  &.Tooltip-Root {
    position: static;
    align-self: auto;
  }
}

.PaymentChips-infoIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-smoke-grey;
  margin-left: 3px;
  margin-top: 3px;
  cursor: pointer;
  &.is-selected {
    fill: @c24-color-dark-green;
  }
}

.PaymentChips-tooltipTitle {
  font-weight: bold;
}

.PaymentChips-recommendation {
  border: @c24-border-gainsboro-grey;
  border-radius: 10px;
  padding: 8px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;

  &.is-selected {
    border-color: @c24-color-dark-green;
    background-color: @c24-color-dark-green-alpha-15;
  }
}
