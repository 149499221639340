.ComparisonButtons-tooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}

.ComparisonButtons-tooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: @c24-color-checked;
  position: relative;
  top: 4px;
}

.ComparisonPage-detailsSingleButton {
  border-bottom: @c24-border-default;
  text-align: center;
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  align-items: center;
  flex: 1;

  &.is-centered {
    justify-content: center;
  }

  &.is-footer {
    padding: 16px 16px 16px;
    border-bottom: none;
  }

  &.no-border {
    border-bottom: none;
  }

  &.is-large {
    min-height: 110px;
  }
}
