.OnboardingChildrenSection-childrenChips {
  .InputChips {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 0px;
    margin-bottom: 0px;

    .InputChip {
      width: 90px;
      height: 49px;
      min-width: 64px;
      text-align: center;
      @media @c24-tablet-wide, @narrow-desktop {
        margin-bottom: 10px;
      }
      @media @c24-wide {
        margin-bottom: 0px;
      }
      margin-right: 0px;
      margin-left: 10px;
      padding: 15px 26px;
      border-radius: 25px;

      &:first-of-type {
        margin-left: 0px;
      }
    }
  }
}

.OnboardingChildrenSection-phoneSvg {
  fill: @c24-color-icon-default;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: 5px;
  vertical-align: text-bottom;
}

.OnboardingChildrenSection-childrenList {
  & .OnboardingSection-row {
    margin-top: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.OnboardingChildrenSection-booster {
  .BoosterMessage-text {
    font-size: 12px;
  }
}
