.OnboardingInvestmentSelection-investmentSelectionOptions {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.OnboardingInvestmentSelection-investmentSelectionOption {
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  border: @c24-border-gainsboro-grey;
  transition: border-color 0.3s;
  will-change: border-color;
  cursor: pointer;

  &:hover {
    border: @c24-border-input-hover;
  }

  &.is-selected {
    border: @c24-border-dark-blue;
  }
}

.OnboardingInvestmentSelection-investmentSelectionOptionBadge {
  width: max-content;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 10px;
  color: @c24-color-malaga-grey;
  background-color: @c24-color-malaga-grey-alpha-15;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.is-recommended {
    color: @c24-color-dark-green;
    background-color: @c24-color-dark-green-alpha-15;
  }

  .Tooltip-Root {
    width: 18px;
    height: 20px;
  }
}

.OnboardingInvestmentSelection-investmentSelectionOptionBadgeIcon {
  margin-left: 5px;
  width: 13px;
  height: 13px;
  fill: @c24-color-dark-green;
}

.OnboardingInvestmentSelection-investmentSelectionOptionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.OnboardingInvestmentSelection-investmentSelectionOptionTooltip {
  margin-top: 4px;
  cursor: help;

  .Tooltip-headline {
    margin-bottom: 8px;
  }
}

.OnboardingInvestmentSelection-questionMarkIcon {
  width: 20px;
  height: 20px;
  fill: @c24-color-nobel-grey;
}

.OnboardingInvestmentSelection-investmentSelectionOptionRadio {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .RadioInput-optionLabel {
    font-size: 18px;
  }
}

.OnboardingInvestmentSelection-shieldSvg {
  height: 23px;
  width: 21px;
  margin-left: 10px;
  > path {
    fill: @c24-color-black;
  }
}

.OnboardingInvestmentSelection-chartLineSvg {
  height: 22px;
  width: 23px;
  margin-left: 10px;
  > path {
    fill: @c24-color-black;
  }
}

.OnboardingInvestmentSelection-divider {
  height: 1px;
  width: 100%;
  background-color: @c24-color-gainsboro-grey;
  transition: background-color 0.3s;
  will-change: background-color;

  &.is-selected {
    background-color: @c24-color-dark-blue;
  }
}

.OnboardingInvestmentSelection-hintContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;

  .highlight {
    white-space: nowrap;
    font-weight: bold;
  }

  &.is-recommended {
    .highlight {
      color: @c24-color-dark-green;
    }
  }
}

.OnboardingInvestmentSelection-hint {
  display: flex;
  flex-direction: row;
}

.OnboardingInvestmentSelection-hintCheckSvgWrapper {
  margin-top: 5px;
}

.OnboardingInvestmentSelection-hintCheckSvg {
  width: 19px;
  height: 15px;
  fill: @c24-color-dark-green;
}

.OnboardingInvestmentSelection-hintText {
  margin-left: 10px;
  font-size: 14px;
  color: @c24-color-black;
}
