@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';

.RadioChipsGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.RadioChipsGroup-label {
  display: flex;
  align-self: center;
  color: @c24-color-input;
  font-size: 15px;
  width: @c24-form-label;
  padding-right: 2px;
}

.RadioChipsGroup-container {
  display: flex;
  border-radius: 5px;
}

.RadioChipsGroup-chip {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  margin-right: 16px;

  font-size: 14px;
  font-weight: normal;
  color: @c24-color-input;
  border: @c24-border-redesign-input;
  border-radius: 25px;
  padding: 15px 32px 15px 32px;
  transition: border-color 0.3s;
  box-shadow: none;
  -webkit-appearance: none;
  will-change: border-color;

  label {
    cursor: pointer;
  }

  &:hover {
    border: @c24-border-input-hover;
  }

  &:focus,
  &:focus-within,
  &:active,
  &:focus:hover {
    border: @c24-border-input-focus;
  }

  &.is-error,
  &.is-error:focus,
  &.is-error:active,
  &.is-error:hover,
  &.is-error:focus:hover {
    border: @c24-border-error;
  }

  &.is-selected {
    border: @c24-border-dark-blue;
    background-color: @c24-color-light-blue;
  }

  &.is-disabled {
    background-color: @c24-color-disabled-background;
  }

  input {
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    top: 0px;
    left: 0px;
    margin: 0px;
    padding: 0px;
    z-index: 1;
  }
}

.RadioChipsGroup-icon {
  border: 0;
  margin-right: 0;
  margin-left: 6px;
}
