.AppointmentBookingConsultantInfo-container {
  width: 548px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px 48px 24px 48px;
  border-radius: 3px;
  box-shadow: @c24-section-box-shadow;
  background-color: @c24-color-white;
}

.AppointmentBookingConsultantInfo-headline {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 16px;
}
