@import '../../styles/variables/colors';
@import '../../styles/variables/borders';
@import '../../styles/variables/component-sizes';

@form-width: 780px;

.PersonalDataForm-headline {
  color: @c24-color-headline;
  font-size: 23px;
  margin: 0px @c24-form-side-gap @c24-form-row-gap;
  font-weight: 400;

  @media @c24-tablet-l-wide {
    margin-left: 24px;
  }
}

.PersonalDataForm-formContainer {
  background-color: @c24-color-white;
  border-radius: 3px;
  box-shadow: @c24-section-box-shadow;
  width: @c24-form-width;
  margin-bottom: 16px;
  padding-top: @c24-form-header-gap;
  height: max-content;

  &.is-tabletApp {
    width: 100%;
  }
}

.PersonalDataForm-section {
  margin-top: 20px;
  border-bottom: @c24-border-section;
}

.PersonalDataForm-ctaSection {
  margin: 0 @c24-form-side-gap @c24-form-page-end-gap;
  display: flex;
}

.PersonalDataForm-inputContainer {
  align-self: flex-start;
  flex: 1;
  min-width: 100px;
}

.PersonalDataForm-backLink {
  font-family: Arial;
  color: @c24-color-dark-blue;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: '\2039';
    display: inline-block;
    margin-right: 5px;
    font-size: 24px;
    font-weight: 400;
    vertical-align: -9%;
    margin-top: -10px;
    margin-bottom: -10px;
  }
}

.PersonalDataForm-trustElementsContainer {
  display: flex;
  margin-bottom: 5px;
  margin-left: 25px;
  font-size: 13px;
}

.PersonalDataForm-checkSvg {
  margin-top: 2px;
  margin-right: 12px;
  width: 16px;
  height: 12px;
  fill: @c24-color-checked;
}

.PersonalDataForm-inquiryButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.PersonalDataForm-inquiryButton {
  width: 240px;
}

.PersonalDataForm-hint {
  color: @c24-color-redesign-button-hint;
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.PersonalDataForm-checkIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  align-items: center;
  fill: @c24-color-redesign-checked;
}

.PersonalDataForm-errorSection {
  color: @c24-color-red;
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
}

.PersonalDataForm-sectionInfo {
  width: 214px;
  position: relative;
}
