.NoTariffsFound {
  padding: 15px;
  border: 1px solid @c24-color-dark-grey;
  background-color: #ffffff;
}

.NoTariffsFound-headline {
  color: @c24-color-headline;
  font-size: 16px;
  font-weight: bold;
}

.NoTariffsFound-text {
  margin-top: 10px;
  font-size: 13px;
  color: @c24-color-noTariffsFound-text;
}
