.TariffFilter {
  position: relative;
  display: block;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.TariffFilter-content {
  display: flex;
  flex-direction: column;
}

.TariffFilter-buttons {
  justify-content: space-between;
  padding: 8px;
  margin: 0;
  height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: @c24-border-divider-grey;
  z-index: 0;

  &:first-child {
    position: static;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:nth-child(2) {
    position: static;
    margin-top: -1px;
  }

  &:last-child {
    position: static;
    margin-top: -1px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    border: @c24-border-dark-blue;
    z-index: 1;
  }

  &.is-selected {
    background-color: @c24-color-light-blue;
    border: @c24-border-dark-blue;
    z-index: 1;
  }
}

.TariffFilter-maxPossiblePension {
  font-size: 11px;
  color: @c24-color-grey;
}
