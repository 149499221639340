.GradeFeatureList {
  width: 450px;
  transition-duration: 500ms;

  &.is-expanded {
    width: 650px;

    .GradeFeatureList-listItemHead {
      cursor: pointer;
    }
  }
}

.GradeFeatureList-header {
  padding: 15px;
  background-color: @c24-background-grade-features;
}

.GradeFeatureList-content {
  display: block;
}

.GradeFeatureList-headerTitle {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.GradeFeatureList-headerContent {
  display: flex;
  align-items: center;
}

.GradeFeatureList-contentText {
  width: 76%;
}

.GradeFeatureList-points {
  width: 24%;
  text-align: right;
  margin-right: 22px;
  font-weight: bold;

  &.is-good span {
    color: @c24-background-grade-good;
  }

  &.is-veryGood span {
    color: @c24-background-grade-very-good;
  }

  &.is-bad span {
    color: @c24-background-grade-bad;
  }
}

.GradeFeatureList-content {
  background-color: @c24-background-grade-feature-list;
}

.GradeFeatureList-listItemHead {
  position: relative;
  padding: 9px 0;
  display: flex;
  align-items: center;
}

.GradeFeatureList-listItem {
  margin: 0 15px;
  border-bottom: @c24-border-default;

  &.is-last {
    border-bottom: none;

    .GradeFeatureList-listItemHead {
      cursor: initial !important;
    }
  }

  &.is-expanded {
    .GradeFeatureList-categoryArrow {
      transform: rotate(180deg);
    }
  }
}

.GradeFeatureList-featureName {
  font-weight: bold;
}

.GradeFeatureList-featureItem {
  display: flex;
  margin-bottom: 10px;
}

.GradeFeatureList-featureValuesCont {
  display: flex;
  flex-grow: 1;
  text-align: right;
  justify-content: flex-end;
  width: 65px;
  white-space: nowrap;
}

.GradeFeatureList-featureFormattedValue {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
}

.GradeFeatureList-featureValues {
  display: flex;
  margin-right: 22px;
  width: 135px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.GradeFeatureList-listItemDetails {
  transition-duration: 500ms;
  max-height: 0;
  overflow: hidden;
}

.GradeFeatureList-featureDescription {
  flex: 1;
}

.GradeFeatureList-title {
  font-weight: 700;
  width: 80%;
  font-size: 14px;

  &.is-normalText {
    font-weight: normal;
    font-size: 12px;
  }
}

.GradeFeatureList-footer {
  display: flex;
  justify-content: center;
  padding: 16px 10px 16px;
  background-color: @c24-background-grade-features;
}

.GradeFeatureList-gradesExplanation {
  padding-bottom: 10px;
  background-color: @c24-background-grade-features;
}

.GradeFeatureList-footerItem {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.GradeFeatureList-footerColor {
  width: 19px;
  height: 15px;

  &--green {
    background-color: @c24-background-grade-very-good;
  }

  &--yellow {
    background-color: @c24-background-grade-good;
  }

  &--red {
    background-color: @c24-background-grade-bad;
  }
}

.GradeFeatureList-footerText {
  margin-left: 5px;
}

.GradeFeatureList-tooltip {
  .Tooltip {
    width: auto;
    border: 1px solid #ccc;
    cursor: default;
    padding: 0;
    background: transparent;
    line-height: inherit;
  }

  .Tooltip-connector {
    max-height: 120%;
  }

  .Tooltip-arrow {
    background: @c24-color-background-grey;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }
}

.GradeFeatureList-expandButton {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: @c24-color-dark-blue;
  user-select: none;
  cursor: pointer;

  &.is-expanded {
    svg {
      transform: rotate(90deg);
    }
  }

  svg {
    width: 14px;
    height: 8px;
    transform: rotate(270deg);
    margin-left: 6px;
    transition-duration: 500ms;

    * {
      fill: @c24-color-dark-blue;
    }
  }
}

.GradeFeatureList-categoryArrow {
  width: 18px;
  height: 8px;
  position: absolute;
  right: 0;
  transition-duration: 500ms;

  * {
    fill: @c24-color-dark-blue;
  }
}

.GradeFeatureList-legendContainer {
  display: flex;
  justify-content: center;
}

.GradeFeatureList-legendItem {
  display: flex;
  align-items: center;
  margin: 7px;
  margin-bottom: 20px;
}

.GradeFeatureList-legendIcon {
  height: 18px;
  width: 18px;
  margin-right: 5px;
}
