.ResultLeftSideConsultantBox-input {
  position: relative;
  display: block;
  margin-bottom: 8px;
}

.ResultLeftSideConsultantBox-tooltipIcon {
  width: 16px;
  height: 16px;
  fill: @c24-color-question-mark;
  transition-duration: 200ms;
}

.ResultLeftSideConsultantBox-tooltipIcon * {
  fill: @c24-color-question-mark;
}

.hover {
  .ResultLeftSideConsultantBox-tooltipIcon {
    fill: @c24-color-icon-active;
  }

  .ResultLeftSideConsultantBox-tooltipIcon * {
    fill: @c24-color-icon-active;
  }
}

.ResultLeftSideConsultantBox-tooltip {
  position: absolute;
  top: 12px;
  right: 0;
  cursor: help;
}
