.OnboardingContributionBooster {
  position: relative;
  display: flex;
  background: @c24-color-dark-green-alpha-15;
  color: @c24-color-dark-green;
  font-size: 14px;
  float: right;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  margin-top: 25px;
  margin-right: 45px;
}

.OnboardingContributionBooster-wrapper {
  margin-bottom: 10px;
}

.OnboardingContributionBooster-thumbSvg {
  width: 16px;
  fill: @c24-color-dark-green;
  margin-right: 5px;
}
