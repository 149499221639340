@import '../../styles/variables/colors';
@import '../../styles/variables/borders';

@max-width-insurance-logo-column: 170px;

.ResultTariffListItem {
  padding: 7px 9px 9px 9px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;

  background-color: @c24-color-white;

  &.is-extraResultZero {
    background-color: @c24-result-zero-tariff-background;
  }
}

.ResultTariffListItem-container {
  border-radius: 3px;
  box-shadow: @c24-section-box-shadow;
  padding: 1px;
  background-color: @c24-color-white;

  &.color-gold {
    border: 1px solid @c24-color-gold;
  }

  &.color-blue {
    border: 1px solid @c24-color-catalina-blue;
  }

  &.color-green {
    border: 1px solid @c24-color-dark-green;
  }

  &.color-orange {
    border: 1px solid @c24-color-vivid-orange;
  }

  &:hover {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }

  &:not(.color-blue):not(.color-gold):not(.color-green):not(.color-orange) {
    border-color: @c24-color-grey;
  }
}

.ResultTariffListItem-itemContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.ResultTariffListItem-left {
  flex: 1;
  flex-direction: column;
  display: flex;
}

.ResultTariffListItem-right {
  width: 21%;
}

.ResultTariffListItem-leftContainer {
  display: flex;
  flex-grow: 1;
  gap: 10px;
}

.ResultTariffListItem-priceContainer {
  border-left: @c24-border-result-page;
  height: 100%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  .WishListHeart-heartContainer {
    margin-top: -2px;
  }
}

.ResultTariffListItem-price {
  line-height: 18px;
  text-align: right;
  margin-bottom: 5px;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    right: 12px;
  }

  .Tooltip {
    text-align: start;
  }
}

.ResultTariffListItem-priceInterval {
  font-size: 13px;
  font-weight: normal;
  float: right;
  color: @c24-color-black;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    right: 12px;
  }
}

.ResultTariffListItem-insuranceContainer {
  margin-left: 5px;

  @media @narrow-desktop {
    margin-left: 0px;
  }

  @media @c24-tablet-wide {
    margin-left: 0px;
  }
}

.ResultTariffListItem-priceToolTip {
  position: relative;
}

.ResultTariffListItem-insuranceLogo {
  display: block;
  max-height: 50px;
  max-width: @max-width-insurance-logo-column;
  margin-right: 20px;

  @media @c24-tablet-wide, @narrow-desktop {
    max-width: 160px;
  }

  @media @desktop {
    max-height: 55px;
  }

  @media @c24-wide {
    max-height: 60px;
  }
}

.ResultTariffListItem-tariffName {
  max-width: @max-width-insurance-logo-column;
  margin-top: 10px;
  padding-bottom: 10px;
}

.ResultTariffListItem-gradeContainer {
  margin-right: 75px;

  @media @narrow-desktop {
    margin-right: 60px;
  }

  @media @c24-tablet-wide {
    margin-right: 60px;
  }
}

.ResultTariffListItem-actionButton {
  padding: 5px;
  height: 40px;

  @media @c24-wide {
    font-size: 15px;
  }
}

.ResultTariffListItem-cta {
  width: 100%;
  display: flex;
  margin-top: 5px;
}

.ResultTariffListItem-ctaContainer {
  display: flex;
  width: 20%;
  justify-content: flex-end;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ResultTariffListItem-ctaContainer {
    width: 22%;
  }
}

@-moz-document url-prefix() {
  .ResultTariffListItem-tabArrow {
    left: 95px;

    @media @c24-wide {
      left: 105px;
    }
  }
}

.ResultTariffListItem-debugInformationContainer {
  padding: 5px;
}

.ResultTariffListItem-possiblePrice {
  font-size: 22px;
  font-weight: bold;
  color: @c24-color-price;
  margin-bottom: 6px;
  white-space: nowrap;
}

.ResultTariffListItem-guaranteedPrice {
  font-size: 16px;
  font-weight: bold;
  color: @c24-color-black;
  margin-top: 7px;
  margin-bottom: 2px;
}

.ResultTariffListItem-priceDescription {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ResultTariffListItem-priceText {
  margin-right: 5px;
  font-size: 11px;
  line-height: 16px;
  &.ResultTariffListItem-possiblePriceText {
    color: @c24-color-dark-green;
  }
}

.ResultTariffListItem-infoSvgIcon {
  height: 12px;
  width: 12px;
  margin-right: 2px;
  pointer-events: auto;
  cursor: help;
  fill: @c24-color-nobel-grey;
  &.ResultTariffListItem-possiblePensionInfoSvgIcon > * {
    fill: @c24-color-dark-green;
  }
}

.ResultTariffListItem-priceToolTip {
  position: relative;
}

.ResultTariffListItem-rentenbezugsInfoSvgIcon {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-top: 2px;
  pointer-events: auto;
  cursor: help;
  fill: @c24-color-nobel-grey;
}

.ResultTariffListItem-rentenbezugsInfoText {
  width: 160px;
  text-align: left;
}

.ResultTariffListItem-rentenbezugsformInfo {
  display: flex;
  margin-top: 12px;
}

.ResultTariffListItem-tariffSelectionCheckbox {
  padding: 8px 0px;
}

.ResultTariffListItem-expandPanel {
  box-shadow: @c24-section-box-shadow;
}

.ResultTariffListItem-topRow {
  display: flex;
  align-items: center;
}

.ResultTariffListItem-resultZeroHeader {
  margin-right: 9px;
}

.ResultTariffListItem-promotionHeader {
  margin-right: 9px;
}

.ResultTariffListItem-heartContainer {
  margin-left: auto;
}

.ResultTariffListItem-leftContainerColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: -10px;

  &:first-child {
    width: 33%;

    @media @c24-tablet-wide, @narrow-desktop {
      max-width: @max-width-insurance-logo-column;
    }
  }
}
