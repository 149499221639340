@import '../../../styles/variables/colors';
@import '../../../styles/variables/borders';

.RadioGroup {
  width: 100%;
}

.RadioGroup-label {
  font-size: 16px;
  margin-bottom: 16px;
}

.RadioGroup-boxContainer {
  display: flex;
  width: 100%;
  min-height: 47px;
  font-size: 14px;
  border-radius: 5px;
  color: @c24-color-input;
  border: @c24-border-redesign-input;
  transition: border-color 0.3s;
  will-change: border-color;

  &.is-vertical {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0px;
    padding-left: 24px;
    padding-right: 24px;
  }

  label {
    cursor: pointer;
  }

  &:hover {
    border: @c24-border-radio-hover;
  }

  &.is-error,
  &.is-error:focus,
  &.is-error:active,
  &.is-error:hover,
  &.is-error:focus:hover {
    border: @c24-border-error;
  }

  &:focus {
    outline: 0 !important;
  }
}

.RadioGroup-separator {
  background-color: @c24-color-divider-grey;
  margin: 8px 0;
  flex: 0 0 1px;

  &.is-vertical {
    margin: 0;
  }
}

.RadioGroup-button {
  cursor: pointer;
  user-select: none;
  display: flex;
  padding: 5px;
  align-items: center;
  position: relative;
  width: 100%;

  &.is-vertical {
    padding: 14px 0px 14px 0px;
  }

  &.is-vertical:last-child {
    padding: 14px 0px 14px 0px;
  }

  &:focus,
  &:focus-within {
    border: @c24-border-input-focus;
    border-radius: 5px;
  }

  &.is-disabled {
    pointer-events: none;
    color: @c24-color-divider-grey;
  }

  .RadioGroup-optionInput {
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    top: 0px;
    left: 0px;
    margin: 0px;
    padding: 0px;
    z-index: 1;
  }
}

.RadioGroup-optionLabelDiv {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 6px;
  height: 100%;
  justify-content: space-between;

  &.is-vertical {
    margin-left: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
  }
}

.RadioGroup-optionLabel {
  font-size: 14px;

  &.is-vertical:before {
    left: 0px;
    top: 14px;
  }

  &.is-vertical:after {
    left: 6px;
    top: 20px;
  }

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: @c24-border-input;
    left: 24px;
    top: 12px;
    transition: 0.3s border-color;
    border-color: @c24-color-nobel-grey;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;

    width: 8px;
    height: 8px;
    border-radius: 4px;
    top: 18px;
    left: 30px;
    background-color: @c24-color-dark-blue;
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: center center;
    transition:
      opacity 0.3s,
      transform 0.3s cubic-bezier(0.5, -0.8, 0.5, 1.8);
  }

  &.is-selected:after {
    opacity: 1;
    transform: scale(1, 1);
  }

  &.is-selected:before {
    border-color: @c24-color-dark-blue;
  }

  &.is-error:before {
    border-color: @c24-color-is-error;
  }

  &.is-error:after {
    border-color: @c24-color-is-error;
  }
}

.RadioGroup-boldLabel {
  font-weight: 700;
}

.RadioGroup-icon {
  border: 0;
  margin-right: 0;
  margin-left: 6px;
}
