.OnboardingContribution {
  display: flex;
  margin-bottom: 16px;
}

.OnboardingContribution-formContainer {
  width: @c24-input-form;
  background-color: @c24-color-white;
  border-radius: 5px;
  box-shadow: @c24-section-box-shadow;
}

.OnboardingContribution-ctaContainer {
  display: flex;
  justify-content: space-between;
  margin: 32px 48px;
  align-items: center;
}

.OnboardingContribution-backButton {
  width: 190px;
  font-size: 13px;
  font-weight: bold;
}

.OnboardingContribution-goToResultsButton {
  width: 200px;
  height: 40px;
  font-size: 14px;
  margin-left: auto;
}
