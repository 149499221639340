@import '../styles/variables/colors';
@import '../styles/variables/borders';
@import '../styles/variables/backgrounds';

.TariffDetailsPage {
  .Page;
}

.TariffDetailsPage-tab {
  background-color: @c24-color-tariff-details-tab-background;
  padding: 12px 0;
  width: 200px;
  margin-right: 4px;
  text-align: center;
  color: @c24-color-tab;
  cursor: pointer;
}

.TariffDetailsPage-selectedTab {
  color: @c24-color-tariff-details-selected-tab;
  background-color: @c24-color-tariff-details-selected-tab-background;
  border: @c24-border-tab;
  border-bottom: none;
  bottom: -1px;
  position: relative;
}

.TariffDetailsPage-selectedTabPanel {
  border-left: @c24-border-tab;
  border-right: @c24-border-tab;
  padding: 22px 25px 5px 25px;
}

.TariffDetailsPage-tabList {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: @c24-border-tab;
}

.TariffDetailsPage-inputContainer {
  border: @c24-border-input-container;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 35px 20px 25px 25px;
}

.TariffDetailsPage-actionButton {
  width: 200px;
}

.TariffDetailsPage-backLink {
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
}

.TariffDetailsPage-checkIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: @c24-color-checked;
}

.TariffDetailsPage-actionButtonHint {
  color: @c24-color-button-hint;
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.TariffDetailsPage-hint {
  font-size: 9px;
  display: block;
  margin-top: 10px;
}

.TariffDetailsPage-legendContainer {
  display: flex;
  justify-content: center;
}

.TariffDetailsPage-comparisonLink {
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  right: 20px;
}

.TariffDetailsPage-legendItem {
  display: flex;
  align-items: center;
  margin: 10px;
  margin-bottom: 20px;
}

.TariffDetailsPage-legendIcon {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.TariffDetailsPage-linkContainer {
  position: relative;
  height: 50px;
}

.TariffDetailsPage-backLinkContainer {
  flex: 1;
}

.TariffDetailsPage-processOverview {
  border: 1px solid @c24-border-tab;
  margin-top: 20px;
}

.TariffDetailsPage-categoriesHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 20px;
  margin-bottom: 20px;
}

.TariffDetailsPage-categoriesTitle {
  font-size: 18px;
  font-weight: bold;
}

.TariffDetailsPage-inquiryButtonTooltipContainer {
  text-align: left;
  font-weight: normal;
  font-family: Verdana;
}

.TariffDetailsPage-inquiryButtonTooltipCheckIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  fill: @c24-color-checked;
  position: relative;
  top: 4px;
}
