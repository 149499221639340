.ErrorMessage {
  max-width: 994px;
  width: 100%;
  height: ~'calc(100vh - 240px)';
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ErrorMessage-header {
  font-size: 30px;
  color: @c24-color-malaga-grey;
  margin-bottom: 0px;
}

.ErrorMessage-text {
  font-size: 15px;
  color: @c24-color-malaga-grey;
}

.ErrorMessage-image {
  width: 752px;
}
