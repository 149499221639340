.TariffSummaryTariffDetails-header {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

.TariffSummaryTariffDetails-row {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.TariffSummaryTariffDetails-logoNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-right: 20px;
}

.TariffSummaryTariffDetails-insuranceLogo {
  max-height: 30px;
  align-self: flex-start;
}

.TariffSummaryTariffDetails-name {
  margin: 10px 0 0;
}

.TariffSummaryTariffDetails-grade {
  &.Grade {
    height: 78px;
    width: auto;
  }

  .Grade-icon {
    height: 78px;
    width: 67px;
    background-size: 67px 78px;
  }

  .Grade-calculatedValue {
    top: 25px;
    font-size: 24px;
  }

  .Grade-subText {
    top: 17px;
    font-size: 9px;
  }

  .Grade-gradeText {
    top: 60px;
    font-size: 10px;

    &.is-bold {
      font-weight: 700;
    }
  }
}
