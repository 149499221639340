@import '../../../styles/variables/colors';

.TuevLogo {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
}

.TuevLogo-svg {
  width: 102px;
  height: 55px;
  cursor: pointer;
  pointer-events: auto;
}

.TuevLogo-tooltipContent {
  color: @c24-color-popup-content;
}

.TuevLogo-tooltipHeadline {
  font-size: 14px;
  font-weight: bold;
}

.TuevLogo-tooltipSubHeadline {
  font-weight: bold;
  color: @c24-color-headline;
}

.TuevLogo-tooltipAddressHeadline {
  margin: 8px 0;
}

.TuevLogo-header {
  font-weight: 700;
  color: @c24-color-black;
  margin-bottom: 15px;
}

.TuevLogo-tooltip .Tooltip {
  width: 475px;
}
