.PointsBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 100%;
  margin-top: 0px;
  font-family: Verdana;
  font-size: 11px;
  font-weight: bold;
  background-color: @c24-gratification-bar-background;
  color: @c24-gratification-bar-foreground;
}

.PointsBar-wrapper {
  display: flex;
  width: 100%;
  margin-left: 50px;

  @media @c24-tablet-l-wide {
    margin-left: 15px;
  }
}

.PointsBar-pointsIcon {
  flex-shrink: 0;
  width: 17px;
  height: 12px;
  fill: @c24-gratification-bar-foreground;
  margin-top: 2px;
  margin-right: 10px;
}

.PointsBar-text {
  margin-right: 10px;
}

.PointsBar-questionIcon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  fill: @c24-gratification-bar-foreground;

  &.is-mobile {
    cursor: pointer;
  }
}

.PointsBar-tooltip {
  padding: 0px !important;
  pointer-events: auto !important;
}

.PointsBar-tooltipText {
  line-height: 1.3;
}
