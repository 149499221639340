@import '../../styles/variables/colors';

.ComparisonStickyHeader {
  background: white;
  position: absolute;
  width: 100%;

  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.3s ease;
}

.ComparisonPage-stickyContainer {
  .sticky {
    background: white;
    z-index: 1500;

    .ComparisonStickyHeader {
      visibility: visible;
      opacity: 1;

      display: flex;
      border-right: @c24-border-default;
      border-left: @c24-border-default;
      box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }
}

.ComparisonStickyHeader-insuranceLogo {
  max-width: 200px;
  height: 50px;
  margin-bottom: 11px;
  margin-top: 5px;
}

.ComparisonStickyHeader-priceContainer {
  text-align: right;
  min-width: 100px;
  line-height: 15px;
}

.ComparisonStickyHeader-insuranceNameAndPrice {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  height: 35px;
}

.ComparisonStickyHeader-insuranceName {
  color: @c24-color-insurance-name-text;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.ComparisonStickyHeader-tariffBlockDetails {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ComparisonStickyHeader-tariffBlock {
  .ComparisonSelectedTariffsHeader-tariffBlock;

  padding: 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-of-type {
    border-right: none;
  }

  &.is-empty {
    padding: 11px 10px;
    display: flex;
    align-items: center;
    pointer-events: auto;
    flex-direction: column;
    justify-content: center;
  }

  .ComparisonPage-detailsButton {
    border-bottom: none;
  }
}

.ComparisonStickyHeader-resultLinkContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  background-color: @c24-color-background-dark-grey;
  padding: 10px 10px;
}

.ComparisonStickyHeader-addTariff {
  width: 52px;
  height: 52px;
  fill: @c24-color-icon-default;

  &:hover {
    fill: @c24-color-button-hover;
    cursor: pointer;
  }
}

.ComparisonStickyHeader-add {
  .ComparisonSelectedTariffsInfoButton-add;

  padding: 0;
  margin-top: 5px;
  text-align: center;
}

.ComparisonStickyHeader-labels {
  margin-top: 7px;
  min-height: 24px;
}

.ComparisonStickyHeader-resultZeroHeader {
  padding-right: 5px;
  padding-left: 5px;
}

.ComparisonStickyHeader-labelSlot {
  min-height: 24px;
  margin-bottom: 5px;
}

.ComparisonStickyHeader-tariffPromotionHeader {
  padding-right: 5px;
  padding-left: 5px;
}

.ComparisonStickyHeader-close {
  position: absolute;
  right: 16px;
  top: 14px;
  pointer-events: auto;
  width: 14px;
  height: 14px;
  fill: @c24-color-close;
  cursor: pointer;
}
