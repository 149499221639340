.ShareCurrentPageModal-header {
  font-size: 18px;
  display: flex;
  margin-bottom: 30px;
}

.ShareCurrentPageModal-buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ShareCurrentPageModal-button {
  font-size: 14px;
  color: @c24-color-dark-blue;
  border: 1px solid @c24-color-dark-blue;
  border-radius: 3px;
  width: 275px;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background: @c24-color-light-blue;
    color: @c24-color-dark-blue;
    text-decoration: none;
  }
}

.ShareCurrentPageModal-emailIcon {
  width: 40px;
  height: 30px;
  margin-right: 15px;
  fill: @c24-color-dark-blue;
}

.ShareCurrentPageModal-copyIcon {
  width: 30px;
  height: 30px;
  margin-right: 25px;
  fill: @c24-color-dark-blue;
}
