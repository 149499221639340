@import '../../styles/variables/backgrounds';
@import '../../styles/variables/colors';

.ComparisonSelectedTariffsFeatures {
  width: 100%;
  margin: 20px 0;
}

.ComparisonSelectedTariffsFeatures-filterContainer {
  display: flex;
  margin-left: 5px;
  margin-top: 50px;
}

.ComparisonSelectedTariffsFeatures-filterOptions {
  margin-top: -10px;
  margin-left: 10px;
  padding: 10px 0;
  border-radius: 30px;
  background-color: @c24-color-background-dark-grey;
}

.ComparisonSelectedTariffsFeatures-option {
  color: @c24-color-comparison-feature-filter-text;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;

  &.is-selected {
    color: @c24-color-comparison-feature-selected-filter-text;
    background-color: @c24-color-comparison-feature-selected-filter-background;
  }
}

.ComparisonSelectedTariffsFeatures-categories {
  margin: 15px 0;
}

.ComparisonSelectedTariffsFeatures-categoryTitle {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 0;
  border-bottom: @c24-border-comparison-features;
  color: @c24-color-black;

  @media @c24-s-wide {
    font-size: 18px;
  }
}

.ComparisonSelectedTariffsFeatures-selectedFilterText {
  display: none;
}
