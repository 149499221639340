.ResultZeroGrid {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
}

.ResultZeroGrid-container {
  width: 100%;
}

.ResultZeroGrid-content {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 9px;
  grid-auto-columns: 1fr;
}
