.DocumentsCategory-document {
  display: block;
  &:last-of-type {
    margin-bottom: 6px;
  }
}

.DocumentsCategory-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  position: relative;
  top: 4px;
}
