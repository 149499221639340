@import './variables/colors';

.Main {
  color: @c24-color-page-text;
  font-size: 12px;
  line-height: 1.428571429; // 20/14;
  text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  * {
    box-sizing: border-box;
  }

  &.has-grey-background {
    background-color: @c24-color-background-light-grey;
  }

  & > * {
    // #__next
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

button {
  color: inherit;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
  }
}
